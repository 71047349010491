import { LanguageCode } from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import React from 'react'

import PrepCheckActions from '../assets/icons/PrepCheckActions.svg'
import PrepCheckItems from '../assets/icons/PrepCheckItems.svg'
import PrepCheckContacts from '../assets/icons/PrepCheckContacts.svg'
import { Markdown } from '../components/atoms/Markdown'

export interface PrepCheckWelcomeTextConfig {
    title: string
    blurbChunk1: string
    yourGoalText: string
    blurbChunk2: string
    aspectDisplay: React.ReactNode
    blurbChunk3: string
    cta: string
}

interface AspectDisplayItem {
    index: number
    text: string
    imageSrc: string
    imageAlt?: string
}

const renderAspectDisplayItem = (
    item: AspectDisplayItem,
    index: number
): React.ReactNode => (
    <div
        style={{
            display: 'grid',
            gridTemplateColumns: '3rem 1.5rem 1fr',
            gap: '1rem',
            alignItems: 'center',
        }}
        key={`aspectDisplayItem_${index + 1}`}
    >
        <img
            src={item.imageSrc}
            style={{ width: '3rem', height: 'auto' }}
            alt={item.imageAlt}
        />
        <Typography
            variant="h1"
            fontWeight={500}
            fontSize="2.5rem"
            textAlign="center"
        >
            {item.index}
        </Typography>
        <Markdown content={item.text} />
    </div>
)

const renderAspectDisplay = (items: AspectDisplayItem[]): React.ReactNode => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        {items.map(renderAspectDisplayItem)}
    </div>
)

export const prepCheckWelcomeText: Map<
    LanguageCode,
    PrepCheckWelcomeTextConfig
> = new Map<LanguageCode, PrepCheckWelcomeTextConfig>([
    [
        LanguageCode.ENGLISH,
        {
            title: 'Welcome to Prep Checks!',
            blurbChunk1: `This is a self-assessment tool that helps you answer the question "How well would I cope with this hazard?"`,
            yourGoalText:
                'Your goal is to answer what is most true to you and your life.',
            blurbChunk2: `Prep Checks will help assess 3 main aspects of your coping capacity:`,
            aspectDisplay: renderAspectDisplay([
                {
                    index: 1,
                    text: `**Knowledge of resilience actions.** What would you do if it happened to you?`,
                    imageSrc: PrepCheckActions,
                    imageAlt: 'Person running',
                },
                {
                    index: 2,
                    text: `**Current access to resilience resources.** What items do you have around you to help?`,
                    imageSrc: PrepCheckItems,
                    imageAlt: 'First aid kit',
                },
                {
                    index: 3,
                    text: `**Trusted help network.** Who would you feel comfortable calling for help?`,
                    imageSrc: PrepCheckContacts,
                    imageAlt: 'Telephone',
                },
            ]),
            blurbChunk3: `**This is NOT a test to see if you know the RIGHT answer.**`,
            cta: 'Start Prep Checks',
        },
    ],
    [
        LanguageCode.FRENCH,
        {
            title: 'Bienvenue à Prep Checks!',
            blurbChunk1: `Il s’agit d’un outil d’auto-évaluation qui vous aide à répondre à la question « Dans quelle mesure pourrais-je faire face à ce danger? »`,
            yourGoalText:
                'Votre objectif est de répondre à ce qui est le plus vrai pour vous et votre vie.',
            blurbChunk2: `Les contrôles de préparation vous aideront à évaluer 3 aspects principaux de votre capacité d’adaptation :`,
            aspectDisplay: renderAspectDisplay([
                {
                    index: 1,
                    text: `**Connaissance des actions de résilience.** Que feriez-vous si cela vous arrivait?`,
                    imageSrc: PrepCheckActions,
                    imageAlt: 'Personne qui court',
                },
                {
                    index: 2,
                    text: `**Accès actuel aux ressources de résilience.** Quels articles avez-vous autour de vous pour aider?`,
                    imageSrc: PrepCheckItems,
                    imageAlt: 'Trousse de premiers soins',
                },
                {
                    index: 3,
                    text: `**Réseau d’aide de confiance.** Qui seriez-vous à l’aise d’appeler à l’aide?`,
                    imageSrc: PrepCheckContacts,
                    imageAlt: 'Téléphone',
                },
            ]),
            blurbChunk3: `**Ce n’est PAS un test pour voir si vous connaissez la BONNE réponse.**`,
            cta: 'Start Prep Checks',
        },
    ],
    [
        LanguageCode.GERMAN,
        {
            title: 'Willkommen bei Prep Checks!',
            blurbChunk1: `Dies ist ein Selbstbewertungsinstrument, das Ihnen hilft, die Frage "Wie gut würde ich mit dieser Gefahr umgehen?" zu beantworten.`,
            yourGoalText:
                'Ihr Ziel ist es, das zu beantworten, was für Sie und Ihr Leben am wahrsten ist.',
            blurbChunk2: `Prep Checks helfen Ihnen, 3 Hauptaspekte Ihrer Bewältigungskapazität zu bewerten:`,
            aspectDisplay: renderAspectDisplay([
                {
                    index: 1,
                    text: `**Wissen über Resilienzmaßnahmen.** Was würdest du tun, wenn es dir passieren würde?`,
                    imageSrc: PrepCheckActions,
                    imageAlt: 'Person, die läuft',
                },
                {
                    index: 2,
                    text: `**Aktueller Zugriff auf Resilienzressourcen.** Welche Gegenstände haben Sie um sich herum, um zu helfen?`,
                    imageSrc: PrepCheckItems,
                    imageAlt: 'Erste-Hilfe-Set',
                },
                {
                    index: 3,
                    text: `**Vertrauenswürdiges Hilfenetzwerk.** Wen würdest du gerne um Hilfe rufen?`,
                    imageSrc: PrepCheckContacts,
                    imageAlt: 'Telefon',
                },
            ]),
            blurbChunk3: `**Dies ist KEIN Test, um zu sehen, ob Sie die RICHTIGE Antwort kennen.**`,
            cta: 'Start Prep Checks',
        },
    ],
    [
        LanguageCode.JAPANESE,
        {
            title: 'プレップチェックへようこそ!',
            blurbChunk1: `これは、「この危険にどれだけうまく対処できるか」という質問に答えるのに役立つ自己評価ツールです。`,
            yourGoalText:
                'あなたの目標は、あなたとあなたの人生に最も真実であることに答えることです。',
            blurbChunk2: `準備チェックは、対処能力の3つの主要な側面を評価するのに役立ちます。`,
            aspectDisplay: renderAspectDisplay([
                {
                    index: 1,
                    text: `**レジリエンスアクションに関する知識。**それがあなたに起こったらどうしますか?`,
                    imageSrc: PrepCheckActions,
                    imageAlt: '走っている人',
                },
                {
                    index: 2,
                    text: `**レジリエンスリソースへの現在のアクセス。**あなたの周りにはどんなアイテムがありますか?`,
                    imageSrc: PrepCheckItems,
                    imageAlt: '応急処置キット',
                },
                {
                    index: 3,
                    text: `**信頼できるヘルプ ネットワーク。**だれに助けを求めるのが快適だと思いますか。`,
                    imageSrc: PrepCheckContacts,
                    imageAlt: '電話',
                },
            ]),
            blurbChunk3: `**これは、正しい答えを知っているかどうかを確認するためのテストではありません。**`,
            cta: 'Start Prep Checks',
        },
    ],
    [
        LanguageCode.MANDARIN,
        {
            title: '欢迎来到准备检查！',
            blurbChunk1: `这是一个自我评估工具，可帮助您回答“我将如何应对这种危害？`,
            yourGoalText: '你的目标是回答什么对你和你的生活最真实。',
            blurbChunk2: `准备检查将有助于评估您应对能力的 3 个主要方面：`,
            aspectDisplay: renderAspectDisplay([
                {
                    index: 1,
                    text: `**了解弹性行动。**如果它发生在你身上，你会怎么做？`,
                    imageSrc: PrepCheckActions,
                    imageAlt: '跑步者',
                },
                {
                    index: 2,
                    text: `**当前对复原资源的访问权限。**您身边有什么物品可以提供帮助？`,
                    imageSrc: PrepCheckItems,
                    imageAlt: '急救箱',
                },
                {
                    index: 3,
                    text: `**值得信赖的帮助网络。**你会觉得谁愿意寻求帮助？`,
                    imageSrc: PrepCheckContacts,
                    imageAlt: '电话',
                },
            ]),
            blurbChunk3: `**这不是一个测试，看看你是否知道正确的答案。**`,
            cta: 'Start Prep Checks',
        },
    ],
    [
        LanguageCode.RUSSIAN,
        {
            title: 'Добро пожаловать в Prep Checks!',
            blurbChunk1: `Это инструмент самооценки, который поможет вам ответить на вопрос «Насколько хорошо я справлюсь с этой опасностью?»`,
            yourGoalText:
                'Ваша цель состоит в том, чтобы ответить, что наиболее верно для вас и вашей жизни.',
            blurbChunk2: `Подготовительные проверки помогут оценить 3 основных аспекта вашей способности справляться с трудностями:`,
            aspectDisplay: renderAspectDisplay([
                {
                    index: 1,
                    text: `**Знание действий по обеспечению устойчивости.** Что бы вы сделали, если бы это случилось с вами?`,
                    imageSrc: PrepCheckActions,
                    imageAlt: 'Бегущий человек',
                },
                {
                    index: 2,
                    text: `**Текущий доступ к ресурсам устойчивости.** Какие предметы у вас есть вокруг вас, чтобы помочь?`,
                    imageSrc: PrepCheckItems,
                    imageAlt: 'Аптечка первой помощи',
                },
                {
                    index: 3,
                    text: `**Доверенная справочная сеть.** Кого бы вы чувствовали себя комфортно, призывая на помощь?`,
                    imageSrc: PrepCheckContacts,
                    imageAlt: 'Телефон',
                },
            ]),
            blurbChunk3: `**Это НЕ тест, чтобы увидеть, знаете ли вы ПРАВИЛЬНЫЙ ответ.**`,
            cta: 'Start Prep Checks',
        },
    ],
    [
        LanguageCode.SPANISH,
        {
            title: '¡Bienvenido a Prep Checks!',
            blurbChunk1: `Esta es una herramienta de autoevaluación que le ayuda a responder la pregunta "¿Qué tan bien enfrentaría este peligro?"`,
            yourGoalText:
                'Tu objetivo es responder a lo que es más verdadero para ti y tu vida.',
            blurbChunk2: `Los controles de preparación ayudarán a evaluar 3 aspectos principales de su capacidad de afrontamiento:`,
            aspectDisplay: renderAspectDisplay([
                {
                    index: 1,
                    text: `**Conocimiento de las acciones de resiliencia.** ¿Qué harías si te pasara a ti?`,
                    imageSrc: PrepCheckActions,
                    imageAlt: 'Persona corriendo',
                },
                {
                    index: 2,
                    text: `**Acceso actual a recursos de resiliencia.** ¿Qué artículos tienes a tu alrededor para ayudarte?`,
                    imageSrc: PrepCheckItems,
                    imageAlt: 'Botiquín de primeros auxilios',
                },
                {
                    index: 3,
                    text: `**Red de ayuda de confianza.** ¿Con quién te sentirías cómodo pidiendo ayuda?`,
                    imageSrc: PrepCheckContacts,
                    imageAlt: 'Teléfono',
                },
            ]),
            blurbChunk3: `**Esta NO es una prueba para ver si sabes la respuesta CORRECTA.**`,
            cta: 'Start Prep Checks',
        },
    ],
])
