// Boosters
import {
    BoosterConfig,
    colors,
    FilterConfig,
} from '@hazadapt-git/public-core-base'
import Disability from '../../assets/icons/boosters/Disability.svg'
import Elderly from '../../assets/icons/boosters/Elderly.svg'
import FarmAnimals from '../../assets/icons/boosters/FarmAnimals.svg'
import Kids from '../../assets/icons/boosters/Kids.svg'
import Pets from '../../assets/icons/boosters/Pets.svg'
import Veteran from '../../assets/icons/boosters/Veteran.svg'
import Wildlife from '../../assets/icons/boosters/Wildlife.svg'
// Filters
import AirQuality from '../../assets/icons/filters/AirQuality.svg'
import FirstAid from '../../assets/icons/filters/FirstAid.svg'
import Home from '../../assets/icons/filters/Home.svg'
import Land from '../../assets/icons/filters/Land.svg'
import MentalHealth from '../../assets/icons/filters/MentalHealth.svg'
import Transportation from '../../assets/icons/filters/Transportation.svg'
import Trending from '../../assets/icons/filters/Trending.svg'
import Water from '../../assets/icons/filters/Water.svg'
import Weather from '../../assets/icons/filters/Weather.svg'
// Unselected boosters
import UnselectedDisability from '../../assets/icons/unselected-boosters/Disability.svg'
import UnselectedElderly from '../../assets/icons/unselected-boosters/Elderly.svg'
import UnselectedFarmAnimals from '../../assets/icons/unselected-boosters/FarmAnimals.svg'
import UnselectedKids from '../../assets/icons/unselected-boosters/Kids.svg'
import UnselectedPets from '../../assets/icons/unselected-boosters/Pets.svg'
import UnselectedVeteran from '../../assets/icons/unselected-boosters/Veteran.svg'
import UnselectedWildlife from '../../assets/icons/unselected-boosters/Wildlife.svg'
// Unselected filters
import UnselectedAirQuality from '../../assets/icons/unselected-filters/AirQuality.svg'
import UnselectedFirstAid from '../../assets/icons/unselected-filters/FirstAid.svg'
import UnselectedHome from '../../assets/icons/unselected-filters/Home.svg'
import UnselectedLand from '../../assets/icons/unselected-filters/Land.svg'
import UnselectedMentalHealth from '../../assets/icons/unselected-filters/MentalHealth.svg'
import UnselectedTransportation from '../../assets/icons/unselected-filters/Transportation.svg'
import UnselectedTrending from '../../assets/icons/unselected-filters/Trending.svg'
import UnselectedWater from '../../assets/icons/unselected-filters/Water.svg'
import UnselectedWeather from '../../assets/icons/unselected-filters/Weather.svg'

export const HazardFilter: { [key: string]: FilterConfig } = {
    Transportation: {
        label: 'Transportation',
        color: colors.chips.DODGER,
        icon: Transportation,
        unselectedIcon: UnselectedTransportation,
        key: 'Transportation',
    },
    Weather: {
        label: 'Weather',
        color: colors.chips.ORCHID,
        icon: Weather,
        unselectedIcon: UnselectedWeather,
        key: 'Weather',
    },
    Trending: {
        label: 'Trending',
        color: colors.primary.CERULEAN,
        icon: Trending,
        unselectedIcon: UnselectedTrending,
        key: 'Trending',
    },
    First_Aid: {
        label: 'First Aid',
        color: colors.chips.HOTPINK,
        icon: FirstAid,
        unselectedIcon: UnselectedFirstAid,
        key: 'First_Aid',
    },
    Mental_Health: {
        label: 'Mental Health',
        color: colors.chips.CARIBBEANGREEN,
        icon: MentalHealth,
        unselectedIcon: UnselectedMentalHealth,
        key: 'Mental_Health',
    },
    Land: {
        label: 'Land',
        color: colors.chips.RUSSETT,
        icon: Land,
        unselectedIcon: UnselectedLand,
        key: 'Land',
    },
    Water: {
        label: 'Water',
        color: colors.chips.LIGHTSEA,
        icon: Water,
        unselectedIcon: UnselectedWater,
        key: 'Water',
    },
    Air_Quality: {
        label: 'Air Quality',
        color: colors.chips.SALMON,
        icon: AirQuality,
        unselectedIcon: UnselectedAirQuality,
        key: 'Air_Quality',
    },
    Home: {
        label: 'Home',
        color: colors.chips.SEAGREEN,
        icon: Home,
        unselectedIcon: UnselectedHome,
        key: 'Home',
    },
}

export const ContentBooster: { [key: string]: BoosterConfig } = {
    Kids: {
        label: 'Kids',
        color: colors.chips.PEROLOUS,
        icon: Kids,
        unselectedIcon: UnselectedKids,
        key: 'Kids',
        type: 'Humans',
    },
    Pets: {
        label: 'Pets',
        color: colors.chips.INDIGO,
        icon: Pets,
        unselectedIcon: UnselectedPets,
        key: 'Pets',
        type: 'Animals',
    },
    Farm_Animals: {
        label: 'Farm Animals',
        color: colors.chips.MINT,
        icon: FarmAnimals,
        unselectedIcon: UnselectedFarmAnimals,
        key: 'Farm_Animals',
        type: 'Animals',
    },
    Elderly: {
        label: 'Elderly',
        color: colors.chips.NEWYORK,
        icon: Elderly,
        unselectedIcon: UnselectedElderly,
        key: 'Elderly',
        type: 'Humans',
    },
    Veteran: {
        label: 'Veteran',
        color: colors.chips.FALU,
        icon: Veteran,
        unselectedIcon: UnselectedVeteran,
        key: 'Veteran',
        type: 'Humans',
    },
    Disability: {
        label: 'Disability',
        color: colors.chips.PALEVIOLET,
        icon: Disability,
        unselectedIcon: UnselectedDisability,
        key: 'Disability',
        type: 'Humans',
    },
    Wildlife: {
        label: 'Wildlife',
        color: colors.chips.MOSQUE,
        icon: Wildlife,
        unselectedIcon: UnselectedWildlife,
        key: 'Wildlife',
        type: 'Animals',
    },
}

export interface SearchResultsWithDYM {
    hazards: number[]
    suggestedQuery?: string
}
