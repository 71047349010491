import { PromotedProductDTO } from '@hazadapt-git/public-core-base'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC, MouseEventHandler } from 'react'

import PromotedProductsIcon from '../../assets/icons/PromotedProducts.svg'
import { useStyles } from '../../lib/styles/universal'
import { Card, HelpIcon } from '../atoms'
import { LoadingView, PromotedProductsView } from '../molecules'

interface PromotedProductsGridProps {
    products: PromotedProductDTO[]
    onLinkPress(id: number, url: string): void
    onBookmarkPress(id: number): void
    onProductAreaHover?(product_id: number): void
    onProductAreaClick?(product_id: number): void
    onShare(id: number, url: string): void
    loading?: boolean
}

export const PromotedProductsGrid: FC<PromotedProductsGridProps> = (
    props: PromotedProductsGridProps
) => (
    <Grid
        container
        justifyContent="space-evenly"
        rowGap="3rem"
        columnGap={{ sm: '1rem', lg: '2rem' }}
    >
        {props.loading ? (
            <LoadingView />
        ) : (
            props.products.map((product) => (
                <Grid
                    item
                    xs={12}
                    sm={5.5}
                    md={3.5}
                    lg={2.5}
                    key={`promotedProduct_${product.id}`}
                    onMouseEnter={() =>
                        props.onProductAreaHover &&
                        props.onProductAreaHover(product.id)
                    }
                    onClick={() =>
                        props.onProductAreaClick &&
                        props.onProductAreaClick(product.id)
                    }
                >
                    <PromotedProductsView
                        {...product}
                        onLinkPress={props.onLinkPress}
                        onBookmarkPress={props.onBookmarkPress}
                        onShare={props.onShare}
                    />
                </Grid>
            ))
        )}
    </Grid>
)

interface PromotedProductsBlockProps extends PromotedProductsGridProps {
    onHelpIconPress?: MouseEventHandler<HTMLDivElement>
}

export const PromotedProductsBlock = React.forwardRef<
    HTMLDivElement,
    PromotedProductsBlockProps
>(
    (
        props: PromotedProductsBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const { classes } = useStyles()
        const { classes: localClasses } = useLocalStyles()

        return (
            <Card
                colors={['white']}
                className={classNames(
                    classes.contentBlock,
                    localClasses.container
                )}
                ref={ref}
            >
                <div className={localClasses.titleContainer}>
                    <img
                        src={PromotedProductsIcon}
                        className={classes.contentBlockDetailsIcon}
                        alt="Shopping cart with a first aid plus in a circle on the cart"
                    />
                    <Typography variant="h2" pl="1rem">
                        Promoted Products
                    </Typography>
                    <HelpIcon
                        helpText={`HazAdapt works with resilience product retailers to connect people to emergency and disaster supplies. Learn more [here](https://www.hazadapt.com/promoted-products).`}
                        onClick={props.onHelpIconPress}
                    />
                </div>
                {props.products.some((p) => p.affiliate_link) ? (
                    <Typography
                        variant="body2"
                        fontStyle="italic"
                        textAlign="center"
                        m="0 auto 2rem"
                        maxWidth="40rem"
                    >
                        Purchases through some of our Promoted Product links
                        support us with a commission at no extra cost to you.
                        Thank you!
                    </Typography>
                ) : null}
                <PromotedProductsGrid {...props} />
            </Card>
        )
    }
)

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem',
        justifyContent: 'center',
    },
})
