import {
    DndContext,
    DragStartEvent,
    DragEndEvent,
    DragCancelEvent,
    DragOverlay,
    MouseSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    PrepCheckUserSubmissionDTO,
    PrepCheckQuestionDTO,
    PrepCheckStatus,
    PrepCheckDND2DAnswerItem,
    PrepCheckAnswerOptionDTO,
} from '@hazadapt-git/public-core-base'
import { Link, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoChevronForward } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import {
    PrepCheckAnswerCard,
    PrepCheckAnswerCardProps,
    PrepCheckCardDropItemProps,
} from '../molecules'
import { PrepCheckAnswerCardGrid } from './PrepCheckAnswerCardGrid'
import { PrepCheckCardDropGrid } from './PrepCheckCardDropGrid'

interface PrepCheck2DQuestionViewProps {
    userAnswers: PrepCheckCardDropItemProps[]
    submission?: PrepCheckUserSubmissionDTO
    updateSubmission(submission: PrepCheckUserSubmissionDTO): void
    question: PrepCheckQuestionDTO
    activeQuestionIdx: number
    prepCheckId: number
    showNextQuestionLink: boolean
    onNextQuestionClick: React.MouseEventHandler
}

export const PrepCheck2DQuestionView: FC<PrepCheck2DQuestionViewProps> = (
    props: PrepCheck2DQuestionViewProps
) => {
    // Drag-and-drop sensors
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 5,
        },
    })
    const sensors = useSensors(mouseSensor)

    const mediumWindowOrLarger = useWindowSizeUp('md')
    const { classes: localClasses } = useLocalStyles()
    const [selectedAnswerCardId, setSelectedAnswerCardId] =
        React.useState<number>()
    const [draggingElem, setDraggingElem] =
        React.useState<PrepCheckAnswerOptionDTO>()

    const onAnswerItemClick = (answer_id: number, dropIndex: number): void => {
        if (answer_id === selectedAnswerCardId) {
            return
        } else if (!selectedAnswerCardId) {
            setSelectedAnswerCardId(answer_id)
        } else {
            // Check if the user clicked on an answer that's in a drop spot
            const answerIsPlaced = props.userAnswers.findIndex(
                (ua) => ua.answerOption?.id === answer_id
            )
            if (answerIsPlaced < 0) {
                setSelectedAnswerCardId(answer_id)
            } else {
                // The user is swapping places of two blocks
                let draft_submission: PrepCheckUserSubmissionDTO
                if (props.submission) {
                    draft_submission = { ...props.submission }
                } else {
                    draft_submission = {
                        prep_check_id: props.prepCheckId,
                        status: PrepCheckStatus.DRAFT,
                        submitted_responses: [],
                    }
                }
                const spotToSwap = props.userAnswers.findIndex(
                    (ua) => ua.answerOption?.id === selectedAnswerCardId
                )
                if (spotToSwap < 0) {
                    // Selected answer is still in the option list, not in the grid
                    return onDropSpotClick(dropIndex)
                }

                // Get the answer data for the two items to be swapped
                const answer1Index = draft_submission.submitted_responses[
                    props.activeQuestionIdx
                ].user_answer.findIndex(
                    (ua) =>
                        ua.answer_id ===
                        props.userAnswers[answerIsPlaced].answerOption?.id
                )
                const answer2Index = draft_submission.submitted_responses[
                    props.activeQuestionIdx
                ].user_answer.findIndex(
                    (ua) =>
                        ua.answer_id ===
                        props.userAnswers[spotToSwap].answerOption?.id
                )

                if (answer1Index < 0 || answer2Index < 0) return

                const tempAnswer1 = {
                    ...draft_submission.submitted_responses[
                        props.activeQuestionIdx
                    ].user_answer[answer1Index],
                }
                if (
                    !tempAnswer1?.answer ||
                    !draft_submission.submitted_responses[
                        props.activeQuestionIdx
                    ].user_answer[answer2Index].answer ||
                    !('placement' in tempAnswer1.answer) ||
                    !(
                        'placement' in
                        draft_submission.submitted_responses[
                            props.activeQuestionIdx
                        ].user_answer[answer2Index].answer
                    )
                )
                    return

                // Swap positions
                draft_submission.submitted_responses[
                    props.activeQuestionIdx
                ].user_answer[answer1Index].answer = {
                    id: draft_submission.submitted_responses[
                        props.activeQuestionIdx
                    ].user_answer[answer1Index].answer_id,
                    placement: (
                        draft_submission.submitted_responses[
                            props.activeQuestionIdx
                        ].user_answer[answer2Index]
                            .answer as PrepCheckDND2DAnswerItem
                    ).placement,
                }
                draft_submission.submitted_responses[
                    props.activeQuestionIdx
                ].user_answer[answer2Index].answer = {
                    id: draft_submission.submitted_responses[
                        props.activeQuestionIdx
                    ].user_answer[answer2Index].answer_id,
                    placement: tempAnswer1.answer.placement,
                }

                props.updateSubmission(draft_submission)

                // Reset selected answer card ID
                setSelectedAnswerCardId(undefined)
            }
        }
    }

    const onDropSpotClick = (id: number): void => {
        if (id >= props.userAnswers.length || id < 0) return

        const selected_answer = props.question.options.find(
            (o) => o.id === selectedAnswerCardId
        )
        if (!selected_answer) return

        let draft_submission: PrepCheckUserSubmissionDTO
        if (props.submission) {
            draft_submission = { ...props.submission }
        } else {
            draft_submission = {
                prep_check_id: props.prepCheckId,
                status: PrepCheckStatus.DRAFT,
                submitted_responses: [],
            }
        }
        const idx = draft_submission.submitted_responses.findIndex(
            (sr) => sr.question_id === props.question.id
        )
        const beforeColumnSize = props.question.options.filter(
            (o) => o.best_stage === 'Before'
        ).length
        const duringColumnSize = props.question.options.filter(
            (o) => o.best_stage === 'During'
        ).length
        if (idx >= 0) {
            // If the user has placed answers for this question already, add to the existing config
            // Check if the spot already has an answer in it
            const spotFilledIdx = draft_submission.submitted_responses[
                idx
            ].user_answer.findIndex(
                (ua) =>
                    'placement' in ua.answer &&
                    ((id < beforeColumnSize &&
                        ua.answer.placement[0] === 'Before') ||
                        (id < beforeColumnSize + duringColumnSize &&
                            ua.answer.placement[0] === 'During') ||
                        (id >= beforeColumnSize + duringColumnSize &&
                            ua.answer.placement[0] === 'After')) &&
                    ((ua.answer.placement[0] === 'Before' &&
                        ua.answer.placement[1] === id + 1) ||
                        (ua.answer.placement[0] === 'During' &&
                            ua.answer.placement[1] ===
                                id - beforeColumnSize + 1) ||
                        (ua.answer.placement[0] === 'After' &&
                            ua.answer.placement[1] ===
                                id - (beforeColumnSize + duringColumnSize) + 1))
            )
            if (spotFilledIdx >= 0) {
                // If it does, replace it
                draft_submission.submitted_responses[idx].user_answer[
                    spotFilledIdx
                ] = {
                    answer_id: selected_answer.id,
                    answer: {
                        id: selected_answer.id,
                        placement:
                            id < beforeColumnSize
                                ? ['Before', id + 1]
                                : id < beforeColumnSize + duringColumnSize
                                ? ['During', id - beforeColumnSize + 1]
                                : [
                                      'After',
                                      id -
                                          (beforeColumnSize +
                                              duringColumnSize) +
                                          1,
                                  ],
                    },
                }
            } else {
                // If it doesn't, fill it
                const previousSpot = draft_submission.submitted_responses[
                    idx
                ].user_answer.findIndex(
                    (ua) => ua.answer_id === selected_answer.id
                )
                if (previousSpot >= 0) {
                    draft_submission.submitted_responses[
                        idx
                    ].user_answer.splice(previousSpot, 1)
                }
                draft_submission.submitted_responses[idx].user_answer.push({
                    answer_id: selected_answer.id,
                    answer: {
                        id: selected_answer.id,
                        placement:
                            id < beforeColumnSize
                                ? ['Before', id + 1]
                                : id < beforeColumnSize + duringColumnSize
                                ? ['During', id - beforeColumnSize + 1]
                                : [
                                      'After',
                                      id -
                                          (beforeColumnSize +
                                              duringColumnSize) +
                                          1,
                                  ],
                    },
                })
            }
        } else {
            // User has not answered this quetsion yet; build new config with answer
            draft_submission.submitted_responses.push({
                question_id: props.question.id,
                user_answer: [
                    {
                        answer_id: selected_answer.id,
                        answer: {
                            id: selected_answer.id,
                            placement:
                                id < beforeColumnSize
                                    ? ['Before', id + 1]
                                    : id < beforeColumnSize + duringColumnSize
                                    ? ['During', id - beforeColumnSize + 1]
                                    : [
                                          'After',
                                          id -
                                              (beforeColumnSize +
                                                  duringColumnSize) +
                                              1,
                                      ],
                        },
                    },
                ],
            })
        }
        props.updateSubmission(draft_submission)
        setSelectedAnswerCardId(undefined)
    }

    const onDropSpotClear = (id: number): void => {
        if (id >= props.userAnswers.length || id < 0) return
        if (!props.submission) return

        if (props.question.question_type === 'Checkboxes') return

        const draft_submission = { ...props.submission }

        const idx = draft_submission.submitted_responses.findIndex(
            (sr) => sr.question_id === props.question.id
        )

        if (idx < 0) return

        // Find the answer item that was chosen to be removed
        const answerItem = props.userAnswers.find((c) => c.index === id)
        if (!answerItem || !answerItem.answerOption) return

        // Check if the answer is in the submission data
        const itemInSubmission = draft_submission.submitted_responses[
            idx
        ].user_answer.findIndex(
            (ua) => ua.answer_id === answerItem.answerOption?.id
        )

        // If it is, remove it from the submission data
        if (itemInSubmission >= 0) {
            draft_submission.submitted_responses[idx].user_answer.splice(
                itemInSubmission,
                1
            )
        }

        props.updateSubmission(draft_submission)
    }

    const handleDragStart = (e: DragStartEvent) => {
        const answer_id = parseInt(e.active.id.toString().split('_')[1])
        const elem = props.question.options.find((o) => o.id === answer_id)
        if (!elem) return
        setDraggingElem(elem)
        setSelectedAnswerCardId(answer_id)
    }

    const handleDragEnd = (e: DragEndEvent) => {
        setDraggingElem(undefined)
        if (!e.over) return
        const dropIndex = parseInt(e.over.id.toString().split('_')[1])
        const item = props.userAnswers.find((ua) => ua.index === dropIndex)
        if (item) {
            if (item.answerOption) {
                onAnswerItemClick(item.answerOption.id, dropIndex)
            } else {
                onDropSpotClick(dropIndex)
            }
        }
    }

    const handleDragCancel = (e: DragCancelEvent) => {
        setDraggingElem(undefined)
        setSelectedAnswerCardId(undefined)
    }

    const pickable_options = props.question.options.filter(
        (o) => !props.userAnswers.some((ua) => ua.answerOption?.id === o.id)
    )

    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            sensors={sensors}
        >
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: mediumWindowOrLarger
                        ? `30rem auto`
                        : `1fr`,
                    columnGap: '2rem',
                    rowGap: '1rem',
                    width: 'fit-content',
                    margin: '0 auto',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        overflowX: mediumWindowOrLarger ? 'hidden' : 'auto',
                        display: pickable_options.length > 0 ? 'block' : 'flex',
                        flexDirection: 'column',
                        alignItems:
                            pickable_options.length > 0 ? undefined : 'center',
                        justifyContent:
                            pickable_options.length > 0
                                ? undefined
                                : 'flex-start',
                    }}
                >
                    {pickable_options.length > 0 ? (
                        <PrepCheckAnswerCardGrid
                            data={pickable_options.map(
                                (o) =>
                                    ({
                                        id: o.id,
                                        answerText: o.text,
                                        questionType: 'TwoD_Grid_Placement',
                                        answerInfo: o.explainer_text,
                                        popoverPlacement: 'left',
                                        onClick: onAnswerItemClick,
                                        image: o.image?.url || o.image?.src,
                                        selected: selectedAnswerCardId === o.id,
                                        draggable: true,
                                    } as PrepCheckAnswerCardProps)
                            )}
                            gridType="TwoD_Grid_Placement"
                            selectedItemId={selectedAnswerCardId}
                        />
                    ) : (
                        <div
                            className={localClasses.allItemsPlaced}
                            style={{
                                margin: mediumWindowOrLarger
                                    ? '3rem auto'
                                    : '1rem auto',
                            }}
                        >
                            <Typography
                                fontWeight={500}
                                textAlign="center"
                                pb="0.5rem"
                            >
                                You have placed all of the items!
                            </Typography>
                            {props.showNextQuestionLink && (
                                <Link
                                    fontWeight={500}
                                    onClick={props.onNextQuestionClick}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Go to Next Question
                                    <IoChevronForward />
                                </Link>
                            )}
                        </div>
                    )}
                </div>
                <PrepCheckCardDropGrid
                    data={props.userAnswers}
                    gridType="TwoD_Grid_Placement"
                    onDropSpotClick={onDropSpotClick}
                    onFilledSpotClick={onAnswerItemClick}
                    onClearSpot={onDropSpotClear}
                    selectedId={selectedAnswerCardId}
                    draggedItem={draggingElem?.id}
                />
            </div>
            <DragOverlay>
                {draggingElem ? (
                    <PrepCheckAnswerCard
                        id={draggingElem.id}
                        answerText={draggingElem.text}
                        questionType="TwoD_Grid_Placement"
                        answerInfo={draggingElem.explainer_text}
                        popoverPlacement="left"
                        selected
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    )
}

const useLocalStyles = makeStyles()({
    allItemsPlaced: {
        padding: '1rem',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
})
