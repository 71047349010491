import {
    LanguageCode,
    LoggedPage,
    NativeLanguageDTO,
    PrepCheckResultItemDTO,
    PrepCheckResultsDTO,
    getLangObjects,
} from '@hazadapt-git/public-core-base'
import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import voca from 'voca'
import {
    PrepCheckResultPreviewItemPropsBase,
    PrepCheckResultsPageTemplate,
} from '../components'
import { PageProps } from '../lib/entities'
import {
    getPrepCheckThunk,
    getPrepCheckUserStatsThunk,
    switchLanguageThunk,
} from '../lib/slices'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import { logEvent, toast } from '../lib/utils'
import { getOnePrepCheckResult } from '../lib/utils/prep'
import { IoCloudDownloadOutline, IoLanguage } from 'react-icons/io5'
import {
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'

interface PrepCheckResultsPageProps extends PageProps {}

export const PrepCheckResultsPage: FC<PrepCheckResultsPageProps> = (
    props: PrepCheckResultsPageProps
) => {
    const { classes: localClasses } = useLocalStyles()

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const allowBack = React.useRef<boolean>(false)

    const { stats: prepCheckStats } = useAppSelector(
        (state: RootState) => state.prep
    )
    const { language, profileReady } = useAppSelector(
        (state: RootState) => state.profile
    )
    const { languages: supportedLanguages } = useAppSelector(
        (state: RootState) => state.hazards
    )

    const [results, setResults] = React.useState<PrepCheckResultsDTO>()
    const [openQuestion, setOpenQuestion] = React.useState<number>()
    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)

    React.useEffect(() => {
        const state = location.state as { [key: string]: any }
        allowBack.current = !!state?.allowBack
    }, [location])

    React.useEffect(() => {
        document.title = 'Your Prep Check Results - HazAdapt'
    }, [])

    React.useEffect(() => {
        if (!profileReady) return

        const urlPieces = location.pathname.split('/')
        const identifier = urlPieces[urlPieces.length - 2]
        if (identifier && voca.isNumeric(identifier)) {
            logEvent('OPEN_PAGE', {
                page: LoggedPage.PREP_CHECK_RESULTS,
                language,
                prep_check_id: parseInt(identifier),
            })
            dispatch(getPrepCheckUserStatsThunk(language))
            getOnePrepCheckResult(parseInt(identifier), language)
                .then((res) => {
                    setResults(res)
                    setOpenQuestion(res.questions[0].question_id)
                })
                .catch((err) => {
                    console.error(err)
                    navigate('/prep-checks', { replace: true })
                })
        }
    }, [language, location.pathname, navigate, dispatch, profileReady])

    const onQuestionPreviewPress = (id: number) => {
        if (openQuestion && openQuestion === id) setOpenQuestion(undefined)
        else setOpenQuestion(id)
    }

    /**
     * Handle a pressed link
     * @param url the URL the user pressed on
     * @returns boolean (required but we ignore it)
     */
    const onLinkPress = async (url: string) => {
        if (url) {
            if (url.startsWith('/')) {
                navigate(url) // Go to the in-app link
            } else {
                window.open(url, '_blank', 'noopener') // Open the link in a new tab
            }
        }
    }

    const onRetakePress: React.MouseEventHandler = (e) => {
        const urlPieces = location.pathname.split('/')
        const identifier = urlPieces[urlPieces.length - 2]
        if (identifier && voca.isNumeric(identifier)) {
            navigate(`/prep-checks/${identifier}?from=${location.pathname}`)
        }
    }

    const onLanguageChange = async (language: LanguageCode) => {
        const urlPieces = location.pathname.split('/')
        const identifier = urlPieces[urlPieces.length - 2]
        if (!identifier || !voca.isNumeric(identifier)) return

        const langObject: NativeLanguageDTO = getLangObjects([language])[0]
        setLanguageSelectorModalOpen(false)
        toast(
            `Downloading results in ${langObject.title}.`,
            <IoCloudDownloadOutline
                color={successColor}
                size={primaryIconSize}
            />
        )
        try {
            await dispatch(switchLanguageThunk(language))
            await dispatch(
                getPrepCheckThunk({ id: parseInt(identifier), language })
            )
            toast(
                `Prep Check results have been translated into ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to translate Prep Check results in ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const onTranslateClick: React.MouseEventHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLanguageSelectorModalOpen(true)
    }

    const onLanguageSelectorModalClose = () => {
        setLanguageSelectorModalOpen(false)
    }

    return results ? (
        <PrepCheckResultsPageTemplate
            prepCheckName={
                results.prepCheckTitle.includes('Prep Check')
                    ? results.prepCheckTitle
                    : `${results.prepCheckTitle} Prep Check`
            }
            prepCheckIcon={results.lightPrepCheckIcon}
            prepCheckStats={prepCheckStats}
            openQuestionId={openQuestion}
            onQuestionPreviewPress={onQuestionPreviewPress}
            questions={
                results.questions
                    .map((q) => {
                        const resultItem: PrepCheckResultItemDTO | undefined =
                            results.questions.find(
                                (rq) => rq.question_id === q.question_id
                            )
                        if (!resultItem) return null
                        else
                            return {
                                questionId: q.question_id,
                                questionName: q.question_name,
                                componentRating: resultItem.component_rating,
                                questionText: q.question_text,
                                questionIcon: q.question_image,
                                breakdownText: resultItem.breakdown_text,
                                previewText: resultItem.result_preview_text,
                            } as PrepCheckResultPreviewItemPropsBase
                    })
                    .filter(
                        (q) => q !== null
                    ) as PrepCheckResultPreviewItemPropsBase[]
            }
            dateLastUpdated={new Date(results.dateLastUpdated)}
            badge={results.badge}
            badgeName={results.badgeName}
            onLinkPress={onLinkPress}
            onRetakePress={onRetakePress}
            allowBack={allowBack.current}
            onBackPress={() => navigate(-1)}
            score={results.score}
            preparednessLevel={results.badgeName}
            loading={props.loading}
            languages={supportedLanguages}
            selectedLanguage={language}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onTranslateClick={onTranslateClick}
        />
    ) : (
        <div className={localClasses.loading}>
            <CircularProgress size="1.5rem" />
            <Typography variant="h4">Loading results...</Typography>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    loading: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 0',
    },
})
