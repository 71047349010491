import {
    LanguageCode,
    PromotedProductDTO,
} from '@hazadapt-git/public-core-base'
import { Grid, IconButton, Typography } from '@mui/material'
import React, { FC } from 'react'

import Translate from '../../assets/icons/Translate-Outline.svg'
import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'
import { getLangObjects, useWindowSizeUp } from '../../lib/utils'
import { HazardCardDTO } from '../molecules'
import {
    HazardCardGrid,
    LanguageSelectorModal,
    NoBookmarksView,
    TabGroup,
} from '../organisms'
import { PromotedProductsGrid } from '../organisms/PromotedProductsBlock'

interface BookmarkPageTemplateProps {
    bookmarkedHazards: HazardCardDTO[]
    bookmarkedProducts: PromotedProductDTO[]
    onHazardPress(id: number): void
    onProductPress(id: number, url: string): void
    onPdfPress(id: number, slug: string): void
    onShare(id: number): void
    onBookmarkHazard(id: number): void
    onBookmarkProduct(product_id: number): void
    onTranslatePress(): void
    selectedLanguage: LanguageCode
    languageSelectorModalOpen: boolean
    onLanguageSelectorModalClose(): void
    onLanguageChange(lang: LanguageCode): void
    languages: LanguageCode[]
    loading: boolean
    activeTab: number
    onTabPress(id: number): void
    loggedIn?: boolean
    onProductAreaHover?(product_id: number): void
    onProductAreaClick?(product_id: number): void
    onProductShare(product_id: number, url: string): void
}

export const BookmarkPageTemplate: FC<BookmarkPageTemplateProps> = (
    props: BookmarkPageTemplateProps
) => {
    const { classes } = useStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const largeWindowOrLarger = useWindowSizeUp('lg')
    return (
        <div
            className={classes.page}
            style={{
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginBottom: '3rem',
            }}
        >
            <Grid
                container
                spacing={0}
                alignItems="center"
                paddingBottom="2.5rem"
            >
                <Grid item xs={0} sm={4} xl={5} />
                <Grid
                    item
                    xs={7}
                    sm={4}
                    xl={2}
                    display="flex"
                    alignItems="center"
                    textAlign="center"
                    justifyContent={
                        smallWindowOrLarger ? 'center' : 'flex-start'
                    }
                >
                    <Typography
                        variant={largeWindowOrLarger ? 'h1' : 'h2'}
                        component="h1"
                        width="100%"
                        textAlign={smallWindowOrLarger ? 'center' : 'left'}
                        color={customDarkBlueColor}
                    >
                        Your Bookmarks
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={5}
                    sm={4}
                    xl={5}
                    display="flex"
                    alignItems="center"
                    paddingBottom="0.625rem"
                >
                    {!props.loading && props.bookmarkedHazards.length > 0 && (
                        <IconButton onClick={props.onTranslatePress}>
                            <img src={Translate} alt="Translate" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            {!props.loggedIn && props.bookmarkedHazards.length === 0 ? (
                <NoBookmarksView variant="hazards" />
            ) : (
                <>
                    {props.loggedIn ? (
                        <TabGroup
                            tabs={[
                                {
                                    id: 1,
                                    scene:
                                        props.bookmarkedHazards.length > 0 ||
                                        props.loading ? (
                                            <HazardCardGrid
                                                data={props.bookmarkedHazards}
                                                onHazardPress={
                                                    props.onHazardPress
                                                }
                                                onPdfPress={props.onPdfPress}
                                                onCopyLinkPress={props.onShare}
                                                onBookmark={
                                                    props.onBookmarkHazard
                                                }
                                                loading={props.loading}
                                            />
                                        ) : (
                                            <NoBookmarksView variant="hazards" />
                                        ),
                                    label: 'Hazards',
                                    onPress: props.onTabPress,
                                },
                                {
                                    id: 2,
                                    scene:
                                        props.bookmarkedProducts.length > 0 ||
                                        props.loading ? (
                                            <PromotedProductsGrid
                                                products={
                                                    props.bookmarkedProducts
                                                }
                                                onLinkPress={
                                                    props.onProductPress
                                                }
                                                onBookmarkPress={
                                                    props.onBookmarkProduct
                                                }
                                                onProductAreaClick={
                                                    props.onProductAreaClick
                                                }
                                                onProductAreaHover={
                                                    props.onProductAreaHover
                                                }
                                                onShare={props.onProductShare}
                                                loading={props.loading}
                                            />
                                        ) : (
                                            <NoBookmarksView variant="products" />
                                        ),
                                    label: 'Products',
                                    onPress: props.onTabPress,
                                },
                            ]}
                            value={props.activeTab}
                        />
                    ) : (
                        <HazardCardGrid
                            data={props.bookmarkedHazards}
                            onHazardPress={props.onHazardPress}
                            onPdfPress={props.onPdfPress}
                            onCopyLinkPress={props.onShare}
                            onBookmark={props.onBookmarkHazard}
                            loading={props.loading}
                        />
                    )}
                    <LanguageSelectorModal
                        languages={getLangObjects(props.languages)}
                        selectedLanguage={props.selectedLanguage}
                        open={props.languageSelectorModalOpen}
                        onClose={props.onLanguageSelectorModalClose}
                        onLanguagePress={props.onLanguageChange}
                    />
                </>
            )}
        </div>
    )
}
