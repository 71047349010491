import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import CSOReducer from './slices/CSOSlice'
import BookmarkReducer from './slices/BookmarkSlice'
import HazardReducer from './slices/HazardSlice'
import PrepReducer from './slices/PrepSlice'
import ProfileReducer from './slices/ProfileSlice'

export const reducers = {
    profile: ProfileReducer,
    csos: CSOReducer,
    hazards: HazardReducer,
    bookmarks: BookmarkReducer,
    prep: PrepReducer,
}

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.REACT_APP_DEPLOY_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
