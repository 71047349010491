import {
    CrisisSupportOption,
    BoosterConfig,
    colors,
} from '@hazadapt-git/public-core-base'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import {
    Card,
    Link,
    Typography,
    IconButton,
    Grid,
    Skeleton,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { CSSProperties, FC } from 'react'

import Available247 from '../../assets/icons/Available247.svg'
import { Markdown, Tag } from '../atoms'
import { useWindowSizeUp } from '../../lib/utils'
import { customDarkBlueColor } from '../../lib/styles/universal'

export interface CSOItemProps {
    config?: CrisisSupportOption
    avatarStyle?: CSSProperties
    filterChips?: BoosterConfig[]
    examplesVisible?: boolean
    whoTheyHelpVisible?: boolean
    loading?: boolean
}

interface ComponentProps extends CSOItemProps {
    onClick(id: number): void
    onHover(id: number): void
    onWhatTheyHelpWithClick(id: number): void
    onWhoTheyHelpClick(id: number): void
    onAboutThisServiceClick(id: number): void
    onFindNearMeClick(id: number, url: string): void
    onViewWebsiteClick(id: number, url: string): void
}

export const CSOItem: FC<ComponentProps> = (props: ComponentProps) => {
    const { classes } = useLocalStyles()

    const [open, setOpen] = React.useState<boolean>(false)
    const [whatTheyHelpWithOpen, setWhatTheyHelpWithOpen] = React.useState(
        props.examplesVisible || false
    )
    const [aboutThisServiceOpen, setAboutThisServiceOpen] =
        React.useState<boolean>(false)
    const [whoTheyHelpOpen, setWhoTheyHelpOpen] = React.useState(
        props.whoTheyHelpVisible || false
    )

    const mediumWindowOrLarger = useWindowSizeUp('md')

    const renderHours = () => (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: mediumWindowOrLarger ? undefined : '1rem',
                flexGrow: 1,
            }}
        >
            {props.config?.always_available && (
                <img
                    src={Available247}
                    className={classes.available247Icon}
                    alt="24 7 option icon"
                />
            )}
            <Typography
                component="p"
                variant="h5"
                fontSize="0.9rem"
                sx={{
                    color: colors.grays.THUNDERCLOUD,
                }}
                pb={0}
            >
                {props.config?.always_available
                    ? 'Available 24/7'
                    : props.config?.hours || ''}
            </Typography>
        </div>
    )

    const onClick = () => {
        if (!props.config) return
        if (!open) props.onClick(props.config.id)
        setOpen(!open)
    }

    const onWhatTheyHelpWithClick = () => {
        if (!props.config) return
        if (!whatTheyHelpWithOpen)
            props.onWhatTheyHelpWithClick(props.config.id)
        setWhatTheyHelpWithOpen(!whatTheyHelpWithOpen)
    }

    const onWhoTheyHelpClick = () => {
        if (!props.config) return
        if (!whoTheyHelpOpen) props.onWhoTheyHelpClick(props.config.id)
        setWhoTheyHelpOpen(!whoTheyHelpOpen)
    }

    const onAboutThisServiceClick = () => {
        if (!props.config) return
        if (!aboutThisServiceOpen)
            props.onAboutThisServiceClick(props.config.id)
        setAboutThisServiceOpen(!aboutThisServiceOpen)
    }

    const onCsoCTAClick = () => {
        if (!props.config || !props.config.link) return
        if (props.config.link.includes('google.com')) {
            props.onFindNearMeClick(props.config.id, props.config.link)
        } else {
            props.onViewWebsiteClick(props.config.id, props.config.link)
        }
    }

    const onCsoHover = () => {
        if (!props.config || !props.config.link) return
        props.onHover(props.config.id)
    }

    return props.loading ? (
        <Card className={classes.container}>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={3}
                    xl={2}
                    display="flex"
                    flexDirection="row"
                    justifyContent={
                        mediumWindowOrLarger ? 'center' : 'flex-start'
                    }
                >
                    <Skeleton
                        variant="circular"
                        height={mediumWindowOrLarger ? '5rem' : '3rem'}
                        width={mediumWindowOrLarger ? '5rem' : '3rem'}
                    />
                </Grid>
                <Grid item xs={12} md={7} xl={8}>
                    <Skeleton
                        variant="rectangular"
                        width={mediumWindowOrLarger ? '40%' : '90%'}
                        height="1.5rem"
                        sx={{ mb: '1rem' }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width={mediumWindowOrLarger ? '60%' : '90%'}
                        height="1rem"
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Skeleton
                        variant="rectangular"
                        width="60%"
                        height="1rem"
                        sx={{ mb: '1rem' }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width={mediumWindowOrLarger ? '90%' : '60%'}
                        height="1rem"
                        sx={{ mb: '1rem' }}
                    />
                </Grid>
            </Grid>
        </Card>
    ) : props.config ? (
        <Card className={classes.container} onMouseEnter={onCsoHover}>
            <Grid container spacing={2}>
                {props.config.icon && (
                    <Grid
                        item
                        xs={12}
                        md={3}
                        xl={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent={
                            mediumWindowOrLarger ? 'center' : 'flex-start'
                        }
                    >
                        <img
                            src={props.config.icon?.src}
                            alt={
                                props.config.icon?.alt ||
                                `${props.config.title} icon`
                            }
                            style={{
                                ...props.avatarStyle,
                                height: mediumWindowOrLarger ? '5rem' : '3rem',
                                width: 'auto',
                                maxWidth: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    md={props.config.icon ? 5.5 : 8.5}
                    lg={props.config.icon ? 6 : 9}
                    xl={props.config.icon ? 7 : 9}
                >
                    <Grid container pb="1rem" alignItems="center">
                        <Grid
                            item
                            xs={12}
                            md={
                                mediumWindowOrLarger &&
                                (props.config.always_available ||
                                    props.config.hours)
                                    ? 7
                                    : true
                            }
                        >
                            {props.filterChips &&
                                props.filterChips.map(
                                    (chip: BoosterConfig, index: number) =>
                                        props.filterChips && chip ? (
                                            <Tag
                                                config={chip}
                                                start={index === 0}
                                                end={
                                                    index ===
                                                    props.filterChips.length - 1
                                                }
                                                key={`alt_911_${
                                                    props.config?.id || 0
                                                }_${chip.key}_mini_chip`}
                                                sx={{
                                                    marginTop: 0,
                                                    marginRight: '1rem',
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )
                                )}
                            <Typography variant="h3" pr="2rem">
                                {props.config.title}
                            </Typography>
                        </Grid>
                        {mediumWindowOrLarger &&
                            (props.config.always_available ||
                                props.config.hours) && (
                                <Grid item xs={12} md={5}>
                                    {renderHours()}
                                </Grid>
                            )}
                    </Grid>
                    {!mediumWindowOrLarger &&
                        (props.config.always_available || props.config.hours) &&
                        renderHours()}
                    {props.config.subheader && (
                        <Typography
                            component="h4"
                            variant="h5"
                            sx={{
                                color: colors.grays.THUNDERCLOUD,
                            }}
                            fontStyle="normal"
                        >
                            {props.config.subheader}
                        </Typography>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight: '2rem',
                            cursor: 'pointer',
                        }}
                        onClick={onClick}
                    >
                        <Link
                            sx={{
                                color: customDarkBlueColor,
                                textDecorationColor: customDarkBlueColor,
                                paddingTop: '0.5rem',
                                paddingBottom: '0.5rem',
                                paddingRight: '0.25rem',
                                fontSize: '0.9rem',
                                fontWeight: 300,
                            }}
                        >
                            {open ? 'Less Details' : 'More Details'}
                        </Link>
                        {open ? (
                            <IoChevronUp
                                size="1rem"
                                color={colors.grays.NOIR}
                            />
                        ) : (
                            <IoChevronDown
                                size="1rem"
                                color={colors.grays.NOIR}
                            />
                        )}
                    </div>
                    {open && (
                        <div>
                            <div className={classes.sectionTitle}>
                                <Typography
                                    component="h5"
                                    variant="h4"
                                    mt="1rem"
                                    mb="1rem"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={onWhatTheyHelpWithClick}
                                >
                                    What They Help With
                                </Typography>
                                <IconButton onClick={onWhatTheyHelpWithClick}>
                                    {whatTheyHelpWithOpen ? (
                                        <IoChevronUp
                                            size="1.25rem"
                                            color={colors.grays.NOIR}
                                        />
                                    ) : (
                                        <IoChevronDown
                                            size="1.25rem"
                                            color={colors.grays.NOIR}
                                        />
                                    )}
                                </IconButton>
                            </div>
                            {whatTheyHelpWithOpen && (
                                <Markdown content={props.config.examples} />
                            )}
                            <div className={classes.sectionTitle}>
                                <Typography
                                    component="h5"
                                    variant="h4"
                                    mt="1rem"
                                    mb="1rem"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={onWhoTheyHelpClick}
                                >
                                    Who They Help
                                </Typography>
                                <IconButton onClick={onWhoTheyHelpClick}>
                                    {whoTheyHelpOpen ? (
                                        <IoChevronUp
                                            size="1.25rem"
                                            color={colors.grays.NOIR}
                                        />
                                    ) : (
                                        <IoChevronDown
                                            size="1.25rem"
                                            color={colors.grays.NOIR}
                                        />
                                    )}
                                </IconButton>
                            </div>
                            {whoTheyHelpOpen && (
                                <Markdown
                                    content={props.config.who_they_help}
                                />
                            )}
                            {props.config.details && (
                                <>
                                    <div className={classes.sectionTitle}>
                                        <Typography
                                            component="h5"
                                            variant="h4"
                                            mt="1rem"
                                            mb="1rem"
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={onAboutThisServiceClick}
                                        >
                                            About This Service
                                        </Typography>
                                        <IconButton
                                            onClick={onAboutThisServiceClick}
                                        >
                                            {aboutThisServiceOpen ? (
                                                <IoChevronUp
                                                    size="1.25rem"
                                                    color={colors.grays.NOIR}
                                                />
                                            ) : (
                                                <IoChevronDown
                                                    size="1.25rem"
                                                    color={colors.grays.NOIR}
                                                />
                                            )}
                                        </IconButton>
                                    </div>
                                    {aboutThisServiceOpen && (
                                        <Markdown
                                            content={props.config.details}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} md={3.5} lg={3}>
                    <div className={classes.contactInfo}>
                        {props.config.link && (
                            <Link
                                component="button"
                                variant="body1"
                                onClick={onCsoCTAClick}
                                sx={{
                                    color: 'blue',
                                    textDecorationColor: 'blue',
                                    marginBottom: '0.2rem',
                                    lineHeight: 'unset',
                                }}
                            >
                                {props.config.link.includes('google.com')
                                    ? 'Find Near Me'
                                    : 'View Website'}
                            </Link>
                        )}
                        {props.config.phone && (
                            <Typography fontWeight={500}>
                                {props.config.is_text_line
                                    ? 'Text Line:'
                                    : 'Phone:'}{' '}
                                {props.config.phone}
                            </Typography>
                        )}
                    </div>
                </Grid>
            </Grid>
        </Card>
    ) : (
        <></>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        width: '100%',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    avatarContainer: {
        flex: '0 1 auto',
        paddingRight: '2rem',
        objectFit: 'contain',
    },
    contentContainer: {
        flex: '1 1 auto',
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    available247Icon: {
        height: '2rem',
        width: 'auto',
        paddingRight: '0.75rem',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: 'auto',
        backgroundColor: 'rgba(235, 235, 235, 0.5)',
        borderRadius: 5,
        padding: '1rem',
    },
})
