import { Markdown, Switch } from '../atoms'
import { Popover, Typography } from '@mui/material'
import React, { FC } from 'react'

import { useWindowSizeUp } from '../../lib/utils'
import { colors, hazardRequestURL } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'
import { localContentColor, theme } from '../../lib/styles/universal'
import { IoLocationSharp } from 'react-icons/io5'

export interface LocalContentSwitchProps {
    onLocalContentToggle(
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ): void
    enabled?: boolean
    showNoLocalContentPopover?: boolean
    hazardName?: string
    onClosePopover: React.MouseEventHandler
    popoverText?: string
}

export const LocalContentSwitch: FC<LocalContentSwitchProps> = (
    props: LocalContentSwitchProps
) => {
    const mediumWindowOrLarger = useWindowSizeUp('md')

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
    const handleClose: React.MouseEventHandler = (e) => {
        setAnchorEl(null)
        props.onClosePopover(e)
    }
    const open = Boolean(anchorEl)
    const { classes: localClasses } = useLocalStyles()

    React.useEffect(() => {
        if (props.showNoLocalContentPopover) {
            setAnchorEl(ref.current)
        }
    }, [props.showNoLocalContentPopover])

    const ref = React.useRef<HTMLDivElement>(null)

    const onLinkPress = (url: string) => {
        window.open(url, '_blank')
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
                ref={ref}
            >
                <IoLocationSharp size="2rem" color={localContentColor} />
                <Typography
                    fontWeight={500}
                    whiteSpace={mediumWindowOrLarger ? 'nowrap' : undefined}
                    textAlign="center"
                >
                    Show Local Information
                </Typography>
                <Switch
                    color={'success'}
                    checked={props.enabled}
                    onChange={props.onLocalContentToggle}
                />
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            backgroundColor: colors.grays.WHITESMOKE,
                            borderRadius: '1rem',
                        },
                    },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Markdown
                    className={localClasses.container}
                    content={
                        props.popoverText ??
                        `The area you are in does not have local information${
                            props.hazardName ? ` for ${props.hazardName}` : ''
                        }. To request hazard information for your area, send us a request [here](${hazardRequestURL}).`
                    }
                    onLinkPress={onLinkPress}
                />
            </Popover>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        margin: '1rem',
        width: '80vw',
        [theme.breakpoints.up('sm')]: {
            width: '30rem',
        },
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    ctas: {
        alignSelf: 'flex-end',
    },
})
