import React, { FC } from 'react'

import { useStyles } from '../../lib/styles/universal'

interface AppCopyrightMessageProps {}

export const AppCopyrightMessage: FC<AppCopyrightMessageProps> = (
    props: AppCopyrightMessageProps
) => {
    const { classes } = useStyles()
    return (
        <div className={classes.appCopyrightMessage}>
            Copyright {'\u00A9'} {new Date().getFullYear()} HazAdapt, Inc.
            <br />
            All rights reserved.
        </div>
    )
}
