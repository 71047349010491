import { Box, Button, Typography } from '@mui/material'
import React, { FC } from 'react'
import {
    ContentBlockPreviewProps,
    WhatToKnow911DetailsProps,
} from '../molecules'
import { CSOView, CSOViewProps } from '../organisms'
import { breakpoints, customDarkBlueColor } from '../../lib/styles/universal'
import {
    openHazAdaptInAppStoreAsync,
    openHazAdaptInGooglePlayAsync,
    useWindowSizeUp,
} from '../../lib/utils'
import MapIcon from './../../assets/images/MapIcon.svg'
import { colors } from '@hazadapt-git/public-core-base'

interface EmergencyHelpTemplateProps extends CSOViewProps {
    whatToKnow911Items: ContentBlockPreviewProps[]
    selectedWTC911Item?: WhatToKnow911DetailsProps
    loading?: boolean
}

export const EmergencyHelpTemplate: FC<EmergencyHelpTemplateProps> = (
    props: EmergencyHelpTemplateProps
) => {
    const largeWindowOrLarger = useWindowSizeUp('lg')
    const smallWindowOrLarger = useWindowSizeUp('sm')

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: smallWindowOrLarger ? '1rem' : '0.5rem',
                paddingRight: smallWindowOrLarger ? '1rem' : '0.5rem',
                paddingBottom: '1rem',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: smallWindowOrLarger ? 'row' : 'column',
                    justifyContent: 'space-between',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    width: '100%',
                    alignSelf: 'center',
                    alignItems: 'center',
                    maxWidth: breakpoints.lg,
                }}
            >
                <Typography
                    variant={largeWindowOrLarger ? 'h1' : 'h2'}
                    component="h1"
                    textAlign={smallWindowOrLarger ? 'left' : 'center'}
                    pb={smallWindowOrLarger ? 0 : '1rem'}
                    color={customDarkBlueColor}
                >
                    Get Emergency Help
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '1rem',
                            paddingLeft: '1rem',
                        }}
                    >
                        <Typography
                            component="h2"
                            variant="h4"
                            sx={{
                                color: colors.primary.CORAL,
                                paddingBottom: smallWindowOrLarger ? 0 : '1rem',
                            }}
                        >
                            Contact 911 in case of a life-threatening emergency.
                        </Typography>
                    </div>
                    <Box
                        sx={{
                            backgroundColor: colors.grays.WHITESMOKE,
                            padding: '0.5rem',
                            borderRadius: '0.625rem',
                            margin: '0.5rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                paddingLeft: smallWindowOrLarger
                                    ? '0.5rem'
                                    : undefined,
                                paddingRight: smallWindowOrLarger
                                    ? '0.5rem'
                                    : undefined,
                                paddingBottom: '0.1rem',
                            }}
                        >
                            <img src={MapIcon} alt="Map Icon" />
                            <Typography
                                sx={{
                                    paddingLeft: '0.5rem',
                                    width: smallWindowOrLarger ? 120 : 90,
                                    textAlign: 'center',
                                    fontWeight: 300,
                                }}
                            >
                                Map available in mobile app
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: 180,
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: customDarkBlueColor,
                                    borderRadius: 20,
                                    width: 85,
                                    height: 25,
                                    fontSize: 12,
                                    padding: 0,
                                }}
                                onClick={openHazAdaptInAppStoreAsync}
                            >
                                App Store
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    backgroundColor: customDarkBlueColor,
                                    borderRadius: 20,
                                    width: 85,
                                    height: 25,
                                    fontSize: 12,
                                    padding: 0,
                                }}
                                onClick={openHazAdaptInGooglePlayAsync}
                            >
                                Google Play
                            </Button>
                        </div>
                    </Box>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: largeWindowOrLarger ? breakpoints.lg : '100%',
                    alignSelf: 'center',
                }}
            >
                <CSOView {...props} />
            </div>
            {/* will implement when data is in isa */}
            {/* <div style={{ padding: '2rem' }}>
            <Typography variant="h1" sx={{ color: customDarkBlueColor, padding: '1rem' }}>Calling 911 What You Need To Know</Typography>
            <WhatToKnow911View 
                items={props.whatToKnowItems}
                selectedItem={props.selectedItem}
            />
            </div> */}
        </div>
    )
}
