import {
    FilterConfig,
    BoosterConfig,
    SupportedHazardFilter,
    SupportedContentBooster,
    colors,
} from '@hazadapt-git/public-core-base'
import { Chip as MuiChip, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'

import {
    unavailableChipColor,
    unavailableChipTextColor,
} from '../../lib/styles/universal'

interface ChipProps {
    config: FilterConfig | BoosterConfig
    active: boolean
    available?: boolean
    start?: boolean
    end?: boolean
    onChipPress?(key: SupportedHazardFilter | SupportedContentBooster): void
    onDelete?(key: SupportedHazardFilter | SupportedContentBooster): void
    showDeleteIcon?: boolean
    sx?: SxProps<Theme>
    clickable?: boolean
}

export const Chip: FC<ChipProps> = (props: ChipProps) => {
    const chipColor =
        props.available || props.available === undefined
            ? props.config.color
            : unavailableChipColor
    const chipTextColor =
        props.available || props.available === undefined
            ? colors.grays.BLANC
            : unavailableChipTextColor

    const baseChipStyle: SxProps<Theme> = {
        marginTop: '0.25rem',
        marginBottom: '0.25rem',
        paddingLeft: '0.45rem',
        cursor: 'pointer',
    }

    const onClick = () => {
        if (!props.onChipPress) return
        if (props.available || props.available === undefined) {
            props.onChipPress(props.config.key)
        }
    }

    return (
        <MuiChip
            sx={
                props.active
                    ? {
                          ...baseChipStyle,
                          ...props.sx,
                          backgroundColor: chipColor,
                          color: chipTextColor,
                          ':hover': {
                              backgroundColor: chipColor,
                          },
                          borderWidth: 1,
                          border: `1px ${chipColor} solid`,
                      }
                    : {
                          ...baseChipStyle,
                          ...props.sx,
                      }
            }
            clickable={props.clickable === false ? false : true}
            label={props.config.label}
            onClick={onClick}
            onDelete={
                props.showDeleteIcon && props.active ? onClick : undefined
            }
            variant={props.active ? 'filled' : 'outlined'}
            icon={
                <img
                    src={
                        props.active
                            ? props.config.icon
                            : props.config.unselectedIcon
                    }
                    alt={`${props.config.label} icon`}
                />
            }
        />
    )
}
