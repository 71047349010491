import {
    LanguageCode,
    colors,
    getLangObjects,
} from '@hazadapt-git/public-core-base'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { useReactToPrint } from 'react-to-print'
import { IoPrint } from 'react-icons/io5'
import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import {
    EmergencyItem,
    LanguageSelectorModal,
    MyEmergencyItemsList,
} from '../organisms'
import Translate from '../../assets/icons/Translate-Outline.svg'

interface MyEmergencyItemsPageTemplateProps {
    itemsUserHas: EmergencyItem[]
    itemsUserDoesNotHave: EmergencyItem[]
    // onShareItemsUserHas: React.MouseEventHandler
    // onShareItemsUserDoesNotHave: React.MouseEventHandler
    onCheckItem(item_name: string): void
    onPrintItemLists: React.MouseEventHandler
    // onShareAllItemLists: React.MouseEventHandler
    showLoginWall?: boolean
    onCloseLoginWall: React.MouseEventHandler
    onLogin: React.MouseEventHandler
    loading?: boolean
    onTranslateClick?: React.MouseEventHandler
    languages: LanguageCode[]
    selectedLanguage: LanguageCode
    languageSelectorModalOpen?: boolean
    onLanguageSelectorModalClose: React.MouseEventHandler
    onLanguageChange(lang: LanguageCode): void
}

export const MyEmergencyItemsPageTemplate: FC<
    MyEmergencyItemsPageTemplateProps
> = (props: MyEmergencyItemsPageTemplateProps) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const largeWindowOrLarger = useWindowSizeUp('lg')

    const itemListsRef = React.useRef(null)
    const [readyToPrint, setReadyToPrint] = React.useState<boolean>(false)

    const handlePrint = useReactToPrint({
        content: () => itemListsRef.current,
        onAfterPrint: () => setReadyToPrint(false),
    })

    React.useEffect(() => {
        if (readyToPrint) {
            handlePrint()
        }
    }, [readyToPrint, handlePrint])

    return props.loading ? (
        <div className={localClasses.loading}>
            <CircularProgress size="1.5rem" />
            <Typography variant="h4">Loading items...</Typography>
        </div>
    ) : (
        <div
            className={classes.page}
            style={{
                padding: '0 3rem',
                margin: '1rem 0 3rem',
            }}
            ref={itemListsRef}
        >
            <div
                className={localClasses.header}
                style={{
                    width: mediumWindowOrLarger ? `calc(100% - 6rem)` : '100%',
                }}
            >
                <div className={localClasses.title}>
                    <Typography
                        variant={largeWindowOrLarger ? 'h1' : 'h2'}
                        component="h1"
                        textAlign="left"
                        color={customDarkBlueColor}
                    >
                        My Emergency Items
                    </Typography>
                    <IconButton onClick={props.onTranslateClick}>
                        <img
                            src={Translate}
                            className={localClasses.translateIcon}
                            alt="Translate"
                        />
                    </IconButton>
                </div>
                <div className={localClasses.actions}>
                    <IconButton
                        onClick={() => {
                            setReadyToPrint(true)
                        }}
                    >
                        <IoPrint
                            color={colors.primary.CERULEAN}
                            size="1.5rem"
                        />
                    </IconButton>
                    {/* <IconButton onClick={props.onShareAllItemLists}>
                        <IoShareSocial
                            color={colors.primary.CERULEAN}
                            size="1.5rem"
                        />
                    </IconButton> */}
                </div>
            </div>
            <Divider
                sx={{
                    width: mediumWindowOrLarger ? `calc(100% - 6rem)` : '100%',
                    alignSelf: 'flex-start',
                    margin: '1rem 0 2rem',
                }}
            />
            <Grid container columnSpacing={12} rowSpacing={4}>
                <Grid
                    item
                    xs={12}
                    md={props.itemsUserDoesNotHave.length === 0 ? 12 : 6}
                    display="flex"
                    justifyContent={
                        mediumWindowOrLarger &&
                        props.itemsUserDoesNotHave.length > 0
                            ? 'flex-end'
                            : 'center'
                    }
                >
                    <MyEmergencyItemsList
                        listTitle="I have..."
                        items={props.itemsUserHas}
                        onCheck={props.onCheckItem}
                        fullyExpanded={readyToPrint}
                        // onShare={props.onShareItemsUserHas}
                    />
                </Grid>
                {props.itemsUserDoesNotHave.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        justifyContent={
                            mediumWindowOrLarger ? 'flex-start' : 'center'
                        }
                    >
                        <MyEmergencyItemsList
                            listTitle="I do not have..."
                            items={props.itemsUserDoesNotHave}
                            onCheck={props.onCheckItem}
                            fullyExpanded={readyToPrint}
                            // onShare={props.onShareItemsUserDoesNotHave}
                        />
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={!!props.showLoginWall}
                PaperProps={{ sx: { padding: '1rem' } }}
            >
                <DialogTitle sx={{ paddingRight: '2.5rem' }}>
                    <Typography variant="h3">Log In to Continue</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You need to be logged in to view your emergency items.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={props.onCloseLoginWall}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onLogin}
                    >
                        Log In
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Language selector modal */}
            <LanguageSelectorModal
                languages={getLangObjects(props.languages)}
                selectedLanguage={props.selectedLanguage}
                open={!!props.languageSelectorModalOpen}
                onClose={props.onLanguageSelectorModalClose}
                onLanguagePress={props.onLanguageChange}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        alignSelf: 'flex-start',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem',
    },
    loading: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 0',
    },
    translateIcon: {
        width: '2rem',
        height: 'auto',
    },
})
