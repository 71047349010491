import {
    HazardDTO,
    HazardOverview,
    LanguageCode,
    NativeLanguageDTO,
    TranslatedString,
} from '@hazadapt-git/public-core-base'
import didYouMean from 'didyoumean'
import Fuse from 'fuse.js'
import rdiff from 'recursive-diff'
import voca from 'voca'
import { isa } from '../api'

import { SearchResultsWithDYM } from '../entities'
import { getCurrentLocation } from './misc'
import { readAsync } from '../async-storage'

/**
 * Searches for and gets all hazards that sufficiently match the given pattern
 * @param hazards
 * @param query
 * @returns Hazard[]
 */
export const searchHazards = async (
    hazards: HazardOverview[],
    query: string,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<{ hazards: HazardOverview[]; suggestedQuery?: string }> => {
    if (query.length === 0) return { hazards }

    try {
        const locoEnabled = readAsync('@loCoOn') === 'true'
        let state: 'denied' | 'granted' | 'prompt' | undefined
        if (navigator.permissions) {
            const result = await navigator.permissions.query({
                name: 'geolocation',
            })
            state = result.state
        }
        let point: [number, number] | undefined = undefined
        if (state !== 'denied' && locoEnabled) {
            try {
                const position: GeolocationPosition = await getCurrentLocation()
                const { latitude, longitude } = position.coords
                point = [latitude, longitude]
            } catch (err) {
                console.error(err)
            }
        }
        const response = await isa.get<SearchResultsWithDYM>(
            `/public/guide/search/v2?query=${query}${
                point ? `&lat=${point[0]}&lng=${point[1]}` : ''
            }`
        )
        if (response.status === 204) return { hazards } // Blank query passed to ISA; show all hazards
        const { hazards: resultIDs, suggestedQuery } = response.data
        const hazardsToReturn: HazardOverview[] = []
        for (const id of resultIDs) {
            const hazard = hazards.find((h) => h.id === id)
            if (hazard) hazardsToReturn.push(hazard)
        }
        return {
            hazards: hazardsToReturn,
            suggestedQuery,
        }
    } catch (err) {
        console.error(err)

        const options = {
            // isCaseSensitive: false,
            // includeScore: false,
            // shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            threshold: 0.2,
            // distance: 100,
            // useExtendedSearch: false,
            ignoreLocation: true,
            // ignoreFieldNorm: false,
            keys: [
                {
                    name: `name.${lang.toString()}`,
                    weight: 4,
                },
                {
                    name: 'types',
                    weight: 3,
                },
                {
                    name: 'tags',
                    weight: 2,
                },
                {
                    name: `description.${lang.toString()}`,
                    weight: 1,
                },
            ],
        }

        const fuse: Fuse<HazardOverview> = new Fuse(hazards, options)
        const search_results: Fuse.FuseResult<HazardOverview>[] =
            fuse.search(query)
        const returned_hazards: HazardOverview[] = [] as HazardOverview[]

        search_results.forEach((sr) => {
            returned_hazards.push(sr.item)
        })

        const keywords: Set<string> = new Set<string>()
        for (const h of hazards) {
            // Add the hazard's name (full and by word),
            // description words, tags, and types
            keywords.add(h.name)
            h.name.split(' ').forEach((k) => keywords.add(k))
            h.description.split(' ').forEach((k) => keywords.add(k))
            h.tags.forEach((k) => keywords.add(k))
            h.types
                .map((t) => t.toString().replace('_', ' '))
                .forEach((k) => keywords.add(k))
        }
        const suggestedQuery: string | undefined = getClosestHazardQueryLocally(
            query,
            Array.from(keywords)
        )

        return {
            hazards: returned_hazards,
            suggestedQuery,
        }
    }
}

/**
 * Get the closest hazard query to the submitted query
 * @param query The submitted query
 * @param keywords The list of "good" hazard keywords
 * @returns string
 */
export const getClosestHazardQueryLocally = (
    query: string,
    keywords: string[]
): string | undefined => {
    const lowercaseKeywords = keywords.map((k: string) => k.toLowerCase())
    if (lowercaseKeywords.includes(query)) {
        return undefined
    }
    const results = didYouMean(query, lowercaseKeywords)
    return results ? results.toString() : undefined
}

/**
 * Takes an old and new state of a hazard object and replaces old values with new values
 * @param currentHazard
 * @param latestHazard
 * @returns Hazard
 */
export function updateHazard(
    currentHazard: HazardDTO,
    latestHazard: HazardDTO
): HazardDTO {
    if (currentHazard.id !== latestHazard.id) {
        return currentHazard
    }

    const diff: rdiff.rdiffResult[] = rdiff.getDiff(currentHazard, latestHazard)
    const updatedHazard: HazardDTO = rdiff.applyDiff(currentHazard, diff)

    return updatedHazard
}

/**
 * removeLanguagesFromObject
 * Removes specified translations from an object
 * @param obj The object to be filtered
 * @param languages The list of languages to remove
 * @returns TranslatedString
 */
export const filterObjectByLanguage = (
    obj: TranslatedString,
    languages: string[]
): TranslatedString => {
    return Object.keys(obj)
        .filter((key) => !languages.includes(key))
        .reduce((filteredObj: TranslatedString, key: string) => {
            filteredObj[key] = obj[key]
            return filteredObj
        }, {})
}

/**
 * getLangRadioButtonObjects
 * Builds a list of objects to feed to the radio button list for the language selector
 * @param languages
 * @returns NativeLanguageDTO[]
 */
export const getLangObjects = (
    languages: LanguageCode[]
): NativeLanguageDTO[] => {
    // If this hazard has no languages, return an empty list
    if (languages !== undefined && languages.length === 0) return []
    return Object.keys(LanguageCode)
        .filter(
            (l) =>
                l.length > 2 &&
                (languages === undefined ||
                    languages.includes(
                        LanguageCode[l as keyof typeof LanguageCode]
                    ))
        ) // Only get supported languages
        .map((l) => {
            const val = LanguageCode[l as keyof typeof LanguageCode]
            const regionLang = new Intl.DisplayNames([val.toString()], {
                type: 'language',
            })
            const languageName: string =
                (val.toString() === 'vi'
                    ? regionLang.of(val.toString())
                    : voca.titleCase(regionLang.of(val.toString()))) || // Handles weird title-casing for Vietnamese
                voca.titleCase(val.toString()) // If it fails for some reason, use the English-language name for this language
            return {
                title: languageName,
                value: val,
            }
        })
}
