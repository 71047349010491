import { Typography, IconButton, Grid, Divider, Container } from '@mui/material'
import React, { FC } from 'react'
import classNames from 'classnames'
import {
    BoosterConfig,
    SupportedContentBooster,
    colors,
} from '@hazadapt-git/public-core-base'

import { makeStyles } from 'tss-react/mui'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { ContentBooster } from '../../lib/entities'
import { Checkbox, Chip } from '../atoms'

interface InformationCustomizationsTabProps {
    boosters: BoosterConfig[]
    onBoosterPress(value: string): void
    fullyExpanded?: boolean
    activeBoosters: SupportedContentBooster[]
}

export const InformationCustomizationsTab: FC<
    InformationCustomizationsTabProps
> = (props: InformationCustomizationsTabProps) => {
    const { classes } = useLocalStyles()

    const [open, setOpen] = React.useState<boolean>(true)

    return (
        <Container
            className={classNames(
                classes.container,
                props.fullyExpanded ? classes.fullyExpandedContainer : undefined
            )}
        >
            <div className={classes.header}>
                <Typography
                    onClick={() => setOpen(!open)}
                    component="h5"
                    variant="h4"
                    mt="1rem"
                    mb="1rem"
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    Information Customizations
                </Typography>
                <IconButton onClick={() => setOpen(!open)}>
                    {open ? (
                        <IoChevronUp color={colors.grays.NOIR} size="1.5rem" />
                    ) : (
                        <IoChevronDown
                            color={colors.grays.NOIR}
                            size="1.5rem"
                        />
                    )}
                </IconButton>
            </div>
            {open && (
                <Grid container columnSpacing={10}>
                    <Grid item xs={12}>
                        <Typography
                            fontWeight={500}
                            color={colors.grays.NIMBUS}
                            textAlign="left"
                            pb="1.5rem"
                        >
                            Select your default information customization
                            preferences for the Hazard Guide.
                        </Typography>
                    </Grid>
                    {Object.values(ContentBooster)
                        .filter((cb) => cb.key !== 'Veteran')
                        .map((cb, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                onClick={(e) => {
                                    e.preventDefault()
                                    props.onBoosterPress(cb.key)
                                }}
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                key={cb.key}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Checkbox
                                        label={
                                            <Chip config={cb} active={true} />
                                        }
                                        checked={props.activeBoosters.includes(
                                            cb.key
                                        )}
                                        labelPlacement="start"
                                        containerStyle={{
                                            cursor: 'pointer',
                                            margin: 0,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    />
                                    {index < props.boosters.length - 1 && (
                                        <Divider
                                            sx={{
                                                width: '100%',
                                                margin: '0.5rem 0',
                                            }}
                                        />
                                    )}
                                </div>
                            </Grid>
                        ))}
                </Grid>
            )}
        </Container>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        borderRadius: '0.5rem',
        height: 'fit-content',
        display: 'flex !important',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    fullyExpandedContainer: {
        maxHeight: 'unset',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
})
