import {
    CrisisSupportOption,
    CSOResponse,
} from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'
import Fuse from 'fuse.js'

import { isa } from '../api'

export const getCrisisSupportOptions = async (): Promise<CSOResponse> => {
    // get latest CSOs
    const res: AxiosResponse<CSOResponse> = await isa.get(
        `/public/crisis-support-option`
    )
    return res.data
}

/**
 * Search for CSOs that match the provided query
 * @param csos The full list of CSOs to search through
 * @param pattern The search query
 * @returns A list of CSOs that match the search query
 */
export const searchCSOs = (
    csos: CrisisSupportOption[],
    pattern: string
): CrisisSupportOption[] => {
    const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        threshold: 0.5,
        // distance: 100,
        // useExtendedSearch: false,
        ignoreLocation: true,
        // ignoreFieldNorm: false,
        keys: [
            {
                name: 'title',
                weight: 5,
            },
            {
                name: 'subheader',
                weight: 4,
            },
            {
                name: 'examples',
                weight: 3,
            },
            {
                name: 'who_they_help',
                weight: 2,
            },
            {
                name: 'details',
                weight: 1,
            },
        ],
    }
    if (pattern.length === 0) return csos

    const fuse: Fuse<CrisisSupportOption> = new Fuse(csos, options)
    const searchResults: Fuse.FuseResult<CrisisSupportOption>[] =
        fuse.search(pattern)
    return searchResults.map((sr) => sr.item)
}
