import { AxiosResponse } from 'axios'
import { authAPI, isa } from '../api'
import { getEnvVars } from '../config'

const { authUrl } = getEnvVars()

export const logout = async (returnToUrl?: string): Promise<void> => {
    try {
        const response = await authAPI.get(`/self-service/logout/browser`)
        const { logout_token } = response.data
        await authAPI.get(`/self-service/logout?token=${logout_token}`)
        if (returnToUrl) window.location.href = returnToUrl
        else if (window.location.pathname === '/settings/account') {
            window.location.pathname = '/settings'
        } else if (window.location.pathname.startsWith('/prep-checks')) {
            window.location.pathname = '/'
        } else {
            window.location.reload()
        }
        return
    } catch (e) {
        console.error(e)
    }
}

/**
 * Takes the user to the login flow of the auth system
 * @param returnToUrl
 * @returns
 */
export const login = async (returnToUrl?: string): Promise<void> => {
    try {
        const response: AxiosResponse<string> = await isa.get(
            `/public/auth/login?callback=${returnToUrl || window.location.href}`
        )
        window.location.href = response.data
    } catch (e) {
        console.error(e)
    }
}

/**
 * Route the user to create an account
 * @param returnToUrl the URL to return to once signup is done
 */
export const signup = async (returnToUrl?: string): Promise<void> => {
    try {
        const response: AxiosResponse<string> = await isa.get(
            `/public/auth/signup?callback=${
                returnToUrl || window.location.href
            }`
        )
        window.location.href = response.data
    } catch (e) {
        console.error(e)
    }
}

/**
 * Takes the user to the Auth dashboard
 */
export const goToAuthDashboard = (): void => {
    window.open(authUrl, '_blank', 'noopener')
}
