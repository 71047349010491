import { Link, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC, CSSProperties } from 'react'

import {
    customMidGrayColor,
    altHazRedColor,
    localContentColor,
} from '../../lib/styles/universal'
import { HazardCategoryProps } from './HazardCategory'
import { IoLocationSharp } from 'react-icons/io5'

interface HazardStageProps extends HazardCategoryProps {
    icon: React.ReactNode
    hasLocalContent: boolean
    style?: CSSProperties
}

export const HazardStage: FC<HazardStageProps> = (props: HazardStageProps) => {
    const { classes } = useLocalStyles()
    return (
        <Link
            onClick={props.onPress}
            style={{ ...props.style, textDecoration: 'none' }}
        >
            <div
                className={classes.grid}
                style={{
                    borderBottom: `2px ${
                        props.active ? altHazRedColor : customMidGrayColor
                    } solid`,
                }}
            >
                {props.hasLocalContent && (
                    <IoLocationSharp
                        size="1.5rem"
                        color={localContentColor}
                        className={classes.localDot}
                    />
                )}
                <div className={classes.icon}>{props.icon}</div>
                <Typography
                    color={props.active ? altHazRedColor : customMidGrayColor}
                    fontWeight={500}
                >
                    {props.name}
                </Typography>
            </div>
        </Link>
    )
}

const useLocalStyles = makeStyles()({
    grid: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingLeft: 1,
        paddingRight: 1,
        cursor: 'pointer',
        justifyContent: 'center',
        textAlign: 'left',
        position: 'relative',
    },
    icon: {
        paddingRight: 5,
    },
    localDot: {
        alignSelf: 'center',
    },
})
