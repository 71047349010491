import { useDraggable } from '@dnd-kit/core'
import React, { FC } from 'react'
import {
    colors,
    PrepCheckAnswerOptionDTO,
    Image,
} from '@hazadapt-git/public-core-base'
import { Avatar, IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Card } from '../atoms'
import ordinal from 'ordinal'
import { PrepCheckAnswerCard } from './PrepCheckAnswerCard'
import classNames from 'classnames'
import { IoCloseOutline } from 'react-icons/io5'
import { useDroppable } from '@dnd-kit/core'

export interface PrepCheckCardDropItemProps {
    index: number
    active: boolean
    questionType: 'OneD_Ordered_List' | 'TwoD_Grid_Placement'
    priority?: number
    prepStage?: string
    answerOption?: PrepCheckAnswerOptionDTO
    onClick?(index: number): void
    onClear?(index: number): void
    selected?: boolean
    dragging?: boolean
}

interface PrepCheck1DAnswerPreviewProps {
    id: number
    image?: Image
    dragging?: boolean
    selected?: boolean
    onClick?(id: number): void
}

export const PrepCheck1DAnswerPreview: FC<PrepCheck1DAnswerPreviewProps> = (
    props: PrepCheck1DAnswerPreviewProps
) => {
    const { classes } = useLocalStyles()
    const backgroundColor = colors.grays.BLANC

    const {
        attributes,
        listeners,
        setNodeRef: setDragRef,
    } = useDraggable({ id: `card_${props.id}` })

    return (
        <Avatar
            {...attributes}
            {...listeners}
            ref={setDragRef}
            className={classNames(
                classes.answeredRank,
                props.selected ? classes.selectedAnsweredRank : undefined
            )}
            sx={{
                color: backgroundColor,
                bgcolor: backgroundColor,
                width: '4rem',
                height: '4rem',
                objectFit: 'contain',
                boxShadow: 'inset 0rem 0.125rem 0.125rem #000000',
                pointerEvents: props.dragging ? 'none' : 'auto',
                visibility: props.dragging ? 'hidden' : 'visible',
            }}
            onClick={() => props.onClick?.(props.id)}
        >
            {!props.dragging && (
                /* eslint-disable-next-line jsx-a11y/img-redundant-alt */
                <img
                    style={{
                        width: '2.5rem',
                        height: '2.5rem',
                        objectFit: 'contain',
                    }}
                    src={props.image?.url || props.image?.src}
                    alt={props.image?.alt}
                />
            )}
        </Avatar>
    )
}

export const PrepCheckCardDropItem: FC<PrepCheckCardDropItemProps> = (
    props: PrepCheckCardDropItemProps
) => {
    const { classes } = useLocalStyles()
    const rankStyle =
        props.active || props.selected
            ? classes.activeRank
            : classes.inactiveRank
    const backgroundColor = props.active
        ? colors.softTones.CERULEAN
        : colors.grays.BLANC
    let prepStageColor = 'During'
    if (props.prepStage === 'Before') {
        prepStageColor = colors.primary.BLUEBERRY
    } else if (props.prepStage === 'During') {
        prepStageColor = colors.primary.CORAL
    } else if (props.prepStage === 'After') {
        prepStageColor = colors.primary.WAIKATO
    }

    const { setNodeRef: setDropRef } = useDroppable({
        id: `drop_${props.index}`,
    })

    return (
        <div ref={setDropRef}>
            {props.priority && props.questionType === 'OneD_Ordered_List' ? (
                props.answerOption ? (
                    <Card
                        colors={[colors.grays.BLANC]}
                        className={classes.container}
                        innerContainerStyle={{ padding: '0 1rem 1rem' }}
                    >
                        <Typography
                            color={
                                props.active
                                    ? colors.grays.NOIR
                                    : colors.grays.THUNDERCLOUD
                            }
                            textAlign="center"
                            fontWeight={500}
                            mb="0.25rem"
                        >
                            {ordinal(props.priority)}
                        </Typography>
                        <div style={{ position: 'relative' }}>
                            <PrepCheck1DAnswerPreview
                                id={props.answerOption.id}
                                image={props.answerOption.image}
                                dragging={props.dragging}
                                onClick={props.onClick}
                                selected={props.selected}
                            />
                            {props.onClear && !props.dragging && (
                                <IconButton
                                    sx={{
                                        backgroundColor:
                                            colors.grays.THUNDERCLOUD,
                                        padding: 0,
                                        position: 'absolute',
                                        top: '-0.25rem',
                                        right: '-0.25rem',
                                        ':hover': {
                                            backgroundColor:
                                                colors.grays.CUMULUS,
                                        },
                                        boxShadow: `0 1px 2px ${colors.grays.NIMBUS}`,
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        props.onClear &&
                                            props.onClear(props.index)
                                    }}
                                >
                                    <IoCloseOutline
                                        size="1.5rem"
                                        color={colors.grays.BLANC}
                                    />
                                </IconButton>
                            )}
                        </div>
                    </Card>
                ) : (
                    <Card
                        colors={[colors.grays.BLANC]}
                        className={classes.container}
                        innerContainerStyle={{ padding: '0 1rem 1rem' }}
                    >
                        {props.priority && (
                            <Typography
                                color={
                                    props.active
                                        ? colors.grays.NOIR
                                        : colors.grays.THUNDERCLOUD
                                }
                                textAlign="center"
                                fontWeight={500}
                                mb="0.25rem"
                            >
                                {ordinal(props.priority)}
                            </Typography>
                        )}
                        <Avatar
                            className={rankStyle}
                            sx={{
                                color: backgroundColor,
                                bgcolor: backgroundColor,
                                width: '3.125rem',
                                height: '3.125rem',
                                boxShadow:
                                    'inset 0rem 0.125rem 0.125rem #000000',
                            }}
                        />
                    </Card>
                )
            ) : (
                <div className={classes.container}>
                    <Avatar
                        className={classes.priority}
                        sx={{
                            bgcolor: prepStageColor,
                            height: '2rem',
                            width: '2rem',
                        }}
                    >
                        {props.priority}
                    </Avatar>
                    <div
                        className={classNames(
                            classes.emptyCard,
                            props.answerOption && !props.dragging
                                ? classes.filledSpot
                                : ''
                        )}
                        onClick={
                            !props.selected && !props.answerOption
                                ? (e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      props.onClick &&
                                          props.onClick(props.index)
                                  }
                                : undefined
                        }
                    >
                        {props.answerOption && !props.dragging && (
                            <div className={classes.answerCard}>
                                <PrepCheckAnswerCard
                                    answerText={props.answerOption.text}
                                    answerInfo={
                                        props.answerOption.explainer_text
                                    }
                                    id={props.answerOption.id}
                                    questionType={props.questionType}
                                    shrinkToFit
                                    selected={props.selected}
                                    onClick={
                                        props.answerOption
                                            ? () => {
                                                  if (
                                                      props.onClick &&
                                                      props.answerOption
                                                  ) {
                                                      props.onClick(
                                                          props.answerOption.id
                                                      )
                                                  }
                                              }
                                            : undefined
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {props.onClear && props.answerOption && (
                        <IconButton
                            sx={{
                                backgroundColor: colors.grays.THUNDERCLOUD,
                                padding: 0,
                                position: 'absolute',
                                top: '-0.25rem',
                                right: '-0.25rem',
                                ':hover': {
                                    backgroundColor: colors.grays.CUMULUS,
                                },
                                boxShadow: `0 1px 2px ${colors.grays.NIMBUS}`,
                            }}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.onClear && props.onClear(props.index)
                            }}
                        >
                            <IoCloseOutline
                                size="1.5rem"
                                color={colors.grays.BLANC}
                            />
                        </IconButton>
                    )}
                </div>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    activeRank: {
        border: `0.1875rem solid !important`,
        borderColor: `${colors.primary.WAIKATO} !important`,
        boxSizing: 'border-box',
    },
    inactiveRank: {
        border: '0.0625rem solid',
        borderColor: colors.grays.WHITESMOKE,
        boxSizing: 'border-box',
    },
    answeredRank: {
        border: '0.0625rem solid',
        borderColor: colors.grays.THUNDERCLOUD,
        boxSizing: 'border-box',
    },
    selectedAnsweredRank: {
        borderColor: colors.primary.WAIKATO,
        borderWidth: '0.1875rem',
    },
    answerCard: {
        display: 'flex',
        zIndex: -1,
    },
    container: {
        display: 'flex',
        cursor: 'pointer',
        position: 'relative',
    },
    emptyCard: {
        minHeight: '7rem',
        height: 'fit-content',
        width: 'min(30vw, 8.5rem)',
        border: '0.09375rem dashed',
        borderRadius: '1rem',
        borderColor: colors.grays.NIMBUS,
        alignContent: 'center',
        boxSizing: 'border-box',
    },
    filledSpot: {
        border: 'none',
    },
    priority: {
        position: 'absolute !important' as any,
        top: '-0.5rem',
        left: '-0.5rem',
        zIndex: 1,
    },
})
