import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import {
    PrepCheckResultPreviewItem,
    PrepCheckResultPreviewItemPropsBase,
} from '../molecules'

interface PrepCheckResultPreviewItemListProps {
    questions: PrepCheckResultPreviewItemPropsBase[]
    onToggle(id: number): void
    open?: number
}

export const PrepCheckResultPreviewItemList: FC<
    PrepCheckResultPreviewItemListProps
> = (props: PrepCheckResultPreviewItemListProps) => {
    const { classes } = useLocalStyles()

    return (
        <div className={classes.container}>
            {props.questions.map((q) => (
                <PrepCheckResultPreviewItem
                    {...q}
                    key={`resultPreviewItem_${q.questionId}`}
                    open={props.open === q.questionId}
                    onToggle={props.onToggle}
                />
            ))}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        width: '100%',
    },
})
