import { BookmarkDTO, BookmarkOverview } from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'

import { isa } from '../api'
import { readAsyncJSON, storeAsyncJSON } from '../async-storage'
import { store } from '../store'

export const addBookmark = async (hazard_id: number): Promise<void> => {
    const user = store.getState().profile.user
    if (user) {
        try {
            // save the bookmark to the user's account
            await isa.post('public/guide/bookmark', { hazard_id })
        } catch (err) {
            throw new Error(
                'There was an error saving your bookmark. Please try again.'
            )
        }
    } else {
        let localBookmarks: BookmarkDTO[] = readAsyncJSON(
            '@bookmarks'
        ) as BookmarkDTO[]
        if (!localBookmarks || localBookmarks.length === 0) {
            // no local bookmarks
            localBookmarks = [
                {
                    hazard_id,
                    bookmark_id: 1,
                },
            ]
        } else {
            // there are local bookmarks
            const index: number = localBookmarks.findIndex(
                (b: BookmarkDTO) => b.hazard_id === hazard_id
            )
            if (index === -1) {
                // this hazard is not already bookmarked
                const maxID: number = Math.max(
                    ...localBookmarks.map((b) => b.bookmark_id)
                ) // find the largest bookmark ID
                localBookmarks.push({
                    hazard_id,
                    bookmark_id: maxID + 1,
                })
            }
        }
        storeAsyncJSON('@bookmarks', localBookmarks)
    }
}

export const getBookmarks = async (): Promise<BookmarkOverview[]> => {
    const { user, language } = store.getState().profile
    if (user) {
        try {
            const response: AxiosResponse<BookmarkOverview[]> = await isa.get(
                // get user's bookmarks from ISA
                `public/v2/authenticated/bookmarks?lang=${user.preferred_language}`
            )
            return response.data
        } catch (err) {
            throw new Error('There was an error getting your bookmarks.')
        }
    } else {
        let localBookmarks: BookmarkDTO[] = readAsyncJSON(
            '@bookmarks'
        ) as BookmarkDTO[]
        if (!localBookmarks) {
            localBookmarks = []
            storeAsyncJSON('@bookmarks', localBookmarks)
        }
        try {
            const response: AxiosResponse<BookmarkOverview[]> = await isa.get(
                // get user's bookmarks from ISA
                `public/v2/bookmarks?lang=${language}${
                    localBookmarks.length > 0
                        ? `&bookmarks=${localBookmarks
                              .map((b) => b.hazard_id)
                              .toString()}`
                        : ''
                }`
            )
            const bookmarks: BookmarkOverview[] = []
            for (const item of response.data) {
                const bookmark = localBookmarks.find(
                    (lb) => lb.hazard_id === item.hazard_id
                )
                if (!bookmark) continue
                bookmarks.push({
                    ...item,
                    bookmark_id: bookmark.bookmark_id,
                })
            }
            return bookmarks
        } catch (err) {
            throw new Error('There was an error getting your bookmarks.')
        }
    }
}

export const removeBookmark = async (bookmark_id: number): Promise<void> => {
    const user = store.getState().profile.user
    if (user) {
        try {
            await isa.delete(
                // delete the bookmark from the backend
                `public/guide/bookmark/${bookmark_id}`
            )
        } catch (err) {
            throw new Error(
                'There was an error deleting your bookmark. Please try again.'
            )
        }
    } else {
        let localBookmarks: BookmarkDTO[] = readAsyncJSON(
            '@bookmarks'
        ) as BookmarkDTO[]
        if (!localBookmarks) {
            localBookmarks = []
            storeAsyncJSON('@bookmarks', localBookmarks)
            throw new Error('No bookmarks')
        }
        const index: number = localBookmarks.findIndex(
            (b) => b.bookmark_id === bookmark_id
        )
        if (index !== -1) {
            localBookmarks.splice(index, 1)
            storeAsyncJSON('@bookmarks', localBookmarks)
        }
    }
}
