import { colors, TrendingHazardDTO } from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

interface TrendingHazardItemProps extends TrendingHazardDTO {
    onPress(id: number): void
}

export const TrendingHazardItem: FC<TrendingHazardItemProps> = (
    props: TrendingHazardItemProps
) => {
    const [fontSize, setFontSize] = React.useState<string>('1rem')
    const { classes: localClasses } = useLocalStyles()

    const containerId = `trending-hazard-${props.id}`
    const titleId = `trending-hazard-${props.id}-name`
    React.useEffect(() => {
        const title = document.getElementById(titleId)

        if (!title) return
        const containerWidth =
            5.25 *
            parseFloat(getComputedStyle(document.documentElement).fontSize)
        if (title.offsetWidth > containerWidth) {
            const resizeRatio = containerWidth / title.offsetWidth
            setFontSize(`${resizeRatio}rem`)
        }
    }, [containerId, titleId])
    return (
        <div
            className={localClasses.container}
            onClick={() => props.onPress(props.id)}
            id={containerId}
        >
            <div className={localClasses.iconContainer}>
                <img
                    src={props.icon.url || props.icon.src}
                    alt={props.icon.alt}
                    className={localClasses.icon}
                />
            </div>
            <Typography
                fontWeight={500}
                textAlign="center"
                sx={{ overflowWrap: 'normal', hyphens: 'auto' }}
                id={titleId}
                fontSize={fontSize}
                lineHeight="1.375rem"
            >
                {props.name}
            </Typography>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        width: '5.25rem',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '0.5rem',
        alignItems: 'center',
        cursor: 'pointer',
    },
    iconContainer: {
        width: '4.5rem',
        height: '4.5rem',
        borderRadius: '50%',
        border: `2px solid ${colors.secondary.HEAT_WAVE}`,
        boxSizing: 'border-box',
        backgroundColor: colors.grays.BLANC,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
    },
    icon: {
        width: '2.75rem',
        height: '2.75rem',
        objectFit: 'contain',
    },
})
