import { SupportedContentBooster } from '@hazadapt-git/public-core-base'
import {
    Chip as MuiChip,
    OutlinedInput,
    Box,
    InputLabel,
    FormControl,
    Select,
} from '@mui/material'
import React, { FC } from 'react'
import { ContentBooster } from '../../lib/entities'

import { customDarkBlueColor } from '../../lib/styles/universal'
// import { useWindowSizeUp } from '../../lib/utils'
import { Chip } from '../atoms'

interface CSOFilterPickerButtonProps {
    value: SupportedContentBooster[]
    open: boolean
    handleOpen(...props: any): void
    handleClose(...props: any): void
    csoFilterSection: React.ReactNode
}

export const CSOFilterPickerButton: FC<CSOFilterPickerButtonProps> = (
    props: CSOFilterPickerButtonProps
) => {
    const ref = React.useRef<HTMLDivElement>(null)
    //We can reimplement the style below when hazard filter returns
    // const MIN_PICKER_WIDTH = 300

    return (
        <FormControl
            sx={{ /*minWidth: MIN_PICKER_WIDTH*/ width: '100%' }}
            ref={ref}
        >
            <InputLabel id="cso-filter-button-label">
                Who needs help?
            </InputLabel>
            <Select
                labelId="cso-filter-button-label"
                id="cso-filter-button"
                multiple
                value={props.value}
                open={props.open}
                onOpen={props.handleOpen}
                onClose={props.handleClose}
                MenuProps={{
                    MenuListProps: {
                        sx: {
                            border: `2px ${customDarkBlueColor} solid`,
                            borderRadius: '0.5rem',
                            boxSizing: 'border-box',
                            width: ref.current?.offsetWidth,
                        },
                    },
                    PaperProps: {
                        sx: {
                            borderRadius: '0.5rem',
                        },
                    },
                }}
                input={
                    <OutlinedInput
                        id="acso-filter-button"
                        label="Who needs help?"
                        sx={{
                            margin: 0,
                            width: '100%',
                            height: '3.5rem',
                            padding: 0,
                        }}
                    />
                }
                renderValue={(selected) => (
                    <Box>
                        {selected
                            .slice(selected.length - 2)
                            .map((value, index: number) => (
                                <Chip
                                    config={ContentBooster[value]}
                                    active
                                    start={index === 0}
                                    onChipPress={() => {}}
                                    sx={{ marginTop: 0, marginBottom: 0 }}
                                    clickable={false}
                                />
                            ))}
                        {selected.length > 2 ? (
                            <MuiChip
                                label={`+ ${selected.length - 2}`}
                                sx={{
                                    margin: 0,
                                    marginLeft: '0.25rem',
                                    cursor: 'pointer',
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </Box>
                )}
            >
                {props.csoFilterSection}
            </Select>
        </FormControl>
    )
}
