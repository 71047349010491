import React, { FC } from 'react'

import { useStyles } from '../../lib/styles/universal'

interface AppVersionNumberProps {}

export const AppVersionNumber: FC<AppVersionNumberProps> = (
    props: AppVersionNumberProps
) => {
    const { classes } = useStyles()
    return (
        <div className={classes.appVersionText}>
            Version {process.env.REACT_APP_VERSION}
        </div>
    )
}
