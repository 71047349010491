import {
    colors,
    LanguageCode,
    prepChecksLearnMoreURL,
} from '@hazadapt-git/public-core-base'
import { Close } from '@mui/icons-material'
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    IconButton,
    DialogActions,
    Button,
    Link,
} from '@mui/material'
import React, { FC } from 'react'

import { prepCheckWelcomeText, PrepCheckWelcomeTextConfig } from '../../i18n'
import { useWindowSizeUp } from '../../lib/utils'
import { Markdown } from '../atoms'

interface PrepCheckWelcomeModalProps {
    open: boolean
    onClose: React.MouseEventHandler
    lang?: LanguageCode
}

export const PrepCheckWelcomeModal: FC<PrepCheckWelcomeModalProps> = (
    props: PrepCheckWelcomeModalProps
) => {
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const modalConfig: PrepCheckWelcomeTextConfig | undefined =
        prepCheckWelcomeText.get(props.lang ?? LanguageCode.ENGLISH)
    return modalConfig ? (
        <Dialog
            open={props.open}
            PaperProps={{ sx: { maxHeight: '80vh', padding: '0.5rem 0' } }}
        >
            <DialogTitle sx={{ paddingRight: '2.5rem' }}>
                <Typography
                    variant={smallWindowOrLarger ? 'h2' : 'h3'}
                    fontWeight={500}
                    sx={{ color: colors.primary.HELIOTROPE }}
                >
                    {modalConfig.title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <IconButton
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                    }}
                >
                    <Close />
                </IconButton>
                <Markdown content={modalConfig.blurbChunk1} />
                <Typography
                    variant={smallWindowOrLarger ? 'h3' : 'h4'}
                    component="p"
                >
                    {modalConfig.yourGoalText}
                </Typography>
                <Markdown content={modalConfig.blurbChunk2} />
                {modalConfig.aspectDisplay}
                <Markdown content={modalConfig.blurbChunk3} />
                <Link href={prepChecksLearnMoreURL} target="_blank">
                    Learn More About Coping Capacity and the Research Behind
                    Prep Checks
                </Link>
            </DialogContent>
            <DialogActions sx={{ margin: '0.5rem 0.5rem 0' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onClose}
                >
                    Start Prep Checks
                </Button>
            </DialogActions>
        </Dialog>
    ) : null
}
