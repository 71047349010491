import {
    FilterConfig,
    BoosterConfig,
    colors,
    SupportedHazardFilter,
    SupportedContentBooster,
} from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import { Chip } from '../atoms'
import { ContentBooster, HazardFilter } from '../../lib/entities'

export interface ChipGridProps {
    chips: (FilterConfig | BoosterConfig)[]
    activeChips: (SupportedHazardFilter | SupportedContentBooster)[]
    availableChips: string[]
    onChipPress?(name: string): void
    guidingText?: string | React.ReactNode
    onResetPress?(...props: any): void
    showDeleteIcon?: boolean
    justifyContent?: 'center' | 'flex-start' | 'flex-end'
    collapsible?: boolean
    collapsed?: boolean
}

export const ChipGrid: FC<ChipGridProps> = (props: ChipGridProps) => {
    const { classes } = useLocalStyles()
    const activeChips = props.activeChips.filter((c) => c !== 'Local').reverse()

    const chips = [
        ...activeChips.map((c) => {
            if (Object.keys(ContentBooster).includes(c)) {
                return ContentBooster[c]
            } else {
                return HazardFilter[c]
            }
        }),
        ...props.chips.filter((c) => !activeChips.includes(c.key)),
    ]

    const renderChip = (chip: FilterConfig | BoosterConfig, index: number) => {
        return (
            <Chip
                key={`${chip.label}_chip`}
                config={chip}
                active={activeChips.includes(chip.key)}
                available={props.availableChips.includes(chip.key)}
                onChipPress={props.onChipPress}
                start={index === 0}
                end={index === chips.length - 1}
                showDeleteIcon={props.showDeleteIcon}
            />
        )
    }

    return (
        <div
            className={classes.chipGrid}
            style={{
                justifyContent: props.justifyContent ?? 'flex-start',
            }}
        >
            {props.guidingText ? (
                typeof props.guidingText === 'string' ? (
                    <Typography
                        color={colors.grays.NIMBUS}
                        fontWeight={500}
                        sx={{ textDecoration: 'underline' }}
                    >
                        {props.guidingText}
                    </Typography>
                ) : (
                    props.guidingText
                )
            ) : null}
            {props.collapsible && props.collapsed
                ? activeChips.map((c, index) => {
                      if (Object.keys(ContentBooster).includes(c)) {
                          return renderChip(ContentBooster[c], index)
                      } else {
                          return renderChip(HazardFilter[c], index)
                      }
                  })
                : chips.map(renderChip)}
            {props.onResetPress && activeChips.length > 0 && (
                <Typography
                    color={colors.grays.CUMULUS}
                    fontWeight={500}
                    onClick={props.onResetPress}
                    sx={{
                        textDecoration: 'underline',
                        paddingLeft: '0.5rem',
                        cursor: 'pointer',
                        display: activeChips.length > 0 ? 'block' : 'none',
                    }}
                >
                    Reset
                </Typography>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    chipGrid: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        columnGap: '0.5rem',
    },
})
