import { Button, Typography } from '@mui/material'
import React from 'react'

import { useStyles } from '../../lib/styles/universal'
import { Card, Markdown } from '../atoms'
import { colors } from '@hazadapt-git/public-core-base'

export interface FAQBlockProps {
    id: number
    question: string
    answer: string
    onLinkPress?(url: string): any
    buttonTitle?: string
    onButtonPress?(...props: any): void
}

export const FAQBlock = React.forwardRef<HTMLDivElement, FAQBlockProps>(
    (props: FAQBlockProps, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { classes } = useStyles()
        return (
            <Card
                ref={ref}
                colors={[colors.grays.BLANC]}
                className={classes.whatToKnow911Card}
            >
                <Typography
                    component="p"
                    variant="h4"
                    fontSize="1.4rem"
                    pb="0.625rem"
                >
                    {props.question}
                </Typography>
                <Markdown
                    content={props.answer}
                    onLinkPress={props.onLinkPress}
                />
                {props.buttonTitle && (
                    <Button variant="contained" onClick={props.onButtonPress}>
                        {props.buttonTitle}
                    </Button>
                )}
            </Card>
        )
    }
)
