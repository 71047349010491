import {
    ErrorMessage,
    HazardOverview,
    HazardOverviewResponse,
    LanguageCode,
    TrendingHazardDTO,
} from '@hazadapt-git/public-core-base'
import {
    ActionReducerMapBuilder,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit'

import {
    getHazards,
    getTrendingHazards,
    logHazardViewEvent,
    logContentBlockViewEvent,
    ViewEventDTO,
    getSupportedLanguages,
} from '../utils/hazards'
import { getBookmarks } from '../utils/bookmarks'
import { readAsync, storeAsync } from '../async-storage'
import { ProfileStoreState } from './ProfileSlice'

export interface HazardStoreState {
    hazards: HazardOverview[]
    languages: LanguageCode[]
    date_modified: string
    trending_hazards: TrendingHazardDTO[]
    trending_hazards_error: boolean
    error?: string | null
    has_local_content: boolean
}

export const INITIAL_HAZARD_STORE_STATE: HazardStoreState = {
    error: null,
    hazards: [],
    languages: [],
    trending_hazards: [],
    trending_hazards_error: false,
    date_modified: new Date(2022, 0).toString(),
    has_local_content: false,
}

/**
 * Gets the latest hazards from the cloud
 */
export const getHazardsThunk = createAsyncThunk<
    HazardOverviewResponse,
    { language?: LanguageCode; position?: GeolocationPosition },
    { rejectValue: ErrorMessage | string }
>(
    'hazards/get',
    async ({ language, position }, { rejectWithValue, getState }) => {
        const state = getState() as {
            profile: ProfileStoreState
        }
        const lang = language ?? state.profile.language
        const getHazardsWithLocation = (hazardsToForceInclude: number[]) =>
            new Promise<HazardOverviewResponse>(async (resolve, reject) => {
                if (position) {
                    const { latitude, longitude } = position.coords
                    try {
                        const res = await getHazards(
                            lang,
                            hazardsToForceInclude,
                            [latitude, longitude]
                        )
                        resolve(res)
                    } catch (err) {
                        storeAsync('@loCoOn', 'false')
                        reject(err)
                    }
                } else {
                    try {
                        const res = await getHazards(
                            lang,
                            hazardsToForceInclude
                        )
                        resolve(res)
                    } catch (err) {
                        storeAsync('@loCoOn', 'false')
                        reject(err)
                    }
                }
            })

        try {
            const bookmarkedHazards: Set<number> = new Set<number>()
            const {
                profile: { user },
            } = getState() as { profile: ProfileStoreState }
            if (user !== undefined) {
                const bookmarks = await getBookmarks()
                for (const b of bookmarks) bookmarkedHazards.add(b.hazard_id)
            }
            const hazardsToForceInclude: number[] =
                Array.from(bookmarkedHazards)

            const loCoDefaultOn = readAsync('@loCoOn') === 'true'
            if (loCoDefaultOn) {
                try {
                    let state: 'denied' | 'granted' | 'prompt' | undefined =
                        undefined
                    if (navigator.permissions) {
                        const result = await navigator.permissions.query({
                            name: 'geolocation',
                        })
                        state = result.state
                    }
                    if (state !== 'denied') {
                        try {
                            return await getHazardsWithLocation(
                                hazardsToForceInclude
                            )
                        } catch (err: any) {
                            console.error(err)
                            return rejectWithValue(err)
                        }
                    } else {
                        storeAsync('@loCoOn', 'false')
                        return await getHazards(lang, hazardsToForceInclude)
                    }
                } catch (err) {
                    console.error(err)
                    storeAsync('@loCoOn', 'false')
                    return await getHazards(lang, hazardsToForceInclude)
                }
            } else {
                return await getHazards(lang, hazardsToForceInclude)
            }
        } catch (err: any) {
            console.error(err)
            return rejectWithValue(err)
        }
    }
)

export const getSupportedLanguagesThunk = createAsyncThunk<
    LanguageCode[],
    void,
    { rejectValue: ErrorMessage | string }
>('hazards/get-supported-languages', async (_, { rejectWithValue }) => {
    try {
        return await getSupportedLanguages()
    } catch (err) {
        return rejectWithValue(ErrorMessage.ISA_REQUEST_FAILED)
    }
})

/**
 * Logs a hazard view event
 */
export const logHazardViewEventThunk = createAsyncThunk<
    TrendingHazardDTO[],
    number,
    { rejectValue: ErrorMessage | string }
>('hazards/log-hazard-view', async (hazard_id: number, { rejectWithValue }) => {
    try {
        const res = await logHazardViewEvent(hazard_id)
        if (res) {
            throw res
        } else return await getTrendingHazards()
    } catch (err: any) {
        return rejectWithValue(ErrorMessage.ISA_REQUEST_FAILED)
    }
})

/**
 * Logs a content block view event
 */
export const logContentBlockViewEventThunk = createAsyncThunk<
    void,
    ViewEventDTO,
    { rejectValue: ErrorMessage | string }
>(
    'hazards/log-content-block-view',
    async (viewEventDTO, { rejectWithValue }) => {
        try {
            await logContentBlockViewEvent(viewEventDTO)
        } catch (err: any) {
            return rejectWithValue(ErrorMessage.ISA_REQUEST_FAILED)
        }
    }
)

/**
 * Gets hazard view counts
 */
export const getTrendingHazardsThunk = createAsyncThunk<
    TrendingHazardDTO[],
    LanguageCode,
    { rejectValue: ErrorMessage | string }
>(
    'hazards/get-hazard-view-counts',
    async (lang: LanguageCode, { rejectWithValue }) => {
        try {
            return await getTrendingHazards(lang)
        } catch (err: any) {
            return rejectWithValue(err)
        }
    }
)

const hazardSlice = createSlice({
    name: 'hazards',
    initialState: INITIAL_HAZARD_STORE_STATE,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<HazardStoreState>) => {
        builder.addCase(getHazardsThunk.fulfilled, (state, action) => {
            state.hazards = action.payload.hazards
            state.has_local_content = action.payload.has_local_content
            state.error = null
        })
        builder.addCase(getHazardsThunk.rejected, (state, action) => {
            state.error = action.payload
        })

        builder.addCase(
            getSupportedLanguagesThunk.fulfilled,
            (state, action) => {
                state.languages = action.payload
                state.error = null
            }
        )

        builder.addCase(
            getSupportedLanguagesThunk.rejected,
            (state, action) => {
                state.languages = [LanguageCode.ENGLISH]
                state.error = action.payload
            }
        )

        builder.addCase(logHazardViewEventThunk.fulfilled, (state, action) => {
            state.trending_hazards = action.payload
            state.trending_hazards_error = false
            state.error = null
        })
        builder.addCase(logHazardViewEventThunk.rejected, (state, action) => {
            state.error = action.payload
            state.trending_hazards_error = true
        })

        builder.addCase(
            logContentBlockViewEventThunk.fulfilled,
            (state, action) => {
                state.error = null
            }
        )
        builder.addCase(
            logContentBlockViewEventThunk.rejected,
            (state, action) => {
                state.error = action.payload
            }
        )

        builder.addCase(getTrendingHazardsThunk.fulfilled, (state, action) => {
            state.trending_hazards = action.payload
            state.trending_hazards_error = false
            state.error = null
        })
        builder.addCase(getTrendingHazardsThunk.rejected, (state, action) => {
            state.error = action.payload
            state.trending_hazards_error = true
        })
    },
})

export default hazardSlice.reducer
