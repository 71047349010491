import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

interface LoadingViewProps {}

export const LoadingView: FC<LoadingViewProps> = () => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div className={localClasses.loading}>
            <CircularProgress size="1.5rem" />
            <Typography variant="h4">Loading...</Typography>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    loading: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 0',
    },
})
