import { colors } from '@hazadapt-git/public-core-base'
import { IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { customBlackColor } from '../../lib/styles/universal'

interface CarouselProps {
    pages: React.ReactNode[]
    containerClassName?: string
}

export const Carousel: FC<CarouselProps> = (props: CarouselProps) => {
    const { classes: localClasses } = useLocalStyles()

    const [carouselIndex, setCarouselIndex] = React.useState<number>(0)

    const onDotPress = (index: number) => {
        const pagesWrapper = document.getElementById('carouselPagesWrapper')

        if (!pagesWrapper) return
        pagesWrapper.scrollTo({
            left: pagesWrapper.offsetWidth * index,
            behavior: 'smooth',
        })

        setCarouselIndex(index)
    }

    React.useEffect(() => {
        const onPageChange = () => {
            const pagesWrapper = document.getElementById('carouselPagesWrapper')
            if (!pagesWrapper || props.pages.length === 0) return

            const scrollLeft = pagesWrapper.scrollLeft
            const pageWidth = pagesWrapper.offsetWidth / props.pages.length

            let index = 0
            while (pageWidth * (index + 1) < scrollLeft) {
                index++
            }
            setCarouselIndex(index)
        }

        const pagesWrapper = document.getElementById('carouselPagesWrapper')
        if (!pagesWrapper) return
        pagesWrapper.addEventListener('scroll', onPageChange)

        return () => {
            pagesWrapper.removeEventListener('scroll', onPageChange)
        }
    }, [props.pages.length])

    return (
        <div
            className={classNames(
                localClasses.container,
                props.containerClassName
            )}
        >
            <div className={localClasses.pagesContainer}>
                <IconButton
                    onClick={() => onDotPress(carouselIndex - 1)}
                    sx={
                        carouselIndex > 0
                            ? undefined
                            : {
                                  pointerEvents: 'none',
                                  visibility: 'hidden',
                              }
                    }
                >
                    <IoChevronBack color={customBlackColor} size="1.5rem" />
                </IconButton>
                <ul className={localClasses.pages} id="carouselPagesWrapper">
                    {props.pages.map((page, index) => (
                        <li
                            key={`carousel_page_${index}`}
                            className={localClasses.pageWrapper}
                        >
                            <div className={localClasses.page}>{page}</div>
                        </li>
                    ))}
                </ul>
                <IconButton
                    onClick={() => onDotPress(carouselIndex + 1)}
                    sx={
                        carouselIndex < props.pages.length - 1
                            ? undefined
                            : {
                                  pointerEvents: 'none',
                                  visibility: 'hidden',
                              }
                    }
                >
                    <IoChevronForward color={customBlackColor} size="1.5rem" />
                </IconButton>
            </div>
            {props.pages.length > 1 && (
                <div className={localClasses.paginationDots}>
                    {props.pages.map((page, index) => (
                        <span
                            className={classNames(
                                localClasses.dot,
                                carouselIndex === index
                                    ? localClasses.activeDot
                                    : null
                            )}
                            key={`pagination_dot_${index}`}
                            onClick={() => onDotPress(index)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
    },
    pagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
    },
    pages: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflowX: 'auto',
        listStyleType: 'none',
        marginBlock: 0,
        paddingInline: 0,
        width: 'min(calc(100vw - 8rem), 40rem)',
        scrollSnapType: 'x mandatory',
        scrollSnapPointsX: 'min(calc(100vw - 8rem), 40rem)',
        flexBasis: '50%',
    },
    pageWrapper: {
        scrollSnapAlign: 'start',
    },
    page: {
        margin: '0 auto',
        width: 'min(calc(100vw - 8rem), 40rem)',
    },
    paginationDots: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.75rem',
        cursor: 'pointer',
    },
    dot: {
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '50%',
        backgroundColor: colors.grays.SILVER,
    },
    activeDot: {
        borderRadius: '50%',
        backgroundColor: colors.primary.HELIOTROPE,
    },
})
