import {
    colors,
    privacyAndSecurityURL,
    User,
} from '@hazadapt-git/public-core-base'
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material'
import React, { FC } from 'react'
import HazAdaptAccountLock from '../../assets/icons/HazAdaptAccountLock.svg'
import UserIcon from '../../assets/icons/UserIcon.svg'
import EmailIcon from '../../assets/icons/EmailIcon.svg'
import PhoneIcon from '../../assets/icons/PhoneIcon.svg'
import LocationIcon from '../../assets/icons/LocationIcon.svg'
import ShieldIcon from '../../assets/icons/ShieldIcon.svg'
import { useWindowSizeUp } from '../../lib/utils'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { makeStyles } from 'tss-react/mui'
import { ImageWithAccessory } from '../molecules'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import {
    customBlackColor,
    customLightGrayColor,
} from '../../lib/styles/universal'
import { HelpIcon } from '../atoms'
import { IoPencil, IoTrash } from 'react-icons/io5'

interface AccountTabProps {
    user?: User | null
    handleSignup(): void | Promise<void>
    handleLogin(): void | Promise<void>
    handleLogout(): void | Promise<void>
    handleManage(): void | Promise<void>
    profilePictureURI?: string
    onProfilePictureChange: React.ChangeEventHandler<HTMLInputElement>
    onProfilePictureDelete: React.MouseEventHandler
}

export const AccountTab: FC<AccountTabProps> = (props: AccountTabProps) => {
    const { classes: localClasses } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const profilePictureInputRef = React.useRef<HTMLInputElement>(null)

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )
    const handleClickAccessory = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (props.profilePictureURI) {
            setAnchorEl(e.currentTarget)
        } else {
            onChangeProfilePictureClick()
        }
    }
    const handleCloseProfilePictureMenu: React.MouseEventHandler = (e) => {
        setAnchorEl(null)
    }
    const openProfilePictureMenu = Boolean(anchorEl)

    const onChangeProfilePictureClick = () => {
        profilePictureInputRef.current?.click()
    }

    return (
        <Grid
            container
            m="0 auto"
            p="0 2rem"
            columnGap="5rem"
            rowGap="3rem"
            justifyContent="center"
        >
            {props.user && (
                <Grid
                    item
                    sm={12}
                    md={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="1rem"
                >
                    <ImageWithAccessory
                        src={props.profilePictureURI}
                        badgeContent={
                            <IconButton
                                aria-label="Open profile picture menu"
                                id="profile-picture-menu-button"
                                onClick={handleClickAccessory}
                            >
                                {smallWindowOrLarger ? (
                                    <CameraAltIcon />
                                ) : (
                                    <CameraAltIcon
                                        sx={{ width: '1rem', height: '1rem' }}
                                    />
                                )}
                            </IconButton>
                        }
                        badgeStyles={
                            smallWindowOrLarger
                                ? badgeStyle
                                : badgeStyleSmallScreen
                        }
                        avatarStyles={{
                            width: smallWindowOrLarger ? '6rem' : '3.125rem',
                            height: smallWindowOrLarger ? '6rem' : '3.125rem',
                            cursor: 'pointer',
                        }}
                    />
                    <Typography variant={smallWindowOrLarger ? 'h3' : 'h4'}>
                        {props.user.first_name} {props.user.last_name}
                    </Typography>
                    <Button
                        variant="contained"
                        endIcon={<OpenInNewIcon />}
                        onClick={props.handleManage}
                    >
                        Manage My Account
                    </Button>
                    {smallWindowOrLarger && (
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{ color: colors.primary.CORAL }}
                            onClick={props.handleLogout}
                        >
                            Log Out
                        </Button>
                    )}
                    <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={props.onProfilePictureChange}
                        ref={profilePictureInputRef}
                    />
                    {props.profilePictureURI && (
                        <Menu
                            anchorEl={anchorEl}
                            onClose={handleCloseProfilePictureMenu}
                            open={openProfilePictureMenu}
                            id="profile-picture-menu"
                            MenuListProps={{
                                'aria-labelledby':
                                    'profile-picture-menu-button',
                                sx: {
                                    padding: 0,
                                },
                            }}
                        >
                            <MenuItem
                                onClick={onChangeProfilePictureClick}
                                sx={{ padding: '0.5rem' }}
                            >
                                <ListItemIcon>
                                    <IoPencil size="1.5rem" />
                                </ListItemIcon>
                                <ListItemText>Change Photo</ListItemText>
                            </MenuItem>
                            <Divider
                                sx={{ margin: '0 !important', width: '100%' }}
                            />
                            <MenuItem
                                onClick={props.onProfilePictureDelete}
                                sx={{ padding: '0.5rem' }}
                            >
                                <ListItemIcon>
                                    <IoTrash size="1.5rem" />
                                </ListItemIcon>
                                <ListItemText>
                                    Delete Profile Picture
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    )}
                </Grid>
            )}
            <Grid
                item
                xs={12}
                md={props.user ? true : 12}
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="1rem"
            >
                <div className={localClasses.manageAccountContainer}>
                    <div className={localClasses.accountRenderingWrapper}>
                        <img
                            src={HazAdaptAccountLock}
                            alt="HazAdapt Account Lock Icon"
                        />
                        <div className={localClasses.authIconContainer}>
                            <img src={UserIcon} alt="User Icon" />
                            <img src={EmailIcon} alt="Email Icon" />
                            <img src={PhoneIcon} alt="Phone Icon" />
                            <img src={LocationIcon} alt="Location Icon" />
                            <img src={ShieldIcon} alt="Shield Icon" />
                        </div>
                    </div>
                    <div className={localClasses.infoUseContainer}>
                        <Typography sx={{ textAlign: 'center' }}>
                            Your account includes your name, username, login
                            info, and ZIP code, all stored in a secure
                            environment.
                        </Typography>
                        <div className={localClasses.privacyDisclaimer}>
                            <HelpIcon helpText="Hazards affect each of us differently based on who we are and where we are. We use this information to help connect you to meaningful resilience information and local resources. HazAdapt will never sell your personal information." />
                            <Typography
                                sx={{ color: colors.grays.THUNDERCLOUD }}
                                fontSize="0.8rem"
                            >
                                How does HazAdapt use my profile information?
                            </Typography>
                        </div>
                    </div>
                    <Link
                        href={privacyAndSecurityURL}
                        rel="noopener"
                        target="_blank"
                        textAlign="center"
                    >
                        Learn about our commitment to privacy and security.
                    </Link>
                    {props.user ? (
                        !smallWindowOrLarger ? (
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    color: colors.primary.CORAL,
                                    marginTop: '1rem',
                                }}
                                onClick={props.handleLogout}
                            >
                                Log Out
                            </Button>
                        ) : null
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                onClick={props.handleSignup}
                            >
                                Create an Account
                            </Button>
                            <Button
                                variant="contained"
                                onClick={props.handleLogin}
                            >
                                Log In
                            </Button>
                        </>
                    )}
                </div>
            </Grid>
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '2rem',
        maxWidth: '40rem',
        margin: '0 auto',
        padding: '0 2rem',
    },
    authActionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
    },
    authCtaButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '1rem',
        paddingTop: '1rem',
    },
    manageAccountContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: '1rem',
    },
    accountRenderingWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '1rem',
    },
    authIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        gap: '2rem',
    },
    infoUseContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.5rem',
    },
    privacyDisclaimer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.25rem',
        alignItems: 'center',
    },
})

const badgeStyle = {
    '& .MuiBadge-badge': {
        color: customBlackColor,
        backgroundColor: customLightGrayColor,
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: 25,
    },
}

const badgeStyleSmallScreen = {
    '& .MuiBadge-badge': {
        color: customBlackColor,
        backgroundColor: customLightGrayColor,
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: 25,
    },
}
