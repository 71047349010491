import { colors } from '@hazadapt-git/public-core-base'
import { Mail } from '@mui/icons-material'
import { Grid, Typography, Divider, Avatar, Button } from '@mui/material'
import React, { FC, useState } from 'react'
import { ContentBooster } from '../../lib/entities'

import logo from '../../logo.svg'
import {
    AppCopyrightMessage,
    AppIcon,
    AppVersionNumber,
    Card,
    Checkbox,
    CompanyLogo,
    DatePicker,
    EditButton,
    EmergencyButton,
    HelpIcon,
    Markdown,
    Picker,
    RadioButton,
    Searchbar,
    Switch,
    TextField,
    Chip,
    Tag,
    ProgressBar,
} from '../atoms'

interface AtomsProps {}

export const Atoms: FC<AtomsProps> = (props: AtomsProps) => {
    const [date, setDate] = useState<Date | null>(null)
    const [checked, setChecked] = useState<boolean>(false)
    const [editOn, setEditOn] = useState<boolean>(false)
    const [selected, setSelected] = useState<boolean>(false)
    const [pickerValue, setPickerValue] = useState<string>('')
    const [query, setQuery] = useState<string>('')

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="1rem"
        >
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    AppCopyrightMessage
                </Typography>
                <AppCopyrightMessage />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    AppIcon
                </Typography>
                <AppIcon />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    AppVersionNumber
                </Typography>
                <AppVersionNumber />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Avatar
                </Typography>
                <Avatar src={logo} sx={{ height: '3rem', width: '3rem' }} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Button
                </Typography>
                <Button variant="contained">Test</Button>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Card
                </Typography>
                <Card colors={[colors.grays.WHITESMOKE]} onClick={() => {}}>
                    <img src={logo} alt="React logo" />
                    <Typography variant="h4">React</Typography>
                </Card>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Checkbox
                </Typography>
                <Checkbox label="Checkbox" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Chip
                </Typography>
                <Chip
                    config={ContentBooster['Pets']}
                    active={selected}
                    onChipPress={() => setSelected(!selected)}
                    showDeleteIcon
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    CompanyLogo
                </Typography>
                <CompanyLogo />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    DatePicker
                </Typography>
                <DatePicker
                    label="Select date"
                    value={date}
                    onChange={setDate}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    EditButton
                </Typography>
                <EditButton
                    editOn={editOn}
                    onClick={() => setEditOn(!editOn)}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    EmergencyButton
                </Typography>
                <EmergencyButton />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HelpIcon + Popover
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Typography sx={{ paddingTop: 2 }}>
                        Text with help icon
                    </Typography>
                    <HelpIcon helpText="This is a popover." />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Markdown
                </Typography>
                <Markdown
                    content={`### A demo of \`react-markdown\`

\`react-markdown\` is a markdown component for React.

👉 Changes are re-rendered as you type.

👈 Try writing some markdown on the left.`}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    MiniChip
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Tag config={ContentBooster['Pets']} start />
                    <Tag config={ContentBooster['Kids']} end />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Picker
                </Typography>
                <Picker
                    label="Select one..."
                    placeholder="Select one..."
                    data={[
                        { value: '1', label: 'One' },
                        { value: '2', label: 'Two' },
                    ]}
                    value={pickerValue}
                    onChange={(e) => setPickerValue(e.target.value as string)}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ProgressBar
                </Typography>
                <ProgressBar progress={0.75} maxWidth="16rem" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    RadioButton
                </Typography>
                <RadioButton label="Radio button" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Searchbar
                </Typography>
                <Searchbar
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    variant="outlined"
                    options={[]}
                    onOptionPress={() => {}}
                    onQuerySubmit={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Switch (a.k.a. Toggle)
                </Typography>
                <Switch
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    label="Switch"
                    color="success"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TextField
                </Typography>
                <TextField
                    icon={<Mail />}
                    variant="outlined"
                    label="Email Address"
                    placeholder="Enter email..."
                />
                <Divider />
            </Grid>
        </Grid>
    )
}
