import { SupportedContentBooster, colors } from '@hazadapt-git/public-core-base'
import { Search } from '@mui/icons-material'
import { Dialog, IconButton, Typography, Grid } from '@mui/material'
import React, { FC } from 'react'

import { TextField } from '../atoms'
import { CSOItemGrid } from './CSOItemGrid'
import { CSOItemProps, CSOFilterPickerButton } from '../molecules'
import {
    altHazRedColor,
    customDarkBlueColor,
    customHazTealColor,
    useStyles,
} from '../../lib/styles/universal'
import { useWindowSizeExact } from '../../lib/utils'
import { IoOptions } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'
import { PickerItem } from '../../lib/entities'

export interface CSOViewProps {
    hazards: PickerItem<number>[]
    activeFilters: SupportedContentBooster[]
    filterPickerOpen: boolean
    handleFilterPickerOpen(...props: any): void
    handleFilterPickerClose(...props: any): void
    csoFilterPicker: React.ReactNode
    csoItems: CSOItemProps[]
    onSelectedHazardChange(id: number): void
    onHazardResetPress(): void
    hazardToFilter: number | ''
    searchQuery: string
    onSearchQueryChange(value: string): void
    loading?: boolean
    onCsoOpen(id: number): void
    onCsoHover(id: number): void
    onWhatTheyHelpWithClick(id: number): void
    onWhoTheyHelpClick(id: number): void
    onAboutThisServiceClick(id: number): void
    onFindNearMeClick(id: number, url: string): void
    onCsoViewWebsiteClick(id: number, url: string): void
}

export const CSOView: FC<CSOViewProps> = (props: CSOViewProps) => {
    const { classes: globalClasses } = useStyles()
    const { classes } = useLocalStyles()

    const xsWindow = useWindowSizeExact('xs')

    const renderCSOFilterPickerOverlay = (): React.ReactNode => (
        <Dialog
            onClose={props.handleFilterPickerClose}
            open={props.filterPickerOpen}
            PaperProps={{
                sx: {
                    width: 300,
                    border: `2px ${customDarkBlueColor} solid`,
                    borderRadius: '0.5rem',
                    boxSizing: 'border-box',
                },
            }}
        >
            {props.csoFilterPicker}
        </Dialog>
    )

    return (
        <div className={classes.csoContainer}>
            <div>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    pb="1.5rem"
                    pt="1rem"
                >
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography
                            variant="h3"
                            pb={0}
                            sx={{
                                color: customDarkBlueColor,
                                paddingLeft: '1rem',
                            }}
                            textAlign={xsWindow ? 'center' : 'left'}
                        >
                            Crisis Support Options
                        </Typography>
                    </Grid>
                    <Grid item xs={10.5} sm={6} md={4} lg={5}>
                        <TextField
                            variant="outlined"
                            value={props.searchQuery}
                            onChange={(e) =>
                                props.onSearchQueryChange(e.target.value)
                            }
                            placeholder="Search"
                            InputProps={{
                                startAdornment: <Search />,
                            }}
                            inputProps={{
                                sx: {
                                    marginLeft: '1rem',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5} sm={6} md={4} lg={3}>
                        {xsWindow ? (
                            <>
                                <IconButton
                                    sx={{
                                        //We can reimplement the style below when hazard filter returns
                                        // marginRight: '2rem',
                                        cursor: 'pointer',
                                    }}
                                    onClick={props.handleFilterPickerOpen}
                                >
                                    <IoOptions
                                        color={customHazTealColor}
                                        size={32}
                                    />
                                </IconButton>
                                {renderCSOFilterPickerOverlay()}
                            </>
                        ) : (
                            <CSOFilterPickerButton
                                value={props.activeFilters}
                                open={props.filterPickerOpen}
                                handleOpen={props.handleFilterPickerOpen}
                                handleClose={props.handleFilterPickerClose}
                                csoFilterSection={props.csoFilterPicker}
                            />
                        )}
                    </Grid>
                </Grid>
                {/* Re-enable Hazards filter below when list is longer */}
                {/* <div className={classes.filterContainer}> */}
                {/* <div className={classes.hazardFilterContainer}> */}
                {/* <Picker
                            labelId="hazard-filter-select-label"
                            id="hazard-filter-select"
                            inputLabel="Hazards"
                            data={props.hazards}
                            value={props.hazardToFilter}
                            onChange={(e) =>
                                props.onSelectedHazardChange(
                                    e.target.value as number
                                )
                            }
                            sx={{
                                width: {
                                    sm: 250,
                                    xs: 200,
                                },
                            }}
                            inputProps={{
                                'data-testid': 'hazards-filter-picker',
                            }}
                        /> */}
                {/* {!xsWindow && props.hazardToFilter && (
                            <Typography
                                color={colors.grays.CUMULUS}
                                fontWeight={500}
                                onClick={props.onHazardResetPress}
                                sx={{
                                    textDecoration: 'underline',
                                    marginLeft: '2rem',
                                    cursor: 'pointer',
                                }}
                            >
                                Reset
                            </Typography>
                        )} */}
                {/* </div> */}
                {/* {xsWindow ? (
                        <>
                            <IconButton
                                sx={{
                                    marginLeft: '2rem',
                                    cursor: 'pointer',
                                }}
                                onClick={props.handleFilterPickerOpen}
                            >
                                <IoOptions
                                    color={customHazTealColor}
                                    size={32}
                                />
                            </IconButton>
                            {renderCSOFilterPickerOverlay()}
                        </>
                    ) : (
                        <CSOFilterPickerButton
                            value={props.activeFilters}
                            open={props.filterPickerOpen}
                            handleOpen={props.handleFilterPickerOpen}
                            handleClose={props.handleFilterPickerClose}
                            csoFilterSection={
                                props.csoFilterPicker
                            }
                        />
                    )} */}
                {/* </div> */}
            </div>
            {xsWindow && props.hazardToFilter && (
                <Typography
                    color={colors.grays.CUMULUS}
                    fontWeight={500}
                    onClick={props.onHazardResetPress}
                    sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                >
                    Reset
                </Typography>
            )}
            <div className={classes.alt911GridWrapper}>
                {!props.loading &&
                    props.searchQuery &&
                    (props.csoItems.length === 0 ? (
                        <Typography
                            variant="h3"
                            sx={{ color: altHazRedColor }}
                            pb="1.5rem"
                            fontWeight={400}
                        >
                            No search results found.
                        </Typography>
                    ) : (
                        <Typography
                            variant="h3"
                            sx={{ color: customDarkBlueColor }}
                            pb="1.5rem"
                            fontWeight={400}
                        >
                            Search results for "
                            <i className={globalClasses.searchQuery}>
                                {props.searchQuery}
                            </i>
                            ":
                        </Typography>
                    ))}
                <CSOItemGrid
                    items={props.csoItems}
                    loading={props.loading}
                    onCsoOpen={props.onCsoOpen}
                    onCsoHover={props.onCsoHover}
                    onWhatTheyHelpWithClick={props.onWhatTheyHelpWithClick}
                    onWhoTheyHelpClick={props.onWhoTheyHelpClick}
                    onAboutThisServiceClick={props.onAboutThisServiceClick}
                    onFindNearMeClick={props.onFindNearMeClick}
                    onCsoViewWebsiteClick={props.onCsoViewWebsiteClick}
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    csoContainer: {
        border: '1px black solid',
        borderRadius: '2rem',
        padding: '1rem',
        //We can reimplement the style below when hazard filter returns
        // marginLeft: '1rem',
        // marginRight: '1rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    hazardFilterContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    alt911GridWrapper: { paddingTop: '1.5rem', width: '100%' },
})
