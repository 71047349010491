import React from 'react'
import ReactDOM from 'react-dom/client'

import 'intl'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/zh'
import '@formatjs/intl-displaynames/locale-data/fr'
import '@formatjs/intl-displaynames/locale-data/es'
import '@formatjs/intl-displaynames/locale-data/fil'
import '@formatjs/intl-displaynames/locale-data/vi'
import '@formatjs/intl-displaynames/locale-data/ar'
import '@formatjs/intl-displaynames/locale-data/ko'
import '@formatjs/intl-displaynames/locale-data/ru'
import '@formatjs/intl-displaynames/locale-data/pt'
import '@formatjs/intl-displaynames/locale-data/ja'
import '@formatjs/intl-displaynames/locale-data/pl'
import '@formatjs/intl-displaynames/locale-data/de'
import 'intl/locale-data/jsonp/en'
import 'react-aspect-ratio/aspect-ratio.css'
import './lib/styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
