import {
    SupportedHazardStage,
    BoosterConfig,
    SupportedContentBooster,
    OrganizationOverview,
    colors,
} from '@hazadapt-git/public-core-base'
import { Grid, IconButton, Skeleton, Typography } from '@mui/material'
import React, { FC } from 'react'
import { IoIosLink } from 'react-icons/io'
import { IoBookmark, IoBookmarkOutline, IoOptions } from 'react-icons/io5'

import PDFIcon from '../../assets/icons/PDFBlue.svg'
import Translate from '../../assets/icons/Translate-Outline.svg'
import { customDarkBlueColor } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import { ChipGrid, LocalContentSwitch } from '../molecules'
import { HazardStageGrid } from './HazardStageGrid'
import { makeStyles } from 'tss-react/mui'

export interface IndvHazardHeaderProps {
    name: string
    id?: number
    slug?: string
    bookmarked?: boolean
    activeStage?: SupportedHazardStage
    boosters: BoosterConfig[]
    activeBoosters: SupportedContentBooster[]
    availableBoosters: SupportedContentBooster[]
    onStagePress(value: SupportedHazardStage): void
    onBoosterPress(value: string): void
    onBoosterReset(): void
    onTranslatePress(): void
    onShare(): void
    onBookmark(): void | Promise<void>
    onPdfPress(id: number, slug: string): void
    onLocalContentToggle(): void
    locoEnabled: boolean
    loading?: boolean
    creator?: OrganizationOverview
    stagesWithLoCo: SupportedHazardStage[]
    showNoLocalContentPopover?: boolean
    onCloseNoLocalContentPopover: React.MouseEventHandler
    localContentPopoverTextOverride?: string
    boostersExpanded: boolean
    onBoosterViewToggle(open: boolean): void
}

export const IndvHazardHeader: FC<IndvHazardHeaderProps> = (
    props: IndvHazardHeaderProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const largeWindowOrLarger = useWindowSizeUp('lg')
    const bookmarking = React.useRef<boolean>(false)

    const onBookmark: React.MouseEventHandler = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (bookmarking.current) return

        bookmarking.current = true
        await props.onBookmark()
        bookmarking.current = false
    }

    return (
        <div className={localClasses.container}>
            <Grid
                container
                spacing={0}
                alignItems="center"
                paddingBottom="1rem"
            >
                <Grid item xs={0} sm={4} />
                <Grid
                    item
                    xs={6}
                    sm={4}
                    display="flex"
                    alignItems="center"
                    textAlign="center"
                    justifyContent={
                        smallWindowOrLarger ? 'center' : 'flex-start'
                    }
                >
                    {props.loading ? (
                        <Skeleton
                            variant="rectangular"
                            width="10rem"
                            height="2rem"
                        />
                    ) : (
                        <Typography
                            variant={largeWindowOrLarger ? 'h1' : 'h2'}
                            component="h1"
                            sx={{ color: customDarkBlueColor }}
                            textAlign={smallWindowOrLarger ? 'center' : 'left'}
                        >
                            {props.name}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6} sm={4} display="flex" alignItems="center">
                    {!props.loading && (
                        <>
                            <IconButton
                                onClick={props.onTranslatePress}
                                aria-label="Translate button"
                            >
                                <img src={Translate} alt="Translate" />
                            </IconButton>
                            <IconButton
                                onClick={props.onShare}
                                aria-label="Share hazard button"
                            >
                                <IoIosLink color={customDarkBlueColor} />
                            </IconButton>
                            <IconButton
                                onClick={onBookmark}
                                aria-label="Bookmark button"
                            >
                                {props.bookmarked ? (
                                    <IoBookmark color={customDarkBlueColor} />
                                ) : (
                                    <IoBookmarkOutline
                                        color={customDarkBlueColor}
                                    />
                                )}
                            </IconButton>
                            {props.onPdfPress && (
                                <IconButton
                                    onClick={() =>
                                        props.onPdfPress &&
                                        props.id &&
                                        props.slug &&
                                        props.onPdfPress(props.id, props.slug)
                                    }
                                    aria-label="View PDF button"
                                >
                                    <img
                                        src={PDFIcon}
                                        alt="PDF icon"
                                        width={24}
                                        height={24}
                                    />
                                </IconButton>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            {props.creator ? (
                <div
                    className={
                        smallWindowOrLarger
                            ? localClasses.desktopCreatorInfo
                            : localClasses.mwCreatorInfo
                    }
                >
                    <Typography
                        fontWeight={500}
                        gridColumn="1/-1"
                        textAlign={smallWindowOrLarger ? 'left' : 'center'}
                    >
                        Provided By:{' '}
                    </Typography>
                    {props.creator.logo && (
                        <img
                            src={
                                props.creator.logo.url ?? props.creator.logo.src
                            }
                            alt={props.creator.logo.alt}
                            className={localClasses.creatorLogo}
                        />
                    )}
                    <Typography
                        component="span"
                        fontWeight={400}
                        gridColumn={!props.creator.logo ? '1/-1' : undefined}
                    >
                        {props.creator.name}
                    </Typography>
                </div>
            ) : (
                <LocalContentSwitch
                    onLocalContentToggle={props.onLocalContentToggle}
                    enabled={props.locoEnabled}
                    showNoLocalContentPopover={props.showNoLocalContentPopover}
                    hazardName={props.name}
                    onClosePopover={props.onCloseNoLocalContentPopover}
                    popoverText={props.localContentPopoverTextOverride}
                />
            )}
            <div style={{ padding: '1rem 0' }}>
                <HazardStageGrid
                    activeStage={props.activeStage}
                    onStagePress={props.onStagePress}
                    stagesWithLoCo={props.creator ? [] : props.stagesWithLoCo}
                />
            </div>
            <div
                style={{
                    display:
                        props.boosters.length > 0 ||
                        props.activeBoosters.length > 0
                            ? 'flex'
                            : 'none',
                    justifyContent: mediumWindowOrLarger
                        ? 'center'
                        : 'flex-start',
                    alignSelf: mediumWindowOrLarger ? 'center' : 'flex-start',
                }}
            >
                <ChipGrid
                    guidingText={
                        mediumWindowOrLarger ? (
                            'Show for:'
                        ) : (
                            <button
                                className={localClasses.mwShowHideBoosters}
                                onClick={() =>
                                    props.onBoosterViewToggle(
                                        !props.boostersExpanded
                                    )
                                }
                            >
                                <IoOptions
                                    size="1.5rem"
                                    color={
                                        props.boostersExpanded
                                            ? colors.grays.NOIR
                                            : colors.secondary.BONDI
                                    }
                                />
                                <Typography
                                    color={
                                        props.boostersExpanded
                                            ? colors.grays.NOIR
                                            : colors.secondary.BONDI
                                    }
                                    fontWeight={500}
                                    whiteSpace="nowrap"
                                    px="0.5rem"
                                >
                                    {props.boostersExpanded
                                        ? 'Hide'
                                        : 'Show More'}
                                </Typography>
                            </button>
                        )
                    }
                    chips={props.boosters}
                    activeChips={props.activeBoosters}
                    availableChips={props.availableBoosters}
                    onChipPress={props.onBoosterPress}
                    onResetPress={props.onBoosterReset}
                    showDeleteIcon
                    collapsible={!mediumWindowOrLarger}
                    collapsed={!props.boostersExpanded}
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        width: '100%',
        paddingBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mwCreatorInfo: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        alignItems: 'center',
        justContent: 'center',
        columnGap: '0.5rem',
        rowGap: '0.25rem',
    },
    desktopCreatorInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    creatorLogo: {
        height: '3rem',
        width: 'auto',
        maxWidth: '8rem',
        objectFit: 'contain',
        objectPosition: 'center',
    },
    mwShowHideBoosters: {
        all: 'unset',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
})
