import { colors, Image } from '@hazadapt-git/public-core-base'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

// import JourneyCompletionCheck from '../../assets/icons/JourneyCompletionCheck.svg'
// import PhaseStar from '../../assets/icons/PhaseStar.svg'
// import { useWindowSizeUp } from '../../lib/utils'
// import { PrepCheckDataPill } from '../molecules'
import Translate from '../../assets/icons/Translate-Filled-WP.svg'

interface PrepCheckBannerProps {
    icon?: Image
    prepCheckName: string
    journeyPhase: string
    prepChecksCompleted: number
    totalPrepChecks: number
    onTranslateClick?: React.MouseEventHandler
    loading?: boolean
}

export const PrepCheckBanner: FC<PrepCheckBannerProps> = (
    props: PrepCheckBannerProps
) => {
    const { classes: localClasses } = useLocalStyles()
    // const smallWindowOrLarger = useWindowSizeUp('sm')

    return (
        <div className={localClasses.banner}>
            <div className={localClasses.prepCheckDetails}>
                {props.icon && (
                    <img
                        src={props.icon.url || props.icon.src}
                        alt={props.icon.alt || `${props.prepCheckName} icon`}
                        className={localClasses.prepCheckIcon}
                    />
                )}
                <div className={localClasses.title}>
                    <Typography variant="h3" sx={{ color: colors.grays.BLANC }}>
                        {props.prepCheckName}
                    </Typography>
                    {!props.loading && (
                        <IconButton onClick={props.onTranslateClick}>
                            <img
                                src={Translate}
                                className={localClasses.translateIcon}
                                alt="Translate"
                            />
                        </IconButton>
                    )}
                </div>
            </div>
            {/* {smallWindowOrLarger && (
                <div className={localClasses.dataPills}>
                    <PrepCheckDataPill
                        icon={
                            <img
                                src={PhaseStar}
                                alt="Journey phase star icon"
                            />
                        }
                        label={props.journeyPhase}
                    />
                    <PrepCheckDataPill
                        icon={
                            <img
                                src={JourneyCompletionCheck}
                                alt="Prep check completion checkmark icon"
                            />
                        }
                        label={`${props.prepChecksCompleted}/${props.totalPrepChecks}`}
                    />
                </div>
            )} */}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    banner: {
        padding: '1rem 1.5rem',
        backgroundColor: colors.primary.HELIOTROPE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    translateIcon: {
        width: '2rem',
        height: 'auto',
    },
    prepCheckDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    prepCheckIcon: {
        height: '3rem',
        width: 'auto',
    },
    dataPills: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '1rem',
    },
})
