import { colors, palette } from '@hazadapt-git/public-core-base'
import {
    createTheme,
    Palette as MuiPalette,
    PaletteOptions as MuiPaletteOptions,
    Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

declare module '@mui/material' {
    interface Palette {
        'prep-checks': MuiPalette['primary']
    }

    interface PaletteOptions {
        'prep-checks'?: MuiPaletteOptions['primary']
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        'prep-checks': true
    }
}

export const customHazTealColor = colors.secondary.BONDI // color for a selected tab and input titles on settings page
export const customDarkBlueColor = colors.primary.CERULEAN // our custom blue branding color
export const customBlackColor = colors.grays.CHARCOAL // our dark gray color
export const customLightGrayColor = colors.grays.SMOOTH // our off-white color
export const customMidGrayColor = colors.grays.NIMBUS // our mid-gray color
export const altHazRedColor = colors.primary.CORAL // a more vivid variant of our haz red
export const transparentBkgdColor = 'rgba(0,0,0,0)' // transparent color
export const successColor = colors.primary.WAIKATO // color for success text or icons
export const errorColor = colors.primary.CORAL // color for error text on page
export const customBorderRadius = '1rem' // universal border radius
export const customRoundedContainerBorderRadius = '1rem' // border radius for a swoop
export const primaryIconSize = '2rem' // toast icon size
export const unavailableChipColor = colors.grays.NIMBUS // color for an unavailable but selected chip
export const unavailableChipTextColor = colors.grays.SILVER // color for the text of an unavailable but selected chip
export const prepCheckColor = colors.primary.HELIOTROPE
export const localContentColor = '#0A8200'

export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1875,
}

export const theme: Theme = createTheme({
    breakpoints: {
        values: breakpoints,
    },
    typography: {
        fontFamily: [
            'Rubik',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
            fontSize: '2rem',
            fontWeight: 600,
            color: colors.grays.NOIR,
            lineHeight: '2.5rem',
        },
        h2: {
            fontSize: '1.75rem',
            fontWeight: 600,
            color: colors.grays.NOIR,
            lineHeight: '2.25rem',
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 500,
            color: colors.grays.NOIR,
            lineHeight: '1.875rem',
        },
        h4: {
            fontSize: '1.25rem',
            fontWeight: 500,
            color: colors.grays.NOIR,
            lineHeight: '1.5rem',
        },
        h5: {
            fontSize: '1rem',
            fontStyle: 'italic',
            color: colors.grays.NOIR,
            lineHeight: '1.25rem',
        },
        body1: {
            color: colors.grays.NOIR,
        },
        body2: {
            color: colors.grays.NOIR,
        },
    },
    palette: {
        primary: {
            main: customDarkBlueColor,
        },
        success: {
            main: successColor,
        },
        error: {
            main: altHazRedColor,
        },
        secondary: {
            main: customBlackColor,
        },
        'prep-checks': {
            main: colors.primary.HELIOTROPE,
            contrastText: colors.grays.BLANC,
            dark: palette['tertiary'][600],
        },
        text: {
            primary: colors.grays.NOIR,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    boxShadow: '0 0 0 0',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                },
                outlined: {
                    color: colors.grays.NOIR,
                    boxSizing: 'border-box',
                },
                text: {
                    fontWeight: 400,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colors.secondary.LINKY,
                    cursor: 'pointer',
                    textDecorationColor: colors.secondary.LINKY,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: '90%',
                    margin: '1rem',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0 0 0 0',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    color: customMidGrayColor,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: '0.625rem',
                },
                bar: {
                    backgroundColor: colors.primary.WAIKATO,
                },
                colorPrimary: {
                    backgroundColor: colors.softTones.WAIKATO,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: colors.secondary.VIRIDIAN,
                    borderRadius: '0.5rem',
                    padding: '0.375rem 0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.25rem',
                    color: colors.grays.BLANC,
                    margin: '0.5rem !important',
                },
                arrow: {
                    color: colors.secondary.VIRIDIAN,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: '0.75rem 1rem',
                },
            },
        },
    },
})

export const useStyles = makeStyles()({
    root: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        overflowX: 'hidden',
        display: 'flex',
        flexFlow: 'column',
        position: 'relative',
        flex: 1,
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        flex: 1,
        height: '100%',
        paddingTop: '1rem',
    },
    pageWithNoTopPadding: {
        paddingTop: 0,
    },
    text: {
        fontSize: '1.5rem',
        color: colors.grays.NOIR,
    },
    appCopyrightMessage: {
        alignItems: 'center',
        color: colors.grays.NOIR,
        textAlign: 'center',
    },
    appVersionText: {
        fontWeight: 500,
        color: colors.grays.NOIR,
        fontSize: '1.125rem',
        textAlign: 'center',
    },
    appIcon: {
        height: '5rem',
        width: '5rem',
        borderRadius: '0.625rem',
    },
    card: {
        borderRadius: '1rem',
        padding: '0.2rem',
    },
    helpIcon: {
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
    },
    whatToKnow911Card: {
        border: '1px #497D9C solid',
    },
    contentBlock: {
        border: `1px ${customDarkBlueColor} solid`,
        width: '100%',
    },
    contentBlockDetailsIcon: {
        height: '4rem',
        width: 'auto',
    },
    searchQuery: {
        fontWeight: 500,
        color: customDarkBlueColor,
    },
    unstyledButton: {
        all: 'unset',
        cursor: 'pointer',
    },
})
