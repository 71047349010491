import {
    colors,
    getLangObjects,
    Image,
    LanguageCode,
    PrepCheckUserStats,
} from '@hazadapt-git/public-core-base'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import {
    PrepCheckResultDetails,
    PrepCheckResultPreviewItemPropsBase,
    PrepCheckScoreGraph,
} from '../molecules'
import {
    LanguageSelectorModal,
    PrepCheckBanner,
    PrepCheckResultPreviewItemList,
} from '../organisms'

interface PrepCheckResultsPageTemplateProps {
    prepCheckName: string
    prepCheckIcon?: Image
    prepCheckStats: PrepCheckUserStats
    questions: PrepCheckResultPreviewItemPropsBase[]
    openQuestionId?: number
    onQuestionPreviewPress(id: number): void
    dateLastUpdated?: Date
    badge?: Image
    score: number
    preparednessLevel: string
    badgeName: string
    onLinkPress(url: string): any
    onRetakePress: React.MouseEventHandler
    allowBack?: boolean
    onBackPress?: React.MouseEventHandler
    loading?: boolean
    onTranslateClick?: React.MouseEventHandler
    languages: LanguageCode[]
    selectedLanguage: LanguageCode
    languageSelectorModalOpen?: boolean
    onLanguageSelectorModalClose: React.MouseEventHandler
    onLanguageChange(lang: LanguageCode): void
}

export const PrepCheckResultsPageTemplate: FC<
    PrepCheckResultsPageTemplateProps
> = (props: PrepCheckResultsPageTemplateProps) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const largeWindowOrLarger = useWindowSizeUp('lg')

    const openQuestion: PrepCheckResultPreviewItemPropsBase | undefined =
        props.questions.find((q) => q.questionId === props.openQuestionId)

    return props.loading ? (
        <div className={localClasses.loading}>
            <CircularProgress size="1.5rem" />
            <Typography variant="h4">Loading results...</Typography>
        </div>
    ) : (
        <div
            className={classNames(classes.page, classes.pageWithNoTopPadding)}
            style={{ alignItems: 'stretch' }}
        >
            <PrepCheckBanner
                icon={props.prepCheckIcon}
                prepCheckName={props.prepCheckName}
                journeyPhase={props.prepCheckStats.journeyPhase}
                prepChecksCompleted={props.prepCheckStats.numCompleted}
                totalPrepChecks={props.prepCheckStats.numTotal}
                loading={props.loading}
                onTranslateClick={props.onTranslateClick}
            />
            {props.allowBack && (
                <div
                    className={localClasses.goBackContainer}
                    onClick={props.onBackPress}
                >
                    <IoArrowBack
                        color={colors.secondary.LINKY}
                        size="1.25rem"
                    />
                    <Typography
                        variant="body1"
                        fontWeight={500}
                        sx={{ color: colors.secondary.LINKY }}
                    >
                        Back
                    </Typography>
                </div>
            )}
            <Grid container spacing={6} padding="1rem" marginTop={0}>
                <Grid item xs={12} md={6} paddingTop="0 !important">
                    <Typography
                        variant={largeWindowOrLarger ? 'h1' : 'h2'}
                        component="h1"
                        textAlign="left"
                        color={customDarkBlueColor}
                    >
                        {props.prepCheckName} Results
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    justifyContent={{
                        xs: 'center',
                        md: 'flex-start',
                    }}
                    textAlign={{
                        xs: 'center',
                        md: 'left',
                    }}
                    gap="1rem"
                    pt={{
                        xs: '1.5rem !important',
                        md: '0 !important',
                    }}
                >
                    <PrepCheckScoreGraph
                        score={props.score}
                        label={props.preparednessLevel}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6} padding="1rem">
                <Grid item xs={12} md={6}>
                    <PrepCheckResultPreviewItemList
                        questions={props.questions}
                        onToggle={props.onQuestionPreviewPress}
                        open={openQuestion ? props.openQuestionId : undefined}
                    />
                </Grid>
                {openQuestion && props.dateLastUpdated && (
                    <Grid item xs={12} md={6}>
                        <PrepCheckResultDetails
                            {...openQuestion}
                            details={openQuestion.breakdownText}
                            dateLastUpdated={props.dateLastUpdated}
                            onLinkPress={props.onLinkPress}
                        />
                    </Grid>
                )}
            </Grid>
            <div
                style={{
                    alignSelf: 'center',
                    margin: mediumWindowOrLarger
                        ? '3rem 1rem 1.5rem'
                        : '1.5rem 1rem',
                }}
            >
                <Button
                    onClick={props.onRetakePress}
                    variant="contained"
                    color="prep-checks"
                >
                    Retake
                </Button>
            </div>

            {/* Language selector modal */}
            <LanguageSelectorModal
                languages={getLangObjects(props.languages)}
                selectedLanguage={props.selectedLanguage}
                open={!!props.languageSelectorModalOpen}
                onClose={props.onLanguageSelectorModalClose}
                onLanguagePress={props.onLanguageChange}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    goBackContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gap: '0.5rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        padding: '0.5rem',
        margin: '0.5rem',
    },
    badge: {
        height: '6rem',
        width: '6rem',
        objectFit: 'contain',
    },
    loading: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 0',
    },
})
