import {
    colors,
    LanguageCode,
    NativeLanguageDTO,
} from '@hazadapt-git/public-core-base'
import { Grid, Divider, Typography, IconButton, Container } from '@mui/material'
import React, { FC } from 'react'
import classNames from 'classnames'

import MicrosoftLogoGray from '../../assets/images/Microsoft-logo_rgb_c-gray.png'
import { RadioButton } from '../atoms'
import { makeStyles } from 'tss-react/mui'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import {
    LanguageHelpIcon,
    languagePickerSubcopy,
} from './LanguageSelectorModal'

interface LanguageTabProps {
    languages: NativeLanguageDTO[]
    selectedLanguage: LanguageCode
    onRadioBtnPress(value: LanguageCode): void
    fullyExpanded?: boolean
    subcopy?: string
}

export const LanguageTab: FC<LanguageTabProps> = (props: LanguageTabProps) => {
    const { classes } = useLocalStyles()
    const [open, setOpen] = React.useState<boolean>(true)

    return (
        <Container
            className={classNames(
                classes.container,
                props.fullyExpanded ? classes.fullyExpandedContainer : undefined
            )}
        >
            <div className={classes.header}>
                <Typography
                    component="h5"
                    variant="h4"
                    mt="1rem"
                    mb="1rem"
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => setOpen(!open)}
                >
                    Languages
                </Typography>
                <IconButton onClick={() => setOpen(!open)}>
                    {open ? (
                        <IoChevronUp color={colors.grays.NOIR} size="1.5rem" />
                    ) : (
                        <IoChevronDown
                            color={colors.grays.NOIR}
                            size="1.5rem"
                        />
                    )}
                </IconButton>
            </div>
            {open && (
                <Grid container columnSpacing={10}>
                    <Grid item xs={12}>
                        <div className={classes.poweredBy}>
                            <Typography variant="h4" fontSize="1rem">
                                Powered by:
                            </Typography>
                            <img
                                src={MicrosoftLogoGray}
                                alt="Microsoft"
                                className={classes.microsoftLogo}
                            />
                        </div>
                        <div className={classes.subcopy}>
                            <Typography
                                fontWeight={500}
                                color={colors.grays.NIMBUS}
                                textAlign="left"
                            >
                                {languagePickerSubcopy(props.subcopy)}
                            </Typography>
                            <LanguageHelpIcon />
                        </div>
                    </Grid>
                    {props.languages.map((lang, index) => (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            onClick={(e) => {
                                e.preventDefault()
                                props.onRadioBtnPress(lang.value)
                            }}
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            key={`${lang.title}_language_radio_button_wrapper`}
                        >
                            <div
                                style={{
                                    width: '100%',
                                }}
                            >
                                <RadioButton
                                    label={lang.title}
                                    value={lang.value}
                                    checked={
                                        props.selectedLanguage === lang.value
                                    }
                                    labelPlacement="start"
                                    dotColor="#00D57C"
                                    containerStyle={{
                                        cursor: 'pointer',
                                        margin: 0,
                                    }}
                                    textStyle={{
                                        fontWeight: 500,
                                        color: colors.grays.NIMBUS,
                                    }}
                                />
                                {index < props.languages.length - 1 && (
                                    <Divider
                                        sx={{
                                            width: '100%',
                                            margin: '0.5rem 0',
                                        }}
                                    />
                                )}
                            </div>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        borderRadius: '0.5rem',
        height: 'fit-content',
        display: 'flex !important',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    fullyExpandedContainer: {
        maxHeight: 'unset',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    poweredBy: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    microsoftLogo: {
        height: '3.5rem',
        width: 'auto',
    },
    subcopy: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '1.5rem',
    },
})
