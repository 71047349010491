import React, { FC } from 'react'

import NoSearchResults from '../../assets/images/NoSearchResults.svg'
import { EmptyContentPlaceholder } from '../molecules'

interface NoSearchResultsViewProps {
    onRequestHazardPress(...props: any): void
}

export const NoSearchResultsView: FC<NoSearchResultsViewProps> = (
    props: NoSearchResultsViewProps
) => (
    <EmptyContentPlaceholder
        imageProps={{
            src: NoSearchResults,
            alt: 'Image for no search results',
        }}
        text="We couldn’t find what you were looking for. Check your spelling or try another search term and let’s search again!"
        buttonProps={{
            text: 'Request a Hazard',
            onClick: props.onRequestHazardPress,
        }}
        textPlacement="top"
    />
)
