import React, { FC } from 'react'
import voca from 'voca'

import { PrepCheckDonePageTemplate } from '../components'
import { PageProps } from '../lib/entities'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import {
    LanguageCode,
    LoggedPage,
    NativeLanguageDTO,
    PrepCheckResultOverview,
    PrepCheckUpNextDTO,
    getLangObjects,
} from '@hazadapt-git/public-core-base'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    getOnePrepCheckResultOverview,
    getUpNextPrepCheck,
    logEvent,
    toast,
} from '../lib/utils'
import {
    getAllPrepChecksThunk,
    getPrepCheckUserStatsThunk,
    switchLanguageThunk,
} from '../lib/slices'
import { IoCloudDownloadOutline, IoLanguage } from 'react-icons/io5'
import {
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'

interface PrepCheckDonePageProps extends PageProps {}

export const PrepCheckDonePage: FC<PrepCheckDonePageProps> = (
    props: PrepCheckDonePageProps
) => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const { language, user, profileReady } = useAppSelector(
        (state: RootState) => state.profile
    )
    const { stats: prepCheckStats, active_prep_check } = useAppSelector(
        (state: RootState) => state.prep
    )
    const { languages: supportedLanguages } = useAppSelector(
        (state: RootState) => state.hazards
    )

    const [upNext, setUpNext] = React.useState<PrepCheckUpNextDTO>()
    const [results, setResults] = React.useState<PrepCheckResultOverview>()

    const [userStatsRetrieved, setUserStatsRetrieved] =
        React.useState<boolean>(false)
    const [resultRetrieved, setResultRetrieved] = React.useState<boolean>(false)
    const [upNextRetrieved, setUpNextRetrieved] = React.useState<boolean>(false)
    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)

    React.useEffect(() => {
        document.title = 'Prep Check Complete - HazAdapt'
    }, [])

    React.useEffect(() => {
        if (!profileReady) return

        const urlPieces = location.pathname.split('/').slice(1)
        const identifier = urlPieces.at(-2)
        if (identifier && voca.isNumeric(identifier)) {
            logEvent('OPEN_PAGE', {
                page: LoggedPage.PREP_CHECK_RESULTS,
                language,
                prep_check_id: parseInt(identifier),
            })
            dispatch(getPrepCheckUserStatsThunk(language)).then(() => {
                setUserStatsRetrieved(true)
            })
            getOnePrepCheckResultOverview(parseInt(identifier), language)
                .then((res) => {
                    setResultRetrieved(true)
                    setResults(res)
                })
                .catch((err) => {
                    console.error(err)
                    navigate('/prep-checks', { replace: true })
                })
            getUpNextPrepCheck(parseInt(identifier))
                .then(setUpNext)
                .catch(console.error)
                .finally(() => {
                    setUpNextRetrieved(true)
                })
        }
    }, [dispatch, language, location.pathname, navigate, profileReady])

    React.useEffect(() => {
        if (!active_prep_check) {
            navigate('/prep-checks', { replace: true })
        }
    }, [active_prep_check, navigate])

    const onRetakePress: React.MouseEventHandler = (e) => {
        if (!active_prep_check) return
        navigate(
            `/prep-checks/${active_prep_check.id}?from=${location.pathname}`
        )
    }

    const onUpNextPress: React.MouseEventHandler = (e) => {
        if (!upNext) return
        navigate(`/prep-checks/${upNext.id}?from=${location.pathname}`)
    }

    const onViewResultsPress: React.MouseEventHandler = (e) => {
        if (!active_prep_check) return
        navigate(`/prep-checks/${active_prep_check.id}/results`, {
            state: { allowBack: true },
        })
    }

    const onJourneyCompletionClick: React.MouseEventHandler = (e) => {
        navigate('/prep-checks')
    }

    const onMyEmergencyItemsClick: React.MouseEventHandler = (e) => {
        navigate('/my-emergency-items')
    }

    const onLanguageChange = async (lang: LanguageCode) => {
        const langObject: NativeLanguageDTO = getLangObjects([lang])[0]
        setLanguageSelectorModalOpen(false)
        toast(
            `Downloading results in ${langObject.title}.`,
            <IoCloudDownloadOutline
                color={successColor}
                size={primaryIconSize}
            />
        )
        try {
            await dispatch(switchLanguageThunk(lang))
            dispatch(getPrepCheckUserStatsThunk(lang))
            await dispatch(getAllPrepChecksThunk(lang))
            toast(
                `Results have been translated into ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to download results in ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const onTranslateClick: React.MouseEventHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLanguageSelectorModalOpen(true)
    }

    const onLanguageSelectorModalClose = () => {
        setLanguageSelectorModalOpen(false)
    }

    return active_prep_check && results ? (
        <PrepCheckDonePageTemplate
            journeyPhaseIcon={prepCheckStats.journeyPhaseIcon}
            journeyPhaseName={prepCheckStats.journeyPhase}
            journeyPhaseSubcopy={prepCheckStats.journeyPhaseSubcopy}
            prepChecksCompleted={prepCheckStats.numCompleted}
            totalPrepChecks={prepCheckStats?.numTotal || 0}
            prepCheckName={
                active_prep_check.title.includes('Prep Check')
                    ? active_prep_check.title
                    : `${active_prep_check.title} Prep Check`
            }
            badgeName={results.badgeName}
            badge={results.badge}
            score={results.score}
            upNext={upNext}
            onUpNextPress={onUpNextPress}
            onViewResultsPress={onViewResultsPress}
            onRetakePress={onRetakePress}
            onJourneyCompletionClick={onJourneyCompletionClick}
            onMyEmergencyItemsClick={onMyEmergencyItemsClick}
            newBadge={results.newBadge}
            badgeAwardText={results.badgeAwardText}
            newJourneyPhase={results.newJourneyPhase}
            newJourneyPhaseAwardText={results.newJourneyPhaseAwardText}
            loggedIn={!!user}
            loading={
                props.loading ||
                !userStatsRetrieved ||
                !resultRetrieved ||
                !upNextRetrieved
            }
            languages={supportedLanguages}
            selectedLanguage={language}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onTranslateClick={onTranslateClick}
        />
    ) : null
}
