import {
    Select,
    SelectProps,
    MenuItem,
    FormControl,
    Box,
    Typography,
    InputLabel,
} from '@mui/material'
import React, { FC } from 'react'

interface PickerProps extends SelectProps {
    data: {
        label: string
        value: any
    }[]
    fullWidth?: boolean
    inputLabel?: string
    disabled?: boolean
}

export const Picker: FC<PickerProps> = (props: PickerProps) => {
    const { inputLabel, data, fullWidth, disabled, ...selectProps } = props
    const renderPicker = () => (
        <>
            {props.label && (
                <Typography variant="h4" pb="0.625rem">
                    {props.label}
                </Typography>
            )}
            {inputLabel && (
                <InputLabel id={props.labelId}>{inputLabel}</InputLabel>
            )}
            <Select
                {...selectProps}
                id={props.id || 'select'}
                label={inputLabel}
            >
                {inputLabel && (
                    <MenuItem disabled value="">
                        {props.inputLabel}
                    </MenuItem>
                )}
                {data.map((d) => (
                    <MenuItem key={`pickerOption_${d.label}`} value={d.value}>
                        {d.label}
                    </MenuItem>
                ))}
                {data.length === 0 && (
                    <MenuItem
                        disabled
                        key={`pickerOption_noOptionsAvailable`}
                        value=" "
                    >
                        No options available.
                    </MenuItem>
                )}
            </Select>
        </>
    )
    return fullWidth ? (
        <FormControl fullWidth disabled={disabled}>
            {renderPicker()}
        </FormControl>
    ) : (
        <Box sx={{ width: '100%', maxWidth: 400, height: 'auto' }}>
            <FormControl fullWidth disabled={disabled}>
                {renderPicker()}
            </FormControl>
        </Box>
    )
}
