import {
    PromotedProductDTO,
    UserFacingHazardStage,
} from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'
import { isa } from '../api'

/**
 * Get community sponsors for a specified hazard
 * @param hazard_id The hazard to query for
 * @param stage The active hazard stage
 * @param point The user's current location, formatted as [lat, lng]
 * @returns PromotedProductDTO[]
 */
export const fetchPromotedProducts = async (
    hazard_id: number,
    stage: UserFacingHazardStage,
    logged_in: boolean,
    point?: [number, number]
): Promise<PromotedProductDTO[]> =>
    new Promise<PromotedProductDTO[]>((resolve, reject) => {
        isa.get(
            `/public/v2/${
                logged_in ? 'authenticated/' : ''
            }promoted-products/${hazard_id}?stage=${stage}${
                point ? `&lat=${point[0]}&lng=${point[1]}` : ''
            }`
        )
            .then((response) => {
                if (response.status !== 200) {
                    reject(response.data?.error || 'Unknown API error')
                } else {
                    resolve(response.data)
                }
            })
            .catch((err) => {
                console.error(err)
                reject(err)
            })
    })

export const getPromotedProducts = (
    hazard_id: number,
    stage: UserFacingHazardStage,
    logged_in: boolean
) =>
    new Promise<PromotedProductDTO[]>((resolve, reject) => {
        fetchPromotedProducts(hazard_id, stage, logged_in)
            .then(resolve)
            .catch(reject)
    })

export const getBookmarkedProducts = async (): Promise<
    PromotedProductDTO[]
> => {
    try {
        const response: AxiosResponse<PromotedProductDTO[]> = await isa.get(
            '/public/v2/bookmarks/products'
        )
        return response.data
    } catch (err) {
        console.error(err)
        return []
    }
}

export const addProductBookmark = async (
    product_id: number
): Promise<number> => {
    const response: AxiosResponse<number> = await isa.post(
        `/public/v2/bookmarks/products/${product_id}`
    )
    return response.data
}

export const deleteProductBookmark = async (
    product_id: number
): Promise<number> => {
    const response: AxiosResponse<number> = await isa.delete(
        `/public/v2/bookmarks/products/${product_id}`
    )
    return response.data
}
