import { Grid, Link, Typography } from '@mui/material'
import React, { FC } from 'react'

interface ReportIssueTabProps {
    onReportIssuePress(...props: any): void
}

export const ReportIssueTab: FC<ReportIssueTabProps> = (
    props: ReportIssueTabProps
) => (
    <Grid container spacing={10} height="100%">
        <Grid item xs={12} textAlign="center">
            <Typography variant="h4" component="p">
                We are sorry you are experiencing a problem with HazAdapt.
            </Typography>
            <Typography variant="h4" component="p">
                Please click on the link below to report your issue.
            </Typography>
        </Grid>
        <Grid key="reportIssueLink" item xs={12} textAlign="center">
            <Link variant="h4" onClick={props.onReportIssuePress}>
                Report Issue Form
            </Link>
        </Grid>
    </Grid>
)
