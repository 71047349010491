import { colors, PromotedProductDTO } from '@hazadapt-git/public-core-base'
import { IconButton, Popover, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC, MouseEvent } from 'react'
import { IoBookmark, IoBookmarkOutline } from 'react-icons/io5'
import { IoIosLink } from 'react-icons/io'

import { customDarkBlueColor } from '../../lib/styles/universal'
import VerifiedRetailerIcon from '../../assets/icons/verified-retailer.svg'

interface PromotedProductsViewProps extends PromotedProductDTO {
    onLinkPress(id: number, url: string): void
    onBookmarkPress(id: number): void | Promise<void>
    onShare(id: number, url: string): void
}

export const PromotedProductsView: FC<PromotedProductsViewProps> = (
    props: PromotedProductsViewProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const bookmarking = React.useRef<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const handlePopoverClose: React.MouseEventHandler = (e) => {
        setAnchorEl(null)
    }
    const handleVerifiedRetailerClick = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
    }
    const popoverAnchorOpen = Boolean(anchorEl)

    const handleBookmarkPress: React.MouseEventHandler = async () => {
        if (bookmarking.current) return

        bookmarking.current = true
        await props.onBookmarkPress(props.id)
        bookmarking.current = false
    }

    const handleLinkPress: React.MouseEventHandler = (ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        props.onLinkPress(props.id, props.link)
    }

    return (
        <div className={localClasses.container}>
            <div
                className={localClasses.clickableArea}
                onClick={handleLinkPress}
            >
                {props.image && (
                    <img
                        src={props.image.url || props.image.src}
                        alt={props.image.alt}
                        style={{
                            maxHeight: '6rem',
                            width: 'auto',
                            maxWidth: '100%',
                            paddingBottom: '1rem',
                        }}
                    />
                )}
                <Typography
                    variant="body1"
                    textAlign="center"
                    fontWeight={500}
                    sx={{
                        color: customDarkBlueColor,
                        textDecorationColor: customDarkBlueColor,
                    }}
                    onClick={handleLinkPress}
                >
                    {props.name}
                </Typography>
            </div>
            <Typography textAlign="center">
                Sold by{' '}
                <span className={localClasses.retailerNameWrapper}>
                    {props.retailer.name}
                    {!props.affiliate_link ? (
                        <>
                            <Popover
                                open={popoverAnchorOpen}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                slotProps={{
                                    paper: {
                                        style: {
                                            backgroundColor:
                                                colors.grays.WHITESMOKE,
                                            borderRadius: '1rem',
                                            padding: '0.5rem',
                                            maxWidth: '20rem',
                                        },
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography fontWeight={500} pb="0.25rem">
                                    Verified Retailer
                                </Typography>
                                <Typography variant="body2">
                                    Buying from Verified Retailers helps support
                                    businesses directly instead of through
                                    marketplaces that take a cut of the revenue
                                    from the seller.
                                </Typography>
                            </Popover>
                            <img
                                src={VerifiedRetailerIcon}
                                alt="Verified retailer"
                                className={localClasses.verifiedRetailerIcon}
                                onClick={handleVerifiedRetailerClick}
                            />
                        </>
                    ) : null}
                </span>
            </Typography>
            {props.local && (
                <Typography
                    variant="h5"
                    fontWeight={500}
                    fontStyle="normal"
                    textAlign="center"
                    sx={{
                        color: colors.primary.WAIKATO,
                        fontSize: '1rem',
                    }}
                    pb="0.625rem"
                >
                    Based on Your Location
                </Typography>
            )}
            <div className={localClasses.bookmark}>
                <IconButton onClick={handleBookmarkPress}>
                    {props.bookmarked ? (
                        <IoBookmark color={customDarkBlueColor} />
                    ) : (
                        <IoBookmarkOutline color={customDarkBlueColor} />
                    )}
                </IconButton>
                <Typography
                    fontWeight={500}
                    sx={{ color: colors.grays.THUNDERCLOUD }}
                >
                    {props.num_bookmarks}
                </Typography>
            </div>
            <div className={localClasses.share}>
                <IconButton onClick={() => props.onShare(props.id, props.link)}>
                    <IoIosLink color={customDarkBlueColor} />
                </IconButton>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
    },
    clickableArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
    },
    clamped: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        lineClamp: 2,
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'calc(90%)',
    },
    bookmark: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    share: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    retailerNameWrapper: {
        alignItems: 'center',
        display: 'inline-flex',
    },
    verifiedRetailerIcon: {
        width: '1rem',
        height: 'auto',
        paddingLeft: '0.25rem',
        cursor: 'pointer',
    },
})
