import React, { FC } from 'react'

import NoBookmarks from '../../assets/images/noBookmarks.svg'
import { EmptyContentPlaceholder } from '../molecules'

interface NoBookmarksViewProps {
    variant: 'hazards' | 'products'
}

export const NoBookmarksView: FC<NoBookmarksViewProps> = (
    props: NoBookmarksViewProps
) => (
    <EmptyContentPlaceholder
        imageProps={{
            src: NoBookmarks,
            alt: 'Image for no bookmarks',
        }}
        text={`You don't have any bookmarked ${props.variant} yet. Found something you want to save? Add it to your Bookmarks!`}
    />
)
