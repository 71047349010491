import {
    Switch as MuiSwitch,
    SwitchProps as MuiSwitchProps,
    FormControlLabel,
} from '@mui/material'
import React, { FC } from 'react'

interface SwitchProps extends MuiSwitchProps {
    label?: string
}

export const Switch: FC<SwitchProps> = (props: SwitchProps) =>
    props.label ? (
        <FormControlLabel
            control={<MuiSwitch {...props} />}
            label={props.label}
        />
    ) : (
        <MuiSwitch {...props} />
    )
