import { colors } from '@hazadapt-git/public-core-base'
import { Chip as MuiChip } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

interface PrepCheckDataPillProps {
    icon: React.ReactElement
    label: string
    onClick?: React.MouseEventHandler
}

export const PrepCheckDataPill: FC<PrepCheckDataPillProps> = (
    props: PrepCheckDataPillProps
) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <MuiChip
            icon={props.icon}
            label={props.label}
            classes={{
                root: localClasses.chip,
                label: localClasses.chipLabel,
                icon: localClasses.icon,
            }}
            onClick={props.onClick}
        />
    )
}

const useLocalStyles = makeStyles()({
    chip: {
        backgroundColor: `${colors.grays.BLANC} !important`,
        color: `${colors.grays.NOIR} !important`,
        boxShadow: `0 1px 0.25rem #00000040`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.75rem',
        width: 'fit-content',
        padding: `0 0.75rem !important`,
    },
    chipLabel: {
        paddingLeft: `0 !important`,
        paddingRight: `0 !important`,
        fontSize: '1rem',
        fontWeight: 400,
    },
    icon: {
        marginLeft: `0 !important`,
    },
})
