import { Circle } from '@mui/icons-material'
import { FormControlLabel, Radio, RadioProps, Typography } from '@mui/material'
import React, { CSSProperties, FC } from 'react'

interface RadioButtonProps extends RadioProps {
    label: string
    labelPlacement?: 'start' | 'end'
    dotColor?: string
    containerStyle?: CSSProperties
    textStyle?: CSSProperties
}

export const RadioButton: FC<RadioButtonProps> = (props: RadioButtonProps) => {
    const {
        label,
        labelPlacement,
        dotColor,
        containerStyle,
        textStyle,
        ...radioProps
    } = props
    return (
        <FormControlLabel
            label={
                <Typography component="p" variant="h4" sx={textStyle}>
                    {label}
                </Typography>
            }
            value={radioProps.value}
            labelPlacement={labelPlacement}
            sx={{
                ...containerStyle,
                display: 'flex',
                flexDirection:
                    labelPlacement === 'start' ? 'row-reverse' : 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}
            control={
                <Radio
                    value={props.value}
                    checked={props.checked}
                    checkedIcon={
                        radioProps.checkedIcon || (
                            <Circle
                                sx={{
                                    color: dotColor || 'primary',
                                }}
                            />
                        )
                    }
                />
            }
        />
    )
}
