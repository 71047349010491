import { SupportedContentBooster, colors } from '@hazadapt-git/public-core-base'
import { Button, Divider } from '@mui/material'
import React, { FC } from 'react'
import { ContentBooster } from '../../lib/entities'
import { CSOFilterPickerSection } from '../molecules'

interface CSOFilterPickerProps {
    activeChips: SupportedContentBooster[]
    availableChips: SupportedContentBooster[]
    onChipPress(value: string): void
    onResetPress(...props: any): void
    onCancelPress(...props: any): void
}

export const CSOFilterPicker: FC<CSOFilterPickerProps> = (
    props: CSOFilterPickerProps
) => {
    const humanChips = Object.values(ContentBooster)
        .filter((cb) => cb.type === 'Humans')
        .filter(
            (cb) =>
                props.activeChips.includes(cb.key) ||
                props.availableChips.includes(cb.key)
        )
    const animalChips = Object.values(ContentBooster)
        .filter((cb) => cb.type === 'Animals')
        .filter(
            (cb) =>
                props.activeChips.includes(cb.key) ||
                props.availableChips.includes(cb.key)
        )
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="text"
                    onClick={props.onResetPress}
                    sx={{
                        textDecorationLine: 'underline',
                        color: colors.grays.NOIR,
                    }}
                >
                    Reset
                </Button>
            </div>
            <div style={{ paddingLeft: '1rem' }}>
                <CSOFilterPickerSection
                    chips={humanChips}
                    activeChips={props.activeChips}
                    availableChips={props.availableChips}
                    title="Humans"
                    onChipPress={props.onChipPress}
                />
            </div>
            {humanChips.length > 0 && animalChips.length > 0 && (
                <Divider
                    sx={{
                        width: '80%',
                        marginLeft: '10%',
                        alignSelf: 'center',
                        color: '#00000080',
                    }}
                />
            )}
            <div style={{ paddingLeft: '1rem' }}>
                <CSOFilterPickerSection
                    chips={animalChips}
                    activeChips={props.activeChips}
                    availableChips={props.availableChips}
                    title="Animals"
                    onChipPress={props.onChipPress}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <Button
                    variant="text"
                    onClick={props.onCancelPress}
                    sx={{
                        textDecorationLine: 'underline',
                        color: colors.grays.NOIR,
                    }}
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}
