import React, { FC, CSSProperties } from 'react'

import LogoLight from '../../assets/images/hazadapt-red-black.svg'
import LogoDark from '../../assets/images/hazadapt-red-white.svg'
import CircleLight from '../../assets/images/circle-h-black.svg'
import CircleDark from '../../assets/images/circle-h-white.svg'

export interface CompanyLogoProps {
    dark?: boolean
    height?: number | string
    width?: number | string
    style?: CSSProperties
    onClick?(...props: any): void
    circle?: boolean
}

export const CompanyLogo: FC<CompanyLogoProps> = (props: CompanyLogoProps) => (
    <img
        src={
            props.circle
                ? props.dark
                    ? CircleDark
                    : CircleLight
                : props.dark
                ? LogoDark
                : LogoLight
        }
        style={{
            ...props.style,
            width:
                props.width || (props.height !== 'undefined' ? 'auto' : '7rem'),
            height: props.height || 'auto',
        }}
        onClick={props.onClick}
        alt="HazAdapt logo"
    />
)
