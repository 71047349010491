import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import { Card } from '../atoms/Card'
import { Atoms } from './Atoms'
import { Molecules } from './Molecules'
import { Organisms } from './Organisms'
import { colors } from '@hazadapt-git/public-core-base'

interface ShowcaseProps {}

export const Showcase: FC<ShowcaseProps> = (props: ShowcaseProps) => {
    const { classes: showcaseClasses } = useShowcaseClasses()
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Card
                    colors={[colors.grays.BLANC]}
                    className={showcaseClasses.container}
                >
                    <Typography
                        variant="h1"
                        textAlign="center"
                        paddingBottom="4rem"
                    >
                        Atoms
                    </Typography>
                    <Atoms />
                </Card>

                <Card
                    colors={[colors.grays.BLANC]}
                    className={showcaseClasses.container}
                >
                    <Typography
                        variant="h1"
                        textAlign="center"
                        paddingBottom="4rem"
                    >
                        Molecules
                    </Typography>
                    <Molecules />
                </Card>

                <Card
                    colors={[colors.grays.BLANC]}
                    className={showcaseClasses.container}
                >
                    <Typography
                        variant="h1"
                        textAlign="center"
                        paddingBottom="4rem"
                    >
                        Organisms
                    </Typography>
                    <Organisms />
                </Card>
            </div>
        </LocalizationProvider>
    )
}

const useShowcaseClasses = makeStyles()({
    container: {
        border: '1px #05446A solid',
        margin: '2rem',
        width: '99%',
        paddingTop: '2rem',
        paddingBottom: '2rem',
    },
})
