import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import { WhenToCall911Item, WhenToCall911ItemProps } from '../molecules'
import { colors } from '@hazadapt-git/public-core-base'

interface WhenToCall911ViewProps {
    data: WhenToCall911ItemProps[]
}

export const WhenToCall911View: FC<WhenToCall911ViewProps> = (
    props: WhenToCall911ViewProps
) => {
    const { classes } = useLocalStyles()
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} padding="2rem" paddingBottom={0}>
                <Typography
                    variant="h2"
                    pb="0.625rem"
                    color={colors.grays.BLANC}
                    textAlign="center"
                >
                    When should I call 911?
                </Typography>
            </Grid>
            <Grid item xs={12} padding="2rem" paddingBottom={0} paddingTop={0}>
                <Typography color={colors.grays.BLANC} textAlign="center">
                    Not every emergency needs 911.
                </Typography>
                <Typography color={colors.grays.BLANC} textAlign="center">
                    Call 911 if you or someone near you is experiencing a
                    life-threatening emergency.
                </Typography>
            </Grid>
            {props.data.map((item: WhenToCall911ItemProps, index: number) => (
                <Grid
                    item
                    key={`wtc911_item${index + 1}`}
                    xs={index < props.data.length - 1 ? 6 : true}
                    md={4}
                    padding="1rem"
                >
                    <WhenToCall911Item {...item} />
                </Grid>
            ))}
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        background:
            'linear-gradient(94.95deg, #5697BE 0%, #477896 100%, #5697BE 100%)',
        borderRadius: '1rem',
        width: '100%',
    },
})
