import { LoggingEvent, LoggingStatus } from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'

import { isa } from '../api'
import { inBetaEnv, inDevEnv } from '../config'
import { store } from '../store'
import { getNoAuthJsonHeader } from './http'

/**
 * logEvent
 * Log an event; send the event data to ISA to send to Amplitude servers
 * @param event_type: event name; each possible value corresponds to an enum value on ISA
 * @param event_properties: any other data to send to ISA for the event
 * @returns The success status of the logging event
 */
export const logEvent = async (
    event_type: LoggingEvent,
    event_properties?: { [key: string]: any },
    user_proprties?: { [key: string]: any }
): Promise<LoggingStatus> => {
    // SKIP LOGGING IF IN BETA ENV
    if (inBetaEnv()) return LoggingStatus.Skipped

    if (inDevEnv()) console.log(event_type)
    const { user, currentZip } = store.getState().profile
    const params = {
        event_type,
        event_properties: {
            ...event_properties,
        },
        user_properties: {
            ...user_proprties,
            zip: user?.zip ?? null, // send the user's ZIP code if the user is logged in and has indicated a ZIP code, otherwise send null
            current_zip: currentZip || null,
        },
        os_name: 'web',
        app_version: process.env.APP_VERSION || undefined,
        time: new Date().getTime(),
    }
    try {
        const response: AxiosResponse<LoggingStatus> = await isa.post(
            // send the logging event to ISA
            '/public/analytics/log',
            params,
            {
                headers: getNoAuthJsonHeader(),
            }
        )
        return response.data // return the logging event's success status
    } catch (err) {
        console.error(err)
        return LoggingStatus.Failed
    }
}
