import { SxProps, Theme } from '@mui/material'
import {
    Checkbox as MuiCheckbox,
    CheckboxProps as MuiCheckboxProps,
    FormControlLabel,
} from '@mui/material'
import React, { FC } from 'react'

interface CheckboxProps extends MuiCheckboxProps {
    label: string | React.ReactElement
    labelPlacement?: 'top' | 'start' | 'bottom' | 'end'
    containerStyle?: SxProps<Theme>
}

export const Checkbox: FC<CheckboxProps> = (props: CheckboxProps) => {
    const { label, labelPlacement, containerStyle, ...checkboxProps } = props
    return (
        <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            sx={containerStyle}
            control={<MuiCheckbox {...checkboxProps} />}
        />
    )
}
