import { colors } from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import React, { FC } from 'react'

import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import { TabGroup, TabGroupProps } from '../organisms'

interface SettingsPageTemplateProps {
    data: { [key: string]: TabGroupProps }
    activeGroup: string
    onGroupNamePress(group: string): void
    loggedIn?: boolean
}

export const SettingsPageTemplate: FC<SettingsPageTemplateProps> = (
    props: SettingsPageTemplateProps
) => {
    const { classes } = useStyles()
    const largeWindowOrLarger = useWindowSizeUp('lg')
    return (
        <div
            className={classes.page}
            style={{
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginBottom: '3rem',
            }}
        >
            <Typography
                variant={largeWindowOrLarger ? 'h1' : 'h2'}
                component="h1"
                width="100%"
                textAlign="left"
                color={customDarkBlueColor}
                paddingBottom="1rem"
            >
                Settings
            </Typography>
            <div
                style={{
                    display: 'flex',
                    paddingBottom: '2rem',
                    alignSelf: 'flex-start',
                }}
            >
                {Object.keys(props.data).map((k, index) => (
                    <Typography
                        component="p"
                        key={`${k}_groupLabel`}
                        variant={'h4'}
                        color={
                            props.activeGroup === k
                                ? colors.grays.NOIR
                                : colors.grays.NIMBUS
                        }
                        paddingLeft={index > 0 ? '1rem' : 0}
                        paddingRight={
                            index < Object.keys(props.data).length - 1
                                ? '1rem'
                                : 0
                        }
                        sx={{
                            cursor: 'pointer',
                            textDecoration:
                                props.activeGroup === k ? 'underline' : 'none',
                        }}
                        onClick={() => props.onGroupNamePress(k)}
                    >
                        {k === 'General' && props.loggedIn ? 'Profile' : k}
                    </Typography>
                ))}
            </div>
            {Object.keys(props.data).map((k) => (
                <TabGroup
                    {...props.data[k]}
                    key={`${k}_tabGroup`}
                    visible={props.activeGroup === k}
                />
            ))}
        </div>
    )
}
