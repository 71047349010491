import { colors } from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { CSSProperties, FC } from 'react'
import { AspectRatio } from 'react-aspect-ratio'

import { Card } from '../atoms'

export interface ScrollingBlockProps {
    imageSrc: string
    imageAlt?: string
    name: string
    onPress(...props: any): void
    style?: CSSProperties
    className?: string
    role?: React.AriaRole
}

export const ScrollingBlock: FC<ScrollingBlockProps> = (
    props: ScrollingBlockProps
) => {
    const { classes } = useLocalStyles()
    return (
        <div
            className={classNames(classes.container, props.className)}
            style={{
                ...props.style,
                cursor: 'pointer',
                width: '7rem',
            }}
            onClick={props.onPress}
            role={props.role}
        >
            <AspectRatio ratio={1} style={{ width: '7rem' }}>
                <Card
                    colors={[colors.grays.WHITESMOKE]}
                    className={classes.card}
                    innerContainerStyle={scrollingBlockCardStyle}
                >
                    <img
                        src={props.imageSrc}
                        className={classes.icon}
                        alt={props.imageAlt || `${props.name} icon`}
                    />
                </Card>
            </AspectRatio>
            <Typography
                component="p"
                variant="h4"
                paddingTop="0.5rem"
                onClick={props.onPress}
                textAlign="center"
                whiteSpace="pre-line"
            >
                {props.name}
            </Typography>
        </div>
    )
}

const scrollingBlockCardStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
}

const useLocalStyles = makeStyles()({
    container: {
        cursor: 'pointer',
    },
    card: { ...scrollingBlockCardStyle },
    icon: {
        height: '5rem',
        width: 'auto',
    },
})
