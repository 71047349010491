import React, { FC } from 'react'
import classNames from 'classnames'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { colors } from '@hazadapt-git/public-core-base'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { Checkbox, Tooltip } from '../atoms'

export interface EmergencyItemPrepCheckTooltip {
    id: number
    name: string
    icon: string
    alt?: string
}

export interface EmergencyItem {
    id: number
    name: string
    userHasItem: boolean
    prep_checks: EmergencyItemPrepCheckTooltip[]
}

interface MyEmergencyItemsListProps {
    listTitle: string
    items: EmergencyItem[]
    onCheck(item_name: string): void
    // onShare: React.MouseEventHandler
    showHazardIcons?: boolean
    fullyExpanded?: boolean
}

export const MyEmergencyItemsList: FC<MyEmergencyItemsListProps> = (
    props: MyEmergencyItemsListProps
) => {
    const { classes } = useLocalStyles()
    const [open, setOpen] = React.useState<boolean>(true)

    return (
        <div
            className={classNames(
                classes.container,
                props.fullyExpanded ? classes.fullyExpandedContiner : undefined
            )}
        >
            {/* List Header */}
            <div className={classes.header}>
                <Typography
                    variant="h4"
                    component="h2"
                    flexGrow={1}
                    mr="1rem"
                    sx={{ color: colors.grays.BLANC }}
                >
                    {props.listTitle}
                </Typography>
                {/* <IconButton onClick={props.onShare}>
                    <IoShareSocial color={colors.grays.BLANC} size="1.5rem" />
                </IconButton> */}
                <IconButton onClick={() => setOpen(!open)}>
                    {open ? (
                        <IoChevronUp color={colors.grays.BLANC} size="1.5rem" />
                    ) : (
                        <IoChevronDown
                            color={colors.grays.BLANC}
                            size="1.5rem"
                        />
                    )}
                </IconButton>
            </div>
            {/* Item List */}
            {open && (
                <ul className={classes.itemList}>
                    {props.items.map((item, index) => (
                        <li
                            className={classNames(
                                classes.item,
                                index % 2 === 0
                                    ? classes.oddItem
                                    : classes.evenItem
                            )}
                            key={`item_${item.id}`}
                        >
                            <Checkbox
                                label={item.name ?? ''}
                                checked={item.userHasItem}
                                onChange={() => props.onCheck(item.name)}
                            />
                            <div
                                className={classes.hazardIcons}
                                style={
                                    item.prep_checks.length >= 4
                                        ? {
                                              maxWidth: '8rem',
                                              flexWrap: 'wrap',
                                          }
                                        : {
                                              flexWrap: 'nowrap',
                                          }
                                }
                            >
                                {item.prep_checks.map((pc) => (
                                    <Tooltip
                                        title={pc.name}
                                        key={`item_${item.id}_linkedPrepCheck_${pc.id}`}
                                        sx={{ padding: '0.375rem 0.75rem' }}
                                    >
                                        <img
                                            src={pc.icon}
                                            className={classes.hazardIcon}
                                            alt={pc.alt || `${pc.name} icon`}
                                        />
                                    </Tooltip>
                                ))}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        borderRadius: '0.5rem',
        height: 'fit-content',
        maxHeight: '33rem',
        width: '28.1875rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    fullyExpandedContiner: {
        maxHeight: 'unset',
    },
    header: {
        backgroundColor: colors.primary.CERULEAN,
        padding: '0.5rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: '0.5rem',
    },
    itemList: {
        flexGrow: 1,
        listStyle: 'none',
        padding: 0,
        overflowY: 'auto',
        margin: 0,
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '2rem',
        padding: '0.5rem 1rem',
    },
    oddItem: {
        backgroundColor: colors.secondary.PANACHE,
    },
    evenItem: {
        backgroundColor: '#C7D5D4',
    },
    hazardIcons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem',
    },
    hazardIcon: {
        height: '1.5rem',
        width: 'auto',
        objectFit: 'contain',
    },
})
