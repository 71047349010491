import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC, MouseEventHandler } from 'react'
import {
    colors,
    Image,
    PrepCheckQuestionType,
} from '@hazadapt-git/public-core-base'
import { Close } from '@mui/icons-material'
import { Markdown } from '../atoms'
import { useWindowSizeUp } from '../../lib/utils'
import { IoInformationCircle } from 'react-icons/io5'

import PrepCheck1DQuestionExplainerDiagram from '../../assets/icons/PrepCheck1DQuestionExplainerDiagram.svg'
import PrepCheck2DQuestionExplainerDiagram from '../../assets/animations/PrepCheck2DQuestionExplainerDiagram.gif'
import PrepCheckCheckboxesQuestionExplainerDiagram from '../../assets/icons/PrepCheckCheckboxesQuestionExplainerDiagram.svg'
import classNames from 'classnames'

interface PrepCheckQuestionHeadingProps {
    icon?: Image
    prepCheckQuestionText: string
    prepCheckQuestionInstructions?: string
    prepCheckQuestionType: PrepCheckQuestionType
    explainerText?: string
    explainerButtonRef?: React.RefObject<HTMLButtonElement>
    showTutorial?: boolean
    onQuestionExplainerClose?: React.MouseEventHandler
    savingInProgress?: boolean
    savedSuccessfully?: boolean
    showSavedCopy?: boolean
}

export const PrepCheckQuestionHeading: FC<PrepCheckQuestionHeadingProps> = (
    props: PrepCheckQuestionHeadingProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const largeWindowOrLarger = useWindowSizeUp('lg')

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )
    const handleClickDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }
    const handleCloseDialog: MouseEventHandler = (e) => {
        props.onQuestionExplainerClose?.(e)
        setAnchorEl(null)
    }
    const openDialog = Boolean(anchorEl)

    return (
        <div>
            <div className={localClasses.container}>
                {props.icon && (
                    <img
                        src={props.icon.url || props.icon.src}
                        alt={props.icon.alt}
                        className={localClasses.image}
                    />
                )}
                <div className={localClasses.textContainer}>
                    <div className={localClasses.prepCheckQuestion}>
                        <Typography variant={largeWindowOrLarger ? 'h3' : 'h4'}>
                            {props.prepCheckQuestionText}
                        </Typography>
                        {props.explainerText && (
                            <>
                                <IconButton
                                    onClick={handleClickDialog}
                                    ref={props.explainerButtonRef}
                                >
                                    <IoInformationCircle
                                        color={colors.grays.NIMBUS}
                                    />
                                </IconButton>
                                <Dialog
                                    open={openDialog || !!props.showTutorial}
                                    onClose={handleCloseDialog}
                                >
                                    <DialogContent>
                                        <IconButton
                                            onClick={handleCloseDialog}
                                            sx={{
                                                position: 'absolute',
                                                top: '0.5rem',
                                                right: '0.5rem',
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                        <Markdown
                                            content={props.explainerText}
                                        />
                                        <img
                                            src={
                                                props.prepCheckQuestionType ===
                                                'TwoD_Grid_Placement'
                                                    ? PrepCheck2DQuestionExplainerDiagram
                                                    : props.prepCheckQuestionType ===
                                                      'Checkboxes'
                                                    ? PrepCheckCheckboxesQuestionExplainerDiagram
                                                    : PrepCheck1DQuestionExplainerDiagram
                                            }
                                            className={classNames(
                                                localClasses.questionExplainerDiagram,
                                                props.prepCheckQuestionType ===
                                                    'Checkboxes'
                                                    ? localClasses.checkboxesQuestionExplainerDiagram
                                                    : undefined
                                            )}
                                            alt={
                                                props.prepCheckQuestionType ===
                                                'TwoD_Grid_Placement'
                                                    ? 'A diagram of two columns with plants and animals, and a card with a butterfly on it'
                                                    : props.prepCheckQuestionType ===
                                                      'Checkboxes'
                                                    ? 'Two checkboxes with safety supplies you have and safety supplies you do not have'
                                                    : 'A list of emergency contacts and a list of slots to place them in order'
                                            }
                                        />
                                    </DialogContent>
                                    {props.showTutorial && (
                                        <DialogActions
                                            sx={{ padding: '0 1rem 1rem' }}
                                        >
                                            <Button
                                                variant="contained"
                                                onClick={handleCloseDialog}
                                            >
                                                Start
                                            </Button>
                                        </DialogActions>
                                    )}
                                </Dialog>
                            </>
                        )}
                    </div>
                    {smallWindowOrLarger && (
                        <Typography mt="0.5rem">
                            {props.prepCheckQuestionInstructions}
                        </Typography>
                    )}
                </div>
            </div>
            {!smallWindowOrLarger && (
                <Typography textAlign="center" mt="1rem">
                    {props.prepCheckQuestionInstructions}
                </Typography>
            )}
            <Typography
                variant="h5"
                textAlign="center"
                sx={{
                    color:
                        !props.savingInProgress && !props.savedSuccessfully
                            ? colors.primary.CORAL
                            : colors.grays.NIMBUS,
                    alignSelf: 'center',
                    p: '0.5rem',
                    visibility: props.showSavedCopy ? 'visible' : 'hidden',
                }}
            >
                {props.savingInProgress
                    ? 'Saving...'
                    : props.savedSuccessfully
                    ? 'Progress saved!'
                    : 'Error saving progress.'}
            </Typography>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    image: {
        width: '5.75rem',
        height: '5.75rem',
        marginRight: '1rem',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    prepCheckQuestion: {
        display: 'flex',
        alignItems: 'center',
    },
    lightFont: {
        fontWeight: 400,
    },
    questionExplainerDiagram: {
        width: `calc(100% - 2rem)`,
        maxHeight: '20rem',
        objectFit: 'contain',
        margin: '0 1rem',
    },
    checkboxesQuestionExplainerDiagram: {
        maxHeight: '4rem',
    },
})
