import { colors, whyHazAdaptURL } from '@hazadapt-git/public-core-base'
import { Link } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

import { customDarkBlueColor } from '../../lib/styles/universal'
import { logWhyHazAdaptHit } from '../../lib/utils'

export const HomePageFooter = () => {
    return (
        <Box
            width="100%"
            padding="2rem"
            bgcolor={colors.secondary.PANACHE}
            display="flex"
            alignItems="center"
            justifySelf="flex-end"
        >
            <Link
                href={whyHazAdaptURL}
                onClick={logWhyHazAdaptHit}
                typography="Why HazAdapt?"
                variant="h3"
                sx={{
                    color: customDarkBlueColor,
                    marginLeft: '2.5rem',
                    textDecorationColor: customDarkBlueColor,
                }}
            >
                Why HazAdapt?
            </Link>
        </Box>
    )
}
