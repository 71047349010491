import {
    FilterConfig,
    SupportedHazardFilter,
    Image,
    getLangObjects,
    LanguageCode,
    colors,
} from '@hazadapt-git/public-core-base'
import { Close } from '@mui/icons-material'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material'
import classNames from 'classnames'
import React, { FC } from 'react'
import { useStyles } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import {
    ChipGrid,
    PrepCheckCardBaseProps,
    PrepCheckWelcomeModal,
} from '../molecules'
import {
    LanguageSelectorModal,
    PrepCheckCardGrid,
    PrepCheckLandingBanner,
} from '../organisms'
import { makeStyles } from 'tss-react/mui'
import { IoOptions } from 'react-icons/io5'

interface PrepCheckLandingPageTemplateProps {
    journeyPhase: string
    journeyPhaseIcon: Image
    journeyPhaseSubcopy: string
    prepChecksCompleted: number
    totalPrepChecks: number
    filters: FilterConfig[]
    activeFilters: SupportedHazardFilter[]
    onChipPress(name: string): void
    onChipReset: React.MouseEventHandler
    prepChecks: PrepCheckCardBaseProps[]
    onSharePress?(e: React.MouseEvent, id: number): void
    onPrepCheckClick(id: number): void
    onJourneyCompletionClick: React.MouseEventHandler
    onMyEmergencyItemsClick: React.MouseEventHandler
    showLoginWall?: boolean
    toggleLoginWall(state: boolean): void
    loggedIn: boolean
    onLogin: React.MouseEventHandler
    showPrepCheckWelcome?: boolean
    onPrepCheckWelcomeClose?: React.MouseEventHandler
    loading?: boolean
    onTranslateClick?: React.MouseEventHandler
    languages: LanguageCode[]
    selectedLanguage: LanguageCode
    languageSelectorModalOpen?: boolean
    onLanguageSelectorModalClose: React.MouseEventHandler
    onLanguageChange(lang: LanguageCode): void
    filtersExpanded: boolean
    onFilterViewToggle(open: boolean): void
}

export const PrepCheckLandingPageTemplate: FC<
    PrepCheckLandingPageTemplateProps
> = (props: PrepCheckLandingPageTemplateProps) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')

    const [showPrepCheckWelcome, setShowPrepCheckWelcome] =
        React.useState<boolean>(false)

    React.useEffect(() => {
        setShowPrepCheckWelcome(!!props.showPrepCheckWelcome)
    }, [props.showPrepCheckWelcome])

    const onPrepCheckWelcomeClose: React.MouseEventHandler = (e) => {
        props.onPrepCheckWelcomeClose?.(e)
        setShowPrepCheckWelcome(false)
    }

    return (
        <div>
            <PrepCheckLandingBanner
                journeyPhaseIcon={props.journeyPhaseIcon}
                journeyPhase={props.journeyPhase}
                journeyPhaseSubcopy={props.journeyPhaseSubcopy}
                prepChecksCompleted={props.prepChecksCompleted}
                totalPrepChecks={props.totalPrepChecks}
                onJourneyCompletionClick={props.onJourneyCompletionClick}
                onMyEmergencyItemsClick={props.onMyEmergencyItemsClick}
                showJourneyPhase={props.loggedIn}
                loading={props.loading}
                onTranslateClick={props.onTranslateClick}
            />
            <div
                className={classNames(
                    classes.page,
                    classes.pageWithNoTopPadding
                )}
                style={{
                    padding: '0 1rem',
                    marginBottom: '3rem',
                }}
            >
                {!props.loading ? (
                    <>
                        {props.filters.length > 0 && (
                            <div
                                style={{
                                    alignSelf: mediumWindowOrLarger
                                        ? 'center'
                                        : 'flex-start',
                                    display:
                                        props.filters.length > 0
                                            ? 'block'
                                            : 'none',
                                    padding: `${
                                        props.filtersExpanded ||
                                        props.activeFilters.length > 0
                                            ? '0.5rem'
                                            : '1rem'
                                    } 0 1.5rem`,
                                }}
                            >
                                <ChipGrid
                                    chips={props.filters}
                                    activeChips={props.activeFilters}
                                    availableChips={props.filters.map(
                                        (f) => f.key
                                    )}
                                    guidingText={
                                        mediumWindowOrLarger ? (
                                            'Filter By:'
                                        ) : (
                                            <button
                                                className={
                                                    localClasses.mwFilterBy
                                                }
                                                onClick={() =>
                                                    props.onFilterViewToggle(
                                                        !props.filtersExpanded
                                                    )
                                                }
                                            >
                                                <IoOptions
                                                    size="1.5rem"
                                                    color={
                                                        props.filtersExpanded
                                                            ? colors.grays.NOIR
                                                            : colors.secondary
                                                                  .BONDI
                                                    }
                                                />
                                                <Typography
                                                    color={
                                                        props.filtersExpanded
                                                            ? colors.grays.NOIR
                                                            : colors.secondary
                                                                  .BONDI
                                                    }
                                                    fontWeight={500}
                                                    whiteSpace="nowrap"
                                                    px="0.5rem"
                                                >
                                                    {props.filtersExpanded
                                                        ? `Hide Filters`
                                                        : 'Show Filters'}
                                                </Typography>
                                            </button>
                                        )
                                    }
                                    onChipPress={props.onChipPress}
                                    onResetPress={props.onChipReset}
                                    showDeleteIcon
                                    collapsible={!mediumWindowOrLarger}
                                    collapsed={!props.filtersExpanded}
                                />
                            </div>
                        )}
                    </>
                ) : null}
                <PrepCheckCardGrid
                    data={props.prepChecks}
                    onPrepCheckClick={props.onPrepCheckClick}
                    onSharePress={props.onSharePress}
                    loading={props.loading}
                />
            </div>

            {/* Login wall */}
            <Dialog
                open={!!props.showLoginWall}
                onClose={() => props.toggleLoginWall(false)}
                PaperProps={{ sx: { padding: '1rem' } }}
            >
                <DialogTitle sx={{ paddingRight: '2.5rem' }}>
                    <Typography variant="h3" component="p">
                        Log In to Continue
                    </Typography>
                    <IconButton
                        onClick={() => props.toggleLoginWall(false)}
                        sx={{
                            position: 'absolute',
                            top: '0.5rem',
                            right: '0.5rem',
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You need to be logged in to do Prep Checks.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => props.toggleLoginWall(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onLogin}
                    >
                        Log In
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Language selector modal */}
            <LanguageSelectorModal
                languages={getLangObjects(props.languages)}
                selectedLanguage={props.selectedLanguage}
                open={!!props.languageSelectorModalOpen}
                onClose={props.onLanguageSelectorModalClose}
                onLanguagePress={props.onLanguageChange}
            />

            {/* Welcome dialog */}
            <PrepCheckWelcomeModal
                open={showPrepCheckWelcome}
                onClose={onPrepCheckWelcomeClose}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    mwFilterBy: {
        all: 'unset',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
})
