import { colors } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { transparentBkgdColor } from '../../lib/styles/universal'
import { Typography } from '@mui/material'

interface PrepCheckScoreGraphProps {
    score?: number
    label?: string
    bottomSpace?: boolean
}

export const PrepCheckScoreGraph: FC<PrepCheckScoreGraphProps> = (
    props: PrepCheckScoreGraphProps
) => {
    const { classes } = useLocalStyles()
    return (
        <div className={classes.scoreGraphWrapper}>
            <div
                className={classes.scoreGraph}
                style={{ marginBottom: props.bottomSpace ? '2rem' : 0 }}
            >
                {props.score !== undefined ? (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: `${props.score * 100}%`,
                                height: `${props.score * 100}%`,
                                background: `linear-gradient(to bottom right, ${transparentBkgdColor} 0%, ${transparentBkgdColor} 50%, ${colors.primary.HELIOTROPE} 50%, ${colors.primary.HELIOTROPE} 100%)`,
                            }}
                        />
                        <div
                            className={classes.pointer}
                            style={{
                                left: `calc(${props.score * 100}% - 0.5rem)`,
                            }}
                        />
                    </>
                ) : null}
            </div>
            {props.label && (
                <Typography variant="h4" mt="1.25rem">
                    {props.label}
                </Typography>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    scoreGraphWrapper: {
        width: 'calc(100% - 2rem)',
        maxWidth: '20rem',
    },
    scoreGraph: {
        width: '100%',
        height: '3rem',
        background: `linear-gradient(to bottom right, ${transparentBkgdColor} 0%, ${transparentBkgdColor} 50%, ${colors.grays.SILVER} 50%, ${colors.grays.SILVER} 100%)`,
        position: 'relative',
    },
    pointer: {
        position: 'absolute',
        bottom: '-1rem',
        borderLeft: '0.5rem solid transparent',
        borderRight: '0.5rem solid transparent',
        borderTop: '0.5rem solid transparent',
        borderBottom: `calc(2 * 0.5rem * 0.866) solid #E1AD01`,
        display: 'inline-block',
    },
})
