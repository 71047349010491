import { Typography, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'

interface DidYouMeanProps {
    suggestedQuery?: string
    onSuggestionPress(...props: any): void
}

export const DidYouMean: FC<DidYouMeanProps> = (props: DidYouMeanProps) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()

    return (
        <>
            <Typography
                className={classes.searchQuery}
                variant="h4"
                fontWeight="normal"
                mb="1.125rem"
                alignSelf="flex-start"
            >
                Did you mean{' '}
                <span className={localClasses.wrappingCharacters}>"</span>
                <Link
                    sx={{
                        color: customDarkBlueColor,
                        textDecorationColor: customDarkBlueColor,
                        fontWeight: 500,
                    }}
                    onClick={() =>
                        props.onSuggestionPress(props.suggestedQuery)
                    }
                >
                    {props.suggestedQuery}
                </Link>
                <span className={localClasses.wrappingCharacters}>?"</span>
            </Typography>
        </>
    )
}

const useLocalStyles = makeStyles()({
    wrappingCharacters: {
        fontWeight: 500,
        color: customDarkBlueColor,
    },
})
