export const storeAsyncJSON = (
    key: string,
    json: object
): void | DOMException => {
    try {
        window.sessionStorage.removeItem(key)
        const jsonValue = JSON.stringify(json)
        window.sessionStorage.setItem(key, jsonValue)
    } catch (e) {
        return e as DOMException
    }
}

export const storeAsync = (key: string, value: string): void | DOMException => {
    try {
        window.sessionStorage.removeItem(key)
        window.sessionStorage.setItem(key, value)
    } catch (e) {
        return e as DOMException
    }
}
