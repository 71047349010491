import {
    CommunitySponsor,
    ContentBlockDTO,
    LanguageCode,
    PrepCheckOverviewDTO,
    PromotedProductDTO,
    SupportedContentBooster,
    SupportedHazardStage,
    UserFacingHazardStage,
    hazardRequestURL,
} from '@hazadapt-git/public-core-base'
import React, { FC, MouseEventHandler, useEffect } from 'react'
import { ElementContent } from 'react-markdown/lib/ast-to-react'

import { useStyles } from '../../lib/styles/universal'
import { getLangObjects } from '../../lib/utils'
import { EmptyContentPlaceholder } from '../molecules'
import EmptyHazardPage from '../../assets/images/EmptyHazardPage.svg'
import {
    ContentBlockView,
    IndvHazardHeader,
    IndvHazardHeaderProps,
    LanguageSelectorModal,
} from '../organisms'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import { Close } from '@mui/icons-material'
import {
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material'

interface IndvHazardPageTemplateProps extends IndvHazardHeaderProps {
    blocks: (ContentBlockDTO | PrepCheckOverviewDTO)[]
    selectedBlock?: number
    selectedLanguage: LanguageCode
    onBlockPress(id: number): void
    onBlockHover?(id: number): void
    onLinkPress(url: string, block_id: number, creator_id?: number): void
    onSponsorLinkPress(id: number, url: string): void
    languageSelectorModalOpen: boolean
    onLanguageSelectorModalClose(): void
    onLanguageChange(lang: LanguageCode): void
    onLocalContentToggle(): void
    locoEnabled: boolean
    languages: LanguageCode[]
    transformLinkUri?(
        href: string,
        children: ElementContent[],
        title: string | null
    ): string
    loading: boolean
    hasCommunitySponsors: boolean
    communitySponsors: CommunitySponsor[]
    communitySponsorsOpen: SupportedHazardStage | null
    onCommunitySponsorsOpen(): void
    communitySponsorBlockId?: number | null
    onSponsorAreaHover?(sponsor_id: number): void
    onSponsorAreaClick?(sponsor_id: number): void
    onSponsorSupplementalLinkPress?(id: number, url: string): void
    onSponsorHelpIconPress?: MouseEventHandler<HTMLDivElement>
    onCommunitySponsorsBlockHover?(): void
    hasPromotedProducts: boolean
    promotedProducts: PromotedProductDTO[]
    promotedProductsOpen: SupportedHazardStage | null
    onPromotedProductsOpen(): void
    promotedProductBlockId?: number | null
    onPromotedProductAreaHover?(product_id: number): void
    onPromotedProductAreaClick?(product_id: number): void
    onPromotedProductClick(product_id: number, url: string): void
    onPromotedProductHelpIconPress?: MouseEventHandler<HTMLDivElement>
    onPromotedProductBookmarkPress(product_id: number): void
    onPromotedProductsBlockHover?(): void
    onPromotedProductShare(product_id: number, url: string): void
    onLocalContentAdditionLinkClick(
        url: string,
        block_id: number,
        creator_id?: number
    ): void
    onLocalContentAdditionHover(block_id: number, creator_id?: number): void
    onPrepCheckBlockClick(id: number): void
    onPrepCheckShare(id: number): void

    // Promoted products login wall props
    showPromotedProductsLoginWall?: boolean
    togglePromotedProductsLoginWall(status: boolean): void
    onLogin: React.MouseEventHandler
}

export const IndvHazardPageTemplate: FC<IndvHazardPageTemplateProps> = (
    props: IndvHazardPageTemplateProps
) => {
    const { classes } = useStyles()
    const {
        blocks,
        selectedBlock,
        selectedLanguage,
        onBlockPress,
        onBlockHover,
        onLinkPress,
        onSponsorLinkPress,
        activeBoosters,
        languages,
        onLanguageChange,
        onLocalContentToggle,
        languageSelectorModalOpen,
        onLanguageSelectorModalClose,
        transformLinkUri,
        loading,
        hasCommunitySponsors,
        communitySponsors,
        communitySponsorsOpen,
        onCommunitySponsorsOpen,
        communitySponsorBlockId,
        hasPromotedProducts,
        promotedProducts,
        promotedProductsOpen,
        onPromotedProductsOpen,
        promotedProductBlockId,
        ...indvHazardHeaderProps
    } = props

    const [otherStages, setOtherStages] = React.useState('other')
    const { classes: localClasses } = useLocalStyles()

    useEffect(() => {
        if (props.activeStage) {
            let userStage: UserFacingHazardStage = 'React'
            if (props.activeStage === 'Before') {
                userStage = 'Prepare'
            } else if (props.activeStage === 'During') {
                userStage = 'React'
            } else if (props.activeStage === 'After') {
                userStage = 'Recover'
            }
            const stages: UserFacingHazardStage[] = [
                'Prepare',
                'React',
                'Recover',
            ]
            const index = stages.findIndex((stage) => stage === userStage)
            if (index !== -1) {
                stages.splice(index, 1)
            }
            let otherStagesText = stages.slice(0, -1).join(', ')
            if (stages.length > 2) {
                otherStagesText += ','
            }
            otherStagesText += ` and ${stages[stages.length - 1]}`
            setOtherStages(otherStagesText)
        }
    }, [props.blocks, props.activeStage])

    return (
        <div className={classNames(classes.page, localClasses.page)}>
            <IndvHazardHeader
                {...indvHazardHeaderProps}
                loading={loading}
                activeBoosters={activeBoosters}
                onLocalContentToggle={props.onLocalContentToggle}
                locoEnabled={props.locoEnabled}
                creator={props.creator}
            />

            {props.blocks.length > 0 || props.hasPromotedProducts || loading ? (
                <ContentBlockView
                    blocks={blocks}
                    selectedBlockId={selectedBlock}
                    language={selectedLanguage}
                    onBlockPress={onBlockPress}
                    onBlockHover={onBlockHover}
                    onLinkPress={onLinkPress}
                    onSponsorLinkPress={onSponsorLinkPress}
                    boosters={activeBoosters.map(
                        (b) => b as unknown as SupportedContentBooster
                    )}
                    transformLinkUri={transformLinkUri}
                    loading={loading}
                    hasCommunitySponsors={hasCommunitySponsors}
                    communitySponsors={communitySponsors}
                    communitySponsorsOpen={
                        communitySponsorsOpen === props.activeStage
                    }
                    onCommunitySponsorsOpen={onCommunitySponsorsOpen}
                    communitySponsorBlockId={communitySponsorBlockId}
                    onSponsorAreaHover={props.onSponsorAreaHover}
                    onSponsorAreaClick={props.onSponsorAreaClick}
                    onSponsorSupplementalLinkPress={
                        props.onSponsorSupplementalLinkPress
                    }
                    onSponsorHelpIconPress={props.onSponsorHelpIconPress}
                    hasPromotedProducts={hasPromotedProducts}
                    promotedProducts={promotedProducts}
                    promotedProductsOpen={
                        promotedProductsOpen === props.activeStage
                    }
                    onPromotedProductsOpen={onPromotedProductsOpen}
                    promotedProductBlockId={promotedProductBlockId}
                    onPromotedProductAreaHover={
                        props.onPromotedProductAreaHover
                    }
                    onPromotedProductAreaClick={
                        props.onPromotedProductAreaClick
                    }
                    onPromotedProductClick={props.onPromotedProductClick}
                    onPromotedProductHelpIconPress={
                        props.onPromotedProductHelpIconPress
                    }
                    onPromotedProductBookmarkPress={
                        props.onPromotedProductBookmarkPress
                    }
                    onLocalContentAdditionLinkClick={
                        props.onLocalContentAdditionLinkClick
                    }
                    onLocalContentAdditionHover={
                        props.onLocalContentAdditionHover
                    }
                    onPrepCheckBlockClick={props.onPrepCheckBlockClick}
                    onPrepCheckShare={props.onPrepCheckShare}
                    onCommunitySponsorsBlockHover={
                        props.onCommunitySponsorsBlockHover
                    }
                    onPromotedProductsBlockHover={
                        props.onPromotedProductsBlockHover
                    }
                    onPromotedProductShare={props.onPromotedProductShare}
                />
            ) : (
                <div style={{ padding: '2rem' }}>
                    <EmptyContentPlaceholder
                        imageProps={{
                            src: EmptyHazardPage,
                            alt: 'EmptyHazardPage',
                        }}
                        title="This page is blank!"
                        text={
                            props.creator
                                ? `The provider of this local information does not have information designated here. You can request more information [here](${hazardRequestURL}). In the meantime, check the ${otherStages} sections. You may find what you're looking for there!`
                                : `Our experts are hard at work to get this information to you soon. In the meantime, check the ${otherStages} sections. You may find what you're looking for there!`
                        }
                        textPlacement="top"
                    />
                </div>
            )}
            <LanguageSelectorModal
                languages={getLangObjects(languages)}
                selectedLanguage={selectedLanguage}
                open={languageSelectorModalOpen}
                onClose={onLanguageSelectorModalClose}
                onLanguagePress={onLanguageChange}
            />

            {/* Login wall */}
            <Dialog
                open={!!props.showPromotedProductsLoginWall}
                onClose={() => props.togglePromotedProductsLoginWall(false)}
                PaperProps={{ sx: { padding: '1rem' } }}
            >
                <DialogTitle sx={{ paddingRight: '2.5rem' }}>
                    <Typography variant="h3" component="p">
                        Log In to Continue
                    </Typography>
                    <IconButton
                        onClick={() =>
                            props.togglePromotedProductsLoginWall(false)
                        }
                        sx={{
                            position: 'absolute',
                            top: '0.5rem',
                            right: '0.5rem',
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You need to be logged in to bookmark Promoted Products.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                            props.togglePromotedProductsLoginWall(false)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onLogin}
                    >
                        Log In
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    page: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginBottom: '3rem',
    },
})
