import {
    BoosterConfig,
    FilterConfig,
    colors,
} from '@hazadapt-git/public-core-base'
import { Avatar, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'

interface TagProps {
    config: FilterConfig | BoosterConfig
    start?: boolean
    end?: boolean
    sx?: SxProps<Theme>
}

export const Tag: FC<TagProps> = (props: TagProps) => {
    const baseStyle: SxProps<Theme> = {
        backgroundColor: props.config.color,
        color: colors.grays.BLANC,
        borderWidth: 1,
        marginLeft: props.start ? 0 : '0.25rem',
        marginRight: props.end ? 0 : '0.25rem',
        border: `1px ${props.config.color} solid`,
        width: '2rem',
        height: '2rem',
    }
    return (
        <Avatar sx={{ ...baseStyle, ...props.sx }}>
            <img
                src={props.config.icon}
                width="60%"
                height="auto"
                alt={`${props.config.label} tag icon`}
            />
        </Avatar>
    )
}
