import { colors } from '@hazadapt-git/public-core-base'
import { Card, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

interface PrepCheckQuestionListItemProps {
    src: string
    text: string
}

export const PrepCheckQuestionListItem: FC<PrepCheckQuestionListItemProps> = (
    props: PrepCheckQuestionListItemProps
) => {
    const { classes } = useLocalStyles()

    return (
        <div>
            <Card
                variant="outlined"
                className={classes.card}
                sx={{
                    borderRadius: '50%',
                    borderColor: colors.grays.NOIR,
                    boxShadow: `0 0.188rem 0.125rem ${colors.grays.SILVER}`,
                }}
            >
                <img src={props.src} alt={`${props.text} icon`} />
            </Card>
            <div className={classes.textContainer}>
                <Typography className={classes.text}>{props.text}</Typography>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    card: {
        height: '6.5rem',
        width: '6.5rem',
        border: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '0.5rem',
    },
    text: {
        fontSize: '0.875rem',
        fontWeight: '500',
        alignItems: 'center',
    },
})
