import { colors, Image, JourneyPhaseDTO } from '@hazadapt-git/public-core-base'
import { Typography, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'
import { useWindowSizeUp } from '../../lib/utils'
import { Carousel } from './Carousel'
import { PrepCheckScoreGraph } from '../molecules'

interface PrepCheckDoneViewProps {
    badge?: Image
    prepCheckName: string
    badgeName: string
    score: number
    onViewResultsPress: React.MouseEventHandler
    onRetakePress: React.MouseEventHandler
    newBadge: boolean
    badgeAwardText: string
    newJourneyPhase?: JourneyPhaseDTO
    newJourneyPhaseAwardText?: string
}

export const PrepCheckDoneView: FC<PrepCheckDoneViewProps> = (
    props: PrepCheckDoneViewProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')

    const pages = [
        <div className={localClasses.carouselPage}>
            <Typography
                sx={{ color: colors.grays.NOIR }}
                textAlign="center"
                mb="2rem"
            >
                {props.badgeAwardText}
            </Typography>
            <PrepCheckScoreGraph score={props.score} bottomSpace />
            <div className={localClasses.badgeName}>
                <Typography variant="h4" sx={{ color: colors.grays.NOIR }}>
                    {props.badgeName}
                </Typography>
            </div>
        </div>,
    ]

    if (props.newJourneyPhase && props.newJourneyPhaseAwardText) {
        pages.push(
            <div className={localClasses.carouselPage}>
                <Typography
                    variant={props.newBadge ? 'h3' : 'body1'}
                    sx={{ color: colors.grays.NOIR }}
                    textAlign="center"
                >
                    {props.newJourneyPhaseAwardText}
                </Typography>
                <div className={localClasses.badge}>
                    <img
                        src={
                            props.newJourneyPhase.icon.url ||
                            props.newJourneyPhase.icon.src
                        }
                        alt={
                            props.newJourneyPhase.icon.alt ||
                            `${props.newJourneyPhase.name}`
                        }
                        className={localClasses.carouselImage}
                    />
                </div>
                <div className={localClasses.badgeName}>
                    <Typography variant="h4" sx={{ color: colors.grays.NOIR }}>
                        {props.newJourneyPhase.name}
                    </Typography>
                    <Typography sx={{ color: colors.grays.NOIR }}>
                        {props.newJourneyPhase.subcopy}
                    </Typography>
                </div>
            </div>
        )
    }

    return (
        <div className={localClasses.page}>
            <div className={localClasses.title}>
                <Typography variant="h2" component="h1">
                    🎉
                </Typography>
                <Typography
                    variant="h2"
                    component="h1"
                    sx={{ color: colors.primary.HELIOTROPE }}
                    fontWeight="500"
                    textAlign="center"
                    px={smallWindowOrLarger ? '2rem' : '1rem'}
                >
                    You completed the {props.prepCheckName}!
                </Typography>
                <Typography
                    variant="h2"
                    component="h1"
                    className={localClasses.mirroredEmoji}
                >
                    🎉
                </Typography>
            </div>
            <Carousel
                containerClassName={classNames(
                    localClasses.carousel,
                    pages.length > 1
                        ? localClasses.carouselWithPagination
                        : undefined
                )}
                pages={pages}
            />
            <div
                style={{
                    display: smallWindowOrLarger ? 'block' : 'flex',
                    flexDirection: smallWindowOrLarger ? 'row' : 'column',
                    justifyContent: smallWindowOrLarger
                        ? 'space-between'
                        : 'center',
                    alignItems: smallWindowOrLarger ? '' : 'center',
                }}
            >
                <div
                    className={localClasses.resultsButton}
                    style={{
                        marginRight: smallWindowOrLarger ? '1.75rem' : '0rem',
                        justifyContent: smallWindowOrLarger
                            ? 'flex-start'
                            : 'center',
                        padding: smallWindowOrLarger ? '0rem' : '1rem',
                    }}
                >
                    <Button
                        onClick={props.onViewResultsPress}
                        variant="contained"
                        color="prep-checks"
                    >
                        View Results
                    </Button>
                </div>
                <div
                    className={localClasses.homeButton}
                    style={{
                        marginLeft: smallWindowOrLarger ? '1.75rem' : '0rem',
                    }}
                >
                    <Button
                        onClick={props.onRetakePress}
                        variant="outlined"
                        color="prep-checks"
                        sx={{ color: colors.primary.HELIOTROPE }}
                    >
                        Retake
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem 0',
        flex: 1,
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 1rem',
    },
    carouselPage: {
        margin: '1rem auto 2rem',
        padding: '0 1rem',
        maxWidth: '30rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    badge: {
        margin: '1rem 0',
    },
    badgeName: {
        textAlign: 'center',
    },
    carouselImage: {
        height: '10rem',
        width: 'auto',
    },
    mirroredEmoji: {
        transform: 'scaleX(-1)',
    },
    resultsButton: {
        float: 'left',
        marginRight: '1.75rem',
    },
    homeButton: {
        float: 'right',
        marginLeft: '1.75rem',
    },
    carousel: {
        width: '100vw',
    },
    carouselWithPagination: {
        marginBottom: '2rem',
    },
})
