import { Skeleton, Stack, Tabs, tabsClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'
import { customDarkBlueColor } from '../../lib/styles/universal'

import { useWindowSizeUp } from '../../lib/utils'
import { ScrollingBlock, ScrollingBlockProps } from '../molecules'
import { colors } from '@hazadapt-git/public-core-base'

interface ScrollingBlockListProps {
    width: string | number
    blocks: ScrollingBlockProps[]
    loading?: boolean
}

export const ScrollingBlockList: FC<ScrollingBlockListProps> = (
    props: ScrollingBlockListProps
) => {
    const { classes } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')

    const renderBlocks = () => {
        if (!props.loading) {
            return props.blocks.map(
                (block: ScrollingBlockProps, index: number) => (
                    <ScrollingBlock
                        {...block}
                        key={`scrollingBlock_${block.name}`}
                        className={classNames(classes.block, block.className)}
                        style={{
                            ...block.style,
                            paddingLeft:
                                index === 0
                                    ? 0
                                    : smallWindowOrLarger
                                    ? '1rem'
                                    : '0.5rem',
                            paddingRight:
                                index === props.blocks.length - 1
                                    ? 0
                                    : smallWindowOrLarger
                                    ? '1rem'
                                    : '0.5rem',
                        }}
                        role="tab"
                    />
                )
            )
        } else {
            return Array.from(Array(5)).map((_, index: number) => (
                <Stack
                    key={`scrollingBlock_placeholder_${index + 1}`}
                    spacing={1}
                    paddingTop="1rem"
                    paddingLeft={
                        index === 0
                            ? 0
                            : smallWindowOrLarger
                            ? '1rem'
                            : '0.5rem'
                    }
                    paddingRight={
                        index === props.blocks.length - 1
                            ? 0
                            : smallWindowOrLarger
                            ? '1rem'
                            : '0.5rem'
                    }
                >
                    <Skeleton
                        variant="rectangular"
                        width="7rem"
                        height="7rem"
                    />
                    <Skeleton variant="text" height="3.5rem" />
                </Stack>
            ))
        }
    }

    return (
        <Tabs
            value={0}
            variant="scrollable"
            visibleScrollbar={!props.loading}
            scrollButtons
            TabIndicatorProps={{
                style: {
                    display: 'none',
                },
            }}
            allowScrollButtonsMobile
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: props.width,
                overflowX: props.loading ? 'hidden' : 'auto',
                [`& .${tabsClasses.scrollButtons}`]:
                    props.loading || props.blocks.length === 0
                        ? undefined
                        : {
                              backgroundColor: customDarkBlueColor,
                              opacity: 1,
                              '&.Mui-disabled': {
                                  backgroundColor: '#7C7C7C4D',
                              },
                          },
            }}
            TabScrollButtonProps={{
                style: {
                    marginBottom: '0.5rem',
                    marginLeft: '0.2rem',
                    marginRight: '0.2rem',
                    padding: '0.625rem',
                    color: colors.grays.BLANC,
                    borderRadius: 50,
                    boxShadow: '0px 2px 5px gray',
                    visibility: props.loading ? 'hidden' : 'visible',
                },
            }}
        >
            {renderBlocks()}
        </Tabs>
    )
}

const useLocalStyles = makeStyles()({
    block: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
    },
})
