import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import Breathing from '../assets/icons/Breathing.svg'
import CarAccident from '../assets/icons/CarAccident.svg'
import ChestPain from '../assets/icons/ChestPain.svg'
import DownWires from '../assets/icons/DownWires.svg'
import DroopingFace from '../assets/icons/DroopingFace.svg'
import Faint from '../assets/icons/Faint.svg'
import Fire from '../assets/icons/Fire.svg'
import GuideBook from '../assets/icons/GuideBook.svg'
import PrepCheckShield from '../assets/icons/PrepCheckShield.svg'
import ThreatenedLife from '../assets/icons/SkullBones.svg'
import SlurredSpeech from '../assets/icons/SlurredSpeech.svg'
import {
    PanelProps,
    WhenToCall911ItemProps,
    HomePageTemplate,
} from '../components'
import { LoggedPage, colors } from '@hazadapt-git/public-core-base'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import { customDarkBlueColor, prepCheckColor } from '../lib/styles/universal'
import { getCurrentLocation, logEvent } from '../lib/utils'
import { PageProps } from '../lib/entities'
import { getTrendingHazardsThunk } from '../lib/slices'
import { readAsync } from '../lib/async-storage'

interface HomePageProps extends PageProps {}

export const whenToCall911ItemBlurbs: { [key: string]: string } = {
    ThreatenedLife: "Someone's life is threatened or in danger",
    Fire: 'If you see smoke or fire',
    CarAccident: 'A car accident with an injury',
    Breathing: 'Difficulty breathing',
    ChestPain: "Chest pains that don't go away",
    Faint: 'Someone faints or collapses',
    SlurredSpeech: 'Slurred speech, confused, altered state',
    DroopingFace: 'Face is drooping, or unable to move body (paralysis)',
    DownWires: 'Wires down on the ground or street',
}

export const HomePage: FC<HomePageProps> = (props: HomePageProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { trending_hazards, trending_hazards_error, has_local_content } =
        useAppSelector((state: RootState) => state.hazards)

    const { language, profileReady } = useAppSelector(
        (state: RootState) => state.profile
    )

    const [locationAvailable, setLocationAvailable] = React.useState<boolean>()

    const [contentReady, setContentReady] = React.useState<boolean>(false)

    const [locoEnabled, setLocoEnabled] = React.useState<boolean>(false)

    React.useEffect(() => {
        document.title = 'HazAdapt'

        const loCoOn = readAsync('@loCoOn') === 'true'
        setLocoEnabled(loCoOn)

        getCurrentLocation()
            .then(() => setLocationAvailable(true))
            .catch(() => setLocationAvailable(false))
    }, [])

    React.useEffect(() => {
        if (!profileReady) return

        logEvent('OPEN_PAGE', {
            page: LoggedPage.HOME,
            language,
        })

        dispatch(getTrendingHazardsThunk(language))
            .then(() => {
                setContentReady(true)
            })
            .catch(console.error)
    }, [language, dispatch, profileReady])

    const whenToCall911Items: WhenToCall911ItemProps[] = [
        {
            image: {
                src: ThreatenedLife,
                alt: 'Skull and cross bones icon',
            },
            text: whenToCall911ItemBlurbs['ThreatenedLife'],
        },
        {
            image: {
                src: Fire,
                alt: 'Fire icon',
            },
            text: whenToCall911ItemBlurbs['Fire'],
        },
        {
            image: {
                src: CarAccident,
                alt: 'Car accident icon',
            },
            text: whenToCall911ItemBlurbs['CarAccident'],
        },
        {
            image: {
                src: Breathing,
                alt: 'Breathing icon',
            },
            text: whenToCall911ItemBlurbs['Breathing'],
        },
        {
            image: {
                src: ChestPain,
                alt: 'Chest pain icon',
            },
            text: whenToCall911ItemBlurbs['ChestPain'],
        },
        {
            image: {
                src: Faint,
                alt: 'Fainting icon',
            },
            text: whenToCall911ItemBlurbs['Faint'],
        },
        {
            image: {
                src: SlurredSpeech,
                alt: 'Slurred speech icon',
            },
            text: whenToCall911ItemBlurbs['SlurredSpeech'],
        },
        {
            image: {
                src: DroopingFace,
                alt: 'Drooping face icon',
            },
            text: whenToCall911ItemBlurbs['DroopingFace'],
        },
        {
            image: {
                src: DownWires,
                alt: 'Down wires icon',
            },
            text: whenToCall911ItemBlurbs['DownWires'],
        },
    ]

    const panels: PanelProps[] = [
        {
            backgroundColor: customDarkBlueColor,
            imageSrc: GuideBook,
            imageAlt: 'Hazard Guide book icon',
            title: 'Hazard Guide',
            subtitle:
                "Your guide for getting through life's different emergencies and disasters.",
            textColor: colors.grays.BLANC,
            onPress: () => navigate('/hazards'),
            flexDirection: 'row',
        },
        {
            backgroundColor: prepCheckColor,
            imageSrc: PrepCheckShield,
            imageAlt: 'Prep Check Shield icon',
            title: 'Prep Checks',
            subtitle:
                'Check your preparedness and strengthen your ability to cope.',
            textColor: colors.grays.BLANC,
            onPress: () => navigate('/prep-checks'),
            flexDirection: 'row',
        },
    ]

    const onTrendingHazardPress = (hazard_id: number) => {
        const hazard = trending_hazards.find((h) => h.id === hazard_id)
        if (!hazard) return
        navigate(`/hazards/${hazard.slug}`)
    }

    const goToLocalContent = () => {
        navigate('/hazards')
    }

    return (
        <HomePageTemplate
            trendingHazards={trending_hazards}
            trendingHazardsError={trending_hazards_error}
            panels={panels}
            whenToCall911Items={whenToCall911Items}
            loading={props.loading || !contentReady}
            onTrendingHazardPress={onTrendingHazardPress}
            hasLoco={has_local_content}
            locoEnabled={locoEnabled}
            locationAvailable={locationAvailable}
            onGoToLocalContent={goToLocalContent}
        />
    )
}
