import React, { FC } from 'react'
import { LoadingView } from '../molecules'
import {
    PrepCheckLandingBanner,
    PrepCheckDoneView,
    PrepCheckUpNextBanner,
    LanguageSelectorModal,
} from '../organisms'
import { useStyles } from '../../lib/styles/universal'
import {
    Image,
    JourneyPhaseDTO,
    LanguageCode,
    PrepCheckUpNextDTO,
    getLangObjects,
} from '@hazadapt-git/public-core-base'
import classNames from 'classnames'
import { makeStyles } from 'tss-react/mui'

interface PrepCheckDonePageTemplateProps {
    journeyPhaseIcon: Image
    journeyPhaseName: string
    journeyPhaseSubcopy: string
    prepChecksCompleted: number
    totalPrepChecks: number
    prepCheckName: string
    badge?: Image
    badgeName: string
    score: number
    upNext?: PrepCheckUpNextDTO
    onUpNextPress: React.MouseEventHandler
    onViewResultsPress: React.MouseEventHandler
    onRetakePress: React.MouseEventHandler
    onJourneyCompletionClick: React.MouseEventHandler
    onMyEmergencyItemsClick: React.MouseEventHandler
    newBadge: boolean
    badgeAwardText: string
    newJourneyPhase?: JourneyPhaseDTO
    newJourneyPhaseAwardText?: string
    loggedIn: boolean
    loading?: boolean
    onTranslateClick?: React.MouseEventHandler
    languages: LanguageCode[]
    selectedLanguage: LanguageCode
    languageSelectorModalOpen?: boolean
    onLanguageSelectorModalClose: React.MouseEventHandler
    onLanguageChange(lang: LanguageCode): void
}

export const PrepCheckDonePageTemplate: FC<PrepCheckDonePageTemplateProps> = (
    props: PrepCheckDonePageTemplateProps
) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    return (
        <div className={localClasses.page}>
            <PrepCheckLandingBanner
                journeyPhaseIcon={props.journeyPhaseIcon}
                journeyPhase={props.journeyPhaseName}
                journeyPhaseSubcopy={props.journeyPhaseSubcopy}
                prepChecksCompleted={props.prepChecksCompleted}
                totalPrepChecks={props.totalPrepChecks}
                onJourneyCompletionClick={props.onJourneyCompletionClick}
                onMyEmergencyItemsClick={props.onMyEmergencyItemsClick}
                showJourneyPhase={props.loggedIn}
                loading={props.loading}
                onTranslateClick={props.onTranslateClick}
            />
            <div
                className={classNames(
                    classes.page,
                    classes.pageWithNoTopPadding
                )}
                style={{
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                }}
            >
                {props.loading ? (
                    <LoadingView />
                ) : (
                    <>
                        <PrepCheckDoneView
                            badge={props.badge}
                            prepCheckName={props.prepCheckName}
                            badgeName={props.badgeName}
                            score={props.score}
                            onViewResultsPress={props.onViewResultsPress}
                            onRetakePress={props.onRetakePress}
                            newBadge={props.newBadge}
                            badgeAwardText={props.badgeAwardText}
                            newJourneyPhase={props.newJourneyPhase}
                            newJourneyPhaseAwardText={
                                props.newJourneyPhaseAwardText
                            }
                        />
                        {props.upNext && (
                            <PrepCheckUpNextBanner
                                icon={props.upNext.icon}
                                upNextName={props.upNext.name}
                                onUpNextPress={props.onUpNextPress}
                            />
                        )}
                    </>
                )}
            </div>

            {/* Language selector modal */}
            <LanguageSelectorModal
                languages={getLangObjects(props.languages)}
                selectedLanguage={props.selectedLanguage}
                open={!!props.languageSelectorModalOpen}
                onClose={props.onLanguageSelectorModalClose}
                onLanguagePress={props.onLanguageChange}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    page: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    loading: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 0',
    },
})
