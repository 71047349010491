import { colors, Image } from '@hazadapt-git/public-core-base'
import { Typography, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

interface PrepCheckUpNextBannerProps {
    icon: Image
    upNextName: string
    onUpNextPress: React.MouseEventHandler
}

export const PrepCheckUpNextBanner: FC<PrepCheckUpNextBannerProps> = (
    props: PrepCheckUpNextBannerProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div className={localClasses.banner}>
            <Typography variant="h3" sx={{ color: colors.grays.NOIR }}>
                Up Next:
            </Typography>
            <img
                src={props.icon.url || props.icon.src}
                alt={props.icon.alt || `${props.upNextName} icon`}
                className={localClasses.prepCheckIcon}
            />
            <div className={localClasses.textAndButton}>
                <Typography
                    variant="h4"
                    sx={{ color: colors.grays.NOIR, fontWeight: '400' }}
                >
                    {props.upNextName}
                </Typography>
                <Button
                    onClick={props.onUpNextPress}
                    variant="contained"
                    color="primary"
                    style={{ flex: 1 }}
                >
                    Tap to Start
                </Button>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    banner: {
        padding: '1rem 1.5rem',
        backgroundColor: colors.softTones.HELIOTROPE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    prepCheckIcon: {
        width: '3.29rem',
        height: 'auto',
        gap: '1rem',
        padding: '0.5rem',
    },
    textAndButton: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    prepCheckName: {
        color: colors.grays.NOIR,
        fontWeight: '400',
    },
})
