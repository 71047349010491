import axios from 'axios'

import { getEnvVars } from './config'

const { apiUrl, authAPIUrl } = getEnvVars()

export const isa = axios.create({
    baseURL: apiUrl,
    headers: {
        'Cache-Control': 'no-cache',
    },
    withCredentials: true,
})

export const authAPI = axios.create({
    baseURL: authAPIUrl,
    headers: {
        Accept: 'application/json',
    },
    withCredentials: true,
})
