import {
    colors,
    Image,
    PrepCheckComponentRating,
} from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { intlFormatDistance } from 'date-fns'
import React, { FC } from 'react'
import { transformLinkUri } from '../../lib/utils'
import { Markdown } from '../atoms'

interface PrepCheckResultDetailsProps {
    questionName: string
    componentRating: PrepCheckComponentRating
    questionIcon?: Image
    details: string
    dateLastUpdated: Date
    onLinkPress(url: string): any
}

export const PrepCheckResultDetails: FC<PrepCheckResultDetailsProps> = (
    props: PrepCheckResultDetailsProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const getComponentRatingColor = () => {
        let color: string
        switch (props.componentRating) {
            case PrepCheckComponentRating.LOW: {
                color = colors.primary.CORAL
                break
            }
            case PrepCheckComponentRating.MEDIUM: {
                color = colors.secondary.HEAT_WAVE
                break
            }
            case PrepCheckComponentRating.HIGH: {
                color = colors.primary.WAIKATO
                break
            }
            default: {
                color = colors.grays.NOIR
                break
            }
        }
        return color
    }

    return (
        <div className={localClasses.container}>
            <Typography variant="h4">
                {props.questionName}:{' '}
                <span style={{ color: getComponentRatingColor() }}>
                    {props.componentRating}
                </span>
            </Typography>
            <div className={localClasses.detailsWrapper}>
                <div className={localClasses.iconAndDateLastUpdated}>
                    {props.questionIcon && (
                        <img
                            src={
                                props.questionIcon.url || props.questionIcon.src
                            }
                            alt={
                                props.questionIcon.alt ||
                                `${props.questionName} icon`
                            }
                            className={localClasses.questionIcon}
                        />
                    )}
                    <Typography
                        fontWeight={300}
                        fontSize="0.875rem"
                        textAlign="center"
                    >
                        Updated{' '}
                        {intlFormatDistance(props.dateLastUpdated, new Date())}
                    </Typography>
                </div>
                <Markdown
                    content={props.details}
                    onLinkPress={props.onLinkPress}
                    transformLinkUri={transformLinkUri}
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        backgroundColor: colors.grays.WHITESMOKE,
        border: `1px ${colors.primary.HELIOTROPE} solid`,
        borderRadius: '1rem',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    detailsWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '1rem',
        sm: {
            gap: '3rem',
        },
    },
    iconAndDateLastUpdated: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
    },
    questionIcon: {
        width: '4.5rem',
        height: 'auto',
    },
})
