import {
    DndContext,
    DragStartEvent,
    DragEndEvent,
    DragCancelEvent,
    DragOverlay,
    MouseSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    PrepCheckAnswer,
    PrepCheckAnswerOptionDTO,
    PrepCheckDND1DAnswerItem,
    PrepCheckQuestionDTO,
    PrepCheckStatus,
    PrepCheckUserSubmissionDTO,
} from '@hazadapt-git/public-core-base'
import { Link, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoChevronForward } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import {
    PrepCheck1DAnswerPreview,
    PrepCheckAnswerCardProps,
    PrepCheckCardDropItemProps,
} from '../molecules'
import { PrepCheckAnswerCardGrid } from './PrepCheckAnswerCardGrid'
import { PrepCheckCardDropGrid } from './PrepCheckCardDropGrid'

interface PrepCheck1DQuestionViewProps {
    userAnswers: PrepCheckCardDropItemProps[]
    submission?: PrepCheckUserSubmissionDTO
    updateSubmission(submission: PrepCheckUserSubmissionDTO): void
    question: PrepCheckQuestionDTO
    activeQuestionIdx: number
    prepCheckId: number
    onNextQuestionClick: React.MouseEventHandler
    showNextQuestionLink: boolean
}

export const PrepCheck1DQuestionView: FC<PrepCheck1DQuestionViewProps> = (
    props: PrepCheck1DQuestionViewProps
) => {
    // Drag-and-drop sensors
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 5,
        },
    })
    const sensors = useSensors(mouseSensor)

    const largeWindowOrLarger = useWindowSizeUp('lg')
    const { classes: localClasses } = useLocalStyles()

    const [selectedAnswerCardId, setSelectedAnswerCardId] =
        React.useState<number>()
    const [draggingElem, setDraggingElem] =
        React.useState<PrepCheckAnswerOptionDTO>()

    const nextAnswerOptionId = props.userAnswers.filter(
        (ua) => ua.answerOption !== undefined
    ).length

    const sortSubmissionAnswers = (
        submission: PrepCheckUserSubmissionDTO
    ): PrepCheckUserSubmissionDTO => {
        ;(
            submission.submitted_responses[props.activeQuestionIdx]
                .user_answer as PrepCheckAnswer<PrepCheckDND1DAnswerItem>[]
        ).sort((a, b) => a.answer.order - b.answer.order)

        return submission
    }

    const onAnswerItemClick = (answer_id: number): void => {
        const answerIsPlaced = props.userAnswers.findIndex(
            (ua) => ua.answerOption?.id === answer_id
        )
        if (answerIsPlaced < 0) {
            let dropSpotId: number = 0
            if (props.submission) {
                const submitted_response =
                    props.submission.submitted_responses.find(
                        (sr) => sr.question_id === props.question.id
                    )
                if (submitted_response) {
                    dropSpotId = submitted_response.user_answer.length
                }
            }
            onDropSpotClick(dropSpotId, answer_id)
        } else {
            if (!selectedAnswerCardId) {
                setSelectedAnswerCardId(answer_id)
            } else {
                // The user is swapping places of two blocks
                const spotToSwap = props.userAnswers.findIndex(
                    (ua) => ua.answerOption?.id === selectedAnswerCardId
                )
                if (spotToSwap < 0) return

                let draft_submission: PrepCheckUserSubmissionDTO
                if (props.submission) {
                    draft_submission = { ...props.submission }
                } else {
                    draft_submission = {
                        prep_check_id: props.prepCheckId,
                        status: PrepCheckStatus.DRAFT,
                        submitted_responses: [],
                    }
                }

                // Get the answer data for the two items to be swapped
                const answer1Index = draft_submission.submitted_responses[
                    props.activeQuestionIdx
                ].user_answer.findIndex(
                    (ua) =>
                        ua.answer_id ===
                        props.userAnswers[answerIsPlaced].answerOption?.id
                )
                const answer2Index = draft_submission.submitted_responses[
                    props.activeQuestionIdx
                ].user_answer.findIndex(
                    (ua) =>
                        ua.answer_id ===
                        props.userAnswers[spotToSwap].answerOption?.id
                )

                if (answer1Index < 0 || answer2Index < 0) return

                const currAnswer1 = {
                    ...draft_submission.submitted_responses[
                        props.activeQuestionIdx
                    ].user_answer[answer1Index],
                }
                const currAnswer2 = {
                    ...draft_submission.submitted_responses[
                        props.activeQuestionIdx
                    ].user_answer[answer2Index],
                }
                if (
                    !('order' in currAnswer2.answer) ||
                    !('order' in currAnswer1.answer)
                )
                    return
                if (currAnswer2.answer.order > currAnswer1.answer.order) {
                    for (
                        let i = currAnswer2.answer.order - 1;
                        i >= currAnswer1.answer.order;
                        i--
                    ) {
                        const answerToMove =
                            draft_submission.submitted_responses[
                                props.activeQuestionIdx
                            ].user_answer.findIndex(
                                (ua) =>
                                    'order' in ua.answer &&
                                    ua.answer.order === i
                            )

                        draft_submission.submitted_responses[
                            props.activeQuestionIdx
                        ].user_answer[answerToMove].answer = {
                            id: draft_submission.submitted_responses[
                                props.activeQuestionIdx
                            ].user_answer[answerIsPlaced].answer_id,
                            order: i + 1,
                        }
                    }
                } else {
                    for (
                        let i = currAnswer2.answer.order + 1;
                        i <= currAnswer1.answer.order;
                        i++
                    ) {
                        const answerToMove =
                            draft_submission.submitted_responses[
                                props.activeQuestionIdx
                            ].user_answer.findIndex(
                                (ua) =>
                                    'order' in ua.answer &&
                                    ua.answer.order === i
                            )

                        draft_submission.submitted_responses[
                            props.activeQuestionIdx
                        ].user_answer[answerToMove].answer = {
                            id: draft_submission.submitted_responses[
                                props.activeQuestionIdx
                            ].user_answer[answerIsPlaced].answer_id,
                            order: i - 1,
                        }
                    }
                }
                draft_submission.submitted_responses[
                    props.activeQuestionIdx
                ].user_answer[answer2Index].answer = {
                    id: draft_submission.submitted_responses[
                        props.activeQuestionIdx
                    ].user_answer[answer2Index].answer_id,
                    order: currAnswer1.answer.order,
                }

                props.updateSubmission(draft_submission)

                // Reset selected answer card ID
                setSelectedAnswerCardId(undefined)
            }
        }
    }

    const onDropSpotClick = (id: number, answer_id?: number): void => {
        if (id >= props.userAnswers.length || id < 0) return

        const selected_answer = props.question.options.find(
            (o) => o.id === (answer_id || selectedAnswerCardId)
        )
        if (!selected_answer) return

        let draft_submission: PrepCheckUserSubmissionDTO
        if (props.submission) {
            draft_submission = { ...props.submission }
        } else {
            draft_submission = {
                prep_check_id: props.prepCheckId,
                status: PrepCheckStatus.DRAFT,
                submitted_responses: [],
            }
        }

        const idx = draft_submission.submitted_responses.findIndex(
            (sr) => sr.question_id === props.question.id
        )

        if (idx >= 0) {
            // If the user has placed answers for this question already, add to the existing config
            // Check if the spot already has an answer in it
            const spotFilledIdx = draft_submission.submitted_responses[
                idx
            ].user_answer.findIndex(
                (ua) => 'order' in ua.answer && ua.answer.order === id + 1
            )

            if (spotFilledIdx >= 0) {
                // If it does, replace it
                draft_submission.submitted_responses[idx].user_answer[
                    spotFilledIdx
                ] = {
                    answer_id: selected_answer.id,
                    answer: {
                        id: selected_answer.id,
                        order: id + 1,
                    },
                }
            } else {
                // If it doesn't, fill it
                const previousSpot = draft_submission.submitted_responses[
                    idx
                ].user_answer.findIndex(
                    (ua) => ua.answer_id === selected_answer.id
                )
                if (previousSpot >= 0) {
                    draft_submission.submitted_responses[
                        idx
                    ].user_answer.splice(previousSpot, 1)
                }
                draft_submission.submitted_responses[idx].user_answer.push({
                    answer_id: selected_answer.id,
                    answer: {
                        id: selected_answer.id,
                        order: id + 1,
                    },
                })
            }
        } else {
            // User has not answered this quetsion yet; build new config with answer
            draft_submission.submitted_responses.push({
                question_id: props.question.id,
                user_answer: [
                    {
                        answer_id: selected_answer.id,
                        answer: {
                            id: selected_answer.id,
                            order: id + 1,
                        },
                    },
                ],
            })
        }
        props.updateSubmission(draft_submission)
        setSelectedAnswerCardId(undefined)
    }

    const onDropSpotClear = (id: number): void => {
        if (id >= props.userAnswers.length || id < 0) return

        if (props.question.question_type === 'Checkboxes') return

        let draft_submission: PrepCheckUserSubmissionDTO
        if (props.submission) {
            draft_submission = sortSubmissionAnswers({ ...props.submission })
        } else {
            draft_submission = {
                prep_check_id: props.prepCheckId,
                status: PrepCheckStatus.DRAFT,
                submitted_responses: [],
            }
        }

        const idx = draft_submission.submitted_responses.findIndex(
            (sr) => sr.question_id === props.question.id
        )

        if (idx < 0) return

        // Find the answer item that was chosen to be removed
        const answerItem = props.userAnswers.find((ua) => ua.index === id)
        if (!answerItem || !answerItem.answerOption) return

        // Check if the answer is in the submission data
        const itemInSubmission = draft_submission.submitted_responses[
            idx
        ].user_answer.findIndex(
            (ua) => ua.answer_id === answerItem.answerOption?.id
        )

        // If it is, remove it from the submission data
        if (itemInSubmission >= 0) {
            // Slide everything after it over by 1
            for (
                let i = itemInSubmission;
                i <
                draft_submission.submitted_responses[idx].user_answer.length;
                i++
            ) {
                const answer = {
                    ...draft_submission.submitted_responses[idx].user_answer[i]
                        .answer,
                }
                if ('order' in answer) {
                    answer.order--
                }

                draft_submission.submitted_responses[idx].user_answer[
                    i
                ].answer = { ...answer }
            }
            draft_submission.submitted_responses[idx].user_answer.splice(
                itemInSubmission,
                1
            )
        }

        props.updateSubmission(draft_submission)
    }

    const handleDragStart = (e: DragStartEvent) => {
        const answer_id = parseInt(e.active.id.toString().split('_')[1])
        const elem = props.question.options.find((o) => o.id === answer_id)
        if (!elem) return
        setDraggingElem(elem)
        if (props.userAnswers.some((ua) => ua.answerOption?.id === elem.id)) {
            // Dragged item is a placed item; run onAnswerItemClick
            onAnswerItemClick(answer_id)
        }
    }

    const handleDragEnd = (e: DragEndEvent) => {
        setDraggingElem(undefined)
        if (!e.over) return
        const dropIndex = parseInt(e.over.id.toString().split('_')[1])
        const spotsWithPlacedAnswers = props.userAnswers.filter(
            (ua) => !!ua.answerOption
        )
        if (dropIndex >= spotsWithPlacedAnswers.length) {
            // User is trying to place an answer in a disallowed spot
            setSelectedAnswerCardId(undefined)
            return
        }
        const item = spotsWithPlacedAnswers.find((ua) => ua.index === dropIndex)
        if (item) {
            if (item.answerOption) {
                onAnswerItemClick(item.answerOption.id)
            } else {
                onDropSpotClick(dropIndex)
            }
        }
    }

    const handleDragCancel = (e: DragCancelEvent) => {
        setDraggingElem(undefined)
        setSelectedAnswerCardId(undefined)
    }

    const pickable_options = props.question.options.filter(
        (o) => !props.userAnswers.some((ua) => ua.answerOption?.id === o.id)
    )

    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            sensors={sensors}
        >
            <div>
                <Typography variant="h4" mb="1rem">
                    Ranking
                </Typography>
                <PrepCheckCardDropGrid
                    data={props.userAnswers}
                    gridType="OneD_Ordered_List"
                    onDropSpotClick={onDropSpotClick}
                    onFilledSpotClick={onAnswerItemClick}
                    onClearSpot={onDropSpotClear}
                    selectedId={selectedAnswerCardId}
                    draggedItem={draggingElem?.id}
                />
                <Typography variant="h4" mb="1rem">
                    Options
                </Typography>
                {pickable_options.length > 0 ? (
                    <PrepCheckAnswerCardGrid
                        data={pickable_options.map(
                            (o) =>
                                ({
                                    id: o.id,
                                    answerText: o.text,
                                    questionType: 'OneD_Ordered_List',
                                    answerInfo: o.explainer_text,
                                    popoverPlacement: 'left',
                                    onClick: onAnswerItemClick,
                                    image: o.image?.url || o.image?.src,
                                    selected: nextAnswerOptionId === o.id,
                                } as PrepCheckAnswerCardProps)
                        )}
                        gridType="OneD_Ordered_List"
                        selectedItemId={selectedAnswerCardId}
                    />
                ) : (
                    <div
                        className={localClasses.allItemsPlaced}
                        style={{
                            textAlign: largeWindowOrLarger ? 'left' : 'center',
                        }}
                    >
                        <Typography
                            fontWeight={500}
                            textAlign="center"
                            pb="0.5rem"
                        >
                            You have placed all of the items!
                        </Typography>
                        {props.showNextQuestionLink && (
                            <Link
                                fontWeight={500}
                                onClick={props.onNextQuestionClick}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                Go to Next Question
                                <IoChevronForward />
                            </Link>
                        )}
                    </div>
                )}
            </div>
            <DragOverlay>
                {draggingElem ? (
                    <PrepCheck1DAnswerPreview
                        id={draggingElem.id}
                        image={draggingElem.image}
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    )
}

const useLocalStyles = makeStyles()({
    allItemsPlaced: {
        margin: '1rem auto 2rem',
        padding: '1rem',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
})
