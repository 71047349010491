import { Grid, Theme, Divider } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { CSSProperties, FC } from 'react'

import { CSOItem, CSOItemProps } from '../molecules'

interface CSOItemGridProps {
    items: CSOItemProps[]
    className?: string
    sx?: SxProps<Theme>
    style?: CSSProperties
    loading?: boolean
    onCsoOpen(id: number): void
    onWhatTheyHelpWithClick(id: number): void
    onWhoTheyHelpClick(id: number): void
    onAboutThisServiceClick(id: number): void
    onFindNearMeClick(id: number, url: string): void
    onCsoViewWebsiteClick(id: number, url: string): void
    onCsoHover(id: number): void
}

export const CSOItemGrid: FC<CSOItemGridProps> = (props: CSOItemGridProps) => {
    const items: CSOItemProps[] = props.loading ? [{}, {}, {}, {}] : props.items
    return (
        <Grid
            container
            spacing={0}
            className={props.className}
            sx={props.sx}
            style={props.style}
        >
            {items.map((item: CSOItemProps, index: number) => {
                return (
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        xl={12}
                        key={item.config?.title ?? `csoItem_${index + 1}`}
                    >
                        {index > 0 && (
                            <Divider
                                sx={{
                                    m: 0,
                                    mt: '1rem',
                                    mb: '2rem',
                                    width: '100%',
                                }}
                            />
                        )}
                        <CSOItem
                            {...item}
                            loading={props.loading}
                            onClick={props.onCsoOpen}
                            onHover={props.onCsoHover}
                            onWhatTheyHelpWithClick={
                                props.onWhatTheyHelpWithClick
                            }
                            onWhoTheyHelpClick={props.onWhoTheyHelpClick}
                            onAboutThisServiceClick={
                                props.onAboutThisServiceClick
                            }
                            onFindNearMeClick={props.onFindNearMeClick}
                            onViewWebsiteClick={props.onCsoViewWebsiteClick}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}
