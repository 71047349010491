import { Grid } from '@mui/material'
import React, { FC } from 'react'

import { useWindowSizeExact, useWindowSizeUp } from '../../lib/utils'
import { ContentBlockPreview, FAQBlockProps } from '../molecules'

export interface FAQGridProps {
    items: FAQBlockProps[]
    selectedID?: number
    onItemPress(id: number): void
}

export const FAQGrid: FC<FAQGridProps> = (props: FAQGridProps) => {
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const smallWindow = useWindowSizeExact('sm')
    const aspectRatio = mediumWindowOrLarger ? 1 : smallWindow ? 4 : 2
    return (
        <Grid container spacing={2}>
            {props.items.map((item: FAQBlockProps) => (
                <Grid key={`faqItem_${item.id}`} item xs={12} md={6} xl={4}>
                    <ContentBlockPreview
                        id={item.id}
                        onPress={props.onItemPress}
                        type="content"
                        title={item.question}
                        selected={item.id === props.selectedID}
                        aspectRatio={aspectRatio}
                        textAlign={aspectRatio > 1 ? 'left' : 'center'}
                        isFAQ
                    />
                </Grid>
            ))}
        </Grid>
    )
}
