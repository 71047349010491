import {
    ErrorMessage,
    LanguageCode,
    PrepCheckDTO,
    PrepCheckOverviewDTO,
    PrepCheckUserStats,
} from '@hazadapt-git/public-core-base'
import {
    ActionReducerMapBuilder,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit'
import {
    getPrepCheck,
    getPrepCheckOverviews,
    getPrepCheckUserStats,
} from '../utils/prep'

export interface PrepStoreState {
    prep_checks: PrepCheckOverviewDTO[]
    active_prep_check?: PrepCheckDTO | null
    stats: PrepCheckUserStats
    error?: string | null
}

export interface GetPrepCheckDTO {
    id: number
    language: LanguageCode
}

export const INITIAL_PREP_STORE_STATE: PrepStoreState = {
    prep_checks: [],
    stats: {
        journeyPhase: '',
        journeyPhaseIcon: {
            src: '<svg></svg>',
            url: '',
            alt: '',
        },
        journeyPhaseSubcopy: '',
        numCompleted: 0,
        numTotal: 0,
    },
}

export const getAllPrepChecksThunk = createAsyncThunk<
    PrepCheckOverviewDTO[],
    LanguageCode,
    { rejectValue: ErrorMessage | string }
>(
    'prep-checks/get-all',
    async (language: LanguageCode, { rejectWithValue }) => {
        try {
            return await getPrepCheckOverviews(language)
        } catch (err: any) {
            console.error(err)
            return rejectWithValue('Unable to get prep checks')
        }
    }
)

export const getPrepCheckThunk = createAsyncThunk<
    PrepCheckDTO,
    GetPrepCheckDTO,
    { rejectValue: ErrorMessage | string }
>('prep-checks/get-one', async ({ id, language }, { rejectWithValue }) => {
    try {
        return await getPrepCheck(id, language)
    } catch (err: any) {
        console.error(err)
        return rejectWithValue('Unable to get prep check')
    }
})

export const getPrepCheckUserStatsThunk = createAsyncThunk<
    PrepCheckUserStats,
    LanguageCode,
    { rejectValue: ErrorMessage | string }
>('prep-check-stats/get', async (lang, { rejectWithValue }) => {
    try {
        return await getPrepCheckUserStats(lang)
    } catch (err: any) {
        console.error(err)
        return rejectWithValue('Unable to get prep check user stats')
    }
})

const prepSlice = createSlice({
    name: 'prep',
    initialState: INITIAL_PREP_STORE_STATE,
    reducers: {
        reset_active_prep_check: (state, action) => {
            state.active_prep_check = null
            state.error = null
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<PrepStoreState>) => {
        builder.addCase(getAllPrepChecksThunk.fulfilled, (state, action) => {
            state.prep_checks = action.payload
            state.error = null
        })

        builder.addCase(getAllPrepChecksThunk.rejected, (state, action) => {
            state.error = action.payload
        })

        builder.addCase(getPrepCheckThunk.fulfilled, (state, action) => {
            state.active_prep_check = action.payload
            state.error = null
        })

        builder.addCase(getPrepCheckThunk.rejected, (state, action) => {
            state.active_prep_check = null
            state.error = action.payload
        })

        builder.addCase(
            getPrepCheckUserStatsThunk.fulfilled,
            (state, action) => {
                state.stats = action.payload
                state.error = null
            }
        )

        builder.addCase(
            getPrepCheckUserStatsThunk.rejected,
            (state, action) => {
                state.error = action.payload
            }
        )
    },
})

export default prepSlice.reducer
