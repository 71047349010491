import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { AspectRatio } from 'react-aspect-ratio'

import { useWindowSizeUp } from '../../lib/utils'
import { colors } from '@hazadapt-git/public-core-base'

export interface WhenToCall911ItemProps {
    image: React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >
    text: string
}

export const WhenToCall911Item: FC<WhenToCall911ItemProps> = (
    props: WhenToCall911ItemProps
) => {
    const { classes } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')
    return (
        <div className={classes.container}>
            {props.image.src && (
                <AspectRatio
                    ratio={1}
                    style={{
                        marginBottom: '1rem',
                        width: smallWindowOrLarger ? '4rem' : '3rem',
                    }}
                >
                    <img
                        src={props.image.src}
                        width="100%"
                        height="100%"
                        alt={props.image.alt}
                    />
                </AspectRatio>
            )}
            <Typography
                textAlign="center"
                color={colors.grays.BLANC}
                paddingLeft="0.5rem"
                paddingRight="0.5rem"
                fontWeight={500}
            >
                {props.text}
            </Typography>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '4rem',
        marginBottom: '1rem',
    },
})
