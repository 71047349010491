import { TrendingHazardDTO } from '@hazadapt-git/public-core-base'
import { Grid, Typography } from '@mui/material'
import React, { FC } from 'react'

import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import {
    LocalContentTeaser,
    PanelProps,
    WhenToCall911ItemProps,
} from '../molecules'
import {
    HomePageFooter,
    PanelGrid,
    TrendingHazardItemList,
    WhenToCall911View,
} from '../organisms'

interface HomePageTemplateProps {
    trendingHazards: TrendingHazardDTO[]
    trendingHazardsError: boolean
    panels: PanelProps[]
    whenToCall911Items: WhenToCall911ItemProps[]
    loading: boolean
    onTrendingHazardPress(hazard_id: number): void
    hasLoco: boolean
    locoEnabled: boolean
    locationAvailable: boolean | undefined
    onGoToLocalContent(): void
}

export const HomePageTemplate: FC<HomePageTemplateProps> = (
    props: HomePageTemplateProps
) => {
    const { classes } = useStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const largeWindowOrLarger = useWindowSizeUp('lg')

    return (
        <div className={classes.page}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'flex-start',
                    paddingBottom: mediumWindowOrLarger ? '2rem' : '0rem',
                }}
            >
                {mediumWindowOrLarger && (
                    <>
                        <Typography
                            variant={largeWindowOrLarger ? 'h1' : 'h2'}
                            component="h1"
                            textAlign="left"
                            color={customDarkBlueColor}
                            paddingLeft="1rem"
                        >
                            Welcome
                        </Typography>
                        <Typography
                            textAlign="left"
                            color={customDarkBlueColor}
                            paddingLeft="1rem"
                        >
                            Life comes with hazards. We'll help you adapt.
                        </Typography>
                    </>
                )}
            </div>
            <div
                style={{
                    paddingBottom: '1rem',
                    width: '100%',
                    alignSelf: 'flex-start',
                }}
            >
                {props.locationAvailable === undefined ||
                !props.locoEnabled ||
                props.loading ? (
                    <TrendingHazardItemList
                        data={props.trendingHazards}
                        showErrorText={props.trendingHazardsError}
                        onPress={props.onTrendingHazardPress}
                        loading={props.loading}
                    />
                ) : (
                    <Grid container spacing={2} pr="2rem">
                        <Grid item lg={8} md={12} sx={{ width: '100%' }}>
                            <TrendingHazardItemList
                                data={props.trendingHazards}
                                showErrorText={props.trendingHazardsError}
                                onPress={props.onTrendingHazardPress}
                                loading={props.loading}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0 2rem 0 2rem',
                            }}
                        >
                            <LocalContentTeaser
                                hasLoco={props.hasLoco}
                                locoEnabled={props.locoEnabled}
                                locationAvailable={props.locationAvailable}
                                onGoToLocalContent={props.onGoToLocalContent}
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
            <div
                style={{
                    width: `calc(100% - 2rem)`,
                    margin: '0 auto',
                    paddingTop: '1rem',
                }}
            >
                <PanelGrid
                    id="home-panel-grid"
                    panels={props.panels}
                    rounded={mediumWindowOrLarger}
                />
            </div>
            <div style={{ padding: '1rem 1rem 3rem' }}>
                <WhenToCall911View data={props.whenToCall911Items} />
            </div>
            <HomePageFooter />
        </div>
    )
}
