import {
    BoosterConfig,
    colors,
    ContentBlockType,
    OrganizationOverview,
} from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { CSSProperties } from 'react'
import { AspectRatio } from 'react-aspect-ratio'

import CommunitySponsorIcon from '../../assets/icons/CommunitySponsor.svg'
import PromotedProductsIcon from '../../assets/icons/PromotedProducts.svg'
import Source from '../../assets/icons/Source.svg'
import {
    customBorderRadius,
    customDarkBlueColor,
    altHazRedColor,
    localContentColor,
} from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import { Card, Tag } from '../atoms'
import { IoLocationSharp } from 'react-icons/io5'

export interface ContentBlockPreviewProps {
    id: number
    icon?: string
    iconAlt?: string
    title: string
    type: ContentBlockType
    onPress(id: number): void
    onHover?(id: number): void
    selected?: boolean
    critical?: boolean
    iconLocation?: 'top' | 'bottom'
    boosterChips?: BoosterConfig[]
    style?: CSSProperties
    aspectRatio?: number
    textAlign?: 'center' | 'left' | 'right'
    isFAQ?: boolean
    creator?: OrganizationOverview
    local?: boolean
}

export const ContentBlockPreview = React.forwardRef<
    HTMLDivElement,
    ContentBlockPreviewProps
>(
    (
        props: ContentBlockPreviewProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const smallWindowOrLarger = useWindowSizeUp('sm')
        const useLocalStyles = makeStyles()({
            contentBlock: {
                borderRadius: '1.5rem',
                cursor: 'pointer',
                position: 'relative',
            },
            contentBlockIcon: {
                height: smallWindowOrLarger ? '5rem' : '6rem',
                width: 'auto',
                maxHeight: smallWindowOrLarger ? '50%' : '60%',
                paddingBottom: smallWindowOrLarger ? '1rem' : 0,
                paddingRight: smallWindowOrLarger ? 0 : '1rem',
            },
            localPin: {
                marginRight: '0.5rem',
            },
        })

        const { classes } = useLocalStyles()
        const aspectRatio =
            props.aspectRatio || (smallWindowOrLarger ? 0.75 : 2)
        return (
            <AspectRatio ratio={aspectRatio} style={{ width: '100%' }}>
                <Card
                    ref={ref}
                    colors={[
                        props.selected
                            ? colors.grays.BLANC
                            : colors.grays.WHITESMOKE,
                    ]}
                    className={classes.contentBlock}
                    innerContainerStyle={{
                        borderRadius: customBorderRadius,
                        border: `0.25rem ${
                            props.selected
                                ? props.critical
                                    ? altHazRedColor
                                    : props.local
                                    ? localContentColor
                                    : customDarkBlueColor
                                : colors.grays.WHITESMOKE
                        } solid`,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: props.isFAQ
                            ? 'row'
                            : smallWindowOrLarger
                            ? props.iconLocation === 'bottom'
                                ? 'column-reverse'
                                : 'column'
                            : 'row',
                        alignItems: 'center',
                        justifyContent:
                            props.textAlign === 'center'
                                ? 'center'
                                : props.textAlign === 'left'
                                ? 'flex-start'
                                : props.textAlign === 'right'
                                ? 'flex-end'
                                : smallWindowOrLarger
                                ? 'center'
                                : 'flex-start',
                        height: '100%',
                        position: 'relative',
                        padding: props.critical
                            ? '1.5rem 1rem'
                            : props.creator || !smallWindowOrLarger
                            ? '3rem 1rem'
                            : '2rem 1rem',
                        ...props.style,
                    }}
                    onClick={() => props.onPress(props.id)}
                    onMouseEnter={() => props.onHover?.(props.id)}
                >
                    {(props.local || props.boosterChips) &&
                        props.type === 'content' && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0.35rem',
                                    left: '0.35rem',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                }}
                            >
                                {props.local && (
                                    <IoLocationSharp
                                        size="2rem"
                                        color={localContentColor}
                                    />
                                )}
                                {props.boosterChips?.map(
                                    (chip: BoosterConfig, index: number) =>
                                        props.boosterChips && chip ? (
                                            <Tag
                                                key={`cb_${props.id}_${chip.key}_mini_chip`}
                                                config={chip}
                                                start={index === 0}
                                                end={
                                                    index ===
                                                    props.boosterChips.length -
                                                        1
                                                }
                                            />
                                        ) : null
                                )}
                            </div>
                        )}
                    {props.icon &&
                        (props.type === 'source' ||
                            (props.type === 'content' &&
                                props.icon !== '<svg></svg>') ||
                            props.type === 'community-sponsors' ||
                            props.type === 'promoted-products') && (
                            <img
                                src={
                                    props.type === 'source'
                                        ? Source
                                        : props.type === 'community-sponsors'
                                        ? CommunitySponsorIcon
                                        : props.type === 'promoted-products'
                                        ? PromotedProductsIcon
                                        : `data:image/svg+xml;base64,${btoa(
                                              props.icon
                                          )}`
                                }
                                className={classes.contentBlockIcon}
                                alt={
                                    props.iconAlt ||
                                    `${
                                        props.type === 'source'
                                            ? 'Sources icon'
                                            : props.type ===
                                              'community-sponsors'
                                            ? 'Community sponsors icon'
                                            : `Icon for ${props.title}`
                                    }`
                                }
                            />
                        )}
                    <Typography
                        component="p"
                        variant="h4"
                        textAlign={
                            props.textAlign ||
                            (smallWindowOrLarger ? 'center' : 'left')
                        }
                        fontStyle="normal"
                        color={
                            props.critical ? altHazRedColor : colors.grays.NOIR
                        }
                        sx={{
                            overflowY: 'auto',
                            wordBreak: 'break-word',
                        }}
                    >
                        {props.title}
                    </Typography>
                    {props.local && props.creator?.logo && (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '0.25rem',
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            {props.creator.logo ? (
                                <img
                                    style={{
                                        height: '3rem',
                                        width: '90%',
                                        objectFit: 'contain',
                                    }}
                                    src={
                                        props.creator.logo.url ??
                                        props.creator.logo.src
                                    }
                                    alt={props.creator.logo.alt}
                                />
                            ) : (
                                <Typography fontWeight={500} textAlign="center">
                                    {props.creator.name}
                                </Typography>
                            )}
                        </div>
                    )}
                </Card>
            </AspectRatio>
        )
    }
)
