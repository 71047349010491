import { colors } from '@hazadapt-git/public-core-base'
import { Help } from '@mui/icons-material'
import { IconButton, Popover } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, {
    FC,
    useState,
    MouseEvent,
    CSSProperties,
    MouseEventHandler,
} from 'react'

import { theme, useStyles } from '../../lib/styles/universal'
import { Markdown } from './Markdown'

interface HelpIconProps {
    helpText: string
    popoverPlacement?: 'left' | 'right' | 'center'
    popoverPaperStyle?: CSSProperties
    popoverTextStyle?: CSSProperties
    onClick?: MouseEventHandler<HTMLDivElement>
}

export const HelpIcon: FC<HelpIconProps> = (props: HelpIconProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => setAnchorEl(null)
    const open = Boolean(anchorEl)
    const { classes } = useStyles()

    const useLocalStyles = makeStyles()({
        container: {
            ...props.popoverTextStyle,
            margin: '1rem',
            width: '80vw',
            [theme.breakpoints.up('sm')]: {
                width: '30rem',
            },
        },
    })
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={classes.helpIcon} onClick={props.onClick}>
            <IconButton
                sx={{ color: colors.grays.NIMBUS }}
                onClick={handleClick}
            >
                <Help />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            ...props.popoverPaperStyle,
                            backgroundColor: colors.grays.WHITESMOKE,
                            borderRadius: '1rem',
                        },
                    },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal:
                        props.popoverPlacement === 'left'
                            ? 'right'
                            : props.popoverPlacement === 'right'
                            ? 'left'
                            : 'center',
                }}
            >
                <Markdown
                    className={localClasses.container}
                    content={props.helpText}
                />
            </Popover>
        </div>
    )
}
