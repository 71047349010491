import { colors } from '@hazadapt-git/public-core-base'
import { Avatar, Menu, MenuItem, MenuItemProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoCaretDownOutline } from 'react-icons/io5'

import { useWindowSizeUp } from '../../lib/utils'
import { TextWithIcon } from './TextWithIcon'

interface ProfileLinkWithAvatarProps {
    src?: string
    name?: string
    options: MenuItemProps[]
}

export const ProfileLinkWithAvatar: FC<ProfileLinkWithAvatarProps> = (
    props: ProfileLinkWithAvatarProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)

    const onAvatarPress = (e: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) setAnchorEl(null)
        else setAnchorEl(e.currentTarget)
    }
    const onClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={localClasses.container} onClick={onAvatarPress}>
            <Avatar
                sx={{
                    width: mediumWindowOrLarger ? '3rem' : '2.5rem',
                    height: mediumWindowOrLarger ? '3rem' : '2.5rem',
                    cursor: 'pointer',
                }}
                src={props.src}
            />
            {mediumWindowOrLarger && (
                <TextWithIcon
                    icon={
                        <IoCaretDownOutline
                            color={colors.grays.BLANC}
                            size="1.25rem"
                        />
                    }
                    iconLocation="right"
                    textStyle={{
                        color: colors.grays.BLANC,
                    }}
                    containerStyle={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                    }}
                    spacing={2}
                >
                    {props.name}
                </TextWithIcon>
            )}
            {props.options.length > 0 && (
                <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
                    {props.options.map((o, i) => (
                        <MenuItem {...o} key={`navProfileOptions_${i}`} />
                    ))}
                </Menu>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        cursor: 'pointer',
    },
})
