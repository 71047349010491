import {
    CrisisSupportOption,
    CSOScope,
    BoosterConfig,
    SupportedContentBooster,
} from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import {
    CSOFilterPicker,
    CSOItemProps,
    EmergencyHelpTemplate,
} from '../components'
import { useAppSelector, useAppDispatch } from '../lib/store'
import { logEvent, searchCSOs } from '../lib/utils'
import { ContentBooster, PageProps, PickerItem } from '../lib/entities'
import { getCSOsThunk } from '../lib/slices'

interface EmergencyHelpProps extends PageProps {}

export const EmergencyHelpPage: FC<EmergencyHelpProps> = (
    props: EmergencyHelpProps
) => {
    const { csos } = useAppSelector((state) => state.csos)
    const { profileReady } = useAppSelector((state) => state.profile)
    const { hazards } = useAppSelector((state) => state.hazards)

    React.useEffect(() => {
        document.title = 'Get Emergency Help - HazAdapt'
    }, [])

    React.useEffect(() => {
        if (!profileReady) return
        logEvent('OPEN_EMERGENCY_CALL_HELPER')
    }, [profileReady])

    const [pickableHazards, setPickableHazards] = React.useState<
        PickerItem<number>[]
    >([])

    const dispatch = useAppDispatch()

    // const [hazardsRetrieved, setHazardsRetrieved] = React.useState<boolean>(false)
    const [csosRetrieved, setCSOsRetrieved] = React.useState<boolean>(false)

    const [filters, setFilters] = React.useState<BoosterConfig[]>([])
    const [activeFilters, setActiveFilters] = React.useState<
        SupportedContentBooster[]
    >([])
    const [availableFilters, setAvailableFilters] = React.useState<
        SupportedContentBooster[]
    >([])

    const [displayedCSOs, setDisplayedCSOs] = React.useState<
        CrisisSupportOption[]
    >([])

    const [formattedCSOs, setFormattedCSOs] = React.useState<CSOItemProps[]>([])

    const [hazardToFilter, setHazardToFilter] = React.useState<number | ''>('')

    const [filterPickerOpen, setFilterPickerOpen] =
        React.useState<boolean>(false)

    const [searchQuery, setSearchQuery] = React.useState<string>('')

    React.useEffect(() => {
        // TODO: When we add the hazard picker back in, we'll need to dispatch getHazardsThunk here as well
        dispatch(getCSOsThunk(true)).finally(() => {
            setCSOsRetrieved(true)
        })
    }, [dispatch])

    React.useEffect(() => {
        // Create/update list of pickable hazards in hazard picker
        const hazardsFilter: PickerItem<number>[] = []
        for (let h of hazards) {
            if (
                csos
                    .filter(
                        (a) =>
                            activeFilters.length === 0 ||
                            a.filters.some((f) => activeFilters.includes(f))
                    )
                    .some((a) => a.hazards.includes(h.id))
            ) {
                hazardsFilter.push({
                    value: h.id,
                    label: h.name,
                })
            }
        }

        // Add "Other Hazards" option
        hazardsFilter.push({
            label: 'Other Hazards',
            value: -1,
        })

        setPickableHazards(hazardsFilter)
    }, [hazards, csos, activeFilters])

    React.useEffect(() => {
        // Initialize or replenish filter states to reflect latest retrieved CSOs
        const supportedFilters: SupportedContentBooster[] = Array.from(
            new Set<SupportedContentBooster>(
                csos.map((a) => a.filters.filter((f) => f !== 'Adults')).flat()
            )
        )
        const filterConfigs: BoosterConfig[] = supportedFilters.map(
            (b: SupportedContentBooster) => ContentBooster[b.replace(' ', '_')]
        )
        setFilters(filterConfigs)
        setActiveFilters((activeFilters) =>
            activeFilters.filter((a) => filterConfigs.some((b) => b.key === a))
        )
        setAvailableFilters((availableFilters) =>
            availableFilters.filter((a) =>
                filterConfigs.some((b) => b.key === a)
            )
        )
    }, [csos])

    React.useEffect(() => {
        let displayedData: CrisisSupportOption[] = []

        if (hazardToFilter) {
            if (hazardToFilter !== -1) {
                // filter CSOs by the selected hazard
                displayedData = csos.filter(
                    (a) =>
                        a.hazards.includes(hazardToFilter) &&
                        (activeFilters.length === 0 ||
                            a.filters.some((f) => activeFilters.includes(f)))
                )
            } else {
                // user has selected "Other Hazards"
                // filter through the CSOs that are not linked to any hazard
                displayedData = csos.filter(
                    (a) =>
                        a.hazards.length === 0 &&
                        (activeFilters.length === 0 ||
                            a.filters.some((f) => activeFilters.includes(f)))
                )
            }
        } else {
            // show all CSOs
            displayedData = csos.filter(
                (a) =>
                    activeFilters.length === 0 ||
                    a.filters.some((f) =>
                        activeFilters.includes(
                            f.replaceAll(' ', '_') as SupportedContentBooster
                        )
                    )
            )
        }

        // sort CSOs
        displayedData.sort((a: CrisisSupportOption, b: CrisisSupportOption) => {
            // determine how many filters are toggled on content
            const intersectionA: SupportedContentBooster[] = a.filters.filter(
                (t) =>
                    activeFilters.includes(
                        t.replace(' ', '_') as SupportedContentBooster
                    )
            )
            const intersectionB: SupportedContentBooster[] = b.filters.filter(
                (t) =>
                    activeFilters.includes(
                        t.replace(' ', '_') as SupportedContentBooster
                    )
            )

            if (intersectionA.length !== intersectionB.length) {
                // sort by most filters
                return intersectionB.length - intersectionA.length
            } else {
                // sort by recency of choosing the filter
                for (const filter of activeFilters) {
                    const filterInA = intersectionA.includes(filter)
                    const filterInB = intersectionB.includes(filter)
                    if (filterInA && !filterInB) {
                        return 1
                    } else if (!filterInA && filterInB) {
                        return -1
                    }
                }
            }
            if (a.scope === CSOScope.LOCAL && b.scope === CSOScope.NATIONAL) {
                return -1
            } else if (
                a.scope === CSOScope.NATIONAL &&
                b.scope === CSOScope.LOCAL
            ) {
                return 1
            } else {
                // peg non-emergency line to the top unless the user has entered a search query
                if (searchQuery.length === 0 && a.priority !== b.priority)
                    return a.priority - b.priority
                else return a.title < b.title ? -1 : a.title > b.title ? 1 : 0
            }
        })

        setDisplayedCSOs(displayedData)
    }, [csos, hazardToFilter, activeFilters, searchQuery])

    React.useEffect(() => {
        let choosableFilters: SupportedContentBooster[] = filters.map(
            (b) => b.key
        )
        if (hazardToFilter) {
            choosableFilters = Array.from(
                new Set<SupportedContentBooster>(
                    displayedCSOs.map((a) => a.filters).flat()
                )
            )
        }
        setAvailableFilters(choosableFilters)
    }, [filters, displayedCSOs, hazardToFilter])

    React.useEffect(() => {
        let csosToShow = [...displayedCSOs]
        if (searchQuery) {
            /** Run search algorithm */
            csosToShow = searchCSOs(csosToShow, searchQuery)
        }
        const formattedCSOs = csosToShow.map((a) => ({
            config: {
                ...a,
                icon: a.icon
                    ? {
                          ...a.icon,
                          src: `data:image/svg+xml;base64,${btoa(a.icon.src)}`,
                      }
                    : null,
            },
            filterChips: a.filters
                .filter((f) =>
                    activeFilters.includes(
                        f.replaceAll(' ', '_') as SupportedContentBooster
                    )
                )
                .map(
                    (f) =>
                        ContentBooster[
                            f.replaceAll(' ', '_') as SupportedContentBooster
                        ]
                ),
        }))
        setFormattedCSOs(formattedCSOs)
    }, [activeFilters, displayedCSOs, searchQuery])

    const onFilterPress = (value: SupportedContentBooster) => {
        const filterStack = [...activeFilters]
        const index = filterStack.findIndex((b) => b === value)
        if (index !== -1) {
            filterStack.splice(index, 1)
        } else {
            filterStack.push(value)
        }
        setActiveFilters(filterStack)
    }

    const onResetFilters = () => {
        setActiveFilters([])
    }

    const onHazardSelect = (id: number) => {
        setHazardToFilter(id)
    }

    const onHazardReset = () => setHazardToFilter('')

    const handleOpen = () => {
        setFilterPickerOpen(true)
    }

    const handleClose = () => {
        setFilterPickerOpen(false)
    }

    const renderCSOFilterPicker = (): React.ReactNode => (
        <CSOFilterPicker
            activeChips={activeFilters}
            availableChips={availableFilters}
            onChipPress={onFilterPress}
            onResetPress={onResetFilters}
            onCancelPress={handleClose}
        />
    )

    const onCsoOpen = (cso_id: number) => {
        logEvent('OPEN_CSO', {
            cso_id,
            active_filters: activeFilters,
            search_query: searchQuery || undefined,
        })
    }

    const onWhatTheyHelpWithClick = (cso_id: number) => {
        logEvent('OPEN_CSO_WHAT_THEY_HELP_WITH', {
            cso_id,
            active_filters: activeFilters,
            search_query: searchQuery || undefined,
        })
    }

    const onWhoTheyHelpClick = (cso_id: number) => {
        logEvent('OPEN_CSO_WHO_THEY_HELP', {
            cso_id,
            active_filters: activeFilters,
            search_query: searchQuery || undefined,
        })
    }

    const onAboutThisServiceClick = (cso_id: number) => {
        logEvent('OPEN_CSO_ABOUT_THIS_SERVICE', {
            cso_id,
            active_filters: activeFilters,
            search_query: searchQuery || undefined,
        })
    }

    const onFindNearMeClick = (cso_id: number, url: string) => {
        logEvent('CLICK_CSO_FIND_NEAR_ME', {
            cso_id,
            active_filters: activeFilters,
            search_query: searchQuery || undefined,
        })
        window.open(url, '_blank')
    }

    const onCsoViewWebsiteClick = (cso_id: number, url: string) => {
        logEvent('CLICK_CSO_VIEW_WEBSITE', {
            cso_id,
            url,
            active_filters: activeFilters,
            search_query: searchQuery || undefined,
        })
        window.open(url, '_blank')
    }

    const onCsoHover = (cso_id: number) => {
        logEvent('HOVER_CSO', {
            cso_id,
            active_filters: activeFilters,
            search_query: searchQuery || undefined,
        })
    }

    return (
        <EmergencyHelpTemplate
            hazards={pickableHazards}
            hazardToFilter={hazardToFilter}
            activeFilters={activeFilters}
            filterPickerOpen={filterPickerOpen}
            handleFilterPickerOpen={handleOpen}
            handleFilterPickerClose={handleClose}
            csoFilterPicker={renderCSOFilterPicker()}
            onSelectedHazardChange={onHazardSelect}
            onHazardResetPress={onHazardReset}
            searchQuery={searchQuery}
            onSearchQueryChange={setSearchQuery}
            //pass in CSOs
            csoItems={formattedCSOs}
            //pass in what to know grid Items when available
            whatToKnow911Items={[]}
            //     selectedItem={selectedWTC911Item ? whatToKnow911DetailItems.find(
            //         (i) => i.id === selectedWTC911Item
            //     ) : whatToKnow911DetailItems[0]
            // }
            loading={props.loading || !csosRetrieved}
            onCsoOpen={onCsoOpen}
            onCsoHover={onCsoHover}
            onWhatTheyHelpWithClick={onWhatTheyHelpWithClick}
            onWhoTheyHelpClick={onWhoTheyHelpClick}
            onAboutThisServiceClick={onAboutThisServiceClick}
            onFindNearMeClick={onFindNearMeClick}
            onCsoViewWebsiteClick={onCsoViewWebsiteClick}
        />
    )
}
