import { colors, HazardOverview } from '@hazadapt-git/public-core-base'
import {
    Toolbar,
    IconButton,
    Drawer,
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    MenuItemProps,
    ListItemIcon,
    Avatar,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'
import { IoMenu } from 'react-icons/io5'
import { RootState, useAppSelector } from '../../lib/store'

import { useWindowSizeUp } from '../../lib/utils'
import {
    CompanyLogo,
    EmergencyButton,
    Searchbar,
    SearchResultMenuItem,
} from '../atoms'
import { ProfileLinkWithAvatar } from '../molecules'

export interface NavBarItem {
    label: string
    route: string
    icon?: React.ReactNode
    hide?: boolean
}

interface SiteHeaderProps {
    searchQuery: string
    onSearchQueryChange(value: string): void
    onLogoPress?(...props: any): void
    options: HazardOverview[]
    onOptionPress(id: number): void
    loadingOptions?: boolean
    onSearchQuerySubmit(...props: any): void
    profilePictureSrc?: string
    name?: string
    onEmergencyButtonPress(): void
    profileOptions: MenuItemProps[]

    // navbar
    navItems: NavBarItem[]
    activeNavItem: string
    onNavItemPress(route: string): void
    onProfilePress: React.MouseEventHandler
}

export const SiteHeader: FC<SiteHeaderProps> = (props: SiteHeaderProps) => {
    const { classes } = useLocalStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
    const { user, profilePictureURI } = useAppSelector(
        (state: RootState) => state.profile
    )

    const onEmergencyButtonPress = () => {
        setDrawerOpen(false)
        props.onEmergencyButtonPress()
    }

    return (
        <div
            className={classNames(
                classes.container,
                props.name && mediumWindowOrLarger
                    ? classes.loggedInContainer
                    : undefined
            )}
        >
            <div
                className={
                    mediumWindowOrLarger
                        ? classes.flexSiteHeader
                        : classes.gridSiteHeader
                }
            >
                <CompanyLogo
                    dark
                    onClick={props.onLogoPress}
                    height="2.5rem"
                    style={{
                        cursor: 'pointer',
                        gridColumn: mediumWindowOrLarger ? undefined : `2 / -2`,
                        justifySelf: 'center',
                    }}
                />
                <div
                    className={classNames(
                        classes.searchBarWrapper,
                        mediumWindowOrLarger ? '' : classes.mwSearchBar
                    )}
                >
                    <Searchbar
                        value={props.searchQuery}
                        onChange={(e) =>
                            props.onSearchQueryChange(e.target.value)
                        }
                        options={props.options.map(
                            (h) =>
                                ({
                                    id: h.id,
                                    icon: h.icon.src,
                                    name: h.name,
                                } as SearchResultMenuItem)
                        )}
                        onOptionPress={props.onOptionPress}
                        variant="outlined"
                        loading={props.loadingOptions}
                        onQuerySubmit={props.onSearchQuerySubmit}
                    />
                </div>
                {mediumWindowOrLarger &&
                    (props.name ? (
                        <ProfileLinkWithAvatar
                            src={props.profilePictureSrc}
                            name={props.name}
                            options={props.profileOptions}
                        />
                    ) : (
                        <div
                            onClick={props.onProfilePress}
                            className={classes.loginLink}
                        >
                            <Typography
                                sx={{
                                    color: colors.grays.BLANC,
                                    textDecoration: 'underline',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 500,
                                }}
                            >
                                Log In
                            </Typography>
                        </div>
                    ))}
                {!mediumWindowOrLarger && (
                    <div
                        className={classNames(
                            classes.mwProfileAndHamburger,
                            !props.name ? classes.loggedOutHamburger : ''
                        )}
                    >
                        {props.name && (
                            <ProfileLinkWithAvatar
                                src={props.profilePictureSrc}
                                name={props.name}
                                options={props.profileOptions}
                            />
                        )}
                        <IconButton
                            sx={{
                                color: colors.grays.NOIR,
                            }}
                            onClick={() => setDrawerOpen(true)}
                            aria-label="Navigation drawer open icon"
                        >
                            <IoMenu color={colors.grays.BLANC} />
                        </IconButton>
                    </div>
                )}
            </div>
            {!mediumWindowOrLarger ? (
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                >
                    <Box
                        role="presentation"
                        onClick={() => setDrawerOpen(false)}
                        onKeyDown={() => setDrawerOpen(false)}
                        className={classes.drawer}
                    >
                        <div
                            onClick={props.onProfilePress}
                            className={classes.profileLink}
                        >
                            {user ? (
                                <>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: colors.grays.BLANC }}
                                    >
                                        Hi, {user.first_name}!
                                    </Typography>
                                    <Avatar
                                        sx={{
                                            width: mediumWindowOrLarger
                                                ? '3rem'
                                                : '2.5rem',
                                            height: mediumWindowOrLarger
                                                ? '3rem'
                                                : '2.5rem',
                                            cursor: 'pointer',
                                        }}
                                        src={profilePictureURI}
                                        onClick={props.onProfilePress}
                                    />
                                </>
                            ) : (
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: colors.grays.BLANC,
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Log In
                                </Typography>
                            )}
                        </div>
                        <CompanyLogo dark style={{ paddingBottom: '0.5rem' }} />
                        <Divider className={classes.drawerDivider} />
                        <List className={classes.drawerNavList}>
                            {props.navItems
                                .filter((item) => !item.hide)
                                .map((item, index) => (
                                    <ListItem
                                        key={item.label}
                                        onClick={() =>
                                            props.onNavItemPress(item.route)
                                        }
                                        disablePadding
                                        className={classes.drawerNavItem}
                                    >
                                        {item.icon && (
                                            <ListItemIcon
                                                sx={{ minWidth: 'unset' }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                        )}
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="h4"
                                                    fontWeight="1rem"
                                                    color={colors.grays.BLANC}
                                                    py="1rem"
                                                >
                                                    {item.label}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    </Box>
                    <EmergencyButton
                        sx={{
                            position: 'absolute',
                            right: '1.5rem',
                            bottom: '1.5rem',
                        }}
                        onClick={onEmergencyButtonPress}
                    />
                </Drawer>
            ) : (
                <Toolbar variant="dense" sx={{ minHeight: '3rem' }}>
                    {props.navItems
                        .filter((item) => !item.hide)
                        .map((item, index) => (
                            <div
                                onClick={() => props.onNavItemPress(item.route)}
                                className={classes.navBarLink}
                                key={item.label}
                            >
                                <Typography
                                    variant="h4"
                                    noWrap
                                    component="div"
                                    className={classNames(
                                        classes.navBarLinkInnerContainer,
                                        props.activeNavItem === item.route
                                            ? classes.activeLink
                                            : ''
                                    )}
                                    sx={{ color: colors.grays.BLANC }}
                                >
                                    {item.label}
                                </Typography>
                            </div>
                        ))}
                </Toolbar>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        backgroundColor: colors.primary.CERULEAN,
        padding: '1.5rem',
    },
    loggedInContainer: {
        padding: '0.5rem 1.5rem 1.5rem',
    },
    searchBarWrapper: {
        width: '100%',
    },
    gridSiteHeader: {
        display: 'grid',
        alignItems: 'center',
        gap: '1.5rem',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
    },
    mwSearchBar: {
        order: 4,
        gridColumn: `1 / span 3`,
    },
    mwProfileAndHamburger: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifySelf: 'flex-end',
        justifyContent: 'flex-end',
        gap: '0.5rem',
    },
    loggedOutHamburger: {
        display: 'flex',
    },
    flexSiteHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '1.5rem',
    },
    drawer: {
        backgroundColor: colors.primary.CERULEAN,
        width: '75vw',
        maxWidth: '20rem',
        height: '100%',
        padding: '9rem 1.5rem 0',
        position: 'relative',
    },
    drawerDivider: {
        margin: '0 !important',
        width: '100% !important',
        backgroundColor: colors.grays.BLANC,
        height: '2px',
    },
    drawerNavList: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem !important',
    },
    drawerNavItem: {
        borderBottom: `1px ${colors.grays.BLANC} solid !important`,
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        alignItems: 'center',
        cursor: 'pointer',
    },
    navBarLink: {
        flexGrow: 1,
        alignSelf: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500,
        color: colors.grays.BLANC,
    },
    navBarLinkInnerContainer: {
        cursor: 'pointer',
        borderBottom: `2px ${colors.primary.CERULEAN} solid`,
    },
    activeLink: {
        borderBottom: `1.5px ${colors.grays.BLANC} solid`,
    },
    profileLink: {
        position: 'absolute',
        top: '1.5rem',
        right: '1.5rem',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    loginLink: {
        cursor: 'pointer',
    },
})
