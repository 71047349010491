import { Avatar, Badge, Theme } from '@mui/material'
import React, { FC } from 'react'
import { SxProps } from '@mui/system'

interface ImageWithAccessoryProps {
    src?: string
    badgeContent?: React.ReactNode
    badgeStyles?: SxProps<Theme>
    avatarStyles?: SxProps<Theme>
}

export const ImageWithAccessory: FC<ImageWithAccessoryProps> = (
    props: ImageWithAccessoryProps
) => {
    return (
        <Badge
            overlap="circular"
            color="default"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={props.badgeContent}
            sx={props.badgeStyles}
        >
            <Avatar sx={props.avatarStyles} src={props.src} />
        </Badge>
    )
}
