import {
    colors,
    FilterConfig,
    OrganizationOverview,
} from '@hazadapt-git/public-core-base'
import { MoreVert } from '@mui/icons-material'
import {
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { AspectRatio } from 'react-aspect-ratio'
import { IoIosLink } from 'react-icons/io'
import {
    IoBookmark,
    IoBookmarkOutline,
    IoLocationSharp,
    IoShareSocial,
} from 'react-icons/io5'

import PDFIcon from '../../assets/icons/PDFBlue.svg'
import {
    customBorderRadius,
    customDarkBlueColor,
    localContentColor,
} from '../../lib/styles/universal'
import { useWindowSizeDown, useWindowSizeExact } from '../../lib/utils'
import { Tag } from '../atoms'

export interface HazardCardDTO {
    id: number
    slug?: string
    icon: string
    iconAlt?: string
    name: string
    description: string
    filterChips: FilterConfig[]
    bookmarked?: boolean
    creator?: OrganizationOverview
    local?: boolean
}

interface HazardCardProps extends HazardCardDTO {
    onCopyLinkPress(id: number): void
    onBookmark(id: number): void | Promise<void>
    onPdfPress(id: number, slug: string): void
    onOptionsPress?(id: number): void
    onPrepCheckPress?(id: number): void
    onPress(id: number): void
    onHover?(id: number): void
}

export const HazardCard: FC<HazardCardProps> = (props: HazardCardProps) => {
    const anchorRef = React.useRef<HTMLButtonElement>(null)
    const bookmarking = React.useRef<boolean>(false)
    const [shareMenuOpen, setShareMenuOpen] = React.useState<boolean>(false)

    const onShareMenuClose = (e: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(e.target as HTMLElement)
        ) {
            return
        }
        setShareMenuOpen(false)
    }

    const onShareKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab') {
            e.preventDefault()
            setShareMenuOpen(false)
        } else if (e.key === 'Escape') {
            setShareMenuOpen(false)
        }
    }

    const onSharePress = () => setShareMenuOpen((prevOpen) => !prevOpen)

    const smallWindowOrSmaller = useWindowSizeDown('md')
    const xsWindowExact = useWindowSizeExact('xs')
    const smallWindowExact = useWindowSizeExact('sm')

    const aspectRatio = xsWindowExact ? 2 : smallWindowExact ? 4 : 0.6

    const useDynamicStyles = makeStyles()({
        hazardCard: {
            cursor: 'pointer',
            flex: 1,
            boxSizing: 'border-box',
            borderRadius: customBorderRadius,
            '&:before': smallWindowOrSmaller
                ? {
                      paddingBottom: `${100 / aspectRatio}%`,
                      content: "''",
                      float: smallWindowOrSmaller ? 'left' : undefined,
                  }
                : undefined,
            '&:after': smallWindowOrSmaller
                ? {
                      display: 'table',
                      content: "''",
                      clear: 'both',
                  }
                : undefined,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            backgroundColor: colors.grays.WHITESMOKE,
        },
        hazardIcon: {
            height: '4rem',
            width: smallWindowOrSmaller ? '4rem' : '100%',
            objectFit: 'contain',
            paddingRight: smallWindowOrSmaller ? '1rem' : 0,
        },
    })

    const { classes: dynamicClasses } = useDynamicStyles()
    const { classes: localClasses } = useLocalStyles()

    const onBookmark: React.MouseEventHandler = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (bookmarking.current) return

        bookmarking.current = true
        await props.onBookmark(props.id)
        bookmarking.current = false
    }

    const renderCard = () => (
        <div
            className={dynamicClasses.hazardCard}
            style={{
                border:
                    props.local && props.creator
                        ? `1px solid ${localContentColor}`
                        : 0,
            }}
            onMouseEnter={() => props.onHover?.(props.id)}
        >
            <Grid
                container
                columnSpacing={1}
                className={localClasses.actionButtonContainer}
            >
                <Grid
                    item
                    xs={8}
                    display="flex"
                    flexWrap="wrap"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => props.onPress(props.id)}
                >
                    {props.local || props.filterChips ? (
                        <div className={localClasses.hazardCardHeader}>
                            {props.local && (
                                <IoLocationSharp
                                    size="2rem"
                                    color={localContentColor}
                                />
                            )}
                            <div className={localClasses.filtersContainer}>
                                {props.filterChips.map(
                                    (chip: FilterConfig, index: number) => {
                                        return chip ? (
                                            <Tag
                                                key={`hazard_${props.id}_${chip.key}_mini_chip`}
                                                config={chip}
                                                start={index === 0}
                                                end={
                                                    index ===
                                                    props.filterChips.length - 1
                                                }
                                            />
                                        ) : (
                                            <></>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: '100%' }} />
                    )}
                </Grid>
                <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >
                    <IconButton
                        onClick={onBookmark}
                        data-testid={`hazard_${props.id}_bookmark_btn`}
                        aria-label="Bookmark button"
                    >
                        {props.bookmarked ? (
                            <IoBookmark color={customDarkBlueColor} />
                        ) : (
                            <IoBookmarkOutline color={customDarkBlueColor} />
                        )}
                    </IconButton>
                    <IconButton
                        ref={anchorRef}
                        onClick={onSharePress}
                        data-testid={`hazard_${props.id}_share_btn`}
                        id={`hazard_${props.id}_share_btn`}
                    >
                        <IoShareSocial color={customDarkBlueColor} />
                    </IconButton>
                    <Menu
                        open={shareMenuOpen}
                        anchorEl={anchorRef.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        role="menu"
                        id={`hazard_${props.id}_share_menu`}
                        aria-labelledby={`hazard_${props.id}_share_btn`}
                        onClose={onShareMenuClose}
                        onKeyDown={onShareKeyDown}
                    >
                        <MenuItem
                            onClick={() => props.onCopyLinkPress(props.id)}
                            aria-label="Copy link option button"
                        >
                            <ListItemIcon>
                                <IoIosLink
                                    size={24}
                                    color={customDarkBlueColor}
                                />
                            </ListItemIcon>
                            <ListItemText>Copy Link</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                props.onPdfPress &&
                                props.slug &&
                                props.onPdfPress(props.id, props.slug)
                            }
                            aria-label="View PDF option button"
                        >
                            <ListItemIcon>
                                <img
                                    src={PDFIcon}
                                    alt="PDF icon"
                                    width={24}
                                    height={24}
                                />
                            </ListItemIcon>
                            <ListItemText>View PDF</ListItemText>
                        </MenuItem>
                    </Menu>
                    {props.onOptionsPress && (
                        <IconButton
                            onClick={() =>
                                props.onOptionsPress &&
                                props.onOptionsPress(props.id)
                            }
                        >
                            <MoreVert
                                sx={{ color: colors.softTones.CERULEAN }}
                            />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    height: '100%',
                    padding: smallWindowOrSmaller
                        ? '2.5rem 1rem'
                        : '3.5rem 1rem',
                    textAlign: smallWindowOrSmaller ? 'center' : 'left',
                    flex: 10,
                    cursor: 'pointer',
                }}
                onClick={() => props.onPress(props.id)}
            >
                <Grid
                    item
                    xs={4}
                    md={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={`data:image/svg+xml;base64,${btoa(props.icon)}`}
                        className={dynamicClasses.hazardIcon}
                        alt={props.iconAlt || `${props.name} icon`}
                    />
                </Grid>
                <Grid
                    item
                    xs={8}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: smallWindowOrSmaller
                            ? 'center'
                            : 'space-evenly',
                    }}
                >
                    <Typography
                        component="p"
                        variant="h3"
                        pb="0.625rem"
                        textAlign={smallWindowOrSmaller ? 'left' : 'center'}
                    >
                        {props.name}
                    </Typography>
                    <Typography
                        lineHeight="1.2rem"
                        textAlign={smallWindowOrSmaller ? 'left' : 'center'}
                        maxHeight="3.6rem"
                        sx={{
                            overflowY: 'auto',
                            paddingRight: smallWindowOrSmaller ? '0.4rem' : 0,
                        }}
                    >
                        {props.description}
                    </Typography>
                    {props.onPrepCheckPress && (
                        <>{/* Later: Prep check link goes here */}</>
                    )}
                </Grid>
                {props.creator &&
                    (props.creator.logo ? (
                        <img
                            className={
                                smallWindowOrSmaller
                                    ? localClasses.mwOrgLogo
                                    : localClasses.orgLogo
                            }
                            src={
                                props.creator.logo.url ?? props.creator.logo.src
                            }
                            alt={props.creator.logo.alt}
                        />
                    ) : (
                        <Typography
                            fontWeight={500}
                            sx={
                                smallWindowOrSmaller
                                    ? {
                                          position: 'absolute',
                                          left: '0.5rem',
                                          bottom: '0.5rem',
                                      }
                                    : {
                                          textAlign: 'center',
                                          m: 'auto',
                                      }
                            }
                            alignSelf="flex-end"
                        >
                            {props.creator.name}
                        </Typography>
                    ))}
            </Grid>
        </div>
    )

    return smallWindowOrSmaller ? (
        renderCard()
    ) : (
        <AspectRatio ratio={aspectRatio} style={{ width: '100%' }}>
            {renderCard()}
        </AspectRatio>
    )
}

const useLocalStyles = makeStyles()({
    hazardCardHeader: {
        position: 'absolute',
        top: '0.35rem',
        left: '1rem',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    localContainer: {
        display: 'flex',
    },
    filtersContainer: {
        display: 'flex',
    },
    mwOrgLogo: {
        height: '2.5rem',
        width: 'auto',
        objectFit: 'contain',
        alignSelf: 'flex-end',
        position: 'absolute',
        left: '0.5rem',
        bottom: '0.5rem',
    },
    orgLogo: {
        height: '2.5rem',
        width: 'auto',
        margin: '0 auto',
        objectFit: 'contain',
        position: 'absolute',
        bottom: '0.5rem',
        left: 0,
        right: 0,
    },
    actionButtonContainer: {
        position: 'absolute',
        top: '0.1rem',
        padding: '0 0.1rem',
    },
})
