import { Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material'
import React, { FC } from 'react'

interface OptionsViewProps extends MenuProps {
    items: MenuItemProps[]
}

export const OptionsView: FC<OptionsViewProps> = (props: OptionsViewProps) => (
    <Menu
        {...props}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
    >
        {props.items.map((item: MenuItemProps, index: number) => (
            <MenuItem {...item} />
        ))}
    </Menu>
)
