import { makeStyles } from 'tss-react/mui'
import { FC } from 'react'
import { theme, useStyles } from '../../lib/styles/universal'
import { Tooltip as MuiTooltip, Fade, Typography } from '@mui/material'
import { SxProps, Theme } from '@mui/system'
import { colors } from '@hazadapt-git/public-core-base'

interface CTAProps {
    text: string
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

interface TooltipProps {
    open?: boolean
    children: React.ReactElement
    placement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
    title: string
    subtitle?: string
    ctas?: CTAProps[]
    sx?: SxProps<Theme>
}

export const Tooltip: FC<TooltipProps> = (props: TooltipProps) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()

    return (
        <MuiTooltip
            placement="bottom"
            TransitionComponent={Fade}
            title={
                <div className={localClasses.tooltip}>
                    <Typography
                        fontWeight={500}
                        fontSize="1.125rem"
                        color={colors.grays.BLANC}
                    >
                        {props.title}
                    </Typography>
                    {props.subtitle ? (
                        <Typography
                            lineHeight="1.25rem"
                            color={colors.grays.BLANC}
                        >
                            {props.subtitle}
                        </Typography>
                    ) : null}
                    {props.ctas ? (
                        <div className={localClasses.ctas}>
                            {props.ctas.map((cta) => (
                                <button
                                    className={classes.unstyledButton}
                                    onClick={cta.onClick}
                                    key={cta.text
                                        .toLowerCase()
                                        .replaceAll(' ', '-')}
                                >
                                    {cta.text}
                                </button>
                            ))}
                        </div>
                    ) : null}
                </div>
            }
            arrow
            open={props.open}
        >
            {props.children}
        </MuiTooltip>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        margin: '1rem',
        width: '80vw',
        [theme.breakpoints.up('sm')]: {
            width: '30rem',
        },
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    ctas: {
        alignSelf: 'flex-end',
    },
})
