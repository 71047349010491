import { colors } from '@hazadapt-git/public-core-base'
import { Button, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'

import { ProgressBar } from '../atoms'

interface PrepCheckProgressBarProps {
    onBackPress: React.MouseEventHandler
    onForwardPress: React.MouseEventHandler
    currentQuestion: number
    questionCount: number
}

export const PrepCheckProgressBar: FC<PrepCheckProgressBarProps> = (
    props: PrepCheckProgressBarProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')
    return (
        <div className={localClasses.container}>
            <Typography fontSize="0.875rem" lineHeight="1rem" fontWeight={500}>
                Question {props.currentQuestion}/{props.questionCount}
            </Typography>
            <div
                className={
                    smallWindowOrLarger
                        ? localClasses.desktopProgressContainer
                        : localClasses.mwProgressContainer
                }
            >
                <div className={localClasses.prevButtonContainer}>
                    <Button
                        onClick={props.onBackPress}
                        sx={{
                            visibility:
                                props.currentQuestion <= 1
                                    ? 'hidden'
                                    : 'visible',
                            pointerEvents:
                                props.currentQuestion <= 1 ? 'none' : 'auto',
                            touchAction:
                                props.currentQuestion <= 1 ? 'none' : 'auto',
                            padding: '0.5rem 1rem',
                        }}
                        variant="text"
                    >
                        <IoChevronBackOutline
                            size="1.75rem"
                            color={colors.grays.NOIR}
                        />
                        {smallWindowOrLarger ? 'Previous' : null}
                    </Button>
                </div>
                <ProgressBar
                    maxWidth="16rem"
                    progress={props.currentQuestion / props.questionCount}
                />
                <div className={localClasses.nextButtonContainer}>
                    <Button
                        onClick={props.onForwardPress}
                        sx={{
                            visibility:
                                props.currentQuestion >= props.questionCount
                                    ? 'hidden'
                                    : 'visible',
                            pointerEvents:
                                props.currentQuestion >= props.questionCount
                                    ? 'none'
                                    : 'auto',
                            touchAction:
                                props.currentQuestion >= props.questionCount
                                    ? 'none'
                                    : 'auto',
                            padding: '0.5rem 1rem',
                        }}
                        variant="text"
                    >
                        {smallWindowOrLarger ? 'Next' : null}
                        <IoChevronForwardOutline
                            size="1.75rem"
                            color={colors.grays.NOIR}
                        />
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.25rem',
        width: '100%',
    },
    desktopProgressContainer: {
        display: 'grid',
        gridTemplateColumns: '8rem 16rem 8rem',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.75rem',
        width: '100%',
    },
    mwProgressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.75rem',
        width: '100%',
    },
    prevButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    nextButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
})
