import { Grid, Link } from '@mui/material'
import React, { FC } from 'react'

import { FAQBlock, FAQBlockProps } from '../molecules'
import { FAQGrid } from './FAQGrid'

interface FAQTabProps {
    blocks: FAQBlockProps[]
    selectedItem?: FAQBlockProps
    blockPressed: boolean
    onReportIssuePress(...props: any): void
    onBlockPress(id: number): void
}

export const FAQTab: FC<FAQTabProps> = (props: FAQTabProps) => {
    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (
            props.selectedItem &&
            props.blockPressed &&
            ref.current?.scrollIntoView
        ) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
            })
        }
    }, [props.selectedItem, props.blockPressed])

    return (
        <Grid container gap="2.5rem" height="100%">
            <Grid key="reportIssueLink" item xs={12}>
                <Link fontWeight={500} onClick={props.onReportIssuePress}>
                    To report a site issue, click or tap here.
                </Link>
            </Grid>
            <Grid item xs>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={5}>
                        <FAQGrid
                            items={props.blocks}
                            selectedID={props.selectedItem?.id}
                            onItemPress={(id) => {
                                props.onBlockPress(id)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        {props.selectedItem && (
                            <FAQBlock {...props.selectedItem} ref={ref} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
