import { Grid, Skeleton } from '@mui/material'
import React, { FC } from 'react'
import { customBorderRadius } from '../../lib/styles/universal'
import { PrepCheckCard, PrepCheckCardBaseProps } from '../molecules'

interface PrepCheckCardGridProps {
    data: PrepCheckCardBaseProps[]
    onSharePress?(e: React.MouseEvent, id: number): void
    onPrepCheckClick(id: number): void
    loading?: boolean
}

export const PrepCheckCardGrid: FC<PrepCheckCardGridProps> = (
    props: PrepCheckCardGridProps
) => {
    return (
        <Grid container spacing={2} mt={props.loading ? '1rem' : undefined}>
            {props.loading
                ? [...Array(12)].map((_, index) => (
                      <Grid
                          key={`prepCheckCardSkeleton_${index}`}
                          item
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                          display="flex"
                          justifyContent="center"
                      >
                          <Skeleton
                              variant="rectangular"
                              sx={{
                                  borderRadius: customBorderRadius,
                                  height: '21rem',
                                  width: '100%',
                                  maxWidth: '13rem',
                              }}
                          />
                      </Grid>
                  ))
                : props.data.map((item: PrepCheckCardBaseProps) => (
                      <Grid
                          key={`prepCheckCard_${item.id}`}
                          item
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                          display="flex"
                          justifyContent="center"
                      >
                          <PrepCheckCard
                              {...item}
                              onSharePress={props.onSharePress}
                              onClick={props.onPrepCheckClick}
                          />
                      </Grid>
                  ))}
        </Grid>
    )
}
