import { Button, IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { Card, Tag, ProgressBar } from '../atoms'
import { IoIosLink } from 'react-icons/io'
import {
    colors,
    FilterConfig,
    Image,
    PrepCheckStatus,
} from '@hazadapt-git/public-core-base'
import { useWindowSizeUp } from '../../lib/utils'
import { PrepCheckScoreGraph } from './PrepCheckScoreGraph'

export interface PrepCheckCardBaseProps {
    id: number
    status?: PrepCheckStatus
    dark_icon: string
    title: string
    badge?: Image
    progressBar: number
    filterChips: FilterConfig[]
    score?: number
    onSharePress?(e: React.MouseEvent, id: number): void
}

export interface PrepCheckCardProps extends PrepCheckCardBaseProps {
    onClick(id: number, done?: boolean): void
}

export const PrepCheckCard: FC<PrepCheckCardProps> = (
    props: PrepCheckCardProps
) => {
    const { classes } = useLocalStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')

    return (
        <Card
            colors={[colors.grays.WHITESMOKE]}
            className={classes.cardWrapper}
            style={{
                width: mediumWindowOrLarger ? '13rem' : '100%',
                position: 'relative',
            }}
            innerContainerStyle={{ padding: 0 }}
            onClick={() =>
                props.onClick(
                    props.id,
                    props.status === PrepCheckStatus.SUBMITTED
                )
            }
        >
            <div className={classes.card}>
                <div className={classes.cardHeader}>
                    <div className={classes.chipWrapper}>
                        {props.filterChips ? (
                            props.filterChips.map(
                                (chip: FilterConfig, index: number) => {
                                    return chip ? (
                                        <Tag
                                            key={`hazard_${props.id}_${chip.key}_mini_chip`}
                                            config={chip}
                                            start={index === 0}
                                            end={
                                                index ===
                                                props.filterChips.length - 1
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )
                                }
                            )
                        ) : (
                            <div style={{ width: '100%' }} />
                        )}
                    </div>

                    <div className={classes.linkCTAWrapper}>
                        <IconButton
                            onClick={(e) =>
                                props.onSharePress &&
                                props.onSharePress(e, props.id)
                            }
                        >
                            <IoIosLink color={colors.primary.CERULEAN} />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <div className={classes.iconWrapper}>
                        <img
                            src={props.dark_icon}
                            className={classes.icon}
                            alt={`${props.title} icon`}
                        />
                    </div>
                    <div className={classes.nameWrapper}>
                        <Typography variant="h4" textAlign="center">
                            {props.title}
                        </Typography>
                    </div>
                </div>
                <div className={classes.cardBottom}>
                    <PrepCheckScoreGraph score={props.score} bottomSpace />
                    {!props.status ? (
                        <Button
                            variant="contained"
                            color="success"
                            sx={{
                                width: '100%',
                                maxWidth: '11rem',
                                borderRadius: '0.75rem',
                                color: colors.grays.BLANC,
                            }}
                        >
                            Start
                        </Button>
                    ) : props.status === PrepCheckStatus.DRAFT ? (
                        <Button
                            variant="contained"
                            sx={{
                                width: '100%',
                                maxWidth: '11rem',
                                borderRadius: '0.75rem',
                            }}
                        >
                            Continue
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            color="success"
                            sx={{
                                width: '100%',
                                maxWidth: '11rem',
                                borderRadius: '0.75rem',
                                color: colors.primary.WAIKATO,
                            }}
                        >
                            View Results
                        </Button>
                    )}
                </div>
            </div>
            {props.status === PrepCheckStatus.DRAFT && (
                <div className={classes.progressBarContainer}>
                    <ProgressBar progress={props.progressBar} />
                </div>
            )}
        </Card>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        cursor: 'pointer',
    },
    cardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 0,
        cursor: 'pointer',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '21rem',
        paddingBottom: '1rem',
        position: 'relative',
        gap: '1rem',
        height: 'calc(100% - 1rem)',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        width: '100%',
        top: 0,
        gap: '0.5rem',
    },
    chipWrapper: {
        paddingTop: '0.5rem',
        paddingLeft: '0.75rem',
        display: 'flex',
    },
    linkCTAWrapper: {
        paddingTop: '0.5rem',
        paddingRight: '0.75rem',
    },
    cardBottom: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '0.25rem',
        width: 'calc(100% - 1rem)',
        margin: '0 auto',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        padding: '3.5rem 0 0.5rem',
    },
    nameWrapper: {
        width: '10rem',
        display: 'flex',
        justifyContent: 'center',
        borderBottom: `0.5px solid ${colors.grays.NIMBUS}`,
        paddingBottom: '0.5rem',
    },
    icon: {
        height: '4rem',
        width: 'auto',
        maxWidth: '80%',
        objectFit: 'contain',
    },
    badge: {
        objectFit: 'contain',
        alignSelf: 'center',
        marginBottom: '1rem',
    },
    progressBarContainer: {
        width: '100%',
        margin: 0,
        position: 'absolute',
        bottom: 0,
    },
})
