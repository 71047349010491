import { Grid } from '@mui/material'
import React, { FC } from 'react'

import {
    WhatToKnow911Details,
    ContentBlockPreviewProps,
    WhatToKnow911DetailsProps,
} from '../molecules'
import { WhatToKnow911Grid } from './WhatToKnow911Grid'

interface WhatToKnow911ViewProps {
    items: ContentBlockPreviewProps[]
    selectedItem?: WhatToKnow911DetailsProps
}

export const WhatToKnow911View: FC<WhatToKnow911ViewProps> = (
    props: WhatToKnow911ViewProps
) => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={5}>
                <WhatToKnow911Grid
                    items={props.items}
                    selectedID={props.selectedItem?.id}
                />
            </Grid>
            <Grid item xs={12} sm={7}>
                {props.selectedItem && (
                    <WhatToKnow911Details {...props.selectedItem} />
                )}
            </Grid>
        </Grid>
    )
}
