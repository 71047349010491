import {
    BoosterConfig,
    SupportedContentBooster,
} from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import React, { FC } from 'react'

import { ChipGrid } from './ChipGrid'

interface CSOFilterPickerSectionProps {
    title: string
    chips: BoosterConfig[]
    activeChips: SupportedContentBooster[]
    availableChips: SupportedContentBooster[]
    onChipPress(value: string): void
}

export const CSOFilterPickerSection: FC<CSOFilterPickerSectionProps> = (
    props: CSOFilterPickerSectionProps
) => {
    return props.chips.length > 0 ? (
        <div>
            <Typography variant="h4" fontWeight="normal" pb="0.625rem">
                {props.title}
            </Typography>
            <ChipGrid
                chips={props.chips}
                activeChips={props.activeChips}
                availableChips={props.availableChips}
                onChipPress={props.onChipPress}
                showDeleteIcon
            />
        </div>
    ) : (
        <></>
    )
}
