import { Grid, Typography } from '@mui/material'
import React, { CSSProperties, FC } from 'react'

import { useStyles } from '../../lib/styles/universal'
import { Card, Markdown } from '../atoms'
import { colors } from '@hazadapt-git/public-core-base'

export interface WhatToKnow911DetailsProps {
    id: number
    containerStyle?: CSSProperties
    title: string
    content: string
    images?: React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >[]
}

export const WhatToKnow911Details: FC<WhatToKnow911DetailsProps> = (
    props: WhatToKnow911DetailsProps
) => {
    const { classes } = useStyles()
    return (
        <Card
            colors={[colors.grays.BLANC]}
            className={classes.whatToKnow911Card}
            style={props.containerStyle}
        >
            <Typography variant="h4" padding="1rem">
                {props.title}
            </Typography>
            <Markdown content={props.content} />
            {props.images && (
                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    justifyContent="center"
                >
                    {props.images.map(
                        (
                            image: React.DetailedHTMLProps<
                                React.ImgHTMLAttributes<HTMLImageElement>,
                                HTMLImageElement
                            >,
                            index: number
                        ) =>
                            image.src ? (
                                <Grid
                                    key={`whattoKnow911DetailImage_${
                                        index + 1
                                    }`}
                                    item
                                    xs={4}
                                >
                                    <img src={image.src} alt={image.alt} />
                                </Grid>
                            ) : (
                                <></>
                            )
                    )}
                </Grid>
            )}
        </Card>
    )
}
