import {
    PrepCheckUserSubmissionDTO,
    PrepCheckQuestionDTO,
    PrepCheckStatus,
} from '@hazadapt-git/public-core-base'
import { Grid } from '@mui/material'
import React, { FC } from 'react'
import { Checkbox } from '../atoms'

interface PrepCheckCheckboxesQuestionViewProps {
    submission?: PrepCheckUserSubmissionDTO
    updateSubmission(submission: PrepCheckUserSubmissionDTO): void
    question: PrepCheckQuestionDTO
    prepCheckId: number
}

export const PrepCheckCheckboxesQuestionView: FC<
    PrepCheckCheckboxesQuestionViewProps
> = (props: PrepCheckCheckboxesQuestionViewProps) => {
    const isChecked = (answer_id: number) => {
        if (!props.submission) {
            return false
        }
        const currentQuestion = props.submission.submitted_responses.find(
            (sr) => props.question.id === sr.question_id
        )
        if (!currentQuestion) {
            return false
        }
        const answer = currentQuestion.user_answer.find(
            (ua) => answer_id === ua.answer_id
        )

        return !!answer
    }
    const onCheckboxClick = (answer_id: number): void => {
        // Get draft submission object
        let draft_submission: PrepCheckUserSubmissionDTO
        if (props.submission) {
            draft_submission = { ...props.submission }
        } else {
            draft_submission = {
                prep_check_id: props.prepCheckId,
                status: PrepCheckStatus.DRAFT,
                submitted_responses: [],
            }
        }

        // Find submitted response for this question
        const idx = draft_submission.submitted_responses.findIndex(
            (sr) => sr.question_id === props.question.id
        )

        if (idx >= 0) {
            // User has previously started this question; use existing response data
            const checkedIdx = draft_submission.submitted_responses[
                idx
            ].user_answer.findIndex((ua) => ua.answer_id === answer_id)
            if (checkedIdx >= 0) {
                // User previously checked item; remove from list
                draft_submission.submitted_responses[idx].user_answer.splice(
                    checkedIdx,
                    1
                )
            } else {
                // User did not previously check item; add to list
                draft_submission.submitted_responses[idx].user_answer.push({
                    answer_id,
                    answer: {
                        id: answer_id,
                        checked: true,
                    },
                })
            }
        } else {
            // User has not previously started this question; build new response data for this question and add item to list
            draft_submission.submitted_responses.push({
                question_id: props.question.id,
                user_answer: [
                    {
                        answer_id,
                        answer: {
                            id: answer_id,
                            checked: true,
                        },
                    },
                ],
            })
        }

        props.updateSubmission(draft_submission)
    }

    return (
        <Grid
            container
            width={'100%'}
            maxWidth={'75rem'}
            margin={'0 auto'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            columnSpacing={2}
            rowSpacing={1}
        >
            {props.question.options.map((item) => (
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={`prepCheck_checkbox_${item.id}`}
                >
                    <Checkbox
                        label={item.text}
                        onChange={() => onCheckboxClick(item.id)}
                        defaultChecked={isChecked(item.id)}
                    />
                </Grid>
            ))}
        </Grid>
    )
}
