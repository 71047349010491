import { colors } from '@hazadapt-git/public-core-base'
import { Tab, Tabs } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'

import {
    customHazTealColor,
    customMidGrayColor,
} from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'

export interface TabProps {
    id: number
    icon?: React.ReactElement
    label: string
    scene?: React.ReactNode
    onPress(id: number): void
}

export interface TabGroupProps {
    tabs: TabProps[]
    value: number
    sceneClassName?: string
    visible?: boolean
}

export const TabGroup: FC<TabGroupProps> = (props: TabGroupProps) => {
    const { classes } = useLocalStyles()
    const renderTabPanel = (tab: TabProps, value: any) => (
        <div
            key={`${tab.label}_scene`}
            className={classNames(classes.tabScene, props.sceneClassName)}
            style={{
                display: value === tab.id ? 'block' : 'none',
            }}
        >
            {tab.scene}
        </div>
    )
    const mediumWindowOrLarger = useWindowSizeUp('md')

    return (
        <div
            style={{
                width: '100%',
                display:
                    props.visible === undefined || props.visible
                        ? 'block'
                        : 'none',
            }}
        >
            <Tabs
                value={props.value}
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
                variant="fullWidth"
                textColor="secondary"
            >
                {props.tabs.map((tab: TabProps, index: number) => (
                    <Tab
                        value={tab.id}
                        key={`${tab.label}_tab`}
                        icon={tab.icon}
                        iconPosition="start"
                        label={tab.label}
                        onClick={() => tab.onPress(tab.id)}
                        style={{
                            color:
                                props.value === tab.id
                                    ? customHazTealColor
                                    : customMidGrayColor,
                            textTransform: 'none',
                            fontSize: mediumWindowOrLarger ? '1.25rem' : '1rem',
                            borderStyle: 'solid',
                            borderTopColor:
                                props.value === tab.id
                                    ? customHazTealColor
                                    : customMidGrayColor,
                            borderLeftColor:
                                props.value === tab.id
                                    ? customHazTealColor
                                    : customMidGrayColor,
                            borderRightColor:
                                props.value === tab.id
                                    ? customHazTealColor
                                    : customMidGrayColor,
                            borderTopWidth: 1,
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: props.value === tab.id ? 0 : 1,
                            borderBottomColor: customHazTealColor,
                            backgroundColor:
                                props.value === tab.id
                                    ? colors.grays.BLANC
                                    : colors.grays.SILK,
                            borderTopLeftRadius: index === 0 ? '2rem' : 0,
                            borderTopRightRadius:
                                index === props.tabs.length - 1 ? '2rem' : 0,
                        }}
                    />
                ))}
            </Tabs>
            {props.tabs
                .filter((tab: TabProps) => tab.scene !== undefined)
                .map((tab: TabProps) => renderTabPanel(tab, props.value))}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    tabScene: {
        borderBottomLeftRadius: '2rem',
        borderBottomRightRadius: '2rem',
        border: `1px ${customHazTealColor} solid`,
        borderTopWidth: 0,
        overflowY: 'auto',
        padding: '2rem',
    },
})
