import { getEnvVars } from '../config'
import { LanguageCode } from '@hazadapt-git/public-core-base'
import { logEvent } from './logging'

const { pdfUrl } = getEnvVars()

export const onPdfPress = async (
    hazard_id: number,
    slug: string,
    language: LanguageCode = LanguageCode.ENGLISH
) => {
    logEvent('OPEN_HAZARD_PDF', { hazard_id, language })
    window.open(
        `${pdfUrl}/${slug}.${language.toString()}.pdf`,
        '_blank',
        'noopener'
    )
}
