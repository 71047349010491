import React, { FC } from 'react'

import AppIconSVG from '../../assets/images/AppIcon.svg'
import { useStyles } from '../../lib/styles/universal'

interface AppIconProps {}

export const AppIcon: FC<AppIconProps> = () => {
    const { classes } = useStyles()
    return (
        <img
            src={AppIconSVG}
            className={classes.appIcon}
            alt="HazAdapt app icon"
        />
    )
}
