import { Grid, Typography } from '@mui/material'
import React, { FC } from 'react'

interface AboutTabProps {}

export const AboutTab: FC<AboutTabProps> = (props: AboutTabProps) => (
    <Grid container spacing={2} alignItems="center" height="100%">
        <Grid item xs={12} md={6.5} textAlign="center">
            <Typography variant="h4" component="p">
                You're using version {process.env.REACT_APP_VERSION} of
                HazAdapt, our first hazard adaption tool that we're continuing
                to grow.
            </Typography>
        </Grid>
        <Grid item xs={12} md={5.5}>
            <Typography sx={{ p: 2 }}>
                HazAdapt can help you know what to do during small and large
                emergencies, as well as when something is severe enough to call
                911 for help. We also provide helpful instructions and tips for
                recovering from an emergency and preparing for next time.
            </Typography>
            <Typography sx={{ p: 2 }}>
                HazAdapt is a wildly motivated graduate student
                group-turned-startup building the next evolution of emergency
                and public safety engagement technology. We partner with the
                public and local emergency response agencies for feedback every
                step of the way.
            </Typography>
        </Grid>
    </Grid>
)
