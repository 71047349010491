import { colors, TrendingHazardDTO } from '@hazadapt-git/public-core-base'
import { Skeleton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import TrendingHazardsIcon from '../../assets/icons/TrendingHazards.svg'
import { TrendingHazardItem } from '../molecules'

interface TrendingHazardItemListProps {
    data: TrendingHazardDTO[]
    showErrorText?: boolean
    onPress(id: number): void
    loading?: boolean
}

export const TrendingHazardItemList: FC<TrendingHazardItemListProps> = (
    props: TrendingHazardItemListProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div>
            <div className={localClasses.header}>
                <img
                    src={TrendingHazardsIcon}
                    className={localClasses.trendingHazardsIcon}
                    alt="Arrow trending up"
                />
                <Typography
                    variant="h4"
                    sx={{ color: colors.primary.CERULEAN }}
                >
                    Trending Hazards
                </Typography>
            </div>
            <div className={localClasses.list}>
                {props.loading ? (
                    [...Array(5)].map((_, index) => (
                        <div
                            style={{
                                marginRight: '1rem',
                                marginLeft: index === 0 ? '1rem' : 0,
                            }}
                            key={`trendingHazardItem_skeleton_${index + 1}`}
                        >
                            <Skeleton
                                variant="circular"
                                width="4.5rem"
                                height="4.5rem"
                                sx={{ margin: '0 auto' }}
                            />
                            <Skeleton
                                variant="rectangular"
                                width="5rem"
                                height="1rem"
                                sx={{ margin: '0.5rem auto 0' }}
                            />
                        </div>
                    ))
                ) : props.showErrorText ? (
                    <Typography
                        px="1rem"
                        sx={{ color: colors.grays.THUNDERCLOUD }}
                    >
                        Unable to load trending hazards.
                    </Typography>
                ) : (
                    props.data.map((item, index) => (
                        <div
                            style={{
                                marginRight: '1rem',
                                marginLeft: index === 0 ? '1rem' : 0,
                            }}
                            key={`trendingHazardItem_${item.id}`}
                        >
                            <TrendingHazardItem
                                {...item}
                                onPress={() => props.onPress(item.id)}
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        marginBottom: '1rem',
        marginLeft: '1rem',
        display: 'grid',
        gridTemplateColumns: '3rem auto',
        alignItems: 'center',
    },
    trendingHazardsIcon: {
        width: '2rem',
        height: 'auto',
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        overflowX: 'auto',
    },
})
