import {
    twitterURL,
    facebookURL,
    instagramURL,
    linkedinURL,
    websiteURL,
    hazAdaptOnAppStoreURL,
    hazAdaptOnGooglePlayURL,
    colors,
} from '@hazadapt-git/public-core-base'
import { Typography, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import {
    FaTwitter,
    FaFacebook,
    FaInstagram,
    FaLinkedinIn,
    FaGlobe,
} from 'react-icons/fa'

import AppStore from '../../assets/images/DownloadOnTheAppStore.svg'
import GooglePlay from '../../assets/images/GetOnGooglePlay.svg'
import { customDarkBlueColor } from '../../lib/styles/universal'
import {
    logFacebookHit,
    logAppStoreLinkHit,
    logGooglePlayLinkHit,
    logWebsiteHit,
    logInstagramHit,
    logLinkedinHit,
    logTwitterHit,
} from '../../lib/utils'
import { AppIcon, CompanyLogo } from '../atoms'

interface FooterProps {}

export const Footer: FC<FooterProps> = (props: FooterProps) => {
    const { classes } = useLocalStyles()
    return (
        <div className={classes.footer}>
            <CompanyLogo style={{ margin: '1rem' }} height="3rem" />
            <AppIcon />
            <Typography
                component="p"
                variant="h4"
                p="1rem"
                textAlign="center"
                sx={{ color: colors.grays.NOIR }}
            >
                Access the Hazard Guide, even when you're offline.
            </Typography>
            <div style={{ display: 'flex' }}>
                <Link
                    href={hazAdaptOnAppStoreURL}
                    rel="noopener"
                    target="_blank"
                    onClick={logAppStoreLinkHit}
                    aria-label="appStoreLink"
                >
                    <img
                        src={AppStore}
                        className={classes.appStoreIcon}
                        style={{
                            paddingRight: '1rem',
                        }}
                        onClick={logAppStoreLinkHit}
                        alt="Download on the App Store icon"
                    />
                </Link>
                <Link
                    href={hazAdaptOnGooglePlayURL}
                    rel="noopener"
                    target="_blank"
                    onClick={logGooglePlayLinkHit}
                    aria-label="playStoreLink"
                >
                    <img
                        src={GooglePlay}
                        className={classes.appStoreIcon}
                        style={{
                            paddingLeft: '1rem',
                        }}
                        onClick={logGooglePlayLinkHit}
                        alt="Get It on Google Play icon"
                    />
                </Link>
            </div>
            <Typography
                pt={2}
                textAlign="center"
                sx={{ color: colors.grays.NOIR }}
            >
                Copyright &copy; {new Date().getFullYear()} HazAdapt, Inc.
            </Typography>
            <div className={classes.iconContainer}>
                <div className={classes.icon}>
                    <Link
                        href={twitterURL}
                        rel="noopener"
                        target="_blank"
                        onClick={logTwitterHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aria-label="twitterLink"
                    >
                        <FaTwitter color={colors.grays.SILK} size="1.2rem" />
                    </Link>
                </div>
                <div className={classes.fbIcon}>
                    <Link
                        href={facebookURL}
                        rel="noopener"
                        target="_blank"
                        onClick={logFacebookHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aria-label="facebookLink"
                    >
                        <FaFacebook color={customDarkBlueColor} size="2rem" />
                    </Link>
                </div>
                <div className={classes.icon}>
                    <Link
                        href={instagramURL}
                        rel="noopener"
                        target="_blank"
                        onClick={logInstagramHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aria-label="instagramLabel"
                    >
                        <FaInstagram color={colors.grays.SILK} size="1.2rem" />
                    </Link>
                </div>
                <div className={classes.icon}>
                    <Link
                        href={linkedinURL}
                        rel="noopener"
                        target="_blank"
                        onClick={logLinkedinHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aria-label="linkedinLink"
                    >
                        <FaLinkedinIn color={colors.grays.SILK} size="1.2rem" />
                    </Link>
                </div>
                <div className={classes.icon}>
                    <Link
                        href={websiteURL}
                        rel="noopener"
                        target="_blank"
                        onClick={logWebsiteHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aria-label="websiteLink"
                    >
                        <FaGlobe color={colors.grays.SILK} size="1.2rem" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    footer: {
        width: '100%',
        backgroundColor: colors.grays.SILK,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconContainer: {
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
    },
    fbIcon: {
        margin: '1rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        cursor: 'pointer',
    },
    icon: {
        backgroundColor: customDarkBlueColor,
        borderRadius: '5rem',
        padding: '0.4rem',
        margin: '1rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        cursor: 'pointer',
    },
    appStoreIcon: {
        height: '3rem',
        width: 'auto',
    },
})
