import { Fab, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'

import EmergencySVG from '../../assets/icons/EmergencyButton.svg'

interface EmergencyButtonProps {
    onClick?(...props: any): void
    sx?: SxProps<Theme>
}

export const EmergencyButton: FC<EmergencyButtonProps> = (
    props: EmergencyButtonProps
) => (
    <Fab
        size="large"
        sx={{ ...props.sx, height: '5rem', width: '5rem' }}
        onClick={props.onClick}
    >
        <img
            src={EmergencySVG}
            style={{ width: '100%', height: '100%' }}
            alt="Emergency help button"
        />
    </Fab>
)
