import { Typography } from '@mui/material'
import React, { CSSProperties, FC } from 'react'
import { useWindowSizeUp } from '../../lib/utils'

import { customBorderRadius } from '../../lib/styles/universal'

export interface PanelProps {
    backgroundColor: string
    imageSrc: string
    imageAlt?: string
    title: string
    subtitle?: string
    textColor: string
    onPress(...props: any): void
    style?: CSSProperties
    flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
}

export const Panel: FC<PanelProps> = (props: PanelProps) => {
    const mediumWindowOrLarger = useWindowSizeUp('md')
    return (
        <div
            style={{
                ...props.style,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: props.backgroundColor,
                width: '100%',
                height: '100%',
                borderRadius: customBorderRadius,
                textAlign: 'center',
                cursor: 'pointer',
            }}
            onClick={props.onPress}
        >
            <div
                style={{
                    padding: '1.5rem',
                    display: 'flex',
                    flexDirection: props.flexDirection || 'column',
                    alignItems: 'center',
                }}
            >
                <img
                    src={props.imageSrc}
                    style={{
                        height: mediumWindowOrLarger ? '4rem' : '7rem',
                        width: 'auto',
                    }}
                    alt={props.imageAlt}
                />
                <div
                    style={{
                        paddingLeft: props.flexDirection === 'row' ? '1rem' : 0,
                        paddingRight:
                            props.flexDirection === 'row-reverse' ? '1rem' : 0,
                    }}
                >
                    <Typography
                        variant="h2"
                        color={props.textColor}
                        textAlign={
                            props.flexDirection === 'row'
                                ? 'left'
                                : props.flexDirection === 'row-reverse'
                                ? 'right'
                                : 'center'
                        }
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        color={props.textColor}
                        textAlign={
                            props.flexDirection === 'row'
                                ? 'left'
                                : props.flexDirection === 'row-reverse'
                                ? 'right'
                                : 'center'
                        }
                    >
                        {props.subtitle}
                    </Typography>
                </div>
            </div>
        </div>
    )
}
