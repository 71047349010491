import {
    BookmarkOverview,
    HazardOverview,
    LanguageCode,
    LoggedPage,
    NativeLanguageDTO,
    PromotedProductDTO,
} from '@hazadapt-git/public-core-base'
import * as clipboard from 'clipboard-polyfill'
import React, { FC } from 'react'
import { IoBookmark, IoCloudDownloadOutline, IoLanguage } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import { BookmarkPageTemplate } from '../components'
import {
    getHazardsThunk,
    removeBookmarkThunk,
    switchLanguageThunk,
} from '../lib/slices'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import {
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'
import {
    getCurrentLocation,
    getLangObjects,
    logEvent,
    onPdfPress,
    toast,
} from '../lib/utils'
import { PageProps } from '../lib/entities'
import {
    deleteProductBookmark,
    getBookmarkedProducts,
} from '../lib/utils/promoted-products'
import { IoIosLink } from 'react-icons/io'
import { readAsync } from '../lib/async-storage'

interface BookmarksPageProps extends PageProps {}

export const BookmarksPage: FC<BookmarksPageProps> = (
    props: BookmarksPageProps
) => {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()

    const { hazards, languages: supportedLanguages } = useAppSelector(
        (state: RootState) => state.hazards
    )
    const { language, profileReady, user } = useAppSelector(
        (state: RootState) => state.profile
    )
    const { bookmarkedHazards } = useAppSelector(
        (state: RootState) => state.bookmarks
    )

    const [hazardBookmarkOverviews, setHazardBookmarkOverviews] =
        React.useState<BookmarkOverview[]>([])
    const [bookmarkedProducts, setBookmarkedProducts] = React.useState<
        PromotedProductDTO[]
    >([])
    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)
    const [activeTab, setActiveTab] = React.useState<number>(1)

    React.useEffect(() => {
        if (!profileReady) return

        document.title = 'Bookmarks - HazAdapt'

        if (!user) return
        logEvent('OPEN_PAGE', { page: LoggedPage.BOOKMARKS })
        getBookmarkedProducts().then(setBookmarkedProducts).catch(console.error)
    }, [profileReady, user])

    React.useEffect(() => {
        const bookmarksToSort = [...bookmarkedHazards]
        bookmarksToSort.sort((a: BookmarkOverview, b: BookmarkOverview) => {
            if (a.local && !b.local) return -1
            else if (!a.local && b.local) return 1
            return a.slug < b.slug ? -1 : 1
        })
        setHazardBookmarkOverviews(bookmarksToSort)
    }, [bookmarkedHazards])

    const onRemoveHazardBookmark = (id: number) =>
        new Promise<void>((resolve) => {
            const bookmark = bookmarkedHazards.find((b) => b.hazard_id === id)
            if (bookmark) {
                const hazard = hazards.find(
                    (h: HazardOverview) => h.id === bookmark.hazard_id
                )
                if (!hazard) {
                    resolve()
                    return
                }
                dispatch(removeBookmarkThunk(bookmark.bookmark_id))
                    .then(() => {
                        logEvent('REMOVE_BOOKMARK', {
                            hazard_id: bookmark.hazard_id,
                        })
                        toast(
                            `Removed ${hazard.name} from your bookmarked hazards.`,
                            <IoBookmark
                                color={successColor}
                                size={primaryIconSize}
                            />
                        )
                    })
                    .catch(console.error)
                    .finally(resolve)
            } else {
                resolve()
            }
        })

    const onHazardPress = (id: number): void => {
        const hazard = hazards.find((h) => h.id === id)
        if (hazard) {
            navigate(`/hazards/${hazard.slug}`)
        }
    }

    const onShare = (id: number): void => {
        // TODO: Share logic
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onOptionsPress = (id: number): void => {
        // TODO: Option menu display logic
    }

    const onLanguageChange = async (language: LanguageCode) => {
        const langObject: NativeLanguageDTO = getLangObjects([language])[0]
        setLanguageSelectorModalOpen(false)
        toast(
            `Downloading hazard information in ${langObject.title}.`,
            <IoCloudDownloadOutline
                color={successColor}
                size={primaryIconSize}
            />
        )
        try {
            let position: GeolocationPosition | undefined
            try {
                const loCoOn = readAsync('@loCoOn') === 'true'
                if (loCoOn) position = await getCurrentLocation()
            } catch (err) {
                console.error(err)
            }
            await dispatch(switchLanguageThunk(language))
            await dispatch(getHazardsThunk({ language, position }))
            toast(
                `Information has been translated into ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to download hazard information in ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const onTranslatePress = () => {
        setLanguageSelectorModalOpen(true)
    }

    const onLanguageSelectorModalClose = () => {
        setLanguageSelectorModalOpen(false)
    }

    const onRemoveProductBookmark = async (product_id: number) => {
        if (!user) return
        try {
            const bookmarked_products = [...bookmarkedProducts]
            const index = bookmarked_products.findIndex(
                (p) => p.id === product_id
            )
            if (index < 0) return

            await deleteProductBookmark(product_id)
            toast(
                `Removed item from your bookmarked products.`,
                <IoBookmark color={successColor} size={primaryIconSize} />
            )

            bookmarked_products.splice(index, 1)
            setBookmarkedProducts(bookmarked_products)
        } catch (err) {
            console.error(err)
        }
    }

    const onProductPress = (product_id: number, url: string) => {
        const product = bookmarkedProducts.find((p) => p.id === product_id)
        logEvent('CLICK_PROMOTED_PRODUCT', {
            via: 'bookmarks',
            product_id,
            product_name: product?.name,
            language,
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            hazard_name: 'N/A (Bookmarks Page)',
            stage: 'N/A (Bookmarks Page)',
            affiliate_link: !!product?.affiliate_link,
        }).catch(console.error)

        window.open(url, '_blank', 'noopener') // Open the link in a new tab
    }

    const onProductAreaHover = (product_id: number) => {
        const product = bookmarkedProducts.find((p) => p.id === product_id)
        logEvent('HOVER_PROMOTED_PRODUCT', {
            product_id,
            product_name: product?.name,
            language,
            via: 'bookmarks',
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            hazard_name: 'N/A (Bookmarks Page)',
            stage: 'N/A (Bookmarks Page)',
            affiliate_link: !!product?.affiliate_link,
        }).catch(console.error)
    }

    const onProductAreaClick = (product_id: number) => {
        const product = bookmarkedProducts.find((p) => p.id === product_id)
        logEvent('CLICK_PROMOTED_PRODUCT_AREA', {
            product_id,
            product_name: product?.name,
            language,
            via: 'bookmarks',
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            hazard_name: 'N/A (Bookmarks Page)',
            stage: 'N/A (Bookmarks Page)',
            affiliate_link: !!product?.affiliate_link,
        }).catch(console.error)
    }

    const onProductShare = async (product_id: number, url: string) => {
        const product = bookmarkedProducts.find((p) => p.id === product_id)
        logEvent('SHARE_PROMOTED_PRODUCT', {
            product_id,
            product_name: product?.name,
            url,
            language,
            via: 'bookmarks',
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            hazard_name: 'N/A (Bookmarks Page)',
            stage: 'N/A (Bookmarks Page)',
            affiliate_link: !!product?.affiliate_link,
        })
        clipboard
            .writeText(url)
            .then(() => {
                toast(
                    'Link copied!',
                    <IoIosLink color={successColor} size={primaryIconSize} />
                )
            })
            .catch(console.error)
    }

    return (
        <BookmarkPageTemplate
            activeTab={activeTab}
            onTabPress={setActiveTab}
            loggedIn={!!user}
            bookmarkedHazards={hazardBookmarkOverviews.map((bookmark) => ({
                id: bookmark.hazard_id,
                slug: bookmark.slug,
                icon: bookmark.icon.src,
                name: bookmark.name,
                local: bookmark.local,
                creator: bookmark.creator,
                description: bookmark.description,
                filterChips: [],
                bookmarked: bookmarkedHazards.some(
                    (b) => b.hazard_id === bookmark.hazard_id
                ),
            }))}
            bookmarkedProducts={bookmarkedProducts}
            onHazardPress={onHazardPress}
            onPdfPress={(id, slug) => onPdfPress(id, slug, language)}
            onShare={onShare}
            onBookmarkHazard={onRemoveHazardBookmark}
            onBookmarkProduct={onRemoveProductBookmark}
            onProductPress={onProductPress}
            onProductAreaHover={onProductAreaHover}
            onProductAreaClick={onProductAreaClick}
            onProductShare={onProductShare}
            languages={supportedLanguages}
            selectedLanguage={language}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onTranslatePress={onTranslatePress}
            loading={props.loading || !profileReady}
        />
    )
}
