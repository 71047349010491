import {
    colors,
    Image,
    PrepCheckComponentRating,
} from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { Markdown } from '../atoms'

export interface PrepCheckResultPreviewItemPropsBase {
    questionId: number
    questionName: string
    componentRating: PrepCheckComponentRating
    questionText: string
    questionIcon?: Image
    breakdownText: string
    previewText?: string
}

interface PrepCheckResultPreviewItemProps
    extends PrepCheckResultPreviewItemPropsBase {
    onToggle(id: number): void
    open: boolean
}

export const PrepCheckResultPreviewItem: FC<PrepCheckResultPreviewItemProps> = (
    props: PrepCheckResultPreviewItemProps
) => {
    const { classes } = useLocalStyles()

    const getComponentRatingTextColor = (
        componentRating: PrepCheckComponentRating
    ): string => {
        let componentRatingTextColor: string
        switch (componentRating) {
            case PrepCheckComponentRating.LOW:
                componentRatingTextColor = colors.primary.CORAL
                break
            case PrepCheckComponentRating.MEDIUM:
                componentRatingTextColor = colors.secondary.HEAT_WAVE
                break
            case PrepCheckComponentRating.HIGH:
                componentRatingTextColor = colors.primary.WAIKATO
                break
            default:
                componentRatingTextColor = colors.grays.NOIR
                break
        }
        return componentRatingTextColor
    }

    return (
        <div className={classes.container}>
            <div
                className={classes.header}
                onClick={() => props.onToggle(props.questionId)}
            >
                <Typography variant="h4">
                    {props.questionName}:{' '}
                    <span
                        style={{
                            color: getComponentRatingTextColor(
                                props.componentRating
                            ),
                        }}
                    >
                        {props.componentRating}
                    </span>
                </Typography>
                {props.open ? (
                    <IoChevronUp color={colors.primary.CERULEAN} size="1rem" />
                ) : (
                    <IoChevronDown
                        color={colors.primary.CERULEAN}
                        size="1rem"
                    />
                )}
            </div>
            {props.open && (
                <div className={classes.body}>
                    {props.questionIcon && (
                        <img
                            src={
                                props.questionIcon.url || props.questionIcon.src
                            }
                            alt={props.questionIcon.alt}
                            className={classes.icon}
                        />
                    )}
                    <div className={classes.resultInfo}>
                        <Typography>{props.questionText}</Typography>
                        {props.previewText && (
                            <Markdown content={props.previewText} />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        backgroundColor: colors.grays.WHITESMOKE,
        border: `1px ${colors.primary.HELIOTROPE} solid`,
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        cursor: 'pointer',
    },
    body: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '2rem',
    },
    icon: {
        height: '4.5rem',
        width: '5.5rem',
        objectFit: 'contain',
        objectPosition: 'center',
    },
    resultInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    hiddenBreakdownText: {
        display: 'none',
    },
    shownBreakdownText: {
        display: 'block',
    },
})
