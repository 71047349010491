import { LocalContentDTO } from '@hazadapt-git/public-core-base'

import React, { FC } from 'react'
import { Markdown } from '../atoms'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ElementContent } from 'react-markdown/lib/ast-to-react'

export interface LocalContentBlockAdditionProps {
    onLinkPress(url: string): void
    onHover: React.MouseEventHandler
    transformLinkUri?(
        href: string,
        children: ElementContent[],
        title: string | null
    ): string
    localContent: LocalContentDTO
}

export const LocalContentBlockAddition: FC<LocalContentBlockAdditionProps> = (
    props: LocalContentBlockAdditionProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div
            className={localClasses.locoBlockContainer}
            onMouseEnter={props.onHover}
        >
            <div className={localClasses.creatorHeader}>
                {props.localContent.creator?.logo && (
                    <img
                        className={localClasses.creatorLogo}
                        src={
                            props.localContent.creator.logo.url ??
                            props.localContent.creator.logo.src
                        }
                        alt={props.localContent.creator.logo.alt}
                    />
                )}
                <Typography fontWeight={500}>
                    {props.localContent.creator?.name}
                </Typography>
            </div>
            <div>
                <Markdown
                    content={props.localContent.body}
                    onLinkPress={props.onLinkPress}
                    transformLinkUri={props.transformLinkUri}
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    locoBlockContainer: {
        paddingLeft: '1rem',
    },
    creatorHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '.5rem',
    },
    creatorLogo: {
        height: '2.5rem',
        objectFit: 'contain',
        paddingRight: '1rem',
    },
})
