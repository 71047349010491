export interface PageProps {
    loading: boolean
}

export interface PickerItem<T> {
    label: string
    value: T
}

export type IconSize = 'small' | 'medium' | 'large' | 'xlarge'

export enum Tooltips {
    LOCAL_CONTENT_INTRO = 'Local_Content_Intro',
    DISABLE_AUTO_SHOW_NO_LOCO = '@DisableAutoShowNoLoCo',
}
