import {
    LanguageCode,
    LoggedPage,
    NativeLanguageDTO,
    getLangObjects,
} from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { EmergencyItem, MyEmergencyItemsPageTemplate } from '../components'
import { PageProps } from '../lib/entities'
import { getAllPrepChecksThunk, switchLanguageThunk } from '../lib/slices'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import { logEvent, login, toast } from '../lib/utils'
import {
    buildEmergencyItemLists,
    getEmergencyItems,
    toggleEmergencyItemStatus,
} from '../lib/utils/prep'
import { IoCloudDownloadOutline, IoLanguage } from 'react-icons/io5'
import {
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'

interface MyEmergencyItemsPageProps extends PageProps {}

export const MyEmergencyItemsPage: FC<MyEmergencyItemsPageProps> = (
    props: MyEmergencyItemsPageProps
) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [itemsUserHas, setItemsUserHas] = React.useState<EmergencyItem[]>([])
    const [itemsUserDoesNotHave, setItemsUserDoesNotHave] = React.useState<
        EmergencyItem[]
    >([])
    const [itemsRetrieved, setItemsRetrieved] = React.useState<boolean>(false)
    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)

    const { language, user, profileReady } = useAppSelector(
        (state: RootState) => state.profile
    )
    const { prep_checks } = useAppSelector((state: RootState) => state.prep)
    const { languages: supportedLanguages } = useAppSelector(
        (state: RootState) => state.hazards
    )

    React.useEffect(() => {
        document.title = 'My Emergency Items - HazAdapt'
    }, [])

    React.useEffect(() => {
        if (!profileReady) return

        logEvent('OPEN_PAGE', {
            page: LoggedPage.MY_EMERGENCY_ITEMS,
            language,
        })
    }, [language, profileReady])

    React.useEffect(() => {
        dispatch(getAllPrepChecksThunk(language))
    }, [dispatch, language])

    React.useEffect(() => {
        if (!props.loading && prep_checks.length > 0) {
            getEmergencyItems(language)
                .then((items) => {
                    const { yes, no } = buildEmergencyItemLists(
                        items,
                        prep_checks
                    )

                    yes.sort(sortEmergencyItems)
                    no.sort(sortEmergencyItems)

                    setItemsUserHas(yes)
                    setItemsUserDoesNotHave(no)
                })
                .catch(console.error)
                .finally(() => {
                    setItemsRetrieved(true)
                })
        }
    }, [language, prep_checks, props.loading])

    const sortEmergencyItems = (a: EmergencyItem, b: EmergencyItem) => {
        if (a.name < b.name) return -1
        else if (a.name > b.name) return 1
        else return 0
    }

    const onCheckItem = async (item_name: string) => {
        let idx = itemsUserHas.findIndex((i) => i.name === item_name)
        if (idx < 0) {
            // We don't have to wait for this to finish
            // because even if the API call fails, we can still change it locally
            // and on next refresh it will just revert to the old state on the frontend
            toggleEmergencyItemStatus(item_name, true)

            // item is in the user's "I Don't Have" list
            idx = itemsUserDoesNotHave.findIndex((i) => i.name === item_name)
            if (idx < 0) return
            const itemUserNowHas = itemsUserDoesNotHave.splice(idx, 1)[0]
            itemUserNowHas.userHasItem = true

            const newItemsUserHas = [...itemsUserHas, itemUserNowHas]
            newItemsUserHas.sort(sortEmergencyItems)

            setItemsUserDoesNotHave([...itemsUserDoesNotHave])
            setItemsUserHas([...newItemsUserHas])
        } else {
            // We don't have to wait for this to finish
            // because even if the API call fails, we can still change it locally
            // and on next refresh it will just revert to the old state on the frontend
            toggleEmergencyItemStatus(item_name, false)

            // item is in the user's "I Have" list
            idx = itemsUserHas.findIndex((i) => i.name === item_name)
            if (idx < 0) return
            const itemUserNowDoesNotHave = itemsUserHas.splice(idx, 1)[0]
            itemUserNowDoesNotHave.userHasItem = false

            const newItemsUserDoesNotHave = [
                ...itemsUserDoesNotHave,
                itemUserNowDoesNotHave,
            ]
            newItemsUserDoesNotHave.sort(sortEmergencyItems)

            setItemsUserHas([...itemsUserHas])
            setItemsUserDoesNotHave([...newItemsUserDoesNotHave])
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onShareItemsUserHas: React.MouseEventHandler = (
        e: React.MouseEvent
    ) => {
        // TODO
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onShareItemsUserDoesNotHave: React.MouseEventHandler = (
        e: React.MouseEvent
    ) => {
        // TODO
    }

    const onPrintItemLists: React.MouseEventHandler = (e: React.MouseEvent) => {
        // TODO
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onShareAllItemLists: React.MouseEventHandler = (
        e: React.MouseEvent
    ) => {
        // TODO
    }

    const onLogin = async () => {
        await login()
    }

    const onCloseLoginWall = () => {
        navigate('/')
    }

    const onLanguageChange = async (lang: LanguageCode) => {
        const langObject: NativeLanguageDTO = getLangObjects([lang])[0]
        setLanguageSelectorModalOpen(false)
        toast(
            `Downloading emergency items in ${langObject.title}.`,
            <IoCloudDownloadOutline
                color={successColor}
                size={primaryIconSize}
            />
        )
        try {
            await dispatch(switchLanguageThunk(lang))
            await dispatch(getAllPrepChecksThunk(lang))
            toast(
                `Emergency items have been translated into ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to download emergency items in ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const onTranslateClick = () => {
        setLanguageSelectorModalOpen(true)
    }

    const onLanguageSelectorModalClose = () => {
        setLanguageSelectorModalOpen(false)
    }

    return (
        <MyEmergencyItemsPageTemplate
            itemsUserHas={itemsUserHas}
            itemsUserDoesNotHave={itemsUserDoesNotHave}
            onCheckItem={onCheckItem}
            // onShareItemsUserHas={onShareItemsUserHas}
            // onShareItemsUserDoesNotHave={onShareItemsUserDoesNotHave}
            onPrintItemLists={onPrintItemLists}
            // onShareAllItemLists={onShareAllItemLists}
            showLoginWall={user === null}
            onCloseLoginWall={onCloseLoginWall}
            onLogin={onLogin}
            loading={props.loading || !itemsRetrieved}
            languages={supportedLanguages}
            selectedLanguage={language}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onTranslateClick={onTranslateClick}
        />
    )
}
