import { Grid } from '@mui/material'
import React, { FC } from 'react'

import { ContentBlockPreview, ContentBlockPreviewProps } from '../molecules'

export interface WhatToKnow911GridProps {
    items: ContentBlockPreviewProps[]
    selectedID?: number
}

export const WhatToKnow911Grid: FC<WhatToKnow911GridProps> = (
    props: WhatToKnow911GridProps
) => (
    <Grid container spacing={2}>
        {props.items.map((item: ContentBlockPreviewProps) => (
            <Grid
                key={`whatToKnow911Item_${item.id}`}
                item
                xs={12}
                sm={6}
                xl={4}
            >
                <ContentBlockPreview
                    {...item}
                    selected={props.selectedID === item.id}
                />
            </Grid>
        ))}
    </Grid>
)
