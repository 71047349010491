import React, { FC, CSSProperties } from 'react'
import { colors } from '@hazadapt-git/public-core-base'
import { IconButton, Typography, Popover, Avatar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Markdown } from '../atoms'
import classNames from 'classnames'
import { useDraggable } from '@dnd-kit/core'
import { IoInformationCircle } from 'react-icons/io5'

export interface PrepCheckAnswerCardProps {
    id: number
    answerText: string
    questionType: 'OneD_Ordered_List' | 'TwoD_Grid_Placement'
    answerInfo?: string
    popoverPlacement?: 'left' | 'right' | 'center'
    popoverPaperStyle?: CSSProperties
    popoverTextStyle?: CSSProperties
    selected?: boolean
    image?: string
    onClick?(id: number): void
    shrinkToFit?: boolean
    draggable?: boolean
}

export const PrepCheckAnswerCard: FC<PrepCheckAnswerCardProps> = (
    props: PrepCheckAnswerCardProps
) => {
    const { classes } = useLocalStyles()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => setAnchorEl(null)
    const open = Boolean(anchorEl)

    const {
        attributes,
        listeners,
        setNodeRef: setDragRef,
        transform,
    } = useDraggable({ id: `card_${props.id}` })

    return props.questionType === 'OneD_Ordered_List' ? (
        <div
            onClick={
                props.onClick
                    ? (e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          props.onClick && props.onClick(props.id)
                      }
                    : undefined
            }
            className={classes.oneD_AnswerCard_Wrapper}
            ref={props.draggable ? setDragRef : undefined}
            {...attributes}
            {...listeners}
        >
            <Avatar
                className={classes.oneD_AnswerCard}
                sx={{
                    bgcolor: colors.grays.BLANC,
                    width: '4rem',
                    height: '4rem',
                    boxShadow: '0rem 0rem 0.125rem #000000',
                    border: '0.06rem solid black',
                    padding: '0.5rem',
                }}
            >
                <img
                    style={{
                        width: '3rem',
                        height: '3em',
                        objectFit: 'contain',
                    }}
                    src={props.image}
                    alt={props.image}
                />
            </Avatar>
            <Typography
                textAlign="center"
                width="4rem"
                fontFamily="Rubik"
                fontSize="0.875rem"
                fontWeight={500}
                margin="0 auto"
                sx={{ overflowWrap: 'normal' }}
            >
                {props.answerText}
            </Typography>
        </div>
    ) : (
        <div
            className={classNames(
                classes.card,
                props.selected ? classes.selectedCard : '',
                props.shrinkToFit ? classes.shrinkCardToFit : ''
            )}
            style={
                transform
                    ? {
                          visibility: 'hidden',
                      }
                    : undefined
            }
            ref={setDragRef}
            onClick={
                props.onClick
                    ? (e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          props.onClick && props.onClick(props.id)
                      }
                    : undefined
            }
            {...attributes}
            {...listeners}
        >
            <Typography
                variant="h5"
                fontStyle="normal"
                fontSize="1rem"
                textAlign="center"
            >
                {props.answerText}
            </Typography>
            {props.answerInfo && (
                <>
                    <div className={classes.infoIcon}>
                        <IconButton onClick={handleClick}>
                            <IoInformationCircle
                                color={colors.grays.THUNDERCLOUD}
                            />
                        </IconButton>
                    </div>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        slotProps={{
                            paper: {
                                style: {
                                    ...props.popoverPaperStyle,
                                    backgroundColor: colors.grays.WHITESMOKE,
                                    borderRadius: '1rem',
                                },
                            },
                        }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal:
                                props.popoverPlacement === 'left'
                                    ? 'right'
                                    : props.popoverPlacement === 'right'
                                    ? 'left'
                                    : 'center',
                        }}
                    >
                        <Markdown
                            className={classes.container}
                            content={props.answerInfo}
                        />
                    </Popover>
                </>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    card: {
        display: 'flex',
        height: 'fit-content',
        minHeight: '7rem',
        width: '8.5rem',
        border: '.0625rem solid',
        backgroundColor: colors.softTones.HELIOTROPE,
        borderColor: colors.grays.THUNDERCLOUD,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flex: '0 0 auto',
        boxSizing: 'border-box',
        borderRadius: '1rem',
        padding: '1rem',
        cursor: 'pointer',
        touchAction: 'manipulation',
    },
    shrinkCardToFit: {
        width: `min(30vw, 8.5rem)`,
    },
    selectedCard: {
        borderWidth: '0.25rem',
        borderColor: colors.primary.CERULEAN,
    },
    infoIcon: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    container: {
        margin: '1rem',
        width: 'fit-content',
        maxWidth: 'min(85vw, 40rem)',
    },
    oneD_AnswerCard: {
        display: 'flex',
        border: '0.06rem solid',
        marginBottom: '0.5rem',
    },
    oneD_AnswerCard_Wrapper: {
        cursor: 'pointer',
        touchAction: 'manipulation',
    },
})
