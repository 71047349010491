import React, { FC, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Typography } from '@mui/material'
import { localContentColor, useStyles } from '../../lib/styles/universal'
import { makeStyles } from 'tss-react/mui'
import { IoChevronDown, IoChevronForward, IoChevronUp } from 'react-icons/io5'
import { colors } from '@hazadapt-git/public-core-base'
import { Markdown } from '../atoms'
import classNames from 'classnames'

interface LocalContentTeaserProps {
    hasLoco: boolean
    locoEnabled: boolean
    locationAvailable: boolean
    onGoToLocalContent(): void
}

export const LocalContentTeaser: FC<LocalContentTeaserProps> = (
    props: LocalContentTeaserProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const { classes } = useStyles()
    const [dropDownOpen, setDropDownOpen] = useState(false)

    const onDropDownOpen = () => {
        setDropDownOpen(!dropDownOpen)
    }

    const onClick = () => {
        if (props.locoEnabled && props.locationAvailable && props.hasLoco) {
            props.onGoToLocalContent()
        } else if (props.locoEnabled && props.locationAvailable) {
            onDropDownOpen()
        }
    }

    return (
        <button
            className={classNames(
                classes.unstyledButton,
                localClasses.container
            )}
            style={{
                border:
                    props.hasLoco && props.locationAvailable
                        ? 0
                        : '1px solid green',
                backgroundColor:
                    props.hasLoco && props.locationAvailable
                        ? localContentColor
                        : 'white',
                cursor: !props.locationAvailable ? 'auto' : 'pointer',
            }}
            onClick={onClick}
            disabled={!props.locationAvailable}
        >
            {props.locationAvailable ? (
                props.locoEnabled ? (
                    props.hasLoco ? (
                        <>
                            <div className={localClasses.textContainer}>
                                <LocationOnIcon
                                    fontSize="large"
                                    style={{
                                        color: 'white',
                                        paddingRight: '0.625rem',
                                    }}
                                />
                                <div>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: 'white' }}
                                    >
                                        Your Current Location
                                    </Typography>
                                    <Typography
                                        fontWeight={500}
                                        sx={{ color: 'white' }}
                                    >
                                        Local Safety Information Available
                                    </Typography>
                                </div>
                            </div>
                            <IoChevronForward
                                fontSize="large"
                                color={colors.grays.BLANC}
                            />
                        </>
                    ) : (
                        <>
                            <div>
                                <Typography
                                    variant="h4"
                                    sx={{ color: localContentColor }}
                                >
                                    No Local Information Available
                                </Typography>
                                {dropDownOpen ? (
                                    <Markdown
                                        content={`To request hazard information for your area, send us a request [here](https://www.hazadapt.com/request-content).`}
                                        styleOverrides={{
                                            p: { marginBottom: 0 },
                                        }}
                                    />
                                ) : null}
                            </div>
                            {dropDownOpen ? (
                                <IoChevronUp
                                    fontSize="large"
                                    color={localContentColor}
                                />
                            ) : (
                                <IoChevronDown
                                    fontSize="large"
                                    color={localContentColor}
                                />
                            )}
                        </>
                    )
                ) : null
            ) : (
                <div>
                    <Typography variant="h4" sx={{ color: localContentColor }}>
                        No Local Information Available
                    </Typography>
                    <Typography
                        fontWeight={500}
                        sx={{ color: localContentColor }}
                    >
                        Location permission required.
                    </Typography>
                </div>
            )}
        </button>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '28rem',
        borderRadius: '0.25rem',
        padding: '1rem',
        boxShadow: `0 0.25rem 0.125rem -0.125rem ${colors.grays.NIMBUS}`,
        marginLeft: '1rem',
        gap: '1rem',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
})
