import { PrepCheckOverviewDTO, colors } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'
import React, { CSSProperties } from 'react'
import { useWindowSizeUp } from '../../lib/utils'
import { customBorderRadius, primaryIconSize } from '../../lib/styles/universal'
import { Card } from '../atoms'
import { AspectRatio } from 'react-aspect-ratio'

import PrepCheckShield from '../../assets/icons/PrepCheckShield.svg'
import { IconButton, Typography } from '@mui/material'
import { IoShareSocial } from 'react-icons/io5'

export interface PrepCheckContentBlockProps extends PrepCheckOverviewDTO {
    onBlockClick(id: number): void
    onShare(id: number): void
    aspectRatio?: number
    iconLocation?: 'top' | 'bottom'
    textAlign?: 'center' | 'left' | 'right'
    style?: CSSProperties
}

export const PrepCheckContentBlock = React.forwardRef<
    HTMLDivElement,
    PrepCheckContentBlockProps
>(
    (
        props: PrepCheckContentBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const smallWindowOrLarger = useWindowSizeUp('sm')
        const useLocalStyles = makeStyles()({
            contentBlock: {
                borderRadius: '1.5rem',
                cursor: 'pointer',
                position: 'relative',
            },
            contentBlockIcon: {
                height: smallWindowOrLarger ? '5rem' : '6rem',
                width: 'auto',
                maxHeight: smallWindowOrLarger ? '50%' : '60%',
                paddingBottom: smallWindowOrLarger ? '1rem' : 0,
                paddingRight: smallWindowOrLarger ? 0 : '1rem',
            },
            localPin: {
                marginRight: '0.5rem',
            },
        })

        const { classes } = useLocalStyles()
        const aspectRatio =
            props.aspectRatio || (smallWindowOrLarger ? 0.75 : 2)

        const imgSrc = props.light_icon
            ? props.light_icon.url ?? props.light_icon.src
            : props.dark_icon
            ? props.dark_icon.url ?? props.dark_icon.src
            : PrepCheckShield

        const onShare: React.MouseEventHandler = (e) => {
            e.preventDefault()
            e.stopPropagation()
            props.onShare(props.id)
        }

        return (
            <AspectRatio ratio={aspectRatio} style={{ width: '100%' }}>
                <Card
                    ref={ref}
                    colors={[colors.primary.HELIOTROPE]}
                    className={classes.contentBlock}
                    innerContainerStyle={{
                        borderRadius: customBorderRadius,
                        border: `0.25rem ${colors.primary.HELIOTROPE} solid`,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: smallWindowOrLarger
                            ? props.iconLocation === 'bottom'
                                ? 'column-reverse'
                                : 'column'
                            : 'row',
                        alignItems: 'center',
                        justifyContent:
                            props.textAlign === 'center'
                                ? 'center'
                                : props.textAlign === 'left'
                                ? 'flex-start'
                                : props.textAlign === 'right'
                                ? 'flex-end'
                                : smallWindowOrLarger
                                ? 'center'
                                : 'flex-start',
                        height: '100%',
                        position: 'relative',
                        padding: !smallWindowOrLarger
                            ? '3rem 1rem'
                            : '2rem 1rem',
                        ...props.style,
                    }}
                    onClick={() => props.onBlockClick(props.id)}
                >
                    <img
                        src={imgSrc}
                        className={classes.contentBlockIcon}
                        alt={
                            props.light_icon?.alt ??
                            props.dark_icon?.alt ??
                            'Prep Checks shield with yellow star and white graphical bars'
                        }
                    />
                    <Typography
                        component="p"
                        variant="h4"
                        textAlign={
                            props.textAlign ||
                            (smallWindowOrLarger ? 'center' : 'left')
                        }
                        fontStyle="normal"
                        color={colors.grays.BLANC}
                        sx={{
                            overflowY: 'auto',
                            wordBreak: 'break-word',
                            flex: smallWindowOrLarger ? undefined : 1,
                        }}
                    >
                        {props.title.includes('Prep Check')
                            ? props.title
                            : `${props.title} Prep Check`}
                    </Typography>
                    <IconButton
                        onClick={onShare}
                        data-testid={`hazard_prepCheck_${props.id}_share_btn`}
                        id={`hazard_prepCheck_${props.id}_share_btn`}
                        sx={{ position: 'absolute', right: 0, top: 0 }}
                    >
                        <IoShareSocial
                            color={colors.grays.BLANC}
                            size={primaryIconSize}
                        />
                    </IconButton>
                </Card>
            </AspectRatio>
        )
    }
)
