import {
    CrisisSupportOption,
    CSOResponse,
    ErrorMessage,
} from '@hazadapt-git/public-core-base'
import {
    ActionReducerMapBuilder,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit'

import { getCrisisSupportOptions } from '../utils/cso'

export interface CSOStoreState {
    csos: CrisisSupportOption[]
    date_modified: string
    error?: string | null
}

export const INITIAL_CSO_STORE_STATE: CSOStoreState = {
    error: null,
    csos: [],
    date_modified: new Date(2022, 1).toString(),
}

/** Gets the latest CSOs from the cloud */
export const getCSOsThunk = createAsyncThunk<
    CSOResponse | null,
    boolean,
    { rejectValue: ErrorMessage | string }
>('csos/get', async (requery: boolean, { rejectWithValue }) => {
    try {
        if (requery) {
            return await getCrisisSupportOptions()
        } else {
            return null
        }
    } catch (err: any) {
        console.error(err)
        return rejectWithValue(err)
    }
})

const csoSlice = createSlice({
    name: 'csos',
    initialState: INITIAL_CSO_STORE_STATE,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<CSOStoreState>) => {
        builder.addCase(getCSOsThunk.fulfilled, (state, action) => {
            if (action.payload) {
                state.csos = action.payload.alternatives
                state.date_modified = action.payload.date_modified
            }
            state.error = null
        })
        builder.addCase(getCSOsThunk.rejected, (state, action) => {
            state.error = action.payload
        })
    },
})

export default csoSlice.reducer
