import { ThemeProvider } from '@mui/material'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { Showcase } from './components/demos/Showcase'
import { store } from './lib/store'
import { theme } from './lib/styles/universal'
import { Router } from './routes'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                {process.env.REACT_APP_MODE === 'showcase' ? (
                    <Showcase />
                ) : (
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                )}
            </Provider>
        </ThemeProvider>
    )
}

export default App
