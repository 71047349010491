import { colors } from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { useWindowSizeUp } from '../../lib/utils'

import { PrepCheckCardDropItem, PrepCheckCardDropItemProps } from '../molecules'

export interface PrepCheckCardDropGridProps {
    data: PrepCheckCardDropItemProps[]
    gridType: 'OneD_Ordered_List' | 'TwoD_Grid_Placement'
    onDropSpotClick(id: number): void
    onFilledSpotClick(answer_id: number, dropIndex: number): void
    onClearSpot(id: number): void
    selectedId?: number
    draggedItem?: number
}

export const PrepCheckCardDropGrid: FC<PrepCheckCardDropGridProps> = (
    props: PrepCheckCardDropGridProps
) => {
    const largeWindowOrLarger = useWindowSizeUp('lg')
    const { classes: localClasses } = useLocalStyles()

    return props.gridType === 'OneD_Ordered_List' ? (
        <div
            className={
                largeWindowOrLarger
                    ? localClasses.oneD_LargeContainer
                    : localClasses.oneD_SmallContainer
            }
        >
            {props.data.map(
                (item: PrepCheckCardDropItemProps, index: number) => (
                    <PrepCheckCardDropItem
                        {...item}
                        key={`PrepCheckCardDropItem_${index}`}
                        onClear={props.onClearSpot}
                        onClick={
                            item.answerOption
                                ? (id) => props.onFilledSpotClick(id, index)
                                : undefined
                        }
                        selected={
                            !!props.selectedId &&
                            item.answerOption?.id === props.selectedId
                        }
                        dragging={props.draggedItem === item.answerOption?.id}
                    />
                )
            )}
        </div>
    ) : (
        <div className={localClasses.twoD_GridContainer}>
            <div className={localClasses.column}>
                <Typography
                    variant="h3"
                    sx={{ color: colors.primary.BLUEBERRY }}
                >
                    Before
                </Typography>
                {props.data.map(
                    (item: PrepCheckCardDropItemProps, index: number) =>
                        item.prepStage === 'Before' ? (
                            <PrepCheckCardDropItem
                                {...item}
                                key={`PrepCheckCardDropItem_${index}`}
                                onClick={
                                    item.answerOption
                                        ? (id) =>
                                              props.onFilledSpotClick(id, index)
                                        : props.onDropSpotClick
                                }
                                onClear={props.onClearSpot}
                                selected={
                                    item.answerOption &&
                                    props.selectedId === item.answerOption.id
                                }
                                dragging={
                                    props.draggedItem === item.answerOption?.id
                                }
                            />
                        ) : null
                )}
            </div>
            <div className={localClasses.column}>
                <Typography variant="h3" sx={{ color: colors.primary.CORAL }}>
                    During
                </Typography>
                {props.data.map(
                    (item: PrepCheckCardDropItemProps, index: number) =>
                        item.prepStage === 'During' ? (
                            <PrepCheckCardDropItem
                                {...item}
                                key={`PrepCheckCardDropItem_${index}`}
                                onClick={
                                    item.answerOption
                                        ? (id) =>
                                              props.onFilledSpotClick(id, index)
                                        : props.onDropSpotClick
                                }
                                onClear={props.onClearSpot}
                                selected={
                                    item.answerOption &&
                                    props.selectedId === item.answerOption.id
                                }
                                dragging={
                                    props.draggedItem === item.answerOption?.id
                                }
                            />
                        ) : null
                )}
            </div>
            <div className={localClasses.column}>
                <Typography variant="h3" sx={{ color: colors.primary.WAIKATO }}>
                    After
                </Typography>
                {props.data.map(
                    (item: PrepCheckCardDropItemProps, index: number) =>
                        item.prepStage === 'After' ? (
                            <PrepCheckCardDropItem
                                {...item}
                                key={`PrepCheckCardDropItem_${index}`}
                                onClick={
                                    item.answerOption
                                        ? (id) =>
                                              props.onFilledSpotClick(id, index)
                                        : props.onDropSpotClick
                                }
                                onClear={props.onClearSpot}
                                selected={
                                    item.answerOption &&
                                    props.selectedId === item.answerOption.id
                                }
                                dragging={
                                    props.draggedItem === item.answerOption?.id
                                }
                            />
                        ) : null
                )}
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    oneD_LargeContainer: {
        display: 'flex',
        gap: '1rem',
        paddingBottom: '1rem',
        flexWrap: 'wrap',
    },
    oneD_SmallContainer: {
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        paddingBottom: '1rem',
        flexWrap: 'wrap',
    },
    twoD_GridContainer: {
        display: 'grid',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gap: '1.5rem',
        padding: '0 1rem 1rem',
        width: 'fit-content',
        margin: '0 auto',
        overflow: 'auto',
    },
    column: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
    },
})
