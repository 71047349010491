import { colors, CommunitySponsor } from '@hazadapt-git/public-core-base'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { IconButton, Link, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import debounce from 'debounce'
import React, { FC } from 'react'

import { customDarkBlueColor } from '../../lib/styles/universal'

interface CommunitySponsorViewProps extends CommunitySponsor {
    onLinkPress(id: number, url: string): void
    onSupplementalLinkPress?(id: number, url: string): void
}

export const CommunitySponsorView: FC<CommunitySponsorViewProps> = (
    props: CommunitySponsorViewProps
) => {
    const { classes } = useLocalStyles()
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const [shouldExpand, setShouldExpand] = React.useState<boolean>(true)

    React.useEffect(() => {
        const hasClamping = (el: HTMLElement) => {
            const { clientHeight, scrollHeight } = el
            return clientHeight !== scrollHeight
        }

        const checkButtonAvailability = () => {
            const message = document.getElementById(`cs_message_${props.id}`)
            if (message) {
                // Save current state to reapply later if necessary.
                const hadClampClass = message.classList.contains('clamped')
                // Make sure that CSS clamping is applied if aplicable.
                if (!hadClampClass) {
                    message.classList.add('clamped')
                }
                // Check for clamping and show or hide button accordingly.
                setShouldExpand(hasClamping(message))
                // Sync clamping with local state.
                if (!hadClampClass) {
                    message.classList.remove('clamped')
                }
            }
        }

        const debouncedCheck = debounce(checkButtonAvailability, 50)

        checkButtonAvailability()
        window.addEventListener('resize', debouncedCheck)

        return () => {
            window.removeEventListener('resize', debouncedCheck)
        }
    }, [props.id])

    return (
        <div className={classes.container}>
            {props.logo && (
                <img
                    src={props.logo.url || props.logo.src}
                    alt={props.logo.alt || `${props.name} logo`}
                    style={{
                        maxHeight: '5rem',
                        width: 'auto',
                        maxWidth: '100%',
                        paddingBottom: '1rem',
                    }}
                />
            )}
            <Typography
                variant="h3"
                textAlign="center"
                pb={props.geo ? 0 : '0.625rem'}
            >
                {props.name}
            </Typography>
            {props.geo && (
                <Typography
                    variant="h5"
                    fontWeight={500}
                    fontStyle="normal"
                    textAlign="center"
                    sx={{
                        color: colors.primary.WAIKATO,
                        fontSize: '1rem',
                    }}
                    pb="0.625rem"
                >
                    Based on Your Location
                </Typography>
            )}
            {props.message && (
                <Typography
                    variant="h5"
                    id={`cs_message_${props.id}`}
                    className={shouldExpand && !expanded ? classes.clamped : ''}
                    textAlign="center"
                    maxHeight={!expanded ? '2.5rem' : undefined}
                    mb="1rem"
                >
                    {props.message}
                </Typography>
            )}
            {shouldExpand && (
                <IconButton onClick={() => setExpanded(!expanded)}>
                    {expanded ? (
                        <IoChevronUp size="1.25rem" color={colors.grays.NOIR} />
                    ) : (
                        <IoChevronDown
                            size="1.25rem"
                            color={colors.grays.NOIR}
                        />
                    )}
                </IconButton>
            )}
            {props.extra_links.map((el) => (
                <Link
                    py="0.5rem"
                    onClick={() =>
                        props.onSupplementalLinkPress &&
                        props.onSupplementalLinkPress(props.id, el.link)
                    }
                    sx={{
                        color: customDarkBlueColor,
                        textDecorationColor: customDarkBlueColor,
                    }}
                >
                    {el.name}
                </Link>
            ))}
            {props.link && (
                <Link
                    py="0.5rem"
                    onClick={() =>
                        props.link && props.onLinkPress(props.id, props.link)
                    }
                    sx={{
                        color: customDarkBlueColor,
                        textDecorationColor: customDarkBlueColor,
                    }}
                >
                    Learn More About This Sponsor
                </Link>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    clamped: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        lineClamp: 2,
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'calc(90%)',
    },
})
