import { Masonry } from '@mui/lab'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { useWindowSizeUp } from '../../lib/utils'

import { PrepCheckAnswerCard, PrepCheckAnswerCardProps } from '../molecules'

export interface PrepCheckAnswerCardGridProps {
    data: PrepCheckAnswerCardProps[]
    gridType: 'OneD_Ordered_List' | 'TwoD_Grid_Placement'
    selectedItemId?: number
}

export const PrepCheckAnswerCardGrid: FC<PrepCheckAnswerCardGridProps> = (
    props: PrepCheckAnswerCardGridProps
) => {
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const { classes: localClasses } = useLocalStyles()

    return props.gridType === 'OneD_Ordered_List' ? (
        <div
            className={
                mediumWindowOrLarger
                    ? localClasses.oneD_LargeContainer
                    : localClasses.oneD_SmallContainer
            }
        >
            {props.data.map((item: PrepCheckAnswerCardProps, index: number) => (
                <PrepCheckAnswerCard
                    {...item}
                    key={`PrepCheckCardDropItem_${index}`}
                />
            ))}
        </div>
    ) : mediumWindowOrLarger ? (
        <Masonry columns={3} spacing={2} sx={{ width: '30rem' }}>
            {props.data.map((item: PrepCheckAnswerCardProps, index: number) => (
                <PrepCheckAnswerCard
                    {...item}
                    key={`PrepCheckCardDropItem_${index}`}
                />
            ))}
            {props.data.length < 3 && <div style={{ order: 3 }} />}
        </Masonry>
    ) : (
        <div
            className={
                mediumWindowOrLarger
                    ? localClasses.twoD_GridContainer
                    : localClasses.twoD_ListContainer
            }
        >
            {props.data.map((item: PrepCheckAnswerCardProps, index: number) => (
                <PrepCheckAnswerCard
                    {...item}
                    key={`PrepCheckCardDropItem_${index}`}
                />
            ))}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    oneD_LargeContainer: {
        display: 'flex',
        gap: '1rem',
        paddingBottom: '1rem',
        flexWrap: 'wrap',
    },
    oneD_SmallContainer: {
        display: 'flex',
        gap: '1rem',
        width: '100%',
        paddingBottom: '1rem',
        paddingTop: '1rem',
        flexWrap: 'wrap',
    },
    twoD_GridContainer: {
        display: 'grid',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gap: '1rem',
    },
    twoD_ListContainer: {
        display: 'flex',
        gap: '1rem',
        paddingBottom: '1rem',
        paddingTop: '1rem',
    },
})
