import React, { CSSProperties, FC } from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useWindowSizeUp } from '../../lib/utils'

interface PrepCheckBadgeDetailsProps {
    badgeSrc: string
    badgeName: string
    badgeDescription?: string
}

export const PrepCheckBadgeDetails: FC<PrepCheckBadgeDetailsProps> = (
    props: PrepCheckBadgeDetailsProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const mediumScreens = useWindowSizeUp('md')
    const badgeStyle: CSSProperties = mediumScreens
        ? {
              height: '8rem',
              width: '8rem',
              objectFit: 'contain',
          }
        : {
              height: '6rem',
              width: '6rem',
              objectFit: 'contain',
          }

    return (
        <div className={localClasses.wrapper}>
            <img
                src={props.badgeSrc}
                style={badgeStyle}
                alt={`${props.badgeName} icon`}
            />
            <div className={localClasses.textWrapper}>
                <Typography variant={mediumScreens ? 'h2' : 'h3'}>
                    {props.badgeName}
                </Typography>
                {props.badgeDescription && (
                    <Typography>{props.badgeDescription}</Typography>
                )}
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
})
