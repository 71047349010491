import {
    PrepCheckQuestionDTO,
    PrepCheckUserStats,
    Image,
    LanguageCode,
    colors,
    PrepCheckUserSubmissionDTO,
    getLangObjects,
} from '@hazadapt-git/public-core-base'
import {
    IconButton,
    Typography,
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    DialogTitle,
    CircularProgress,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { FC } from 'react'
import {
    LanguageSelectorModal,
    PrepCheck1DQuestionView,
    PrepCheck2DQuestionView,
    PrepCheckBanner,
    PrepCheckCheckboxesQuestionView,
} from '../organisms'
import {
    PrepCheckCardDropItemProps,
    PrepCheckProgressBar,
    PrepCheckQuestionHeading,
} from '../molecules'
import { Markdown } from '../atoms'
import { makeStyles } from 'tss-react/mui'

interface PrepCheckPageTemplateProps {
    name: string
    icon?: Image
    questionNumber: number
    totalQuestions: number
    language: LanguageCode
    question: PrepCheckQuestionDTO
    userStats: PrepCheckUserStats
    userAnswers: PrepCheckCardDropItemProps[]
    onPreviousQuestionClick: React.MouseEventHandler
    onNextQuestionClick: React.MouseEventHandler
    prepCheckExplainerText?: string
    onDoneClick: React.MouseEventHandler
    submission?: PrepCheckUserSubmissionDTO
    updateSubmission(submission: PrepCheckUserSubmissionDTO): void
    prepCheckId: number
    showLoginWall?: boolean
    onCloseLoginWall: React.MouseEventHandler
    onLogin: React.MouseEventHandler
    showTutorial?: boolean
    onQuestionExplainerClose?: React.MouseEventHandler
    savingInProgress?: boolean
    savedSuccessfully?: boolean
    loading?: boolean
    onTranslateClick?: React.MouseEventHandler
    languages: LanguageCode[]
    selectedLanguage: LanguageCode
    languageSelectorModalOpen?: boolean
    onLanguageSelectorModalClose: React.MouseEventHandler
    onLanguageChange(lang: LanguageCode): void
}

export const PrepCheckPageTemplate: FC<PrepCheckPageTemplateProps> = (
    props: PrepCheckPageTemplateProps
) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )
    const [showSavedCopy, setShowSavedCopy] = React.useState<boolean>(false)
    // const handleClickDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(e.currentTarget)
    // }
    const handleCloseDialog = () => setAnchorEl(null)
    const openDialog = Boolean(anchorEl)

    const { classes: localClasses } = useLocalStyles()
    const explainerButtonRef = React.useRef<HTMLButtonElement>(null)

    const updateSubmission = (submission: PrepCheckUserSubmissionDTO) => {
        if (!showSavedCopy) setShowSavedCopy(true)
        props.updateSubmission(submission)
    }

    return props.loading ? (
        <div className={localClasses.loading}>
            <CircularProgress size="1.5rem" />
            <Typography variant="h4">Loading Prep Check...</Typography>
        </div>
    ) : (
        <div>
            <PrepCheckBanner
                icon={props.icon}
                prepCheckName={props.name}
                journeyPhase={props.userStats.journeyPhase}
                prepChecksCompleted={props.userStats.numCompleted}
                totalPrepChecks={props.userStats.numTotal}
                loading={props.loading}
                onTranslateClick={props.onTranslateClick}
            />
            <div className={localClasses.container}>
                {/* <Typography
                    variant="h1"
                    textAlign="left"
                    fontSize="2rem"
                    color={customDarkBlueColor}
                >
                    {props.name}
                    {props.name.includes('Prep Check') ? '' : ' Prep Check'}
                    {props.prepCheckExplainerText && (
                        <IconButton
                            sx={{ color: colors.primary.WAIKATO }}
                            onClick={handleClickDialog}
                        >
                            <Help />
                        </IconButton>
                    )}
                </Typography> */}

                <PrepCheckQuestionHeading
                    icon={props.question.image}
                    prepCheckQuestionText={`${props.questionNumber}. ${props.question.question}`}
                    prepCheckQuestionInstructions={
                        props.question.subheading
                            ? props.question.subheading
                            : undefined
                    }
                    prepCheckQuestionType={props.question.question_type}
                    explainerText={props.question.explainer_text}
                    explainerButtonRef={explainerButtonRef}
                    showTutorial={props.showTutorial}
                    onQuestionExplainerClose={props.onQuestionExplainerClose}
                    showSavedCopy={showSavedCopy}
                    savedSuccessfully={props.savedSuccessfully}
                    savingInProgress={props.savingInProgress}
                />
                {props.question.question_type === 'Checkboxes' ? (
                    // map over checkbox answers
                    // make grid item
                    <PrepCheckCheckboxesQuestionView
                        submission={props.submission}
                        updateSubmission={updateSubmission}
                        question={props.question}
                        prepCheckId={props.prepCheckId}
                    />
                ) : props.question.question_type === 'OneD_Ordered_List' ? (
                    <PrepCheck1DQuestionView
                        userAnswers={props.userAnswers}
                        submission={props.submission}
                        updateSubmission={updateSubmission}
                        question={props.question}
                        activeQuestionIdx={props.questionNumber - 1}
                        prepCheckId={props.prepCheckId}
                        onNextQuestionClick={props.onNextQuestionClick}
                        showNextQuestionLink={
                            props.questionNumber < props.totalQuestions
                        }
                    />
                ) : (
                    <PrepCheck2DQuestionView
                        userAnswers={props.userAnswers}
                        submission={props.submission}
                        updateSubmission={updateSubmission}
                        question={props.question}
                        activeQuestionIdx={props.questionNumber - 1}
                        prepCheckId={props.prepCheckId}
                        onNextQuestionClick={props.onNextQuestionClick}
                        showNextQuestionLink={
                            props.questionNumber < props.totalQuestions
                        }
                    />
                )}
                <PrepCheckProgressBar
                    // Handle on back press
                    onBackPress={props.onPreviousQuestionClick}
                    // Handle on forward press
                    onForwardPress={props.onNextQuestionClick}
                    currentQuestion={props.questionNumber}
                    questionCount={props.totalQuestions}
                />
                {props.questionNumber === props.totalQuestions && (
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ alignSelf: 'center', color: colors.grays.BLANC }}
                        onClick={props.onDoneClick}
                    >
                        Done
                    </Button>
                )}
            </div>

            {/* Explainer text dialog */}
            {props.prepCheckExplainerText && (
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogContent>
                        <IconButton
                            onClick={handleCloseDialog}
                            sx={{
                                position: 'absolute',
                                top: '0.5rem',
                                right: '0.5rem',
                            }}
                        >
                            <Close />
                        </IconButton>
                        <Markdown content={props.prepCheckExplainerText} />
                    </DialogContent>
                </Dialog>
            )}

            {/* Login wall */}
            <Dialog
                open={!!props.showLoginWall}
                PaperProps={{ sx: { padding: '1rem' } }}
            >
                <DialogTitle sx={{ paddingRight: '2.5rem' }}>
                    <Typography variant="h3" component="p">
                        Log In to Continue
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You need to be logged in to do Prep Checks.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={props.onCloseLoginWall}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onLogin}
                    >
                        Log In
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Language selector modal */}
            <LanguageSelectorModal
                languages={getLangObjects(props.languages)}
                selectedLanguage={props.selectedLanguage}
                open={!!props.languageSelectorModalOpen}
                onClose={props.onLanguageSelectorModalClose}
                onLanguagePress={props.onLanguageChange}
            />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        padding: '1rem 1rem 3rem',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        gap: '1rem',
    },
    loading: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 0',
    },
})
