import {
    ErrorMessage,
    LanguageCode,
    User,
} from '@hazadapt-git/public-core-base'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { IoAlertCircle } from 'react-icons/io5'

import { isa } from '../api'
import { storeAsync } from '../async-storage'
import { errorColor, primaryIconSize } from '../styles/universal'
import { toast } from './misc'

export interface UpdateProfileObject {
    oldUser: User
    newUser: User
}

export const updateProfile = async (user: User): Promise<void> => {
    try {
        const response: AxiosResponse = await isa.put('/public/profile', {
            user,
        })

        if (response.status !== 200) {
            throw new Error(ErrorMessage.ISA_REQUEST_FAILED)
        }
    } catch (e) {
        throw new Error(ErrorMessage.ASYNC_STORAGE_REQUEST_FAILED)
    }
}

export const getProfile = async (): Promise<User> => {
    try {
        const response: AxiosResponse = await isa.get('/public/profile')
        if (response.status !== 200) {
            throw new Error(ErrorMessage.ISA_REQUEST_FAILED)
        }

        return response.data as User
    } catch (e) {
        throw new Error((e as AxiosError<any>).response?.data.error)
    }
}

/**
 * Get a signed URL for the user's profile picture
 * @returns string
 */
export const getProfilePicture = async (): Promise<string> => {
    try {
        const response: AxiosResponse<string> = await isa.get(
            '/public/profile/profile-picture'
        )

        if (response.status !== 200) {
            throw new Error(ErrorMessage.ISA_REQUEST_FAILED)
        }

        // Test that image file exists at URL (will jump into catch block if it fails)
        await axios.head(response.data)

        // Signed URL
        return response.data
    } catch (e) {
        console.error(e)
        throw ErrorMessage.FS_REQUEST_FAILED
    }
}

/**
 * Uploads the user's profile picture to S3
 * @param image the profile picture file
 * @returns a signed URL for the profile picture
 */
export const addProfilePicture = async (image: File): Promise<string> => {
    // Attach profile picture to the POST request
    const formData = new FormData()
    formData.append('profile', image)
    try {
        const response = await isa.post(
            '/public/profile/profile-picture',
            formData,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
                validateStatus: () => true,
            }
        )
        if (response.status !== 201) {
            throw new Error(response.data)
        }
        return response.data
    } catch (e: any) {
        console.error(e)
        toast(
            'There was an error uploading your profile picture. Please try again.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
        throw e
    }
}

/**
 * Deletes the user's profile picture
 */
export const deleteProfilePicture = async (): Promise<void> => {
    try {
        // Send the DELETE request
        const response: AxiosResponse = await isa.delete(
            '/public/profile/profile-picture'
        )
        if (response.status !== 200) {
            throw new Error(ErrorMessage.ISA_REQUEST_FAILED)
        }
    } catch (e) {
        console.error(e)
        throw new Error(ErrorMessage.FS_REQUEST_FAILED)
    }
}

export const switchLanguage = (lang: LanguageCode): void | DOMException => {
    storeAsync('@language', lang.toString())
}
