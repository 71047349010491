import { Grid, Skeleton } from '@mui/material'
import React, { FC } from 'react'
import { AspectRatio } from 'react-aspect-ratio'

import { customBorderRadius } from '../../lib/styles/universal'
import { useWindowSizeExact, useWindowSizeUp } from '../../lib/utils'
import { HazardCard, HazardCardDTO } from '../molecules'

interface HazardCardGridProps {
    data: HazardCardDTO[]
    onHazardHover?(id: number): void
    onHazardPress(id: number): void
    onCopyLinkPress(id: number): void
    onBookmark(id: number): void
    onPdfPress(id: number, slug: string): void
    onOptionsPress?(id: number): void
    onPrepCheckPress?(id: number): void
    loading?: boolean
}

export const HazardCardGrid: FC<HazardCardGridProps> = (
    props: HazardCardGridProps
) => {
    const xsWindowExact = useWindowSizeExact('xs')
    const smallWindowExact = useWindowSizeExact('sm')
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const aspectRatio = xsWindowExact ? 2 : smallWindowExact ? 4 : 0.6
    const numSkeletons = mediumWindowOrLarger ? 12 : 4
    return (
        <Grid container alignItems="center" spacing={2}>
            {props.loading
                ? Array.from(Array(numSkeletons)).map((_, i) => (
                      <Grid
                          item
                          xs={12}
                          md={3}
                          lg={2}
                          key={`hazardCard_placeholder_${i + 1}`}
                      >
                          <AspectRatio
                              ratio={aspectRatio}
                              style={{ width: '100%' }}
                          >
                              <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height="100%"
                                  sx={{ borderRadius: customBorderRadius }}
                              />
                          </AspectRatio>
                      </Grid>
                  ))
                : props.data.map((hazard: HazardCardDTO) => (
                      <Grid
                          item
                          xs={12}
                          md={3}
                          lg={2}
                          key={`hazardCard_${hazard.id}`}
                      >
                          <HazardCard
                              {...hazard}
                              onPress={props.onHazardPress}
                              onHover={props.onHazardHover}
                              onCopyLinkPress={props.onCopyLinkPress}
                              onBookmark={props.onBookmark}
                              onPdfPress={props.onPdfPress}
                              onOptionsPress={props.onOptionsPress}
                              onPrepCheckPress={props.onPrepCheckPress}
                          />
                      </Grid>
                  ))}
        </Grid>
    )
}
