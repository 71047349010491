import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { TextField, Typography } from '@mui/material'
import React, { FC } from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import { colors } from '@hazadapt-git/public-core-base'

interface DatePickerProps {
    label?: string
    placeholder?: string
    onChange(date: Date | null): void
    value: Date | null
    disabled?: boolean
    variant?: 'line' | 'box'
}

export const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => (
    <div>
        {props.label && (
            <Typography variant="h4" pb="0.625rem">
                {props.label}
            </Typography>
        )}
        <MuiDatePicker
            {...props}
            slots={{
                openPickerIcon: () => (
                    <IoCalendarOutline color={colors.primary.CERULEAN} />
                ),
                textField: (params) => (
                    <TextField
                        {...params}
                        variant={
                            props.variant === 'line' ? 'standard' : 'outlined'
                        }
                    />
                ),
            }}
        />
    </div>
)
