import { SupportedHazardStage } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'

import PrepareGray from '../../assets/icons/Prepare-Gray.svg'
import PrepareRed from '../../assets/icons/Prepare-Red.svg'
import ReactGray from '../../assets/icons/React-Gray.svg'
import ReactRed from '../../assets/icons/React-Red.svg'
import RecoverGray from '../../assets/icons/Recover-Gray.svg'
import RecoverRed from '../../assets/icons/Recover-Red.svg'
import { useWindowSizeUp } from '../../lib/utils'
import { HazardStage } from '../molecules'

export interface HazardStageGridProps {
    activeStage?: SupportedHazardStage
    onStagePress(stage: SupportedHazardStage): void
    stagesWithLoCo: SupportedHazardStage[]
}

export const HazardStageGrid: FC<HazardStageGridProps> = (
    props: HazardStageGridProps
) => {
    const smallWindowOrLarger = useWindowSizeUp('sm')
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: smallWindowOrLarger
                    ? 'center'
                    : 'space-between',
                gap: '1rem',
            }}
        >
            <HazardStage
                active={props.activeStage === 'Before'}
                name="Prepare"
                onPress={() => props.onStagePress('Before')}
                icon={
                    <img
                        src={
                            props.activeStage === 'Before'
                                ? PrepareRed
                                : PrepareGray
                        }
                        width={smallWindowOrLarger ? '100%' : '90%'}
                        alt="Prepare icon"
                    />
                }
                style={{
                    marginLeft: '0.5rem',
                    marginRight: smallWindowOrLarger ? '1.75rem' : 0,
                }}
                hasLocalContent={props.stagesWithLoCo.includes('Before')}
            />
            <HazardStage
                active={props.activeStage === 'During'}
                name="React"
                onPress={() => props.onStagePress('During')}
                icon={
                    <img
                        src={
                            props.activeStage === 'During'
                                ? ReactRed
                                : ReactGray
                        }
                        width={smallWindowOrLarger ? '100%' : '90%'}
                        alt="React icon"
                    />
                }
                style={{
                    marginRight: smallWindowOrLarger ? '1.75rem' : 0,
                    marginLeft: smallWindowOrLarger ? '1.75rem' : 0,
                }}
                hasLocalContent={props.stagesWithLoCo.includes('During')}
            />
            <HazardStage
                active={props.activeStage === 'After'}
                name="Recover"
                onPress={() => props.onStagePress('After')}
                icon={
                    <img
                        src={
                            props.activeStage === 'After'
                                ? RecoverRed
                                : RecoverGray
                        }
                        width={smallWindowOrLarger ? '100%' : '90%'}
                        alt="Recover icon"
                    />
                }
                style={{
                    marginLeft: smallWindowOrLarger ? '1.75rem' : 0,
                    marginRight: '0.5rem',
                }}
                hasLocalContent={props.stagesWithLoCo.includes('After')}
            />
        </div>
    )
}
