import {
    FilterConfig,
    HazardSortingMethod,
    LanguageCode,
    SupportedHazardFilter,
    colors,
} from '@hazadapt-git/public-core-base'
import { Grid, IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'

import Translate from '../../assets/icons/Translate-Outline.svg'
import { PickerItem } from '../../lib/entities'
import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'
import {
    getLangObjects,
    openHazardRequestFormAsync,
    useWindowSizeUp,
} from '../../lib/utils'
import { Picker } from '../atoms'
import {
    ChipGrid,
    HazardCardDTO,
    DidYouMean,
    LocalContentSwitch,
} from '../molecules'
import {
    HazardCardGrid,
    HazardCategoryGrid,
    LanguageSelectorModal,
    NoSearchResultsView,
} from '../organisms'
import { IoOptions } from 'react-icons/io5'

interface HazardGuidePageTemplateProps {
    hazardGuideData: HazardCardDTO[]
    onHazardPress(id: number): void
    onHazardHover?(id: number): void
    onCopyLinkPress(id: number): void
    onBookmark(id: number): void
    onPdfPress(id: number, slug: string): void
    activeCategories: string[]
    filters: FilterConfig[]
    availableFilters: SupportedHazardFilter[]
    onCategoryPress(category: string): void
    activeFilters: SupportedHazardFilter[]
    onChipPress(name: string): void
    onChipReset(...props: any): void
    onLocalContentToggle(): void
    onTranslatePress(): void
    noSearchResults: boolean
    loading: boolean
    selectedLanguage: LanguageCode
    languageSelectorModalOpen: boolean
    onLanguageSelectorModalClose(): void
    onLanguageChange(lang: LanguageCode): void
    languages: LanguageCode[]
    searchQuery?: string
    suggestedQuery?: string
    onSuggestionPress(...props: any): void
    locoEnabled: boolean
    showNoLocalContentPopover?: boolean
    onCloseNoLocalContentPopover: React.MouseEventHandler
    localContentPopoverTextOverride?: string
    sortMethod: HazardSortingMethod
    onSortingMethodChange(method: HazardSortingMethod): void
    filtersExpanded: boolean
    onFilterViewToggle(open: boolean): void
}

export const HazardGuidePageTemplate: FC<HazardGuidePageTemplateProps> = (
    props: HazardGuidePageTemplateProps
) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const largeWindowOrLarger = useWindowSizeUp('lg')

    const SORTING_OPTIONS: PickerItem<HazardSortingMethod>[] = [
        {
            label: 'A to Z',
            value: 'asc',
        },
        {
            label: 'Z to A',
            value: 'desc',
        },
        {
            label: 'Relevance',
            value: 'relevance',
        },
    ]

    return (
        <div
            className={classes.page}
            style={{
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginBottom: '3rem',
            }}
        >
            <Grid
                container
                spacing={0}
                alignItems="center"
                paddingBottom="0.625rem"
            >
                <Grid item xs={2} sm={4} xl={5} />
                <Grid
                    item
                    xs={8}
                    sm={4}
                    xl={2}
                    display="flex"
                    alignItems="center"
                    textAlign="center"
                    justifyContent="flex-start"
                >
                    <Typography
                        variant={largeWindowOrLarger ? 'h1' : 'h2'}
                        component="h1"
                        width="100%"
                        textAlign="center"
                        pb={0}
                        color={customDarkBlueColor}
                    >
                        Hazard Guide
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={2}
                    sm={4}
                    xl={5}
                    display="flex"
                    alignItems="center"
                >
                    {!props.loading && props.hazardGuideData.length > 0 && (
                        <IconButton onClick={props.onTranslatePress}>
                            <img src={Translate} alt="Translate" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <div style={{ marginBottom: '1rem' }}>
                <LocalContentSwitch
                    onLocalContentToggle={props.onLocalContentToggle}
                    enabled={props.locoEnabled}
                    showNoLocalContentPopover={props.showNoLocalContentPopover}
                    onClosePopover={props.onCloseNoLocalContentPopover}
                    popoverText={props.localContentPopoverTextOverride}
                />
            </div>
            {!props.noSearchResults && (
                <div
                    style={{
                        paddingBottom: '1rem',
                    }}
                >
                    <HazardCategoryGrid
                        categories={['Health', 'Crime', 'Common Incidents']}
                        activeCategories={props.activeCategories}
                        onCategoryPress={props.onCategoryPress}
                    />
                </div>
            )}
            {props.filters.length > 0 && (
                <div
                    className={classNames(
                        localClasses.filterAndSort,
                        largeWindowOrLarger
                            ? localClasses.desktopFilterAndSort
                            : undefined
                    )}
                    style={{
                        alignSelf: mediumWindowOrLarger
                            ? 'center'
                            : 'flex-start',
                    }}
                >
                    {mediumWindowOrLarger ? (
                        <ChipGrid
                            chips={props.filters}
                            activeChips={props.activeFilters}
                            availableChips={props.availableFilters}
                            guidingText="Filter By:"
                            onChipPress={props.onChipPress}
                            onResetPress={props.onChipReset}
                            showDeleteIcon
                            collapsible={!mediumWindowOrLarger}
                            collapsed={!props.filtersExpanded}
                        />
                    ) : (
                        <button
                            className={localClasses.mwFilterBy}
                            onClick={() =>
                                props.onFilterViewToggle(!props.filtersExpanded)
                            }
                        >
                            <IoOptions
                                size="1.5rem"
                                color={
                                    props.filtersExpanded
                                        ? colors.grays.NOIR
                                        : colors.secondary.BONDI
                                }
                            />
                            <Typography
                                color={
                                    props.filtersExpanded
                                        ? colors.grays.NOIR
                                        : colors.secondary.BONDI
                                }
                                fontWeight={500}
                                whiteSpace="nowrap"
                                px="0.5rem"
                            >
                                {props.filtersExpanded
                                    ? `Hide Filters`
                                    : 'Show Filters'}
                            </Typography>
                        </button>
                    )}
                    <div className={localClasses.sort}>
                        <Typography
                            color={
                                mediumWindowOrLarger
                                    ? colors.grays.NIMBUS
                                    : colors.secondary.BONDI
                            }
                            fontWeight={500}
                            whiteSpace="nowrap"
                            pr="0.5rem"
                            sx={{
                                textDecoration: 'underline',
                            }}
                        >
                            Sort By:
                        </Typography>
                        <Picker
                            data={SORTING_OPTIONS}
                            value={props.sortMethod ?? 'asc'}
                            onChange={(e) =>
                                props.onSortingMethodChange(
                                    e.target.value as HazardSortingMethod
                                )
                            }
                            autoWidth
                        />
                    </div>
                    {!mediumWindowOrLarger &&
                    (props.activeFilters.length > 0 ||
                        props.filtersExpanded) ? (
                        <div className={localClasses.mwChipsContainer}>
                            <ChipGrid
                                chips={props.filters}
                                activeChips={props.activeFilters}
                                availableChips={props.availableFilters}
                                onChipPress={props.onChipPress}
                                onResetPress={props.onChipReset}
                                showDeleteIcon
                                collapsible={!mediumWindowOrLarger}
                                collapsed={!props.filtersExpanded}
                            />
                        </div>
                    ) : null}
                </div>
            )}
            {props.loading || props.hazardGuideData.length > 0 ? (
                <>
                    {props.searchQuery && !props.loading && (
                        <Typography
                            component="h2"
                            variant="h3"
                            alignSelf="flex-start"
                            mb="1.125rem"
                            fontWeight={400}
                        >
                            Search results for{' '}
                            <i className={classes.searchQuery}>
                                "{props.searchQuery}"
                            </i>
                            :
                        </Typography>
                    )}
                    {props.suggestedQuery && !props.loading && (
                        <DidYouMean
                            suggestedQuery={props.suggestedQuery}
                            onSuggestionPress={props.onSuggestionPress}
                        />
                    )}
                    <HazardCardGrid
                        data={props.hazardGuideData}
                        onHazardPress={props.onHazardPress}
                        onHazardHover={props.onHazardHover}
                        onCopyLinkPress={props.onCopyLinkPress}
                        onBookmark={props.onBookmark}
                        onPdfPress={props.onPdfPress}
                        loading={props.loading}
                    />
                    <LanguageSelectorModal
                        languages={getLangObjects(props.languages)}
                        selectedLanguage={props.selectedLanguage}
                        open={props.languageSelectorModalOpen}
                        onClose={props.onLanguageSelectorModalClose}
                        onLanguagePress={props.onLanguageChange}
                    />
                </>
            ) : (
                <>
                    {props.suggestedQuery && (
                        <DidYouMean
                            suggestedQuery={props.suggestedQuery}
                            onSuggestionPress={props.onSuggestionPress}
                        />
                    )}
                    <NoSearchResultsView
                        onRequestHazardPress={openHazardRequestFormAsync}
                    />
                </>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    filterAndSort: {
        display: 'grid',
        paddingBottom: '1rem',
        rowGap: '0.5rem',
        columnGap: '1rem',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    desktopFilterAndSort: {
        display: 'flex',
        flexDirection: 'row',
        gridTemplateColumns: 'unset',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '1rem',
        columnGap: '3rem',
    },
    sort: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        justifySelf: 'flex-end',
    },
    mwFilterBy: {
        all: 'unset',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    mwChipsContainer: {
        gridColumn: '1 / -1',
    },
})
