import {
    ContentBlockDTO,
    LanguageCode,
    SupportedContentBooster,
    SupportedHazardStage,
    NativeLanguageDTO,
    CSOScope,
    PrepCheckStatus,
    PrepCheckComponentRating,
    HazardOverview,
    colors,
} from '@hazadapt-git/public-core-base'
import { Help, Info, Lock } from '@mui/icons-material'
import { Typography, Divider, Grid, Button } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

import Breathing from '../../assets/icons/Breathing.svg'
import { customDarkBlueColor, prepCheckColor } from '../../lib/styles/universal'
import {
    getLangObjects,
    getHazards,
    openHazardRequestFormAsync,
    openPrivacyPolicyAsync,
    openReportABugFormAsync,
    openTermsAndConditionsAsync,
    searchHazards,
    useWindowSizeUp,
} from '../../lib/utils'
import logo from '../../logo.svg'
import {
    CSOItemProps,
    ContentBlockPreviewProps,
    HazardCardDTO,
    WhatToKnow911DetailsProps,
    PrepCheckAnswerCardProps,
    PrepCheckCardDropItemProps,
    PrepCheckCardBaseProps,
    PrepCheckResultPreviewItemPropsBase,
} from '../molecules'
import {
    TermsCheckbox,
    HazardCategoryGrid,
    HazardStageGrid,
    WhatToKnow911View,
    ScrollingBlockList,
    PanelGrid,
    NoSearchResultsView,
    NoBookmarksView,
    AboutTab,
    FAQTab,
    TabGroup,
    WhenToCall911View,
    HazardCardGrid,
    ContentBlockView,
    HomePageFooter,
    SiteHeader,
    IndvHazardHeader,
    ReportIssueTab,
    LanguageSelectorModal,
    LanguageTab,
    CSOItemGrid,
    CSOView,
    CSOFilterPicker,
    PrepCheckBanner,
    PrepCheckCardDropGrid,
    PrepCheckLandingBanner,
    PrepCheckDoneView,
    PrepCheckUpNextBanner,
    PrepCheckResultPreviewItemList,
    PrepCheckQuestionExplainer,
    PrepCheckCardGrid,
    MyEmergencyItemsList,
} from '../organisms'
import { HazardFilter, ContentBooster } from '../../lib/entities'
import { PrepCheckAnswerCardGrid } from '../organisms/PrepCheckAnswerCardGrid'

interface OrganismsProps {}

export const Organisms: FC<OrganismsProps> = (props: OrganismsProps) => {
    const [selectedWTC911Item, setSelectedWTC911Item] = useState<
        number | undefined
    >()
    const [activeCategories, setActiveCategories] = useState<
        ('Health' | 'Crime' | 'Common Incidents')[]
    >(['Health'])
    const [activeStage, setActiveStage] =
        useState<SupportedHazardStage>('During')
    const prepCheckAnswerCardItems: PrepCheckAnswerCardProps[] = [
        {
            answerText: '911',
            answerInfo: 'Loris Ipsum...',
            id: 1,
            questionType: 'OneD_Ordered_List',
            image: logo,
            onClick: () => {},
        },
        {
            answerText: 'Family or Friend',
            answerInfo: 'Loris Ipsum...',
            id: 2,
            questionType: 'OneD_Ordered_List',
            image: logo,
            onClick: () => {},
        },
        {
            answerText: 'Neighbor',
            answerInfo: 'Loris Ipsum...',
            id: 3,
            questionType: 'OneD_Ordered_List',
            image: logo,
            onClick: () => {},
        },
        {
            answerText: 'Doctor or Medical Provider',
            answerInfo: 'Loris Ipsum...',
            id: 4,
            questionType: 'OneD_Ordered_List',
            image: logo,
            onClick: () => {},
        },
        {
            answerText: 'Veterinarian',
            answerInfo: 'Loris Ipsum...',
            id: 5,
            questionType: 'OneD_Ordered_List',
            image: logo,
            onClick: () => {},
        },
        {
            answerText: 'Community',
            answerInfo: 'Loris Ipsum...',
            id: 6,
            questionType: 'OneD_Ordered_List',
            image: logo,
            onClick: () => {},
        },
    ]

    const prepCheckCardDropItemData_oneD: PrepCheckCardDropItemProps[] = [
        {
            index: 1,
            active: true,
            questionType: 'OneD_Ordered_List',
            priority: 1,
            onClick: () => {},
        },
        {
            index: 2,
            active: false,
            questionType: 'OneD_Ordered_List',
            priority: 2,
            onClick: () => {},
        },
        {
            index: 3,
            active: false,
            questionType: 'OneD_Ordered_List',
            priority: 3,
            onClick: () => {},
        },
        {
            index: 4,
            active: false,
            questionType: 'OneD_Ordered_List',
            priority: 4,
            onClick: () => {},
        },
        {
            index: 5,
            active: false,
            questionType: 'OneD_Ordered_List',
            priority: 5,
            onClick: () => {},
        },
        {
            index: 6,
            active: false,
            questionType: 'OneD_Ordered_List',
            priority: 6,
            onClick: () => {},
        },
    ]

    const prepCheckCardDropItemData_twoD: PrepCheckCardDropItemProps[] = [
        {
            index: 1,
            active: true,
            questionType: 'TwoD_Grid_Placement',
            priority: 1,
            prepStage: 'Before',
            onClick: () => {},
        },
        {
            index: 2,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 2,
            prepStage: 'Before',
            onClick: () => {},
        },
        {
            index: 3,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 3,
            prepStage: 'Before',
            onClick: () => {},
        },
        {
            index: 4,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 1,
            prepStage: 'During',
            onClick: () => {},
        },
        {
            index: 5,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 2,
            prepStage: 'During',
            onClick: () => {},
        },
        {
            index: 6,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 3,
            prepStage: 'During',
            onClick: () => {},
        },
        {
            index: 7,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 1,
            prepStage: 'After',
            onClick: () => {},
        },
        {
            index: 8,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 2,
            prepStage: 'After',
            onClick: () => {},
        },
        {
            index: 9,
            active: false,
            questionType: 'TwoD_Grid_Placement',
            priority: 3,
            prepStage: 'After',
            onClick: () => {},
        },
    ]

    const whatToKnow911GridItems: ContentBlockPreviewProps[] = [
        {
            id: 1,
            title: 'Block 1',
            onPress: () => setSelectedWTC911Item(1),
            type: 'content',
        },
        {
            id: 2,
            title: 'Block 2',
            onPress: () => setSelectedWTC911Item(2),
            type: 'content',
        },
        {
            id: 3,
            title: 'Block 3',
            onPress: () => setSelectedWTC911Item(3),
            type: 'content',
        },
    ]
    const whatToKnow911DetailItems: WhatToKnow911DetailsProps[] = [
        {
            id: 1,
            title: 'Block 1',
            content: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            images: [{ src: logo }, { src: logo }, { src: logo }],
        },
        {
            id: 2,
            title: 'Block 2',
            content: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            images: [{ src: logo }, { src: logo }, { src: logo }],
        },
        {
            id: 3,
            title: 'Block 3',
            content: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            images: [{ src: logo }, { src: logo }, { src: logo }],
        },
    ]

    const contentBlocks: ContentBlockDTO[] = [
        {
            id: 1,
            title: 'Block 1',
            body: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            block_icon: { src: logo, alt: '', url: '' },
            stage: 'During',
            priority: 1,
            type: 'content',
            toggles: ['Adults'],
            important_sources: [],
            image: undefined,
            header: 'Header!',
            critical: true,
            local: false,
            local_content: [],
        },
        {
            id: 2,
            title: 'Block 2',
            body: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            block_icon: { src: logo, alt: '', url: '' },
            stage: 'During',
            priority: 1,
            type: 'content',
            toggles: ['Adults'],
            important_sources: [],
            image: undefined,
            header: 'Header!',
            critical: true,
            local: false,
            local_content: [],
        },
        {
            id: 3,
            title: 'Block 3',
            body: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            block_icon: { src: logo, alt: '', url: '' },
            stage: 'During',
            priority: 1,
            type: 'content',
            toggles: ['Adults'],
            important_sources: [],
            image: undefined,
            header: 'Header!',
            critical: true,
            local: false,
            local_content: [],
        },
        {
            id: 4,
            title: 'Block 4',
            body: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            block_icon: { src: logo, alt: '', url: '' },
            stage: 'During',
            priority: 1,
            type: 'content',
            toggles: ['Adults'],
            important_sources: [],
            image: undefined,
            header: 'Header!',
            critical: true,
            local: false,
            local_content: [],
        },
        {
            id: 5,
            title: 'Block 5',
            body: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            block_icon: { src: logo, alt: '', url: '' },
            stage: 'During',
            priority: 1,
            type: 'content',
            toggles: ['Adults'],
            important_sources: [],
            image: undefined,
            header: 'Header!',
            critical: true,
            local: false,
            local_content: [],
        },
        {
            id: 6,
            title: 'Block 6',
            body: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
            block_icon: { src: logo, alt: '', url: '' },
            stage: 'During',
            priority: 1,
            type: 'content',
            toggles: ['Adults'],
            important_sources: [],
            image: undefined,
            header: 'Header!',
            critical: true,
            local: false,
            local_content: [],
        },
    ]
    const [selectedBlock, setSelectedBlock] = useState<number>(1)
    const [termsCheckboxChecked, setTermsCheckboxChecked] =
        useState<boolean>(false)

    const [activeNavBarItem, setActiveNavBarItem] = useState<string>('/home')
    const [activeTab, setActiveTab] = useState<number>(1)

    const [selectedFAQ, setSelectedFAQ] = React.useState<number | undefined>()
    const faqs = [
        {
            id: 1,
            question: 'How can I help?',
            answer: `A demo of \`react-markdown\`\n
\`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
        },
        {
            id: 2,
            question: 'How can I help?',
            answer: `A demo of \`react-markdown\`\n
\`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
        },
        {
            id: 3,
            question: 'How can I help?',
            answer: `A demo of \`react-markdown\`\n
\`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
        },
        {
            id: 4,
            question: 'How can I help?',
            answer: `A demo of \`react-markdown\`\n
\`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
        },
    ]

    const smallWindowOrLarger = useWindowSizeUp('sm')
    const hazardCardData: HazardCardDTO[] = [
        {
            id: 1,
            icon: logo,
            name: 'Hazard 1',
            description: 'Example hazard for the showcase.',
            filterChips: [
                { ...HazardFilter['Air_Quality'] },
                { ...HazardFilter['Transportation'] },
                { ...HazardFilter['Trending'] },
            ],
            bookmarked: true,
        },
        {
            id: 2,
            icon: logo,
            name: 'Hazard 2',
            description: 'Example hazard for the showcase.',
            filterChips: [],
            local: true,
            creator: {
                id: 1,
                name: 'Oregon State University',
                logo: {
                    url: 'https://communications.oregonstate.edu/sites/communications.oregonstate.edu/files/osu-primarylogo-2-compressor.jpg',
                    src: ' ',
                    alt: 'OSU',
                },
            },
        },
        {
            id: 3,
            icon: logo,
            name: 'Hazard 3',
            description: 'Example hazard for the showcase.',
            filterChips: [HazardFilter['Weather']],
        },
        {
            id: 4,
            icon: logo,
            name: 'Hazard 4',
            description: 'Example hazard for the showcase.',
            filterChips: [],
        },
        {
            id: 5,
            icon: logo,
            name: 'Hazard 5',
            description: 'Example hazard for the showcase.',
            filterChips: [],
        },
        {
            id: 6,
            icon: logo,
            name: 'Hazard 6',
            description: 'Example hazard for the showcase.',
            filterChips: [],
        },
        {
            id: 7,
            icon: logo,
            name: 'Hazard 7',
            description: 'Example hazard for the showcase.',
            filterChips: [],
        },
        {
            id: 8,
            icon: logo,
            name: 'Hazard 8',
            description:
                'Unplanned, unwanted, uncontrolled fire starting in an area of combustible vegetation and can threaten people and property. Unplanned, unwanted, uncontrolled fire starting in an area of combustible vegetation and can threaten people and property.',
            filterChips: [],
        },
    ]

    const [openPCResultPreviewItem, setOpenPCResultPreviewItem] =
        React.useState<number>()
    const prepCheckResultPreviewQuestions: PrepCheckResultPreviewItemPropsBase[] =
        [
            {
                questionId: 1,
                questionName: 'Wildfire Actions',
                componentRating: PrepCheckComponentRating.HIGH,
                breakdownText: `<strong><font color="red">3 Important Missing Items!</font></strong>\n
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                questionIcon: {
                    src: logo,
                    alt: '',
                },
                questionText:
                    '1. Do you know what to do in the event of a wildfire?',
            },
            {
                questionId: 2,
                questionName: 'Wildfire Emergency Items',
                componentRating: PrepCheckComponentRating.LOW,
                breakdownText: `<strong><font color="red">3 Important Missing Items!</font></strong>\n
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                questionIcon: {
                    src: logo,
                    alt: '',
                },
                questionText:
                    '2. What safety supplies do you have around you that could help you in the event of a wildfire?',
            },
            {
                questionId: 3,
                questionName: 'Wildfire Emergency Contacts',
                componentRating: PrepCheckComponentRating.MEDIUM,
                breakdownText: `<strong><font color="red">3 Important Missing Items!</font></strong>\n
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                questionIcon: {
                    src: logo,
                    alt: '',
                },
                questionText:
                    '3. Who would you feel comfortable calling for emergency help during a wildfire?',
            },
        ]

    const [bookmarks, setBookmarks] = useState<number[]>([])
    const onBookmark = (id: number) => {
        const books = [...bookmarks]
        const index = books.findIndex((b) => b === id)
        if (index === -1) books.push(id)
        else books.splice(index, 1)
        setBookmarks(books)
    }

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(
        LanguageCode.ENGLISH
    )
    const [query, setQuery] = useState<string>('')
    const [activeBoosters, setActiveBoosters] = useState<string[]>([])

    const [indvBookmarked, setIndvBookmarked] = useState<boolean>(false)
    const [loadingHazards, setLoadingHazards] = useState<boolean>(true)
    const [hazards, setHazards] = useState<HazardOverview[]>([])

    const langObjects: NativeLanguageDTO[] = [
        { title: 'English', value: LanguageCode.ENGLISH },
        { title: 'Español', value: LanguageCode.SPANISH },
    ]

    const csoItems: CSOItemProps[] = [
        {
            filterChips: [ContentBooster['Farm_Animals']],
            config: {
                id: 1,
                title: 'Connect',
                priority: 1,
                subheader: 'Subheader Text',
                phone: '(555) 123-4567',
                is_text_line: false,
                link: 'https://google.com',
                hours: null,
                always_available: true,
                who_they_help: 'Kids, Pets',
                examples: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                details: null,
                filters: ['Kids', 'Pets'],
                icon: null,
                hazards: [21],
                scope: CSOScope.LOCAL,
            },
        },
        {
            filterChips: [ContentBooster['Kids']],
            config: {
                id: 2,
                title: 'Adapt',
                priority: 1,
                subheader: 'Subheader Text',
                phone: '(555) 123-4567',
                is_text_line: true,
                link: 'https://hazadapt.com',
                hours: 'Monday through Friday, 8am - 5pm',
                always_available: false,
                who_they_help: 'Kids, Pets',
                examples: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                details: 'Here are the details',
                filters: ['Kids', 'Pets'],
                icon: {
                    src: Breathing,
                    alt: '',
                },
                hazards: [21],
                scope: CSOScope.LOCAL,
            },
        },
        {
            filterChips: [],
            config: {
                id: 3,
                title: 'Overcome',
                priority: 1,
                subheader: 'Subheader Text',
                phone: '(555) 123-4567',
                is_text_line: false,
                link: 'https://google.com',
                hours: null,
                always_available: true,
                who_they_help: 'Kids, Pets',
                examples: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                details: null,
                filters: ['Kids', 'Pets'],
                icon: {
                    src: Breathing,
                    alt: '',
                },
                hazards: [21],
                scope: CSOScope.LOCAL,
            },
        },
    ]

    useEffect(() => {
        getHazards(LanguageCode.ENGLISH, [])
            .then(({ hazards }) => {
                setHazards(hazards)
                setLoadingHazards(false)
            })
            .catch(console.error)
    }, [])

    useEffect(() => {
        searchHazards(hazards, query.trim().toLowerCase())
            .then(({ hazards }) => setHazards(hazards))
            .catch(console.error)
    }, [query, hazards])

    const [activeChips, setActiveChips] = useState<SupportedContentBooster[]>(
        []
    )
    const onChipPress = (name: SupportedContentBooster) => {
        const active = [...activeChips]
        const index = active.findIndex((c) => c === name)
        if (index === -1) active.push(name)
        else active.splice(index, 1)
        setActiveChips(active)
    }

    const onResetPress = () => {
        setActiveChips([])
    }

    const [open, setOpen] = React.useState<boolean>(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const [searchQuery, setSearchQuery] = React.useState<string>('')
    const [prepCheckQuestionExplainerOpen, setPrepCheckQuestionExplainerOpen] =
        React.useState(false)
    // eslint-disable-next-line
    const [prepCheckStage, setPrepCheckStage] = useState<PrepCheckStatus>()

    const prepCheckCardData: PrepCheckCardBaseProps[] = [
        {
            id: 1,
            status: prepCheckStage,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
        {
            id: 2,
            status: PrepCheckStatus.SUBMITTED,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
        {
            id: 3,
            status: prepCheckStage,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
        {
            id: 4,
            status: PrepCheckStatus.DRAFT,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
        {
            id: 5,
            status: prepCheckStage,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
        {
            id: 6,
            status: prepCheckStage,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
        {
            id: 7,
            status: prepCheckStage,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
        {
            id: 8,
            status: prepCheckStage,
            dark_icon: logo,
            title: 'Wildfire',
            badge:
                prepCheckStage === PrepCheckStatus.SUBMITTED
                    ? {
                          src: logo,
                          alt: 'Silver Badge Icon',
                      }
                    : undefined,
            progressBar: 0.25,
            filterChips: [HazardFilter['Weather']],
            score: 0.67,
        },
    ]

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="1rem"
        >
            <Typography
                variant="h5"
                textAlign="center"
                width="50%"
                paddingBottom="3rem"
            >
                <strong>NOTE:</strong> Tabs are listed at the end.
            </Typography>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ContentBlockView
                </Typography>
                <ContentBlockView
                    blocks={contentBlocks}
                    selectedBlockId={selectedBlock}
                    language={LanguageCode.ENGLISH}
                    onBlockPress={setSelectedBlock}
                    onLinkPress={() => {}}
                    boosters={['Adults', 'Kids', 'Pets', 'Elderly']}
                    hasCommunitySponsors={false}
                    communitySponsors={[]}
                    communitySponsorsOpen={false}
                    onCommunitySponsorsOpen={() => {}}
                    communitySponsorBlockId={1}
                    onSponsorLinkPress={() => {}}
                    onLocalContentAdditionHover={() => {}}
                    onLocalContentAdditionLinkClick={() => {}}
                    overrideScroll
                    onPrepCheckBlockClick={() => {}}
                    onPrepCheckShare={() => {}}
                    hasPromotedProducts={false}
                    promotedProducts={[]}
                    promotedProductsOpen={false}
                    onPromotedProductsOpen={() => {}}
                    promotedProductBlockId={1}
                    onPromotedProductClick={() => {}}
                    onPromotedProductBookmarkPress={() => {}}
                    onPromotedProductShare={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    CSOItemGrid
                </Typography>
                <CSOItemGrid
                    items={csoItems}
                    onCsoOpen={() => {}}
                    onWhatTheyHelpWithClick={() => {}}
                    onWhoTheyHelpClick={() => {}}
                    onAboutThisServiceClick={() => {}}
                    onFindNearMeClick={() => {}}
                    onCsoViewWebsiteClick={() => {}}
                    onCsoHover={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    CSOView
                </Typography>
                <CSOView
                    hazards={[
                        { value: 1, label: 'One' },
                        { value: 2, label: 'Two' },
                    ]}
                    activeFilters={activeChips}
                    filterPickerOpen={open}
                    handleFilterPickerOpen={handleOpen}
                    handleFilterPickerClose={handleClose}
                    csoFilterPicker={
                        <CSOFilterPicker
                            activeChips={activeChips}
                            availableChips={Object.values(ContentBooster).map(
                                (cb) => cb.key
                            )}
                            onChipPress={onChipPress}
                            onResetPress={onResetPress}
                            onCancelPress={handleClose}
                        />
                    }
                    csoItems={csoItems}
                    onSelectedHazardChange={() => {}}
                    onHazardResetPress={() => {}}
                    hazardToFilter=""
                    searchQuery={searchQuery}
                    onSearchQueryChange={setSearchQuery}
                    onCsoOpen={() => {}}
                    onCsoHover={() => {}}
                    onWhatTheyHelpWithClick={() => {}}
                    onWhoTheyHelpClick={() => {}}
                    onAboutThisServiceClick={() => {}}
                    onFindNearMeClick={() => {}}
                    onCsoViewWebsiteClick={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    EmergencyCallHelperDisclaimer
                </Typography>
                [TODO]
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HazardCardGrid
                </Typography>
                <HazardCardGrid
                    data={hazardCardData}
                    onHazardPress={() => {}}
                    onBookmark={onBookmark}
                    onCopyLinkPress={() => {}}
                    onOptionsPress={() => {}}
                    onPrepCheckPress={() => {}}
                    onPdfPress={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HazardCategoryGrid
                </Typography>
                <HazardCategoryGrid
                    categories={['Health', 'Crime', 'Common Incidents']}
                    activeCategories={activeCategories}
                    onCategoryPress={(
                        category: 'Health' | 'Crime' | 'Common Incidents'
                    ) => {
                        const activeCats = [...activeCategories]
                        const index = activeCats.findIndex(
                            (c) => c === category
                        )
                        if (index !== -1) {
                            activeCats.splice(index, 1)
                        } else {
                            activeCats.push(category)
                        }
                        setActiveCategories(activeCats)
                    }}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HazardStageGrid
                </Typography>
                <HazardStageGrid
                    activeStage={activeStage}
                    onStagePress={setActiveStage}
                    stagesWithLoCo={['After']}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HomePageFooter
                </Typography>
                <HomePageFooter />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    IndvHazardHeader
                </Typography>
                <IndvHazardHeader
                    name="Hurricane"
                    bookmarked={indvBookmarked}
                    activeStage={activeStage}
                    onStagePress={setActiveStage}
                    boosters={Object.keys(ContentBooster).map(
                        (cb) => ContentBooster[cb]
                    )}
                    activeBoosters={
                        activeBoosters as unknown[] as SupportedContentBooster[]
                    }
                    availableBoosters={
                        activeBoosters as unknown[] as SupportedContentBooster[]
                    }
                    onBoosterPress={(value: string) => {
                        const active = [...activeBoosters]
                        const index = active.indexOf(value)
                        if (index >= 0) active.splice(index, 1)
                        else active.push(value)
                        setActiveBoosters(active)
                    }}
                    onBoosterReset={() => setActiveBoosters([])}
                    onTranslatePress={() => {}}
                    onShare={() => {}}
                    onBookmark={() => setIndvBookmarked(!indvBookmarked)}
                    onPdfPress={() => {}}
                    onLocalContentToggle={() => {}}
                    locoEnabled
                    stagesWithLoCo={['Before']}
                    onCloseNoLocalContentPopover={() => {}}
                    boostersExpanded
                    onBoosterViewToggle={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LanguageSelectorModal
                </Typography>
                <LanguageSelectorModal
                    open={dialogOpen}
                    selectedLanguage={selectedLanguage}
                    languages={getLangObjects([
                        LanguageCode.ENGLISH,
                        LanguageCode.SPANISH,
                        LanguageCode.FRENCH,
                    ])}
                    onLanguagePress={setSelectedLanguage}
                    onClose={() => setDialogOpen(false)}
                />
                <Button variant="outlined" onClick={() => setDialogOpen(true)}>
                    Open Modal
                </Button>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    MyEmergencyItemsList
                </Typography>
                <MyEmergencyItemsList
                    listTitle="I have..."
                    // onShare={() => {}}
                    onCheck={() => {}}
                    items={[
                        {
                            id: 1,
                            name: 'Item 1',
                            userHasItem: true,
                            prep_checks: [
                                {
                                    id: 1,
                                    name: 'Test Prep Check',
                                    icon: logo,
                                },
                            ],
                        },
                        {
                            id: 2,
                            name: 'Item 2',
                            userHasItem: true,
                            prep_checks: [
                                {
                                    id: 1,
                                    name: 'Test Prep Check',
                                    icon: logo,
                                },
                            ],
                        },
                        {
                            id: 3,
                            name: 'Item 3',
                            userHasItem: true,
                            prep_checks: [
                                {
                                    id: 1,
                                    name: 'Test Prep Check',
                                    icon: logo,
                                },
                            ],
                        },
                    ]}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    NoBookmarksView
                </Typography>
                <NoBookmarksView variant="hazards" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    NoSearchResultsView
                </Typography>
                <NoSearchResultsView
                    onRequestHazardPress={openHazardRequestFormAsync}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PanelGrid
                </Typography>
                <PanelGrid
                    panels={[
                        {
                            imageSrc: logo,
                            backgroundColor: customDarkBlueColor,
                            title: 'Example Panel 1',
                            subtitle: 'Lorem ipsum stuff...',
                            textColor: colors.grays.BLANC,
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            backgroundColor: prepCheckColor,
                            title: 'Example Panel 2',
                            subtitle: 'Lorem ipsum stuff...',
                            textColor: colors.grays.BLANC,
                            onPress: () => {},
                        },
                    ]}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckAnswerCardGrid
                </Typography>
                <PrepCheckAnswerCardGrid
                    data={prepCheckAnswerCardItems}
                    gridType="OneD_Ordered_List"
                    selectedItemId={1}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckCardDropItem
                </Typography>
                <PrepCheckCardDropGrid
                    data={prepCheckCardDropItemData_oneD}
                    gridType="OneD_Ordered_List"
                    onDropSpotClick={() => {}}
                    onClearSpot={() => {}}
                    onFilledSpotClick={() => {}}
                />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <PrepCheckCardDropGrid
                    data={prepCheckCardDropItemData_twoD}
                    gridType="TwoD_Grid_Placement"
                    onDropSpotClick={() => {}}
                    onClearSpot={() => {}}
                    onFilledSpotClick={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckBanner
                </Typography>
                <PrepCheckBanner
                    icon={{
                        src: logo,
                        alt: '',
                    }}
                    prepCheckName="Wildfire Prep Check"
                    journeyPhase="Scout II"
                    prepChecksCompleted={1}
                    totalPrepChecks={25}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckCardGrid
                </Typography>
                <PrepCheckCardGrid
                    data={prepCheckCardData}
                    onPrepCheckClick={() => {}}
                />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckLandingBanner
                </Typography>
                <PrepCheckLandingBanner
                    journeyPhaseIcon={{
                        src: logo,
                        alt: '',
                    }}
                    journeyPhase="Scout II"
                    journeyPhaseSubcopy="You've just started your preparedness journey."
                    prepChecksCompleted={1}
                    totalPrepChecks={25}
                    onMyEmergencyItemsClick={() => {}}
                    showJourneyPhase
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckResultPreviewItemList
                </Typography>
                <PrepCheckResultPreviewItemList
                    open={openPCResultPreviewItem}
                    questions={prepCheckResultPreviewQuestions}
                    onToggle={(id) => {
                        if (openPCResultPreviewItem === id) {
                            setOpenPCResultPreviewItem(undefined)
                        } else {
                            setOpenPCResultPreviewItem(id)
                        }
                    }}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckQuestionExplainer
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => setPrepCheckQuestionExplainerOpen(true)}
                >
                    Open Question Explainer
                </Button>
                <PrepCheckQuestionExplainer
                    content={`
### Check off the supplies you have now.
Safety supplies can help you avoid harm, respond to danger, and recover better.  This personal inventory question organizes two lists:

**The goal is to have as many safety supplies as possible, most especially the essential items.**

This question measures your preparedness based on how many items you have and which items you have.
Some supplies may be a first choice because have qualities that make them more successful in their job, but other supplies may also help if you do not have the higher-quality item.
    
For example, a fire extinguisher will likely be better at putting out a small fire compared to a bucket of water. However, a bucket of water can help if you do not have a fire extinguisher. The best scenario is to have both items.
                    `}
                    open={prepCheckQuestionExplainerOpen}
                    onClose={() => setPrepCheckQuestionExplainerOpen(false)}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckResultView
                </Typography>
                <PrepCheckDoneView
                    badge={{
                        src: logo,
                        alt: '',
                    }}
                    score={0.67}
                    prepCheckName="Wildfire Prep Check"
                    badgeName="Wildfire Wizard"
                    onViewResultsPress={() => {}}
                    onRetakePress={() => {}}
                    newBadge
                    badgeAwardText="You got a new badge!"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckUpNextBanner
                </Typography>
                <PrepCheckUpNextBanner
                    icon={{
                        src: logo,
                        alt: '',
                    }}
                    onUpNextPress={() => {}}
                    upNextName="Burns"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ScrollingBlockList
                </Typography>
                <ScrollingBlockList
                    width="100%"
                    blocks={[
                        {
                            imageSrc: logo,
                            name: 'Block 1',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 2',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 3',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 4',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 5',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 6',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 7',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 8',
                            onPress: () => {},
                        },
                        {
                            imageSrc: logo,
                            name: 'Block 9',
                            onPress: () => {},
                        },
                    ]}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    SiteHeader
                </Typography>
                <SiteHeader
                    searchQuery={query}
                    onSearchQueryChange={(value) => {
                        setQuery(value)
                    }}
                    onLogoPress={() => console.log('logopress')}
                    profilePictureSrc={logo}
                    name=""
                    options={hazards}
                    onOptionPress={() => {}}
                    loadingOptions={loadingHazards}
                    onSearchQuerySubmit={() => {}}
                    onEmergencyButtonPress={() => {}}
                    navItems={[
                        {
                            label: 'Home',
                            route: '/home',
                        },
                        {
                            label: 'Hazard Guide',
                            route: '/hazards',
                        },
                        {
                            label: 'Bookmarks',
                            route: '/bookmarks',
                        },
                        {
                            label: 'Settings',
                            route: '/settings',
                        },
                    ]}
                    activeNavItem={activeNavBarItem}
                    onNavItemPress={(route) => setActiveNavBarItem(route)}
                    profileOptions={[
                        {
                            children: 'Option 1',
                        },
                        {
                            children: 'Option 2',
                        },
                    ]}
                    onProfilePress={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TermsCheckbox
                </Typography>
                <TermsCheckbox
                    checked={termsCheckboxChecked}
                    onCheckboxPress={() =>
                        setTermsCheckboxChecked(!termsCheckboxChecked)
                    }
                    onTermsLinkPress={openTermsAndConditionsAsync}
                    onPrivacyPolicyLinkPress={openPrivacyPolicyAsync}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    WhatToKnow911Grid + WhatToKnow911View
                </Typography>
                <WhatToKnow911View
                    items={whatToKnow911GridItems}
                    selectedItem={whatToKnow911DetailItems.find(
                        (i) => i.id === selectedWTC911Item
                    )}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    WhenToCall911View
                </Typography>
                <WhenToCall911View
                    data={[
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                        {
                            image: {
                                src: Breathing,
                                alt: 'Breathing icon',
                            },
                            text: "If you're having trouble breathing...",
                        },
                    ]}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h2">Tabs and Tab Groups</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    AboutTab
                </Typography>
                <AboutTab />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    FAQTab
                </Typography>
                <FAQTab
                    blocks={faqs}
                    onReportIssuePress={openReportABugFormAsync}
                    selectedItem={faqs.find((f) => f.id === selectedFAQ)}
                    blockPressed={false}
                    onBlockPress={setSelectedFAQ}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ReportIssueTab
                </Typography>
                <ReportIssueTab onReportIssuePress={openReportABugFormAsync} />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LanguagesTab
                </Typography>
                <LanguageTab
                    languages={langObjects}
                    selectedLanguage={selectedLanguage}
                    onRadioBtnPress={setSelectedLanguage}
                    // testID=""
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    TabGroup
                </Typography>
                <TabGroup
                    tabs={[
                        {
                            id: 0,
                            label: 'Privacy Policy',
                            icon: (
                                <Lock
                                    fontSize={
                                        smallWindowOrLarger ? 'large' : 'medium'
                                    }
                                />
                            ),
                            onPress: openPrivacyPolicyAsync,
                        },
                        {
                            id: 1,
                            label: 'About',
                            icon: (
                                <Info
                                    fontSize={
                                        smallWindowOrLarger ? 'large' : 'medium'
                                    }
                                />
                            ),
                            onPress: setActiveTab,
                            scene: <AboutTab />,
                        },
                        {
                            id: 2,
                            label: 'Help',
                            icon: (
                                <Help
                                    fontSize={
                                        smallWindowOrLarger ? 'large' : 'medium'
                                    }
                                />
                            ),
                            onPress: setActiveTab,
                            scene: (
                                <FAQTab
                                    blocks={faqs}
                                    onReportIssuePress={openReportABugFormAsync}
                                    selectedItem={faqs.find(
                                        (f) => f.id === selectedFAQ
                                    )}
                                    blockPressed
                                    onBlockPress={setSelectedFAQ}
                                />
                            ),
                        },
                    ]}
                    value={activeTab}
                />
                <Divider />
            </Grid>
        </Grid>
    )
}
