import {
    CommunitySponsor,
    HazardDTO,
    HazardMultiLangDTO,
    HazardOverviewResponse,
    LanguageCode,
    SupportedHazardStage,
    TrendingHazardDTO,
    UserFacingHazardStage,
} from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'

import { isa } from '../api'
import { store } from '../store'
import { getCurrentLocation } from './misc'

export interface ViewEventDTO {
    hazard_id: number
    content_block_id?: number
}

export interface GuideObject {
    hazards: HazardDTO[]
    languages: LanguageCode[]
    date_modified: string
}

export const backendStageMap: Map<string, SupportedHazardStage> = new Map<
    string,
    SupportedHazardStage
>([
    ['Prepare', 'Before'],
    ['React', 'During'],
    ['Recover', 'After'],
])

export const userFacingStageMap: Record<
    SupportedHazardStage,
    UserFacingHazardStage
> = {
    Before: 'Prepare',
    During: 'React',
    After: 'Recover',
}

/**
 * Gets the latest hazards from the cloud,
 * falling back to locally stored content if necessary.
 */
export const getHazards = async (
    lang: LanguageCode,
    hazardsToForceInclude: number[],
    point?: [number, number]
): Promise<HazardOverviewResponse> => {
    // get latest hazards
    const response: AxiosResponse<HazardOverviewResponse> = await isa.get(
        `/public/v2/hazards?lang=${lang}${
            point ? `&lat=${point[0]}&lng=${point[1]}` : ''
        }${
            hazardsToForceInclude.length > 0
                ? `&bookmarks=${hazardsToForceInclude.toString()}`
                : ''
        }`
    )
    return response.data
}

export const getHazard = async (
    identifier: string,
    lang: LanguageCode,
    point?: [number, number]
): Promise<HazardMultiLangDTO> => {
    const response: AxiosResponse<HazardMultiLangDTO> = await isa.get(
        `/public/guide/hazard/${identifier}?languages=${lang}${
            point ? `&lat=${point[0]}&lng=${point[1]}` : ''
        }`
    )
    return response.data
}

export const getSupportedLanguages = async () => {
    try {
        const response: AxiosResponse<LanguageCode[]> = await isa.get(
            `/public/v2/supported-languages`
        )
        return response.data
    } catch (err) {
        return [LanguageCode.ENGLISH]
    }
}

/**
 * Logs an instance of a hazard being viewed by the user
 * @param: hazard_id
 */
export const logHazardViewEvent = async (
    hazard_id: number
): Promise<void | Error> => {
    const user = store.getState().profile.user
    if (user) {
        // logged in
        await isa.post('/public/guide/hazard-view-event/', { hazard_id })
    } else {
        // not logged in
        await isa.post('/public/guide/hazard-view-event/noauth', { hazard_id })
    }
}

/**
 * Logs an instance of a hazard's content block being viewed by the user
 * @param: { hazard_id: number, content_block_id: number }
 */
export const logContentBlockViewEvent = async ({
    hazard_id,
    content_block_id,
}: ViewEventDTO): Promise<void | Error> => {
    const user = store.getState().profile.user
    if (user) {
        await isa.post(
            // get the user's view event data from ISA
            '/public/guide/hazard-view-event',
            { hazard_id, content_block_id }
        )
    } else {
        await isa.post(
            // get the user's view event data from ISA
            '/public/guide/hazard-view-event/noauth',
            { hazard_id, content_block_id }
        )
    }
}

/**
 * Gets a user's hazard view counts
 */
export const getTrendingHazards = async (
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<TrendingHazardDTO[]> => {
    try {
        const response: AxiosResponse<TrendingHazardDTO[]> = await isa.get(
            `/public/v2/trending-hazards?lang=${lang}`
        )
        return response.data
    } catch (err) {
        console.error(err)
        throw err
    }
}

/**
 * Get community sponsors for a specified hazard
 * @param hazard_id The hazard to query for
 * @param point The user's current location, formatted as [lat, lng]
 * @returns CommunitySponsor[]
 */
export const fetchCommunitySponsors = async (
    hazard_id: number,
    point?: [number, number]
): Promise<CommunitySponsor[]> =>
    new Promise<CommunitySponsor[]>((resolve, reject) => {
        isa.get(
            `/public/guide/community-sponsor/${hazard_id}${
                point ? `?lat=${point[0]}&lng=${point[1]}` : ''
            }`
        )
            .then((response) => {
                if (response.status !== 200) {
                    reject(response.data?.error || 'Unknown API error')
                } else {
                    resolve(response.data)
                }
            })
            .catch((err) => {
                console.error(err)
                reject(err)
            })
    })

/**
 * Sort community sponsors
 * @param a community sponsor
 * @param b community sponsor
 * @returns number
 */
export const sortCommunitySponsors = (
    a: CommunitySponsor,
    b: CommunitySponsor
) => {
    if (a.geo && !b.geo) {
        return -1
    } else if (!a.geo && b.geo) {
        return 1
    } else return 0
}

/**
 * Fetches and sorts community sponsors for the given hazard
 * @param hazard_id
 * @returns CommunitySponsor[]
 */
export const getCommunitySponsors = async (
    hazard_id: number
): Promise<CommunitySponsor[]> => {
    let position: GeolocationPosition | undefined
    try {
        position = await getCurrentLocation()
    } catch (err) {
        console.error(err)
    }

    const sponsors = await fetchCommunitySponsors(
        hazard_id,
        position
            ? [position.coords.latitude, position.coords.longitude]
            : undefined
    )
    sponsors.sort(sortCommunitySponsors)
    return sponsors
}

export const loCoLocationFailedMessage = `Unable to retrieve your current location. Please check your location permissions and try again.`
export const loCoLocationDeniedMessage = `Location permission denied. To see local information, enable location permission in your browser settings and try again.`
