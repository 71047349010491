import { Box } from '@mui/material'
import React, { FC } from 'react'
import { useWindowSizeUp } from '../../lib/utils'

import { Panel, PanelProps } from '../molecules'

interface PanelGridProps {
    panels: PanelProps[]
    rounded?: boolean
    id?: string
}

export const PanelGrid: FC<PanelGridProps> = (props: PanelGridProps) => {
    const mediumWindowOrLarger = useWindowSizeUp('md')

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: mediumWindowOrLarger
                    ? 'repeat(2, 1fr)'
                    : '1fr',
                gap: '1rem',
            }}
        >
            {props.panels.map((panel: PanelProps, index: number) => (
                <Box
                    key={`panel_${index}_${panel.title
                        .toLowerCase()
                        .replaceAll(' ', '_')
                        .replaceAll(',', '_')}`}
                >
                    <Panel {...panel} />
                </Box>
            ))}
        </Box>
    )
}
