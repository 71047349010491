import {
    hazardRequestURL,
    privacyPolicyURL,
    termsAndConditionsURL,
    reportABugURL,
    submitFeedbackURL,
    hazAdaptOnAppStoreURL,
    hazAdaptOnGooglePlayURL,
    reportTranslationErrorURL,
} from '@hazadapt-git/public-core-base'
import { logEvent } from './logging'

export const openTermsAndConditionsAsync = () => {
    logEvent('OPEN_TERMS_CONDITIONS')
    window.open(termsAndConditionsURL)
}

export const openPrivacyPolicyAsync = () => {
    logEvent('OPEN_PRIVACY_POLICY')
    window.open(privacyPolicyURL)
}

export const openHazardRequestFormAsync = () => {
    logEvent('REQUEST_HAZARD')
    window.open(hazardRequestURL)
}

export const openReportABugFormAsync = () => {
    logEvent('REPORT_A_BUG')
    window.open(reportABugURL)
}

export const openReportTranslationErrorFormAsync = () => {
    logEvent('REPORT_TRANSLATION_ERROR')
    window.open(reportTranslationErrorURL)
}

export const openSubmitFeedbackFormAsync = () => {
    logEvent('SUBMIT_FEEDBACK')
    window.open(submitFeedbackURL)
}

export const openHazAdaptInAppStoreAsync = () => {
    logAppStoreLinkHit()
    window.open(hazAdaptOnAppStoreURL)
}

export const openHazAdaptInGooglePlayAsync = () => {
    logGooglePlayLinkHit()
    window.open(hazAdaptOnGooglePlayURL)
}

export const logTwitterHit = () => {
    logEvent('OPEN_TWITTER')
}

export const logFacebookHit = () => {
    logEvent('OPEN_FACEBOOK')
}

export const logInstagramHit = () => {
    logEvent('OPEN_INSTAGRAM')
}

export const logLinkedinHit = () => {
    logEvent('OPEN_LINKEDIN')
}

export const logWebsiteHit = () => {
    logEvent('OPEN_WEBSITE')
}

export const logWhyHazAdaptHit = () => {
    logEvent('OPEN_WHY_HAZADAPT')
}

export const logAppStoreLinkHit = () => {
    logEvent('GO_TO_APP_IN_APPLE_APP_STORE')
}

export const logGooglePlayLinkHit = () => {
    logEvent('GO_TO_APP_IN_GOOGLE_PLAY')
}
