import {
    colors,
    LanguageCode,
    NativeLanguageDTO,
} from '@hazadapt-git/public-core-base'
import {
    Box,
    Dialog,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

import MicrosoftLogoWht from '../../assets/images/Microsoft-logo_rgb_c-wht.png'
import { customDarkBlueColor } from '../../lib/styles/universal'
import { RadioButton, HelpIcon } from '../atoms'
import { IoClose } from 'react-icons/io5'

interface LanguageSelectorModalProps {
    open: boolean
    languages: NativeLanguageDTO[]
    onLanguagePress(value: LanguageCode): void
    selectedLanguage: LanguageCode
    onClose(...props: any): void
    subcopy?: string
}

export const LanguageHelpIcon = () => (
    <HelpIcon helpText="We use AI language translation. Thank you for your patience as we work towards a fully human-verified translation." />
)

export const languagePickerSubcopy = (subcopy?: string) =>
    subcopy ?? 'Select your language for the Hazard Guide and Prep Checks.'

export const LanguageSelectorModal: FC<LanguageSelectorModalProps> = (
    props: LanguageSelectorModalProps
) => {
    const { classes } = useLocalStyles()
    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth="xs"
        >
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <DialogTitle className={classes.dialogHeader}>
                    <div>
                        Languages
                        <div className={classes.poweredBy}>
                            <Typography
                                variant="h4"
                                fontSize="1rem"
                                sx={{ color: colors.grays.BLANC }}
                            >
                                Powered by:
                            </Typography>
                            <img
                                src={MicrosoftLogoWht}
                                alt="Microsoft"
                                className={classes.microsoftLogo}
                            />
                        </div>
                    </div>
                    <IconButton
                        onClick={props.onClose}
                        sx={{ marginTop: '-0.375rem' }}
                    >
                        <IoClose color={colors.grays.BLANC} size="2rem" />
                    </IconButton>
                </DialogTitle>
                <div className={classes.dialogBody}>
                    <div className={classes.dialogBodyHeading}>
                        <Typography
                            sx={{ pt: 2 }}
                            fontWeight={500}
                            color={colors.grays.NIMBUS}
                            paddingY="0.5rem"
                            paddingX={0}
                            paddingRight={0}
                            textAlign="left"
                        >
                            {languagePickerSubcopy(props.subcopy)}
                        </Typography>
                        <LanguageHelpIcon />
                    </div>
                    {props.languages.map((lang, index) => (
                        <div
                            key={`${lang.title}_radioButton`}
                            style={{ width: '100%' }}
                            onClick={(e) => {
                                e.preventDefault()
                                props.onLanguagePress(lang.value)
                            }}
                        >
                            <RadioButton
                                label={lang.title}
                                value={lang.value}
                                checked={props.selectedLanguage === lang.value}
                                labelPlacement="start"
                                dotColor="#00D57C"
                                containerStyle={{
                                    paddingTop: '0.25rem',
                                    paddingBottom: '0.25rem',
                                    cursor: 'pointer',
                                    margin: 0,
                                    paddingLeft: '0.25rem',
                                }}
                                textStyle={{
                                    fontWeight: 500,
                                    color: colors.grays.NIMBUS,
                                }}
                            />
                            {index < props.languages.length - 1 && (
                                <Divider
                                    style={{
                                        alignSelf: 'center',
                                        margin: 0,
                                        width: '100%',
                                        backgroundColor: '#0000004D',
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </Box>
        </Dialog>
    )
}

const useLocalStyles = makeStyles()({
    dialogHeader: {
        backgroundColor: customDarkBlueColor,
        color: colors.grays.BLANC,
        borderBottomRightRadius: '2rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingLeft: '1.5rem',
        padding: '1rem 1rem 0.5rem 1.5rem !important',
    },
    poweredBy: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    dialogBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
    },
    dialogBodyHeading: {
        display: 'flex',
        alignSelf: 'flex-start',
    },
    microsoftLogo: {
        height: '3.5rem',
        width: 'auto',
    },
})
