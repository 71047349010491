import {
    ContentBlockType,
    Image,
    LocalContentDTO,
    OrganizationOverview,
    Source,
    colors,
    convertSourcesToMarkdown,
} from '@hazadapt-git/public-core-base'
import { Button, IconButton, Link, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { useRef } from 'react'
import { ElementContent } from 'react-markdown/lib/ast-to-react'

import SourceIcon from '../../assets/icons/Source.svg'

import {
    altHazRedColor,
    localContentColor,
    useStyles,
} from '../../lib/styles/universal'
import { Card, Markdown } from '../atoms'
import { LocalContentBlockAddition } from './LocalContentBlockAddition'
import { IoChevronDown, IoChevronUp, IoLocationSharp } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import { LocationOn } from '@mui/icons-material'

export interface ContentBlockDetailsProps {
    id: number
    title: string
    blockIcon?: string
    blockIconAlt?: string
    image?: Image
    header?: string
    body?: string
    importantSources: Source[]
    localContent: LocalContentDTO[] // if lococontent, render local content as markdown element
    type: ContentBlockType
    critical?: boolean
    onLinkPress(url: string): void
    onLocalContentAdditionLinkClick(
        url: string,
        block_id: number,
        creator_id?: number
    ): void
    onLocalContentAdditionHover(block_id: number, creator_id?: number): void
    transformLinkUri?(
        href: string,
        children: ElementContent[],
        title: string | null
    ): string
    local?: boolean
    creator?: OrganizationOverview
}

export const ContentBlockDetails = React.forwardRef<
    HTMLDivElement,
    ContentBlockDetailsProps
>(
    (
        props: ContentBlockDetailsProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const { classes } = useStyles()
        const { classes: localClasses } = useLocalStyles()

        const mediumWindowOrLarger = useWindowSizeUp('md')

        const [relevantSourcesCollapsed, setRelevantSourcesCollapsed] =
            React.useState<boolean>(true)

        const handleImportantSourcesClick: React.MouseEventHandler = (ev) => {
            ev.preventDefault()
            ev.stopPropagation()
            setRelevantSourcesCollapsed((state) => !state)
        }

        const loco = useRef<null | HTMLDivElement>(null)

        const handleJumpToLoco: React.MouseEventHandler = (e) => {
            e.preventDefault()
            loco.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }

        return (
            <Card
                colors={[colors.grays.BLANC]}
                className={classes.contentBlock}
                ref={ref}
            >
                <div className={localClasses.header}>
                    {props.creator && (
                        <div className={localClasses.creatorInfo}>
                            {props.creator.logo && (
                                <img
                                    src={
                                        props.creator.logo.url ??
                                        props.creator.logo.src
                                    }
                                    alt={props.creator.logo.alt}
                                    className={localClasses.creatorLogo}
                                />
                            )}
                            <Typography variant="h4" fontSize="1.125rem">
                                {props.creator.name}
                            </Typography>
                        </div>
                    )}
                    <div className={localClasses.titleContainer}>
                        <div className={localClasses.title}>
                            {props.blockIcon &&
                                (props.type === 'source' ||
                                    (props.type === 'content' &&
                                        props.blockIcon !== '<svg></svg>')) && (
                                    <img
                                        src={
                                            props.type === 'source'
                                                ? SourceIcon
                                                : `data:image/svg+xml;base64,${btoa(
                                                      props.blockIcon
                                                  )}`
                                        }
                                        className={
                                            classes.contentBlockDetailsIcon
                                        }
                                        alt={
                                            props.blockIconAlt ||
                                            `${props.title} icon`
                                        }
                                    />
                                )}
                            <Typography
                                variant="h2"
                                color={
                                    props.critical
                                        ? altHazRedColor
                                        : colors.grays.NOIR
                                }
                                paddingLeft={
                                    props.blockIcon &&
                                    (props.type === 'source' ||
                                        (props.type === 'content' &&
                                            props.blockIcon !== '<svg></svg>'))
                                        ? '1rem'
                                        : 0
                                }
                            >
                                {props.title}
                            </Typography>
                        </div>
                        {props.localContent.length > 0 &&
                            mediumWindowOrLarger && (
                                <Button
                                    variant={'outlined'}
                                    startIcon={<LocationOn />}
                                    onClick={handleJumpToLoco}
                                    sx={{
                                        color: localContentColor,
                                        border: '3px solid',
                                    }}
                                >
                                    Jump to Local Information
                                </Button>
                            )}
                    </div>
                    {props.localContent.length > 0 && !mediumWindowOrLarger && (
                        <Link
                            underline="always"
                            onClick={handleJumpToLoco}
                            sx={{
                                color: localContentColor,
                                textDecorationColor: localContentColor,
                            }}
                        >
                            Jump to Local Information
                        </Link>
                    )}
                </div>
                {props.header && (
                    <Typography variant="h3" pt="1rem" pb="0.625rem">
                        {props.header}
                    </Typography>
                )}
                {props.image?.url && (
                    <img
                        src={props.image.url}
                        alt={props.image.alt}
                        style={{
                            width: 'min(100%, 25rem)',
                            height: 'auto',
                            maxHeight: '12rem',
                            objectFit: 'contain',
                        }}
                    />
                )}
                {props.body && (
                    <Markdown
                        content={props.body}
                        onLinkPress={props.onLinkPress}
                        transformLinkUri={props.transformLinkUri}
                    />
                )}
                {props.localContent.length > 0 && (
                    <>
                        <div
                            className={localClasses.localInfoHeader}
                            ref={loco}
                        >
                            <IoLocationSharp
                                size="2rem"
                                color={localContentColor}
                            />
                            <Typography
                                color={localContentColor}
                                variant="h4"
                                textTransform="uppercase"
                                id="LocalInfo"
                            >
                                Local Information
                            </Typography>
                        </div>
                        {props.localContent.map((content) => (
                            <LocalContentBlockAddition
                                key={content.creator?.id}
                                onLinkPress={(url) =>
                                    props.onLocalContentAdditionLinkClick(
                                        url,
                                        props.id,
                                        content.creator?.id
                                    )
                                }
                                onHover={() =>
                                    props.onLocalContentAdditionHover(
                                        props.id,
                                        content.creator?.id
                                    )
                                }
                                localContent={content}
                            />
                        ))}
                    </>
                )}
                {props.importantSources.length > 0 ? (
                    <>
                        <div
                            className={localClasses.relevantSourcesHeader}
                            onClick={handleImportantSourcesClick}
                        >
                            <Typography variant="h4" mt="1rem" mb="1rem">
                                Relevant Sources
                            </Typography>
                            <IconButton>
                                {!relevantSourcesCollapsed ? (
                                    <IoChevronUp
                                        size="1.25rem"
                                        color={colors.grays.NOIR}
                                    />
                                ) : (
                                    <IoChevronDown
                                        size="1.25rem"
                                        color={colors.grays.NOIR}
                                    />
                                )}
                            </IconButton>
                        </div>
                        {!relevantSourcesCollapsed ? (
                            <Markdown
                                onLinkPress={props.onLinkPress}
                                content={convertSourcesToMarkdown(
                                    props.importantSources
                                )}
                                transformLinkUri={props.transformLinkUri}
                            />
                        ) : null}
                    </>
                ) : null}
            </Card>
        )
    }
)

const useLocalStyles = makeStyles()({
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        width: '100%',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    localInfoHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        paddingTop: '1rem',
    },
    creatorInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.5rem',
        whiteSpace: 'normal',
        paddingBottom: '1rem',
        width: 'fit-content',
        borderBottom: `1px ${colors.grays.CUMULUS} solid`,
        boxSizing: 'border-box',
    },
    creatorLogo: {
        height: '3rem',
        width: 'auto',
    },
    relevantSourcesHeader: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
})
