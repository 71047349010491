import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoClose } from 'react-icons/io5'
import { Markdown } from '../atoms'

interface PrepCheckQuestionExplainerProps {
    content: string
    open: boolean
    onClose: React.MouseEventHandler
}

export const PrepCheckQuestionExplainer: FC<PrepCheckQuestionExplainerProps> = (
    props: PrepCheckQuestionExplainerProps
) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle className={localClasses.header} sx={{ pb: 0 }}>
                <IconButton onClick={props.onClose}>
                    <IoClose />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Markdown content={props.content} />
            </DialogContent>
        </Dialog>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        textAlign: 'right',
    },
})
