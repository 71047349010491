import { colors, Image } from '@hazadapt-git/public-core-base'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { FC } from 'react'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'

import JourneyCompletionCheck from '../../assets/icons/JourneyCompletionCheck.svg'
import PhaseStar from '../../assets/icons/PhaseStar.svg'
import Shield from '../../assets/icons/PrepCheckShield.svg'
import Translate from '../../assets/icons/Translate-Filled-WP.svg'
import MyEmergencyItems from '../../assets/icons/MyEmergencyItems.svg'
import { PrepCheckDataPill } from '../molecules'
import { Markdown } from '../atoms'
import { useWindowSizeUp } from '../../lib/utils'

interface PrepCheckLandingBannerProps {
    journeyPhase: string
    journeyPhaseIcon: Image
    journeyPhaseSubcopy: string
    prepChecksCompleted: number
    totalPrepChecks: number
    onJourneyPhaseClick?: React.MouseEventHandler
    onJourneyCompletionClick?: React.MouseEventHandler
    onMyEmergencyItemsClick?: React.MouseEventHandler
    showJourneyPhase: boolean
    onTranslateClick?: React.MouseEventHandler
    loading?: boolean
}

export const PrepCheckLandingBanner: FC<PrepCheckLandingBannerProps> = (
    props: PrepCheckLandingBannerProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const largeWindowOrLarger = useWindowSizeUp('lg')

    const [open, setOpen] = React.useState<boolean>(false)

    const toggleOpen: React.MouseEventHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(!open)
    }

    const subcopy = (
        <Markdown
            content={`How well would you cope with this hazard?

Check your preparedness and learn where to strengthen.
            `}
            containerClassName={localClasses.prepCheckPhraseContainer}
            className={localClasses.prepCheckPhrase}
            styleOverrides={{
                p: { marginBlock: '0.25rem' },
            }}
        />
    )

    const dataPills = (
        <div className={localClasses.dataPills}>
            <PrepCheckDataPill
                icon={<img src={PhaseStar} alt="Journey phase star icon" />}
                label={props.journeyPhase}
                onClick={props.onJourneyPhaseClick}
            />
            <PrepCheckDataPill
                icon={
                    <img
                        src={JourneyCompletionCheck}
                        alt="Prep check completion checkmark icon"
                    />
                }
                label={`${props.prepChecksCompleted}/${props.totalPrepChecks}`}
                onClick={props.onJourneyCompletionClick}
            />
            <PrepCheckDataPill
                icon={
                    <img src={MyEmergencyItems} alt="My emergency items icon" />
                }
                label="My Items"
                onClick={props.onMyEmergencyItemsClick}
            />
        </div>
    )

    return (
        <button
            className={localClasses.banner}
            style={{
                flexDirection: largeWindowOrLarger ? 'row' : 'column',
                cursor: largeWindowOrLarger ? 'auto' : 'pointer',
            }}
            onClick={toggleOpen}
        >
            <div
                className={
                    largeWindowOrLarger
                        ? localClasses.desktopPrepCheckDetails
                        : localClasses.mwPrepCheckDetails
                }
            >
                <div className={localClasses.titleContainer}>
                    <img
                        src={Shield}
                        alt={`Prep checks icon`}
                        className={
                            mediumWindowOrLarger
                                ? localClasses.mediumPrepCheckIcon
                                : localClasses.smallPrepCheckIcon
                        }
                    />
                    <div
                        className={classNames(
                            localClasses.prepCheckText,
                            largeWindowOrLarger
                                ? undefined
                                : localClasses.tightPrepCheckText
                        )}
                    >
                        <div className={localClasses.title}>
                            <Typography
                                variant={largeWindowOrLarger ? 'h2' : 'h3'}
                                sx={{ color: colors.grays.BLANC }}
                                pr={mediumWindowOrLarger ? '1rem' : 0}
                            >
                                Prep Checks
                            </Typography>
                            {!props.loading && (
                                <IconButton onClick={props.onTranslateClick}>
                                    <img
                                        src={Translate}
                                        className={localClasses.translateIcon}
                                        alt="Translate"
                                    />
                                </IconButton>
                            )}
                        </div>
                        {mediumWindowOrLarger || open ? subcopy : null}
                    </div>
                </div>
                {!largeWindowOrLarger && props.showJourneyPhase && (
                    <div className={localClasses.journeyPhasePreviewWrapper}>
                        <div
                            className={localClasses.journeyPhasePreview}
                            style={{
                                flexDirection: smallWindowOrLarger
                                    ? 'row'
                                    : 'column',
                            }}
                        >
                            <img
                                src={
                                    props.journeyPhaseIcon.url ||
                                    props.journeyPhaseIcon.src
                                }
                                alt={
                                    props.journeyPhaseIcon.alt ||
                                    `${props.journeyPhase} icon`
                                }
                                className={localClasses.mwJourneyPhaseIcon}
                            />
                            <Typography
                                sx={{
                                    color: colors.grays.BLANC,
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    textAlign: 'center',
                                }}
                                fontWeight={500}
                            >
                                {props.journeyPhase}
                            </Typography>
                        </div>
                        <IconButton
                            onClick={toggleOpen}
                            sx={{
                                width: '3rem',
                                height: '3rem',
                            }}
                        >
                            {open ? (
                                <IoChevronUp color={colors.grays.BLANC} />
                            ) : (
                                <IoChevronDown color={colors.grays.BLANC} />
                            )}
                        </IconButton>
                    </div>
                )}
                {/* {(!largeWindowOrLarger && open) ? subcopy : null } */}
            </div>
            {(largeWindowOrLarger || open) && props.showJourneyPhase && (
                <div className={localClasses.journey}>
                    <div className={localClasses.dataPillDetails}>
                        <img
                            src={
                                props.journeyPhaseIcon.url ||
                                props.journeyPhaseIcon.src
                            }
                            alt={
                                props.journeyPhaseIcon.alt ||
                                `${props.journeyPhase} icon`
                            }
                            className={
                                largeWindowOrLarger
                                    ? localClasses.desktopJourneyPhaseIcon
                                    : localClasses.mwJourneyPhaseIcon
                            }
                        />
                        <div className={localClasses.dataPillObjects}>
                            <Typography
                                variant="h3"
                                sx={{
                                    color: colors.grays.BLANC,
                                    width: '100%',
                                    wordWrap: 'break-word',
                                }}
                            >
                                Preparedness Phase: {props.journeyPhase}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ color: colors.grays.BLANC }}
                            >
                                {props.journeyPhaseSubcopy}
                            </Typography>
                            {largeWindowOrLarger ? dataPills : null}
                        </div>
                    </div>
                    {!largeWindowOrLarger ? dataPills : null}
                </div>
            )}
        </button>
    )
}

const useLocalStyles = makeStyles()({
    banner: {
        padding: '1rem 1.5rem',
        backgroundColor: colors.primary.HELIOTROPE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '3rem',
        rowGap: '1rem',
        border: 'none',
        margin: 0,
        width: '100vw',
        textAlign: 'left',
        fontFamily: 'inherit',
    },
    desktopPrepCheckDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
        width: '100%',
    },
    mwPrepCheckDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        width: '100%',
    },
    desktopPrepCheckIcon: {
        width: '6rem',
        height: 'auto',
    },
    mediumPrepCheckIcon: {
        width: '3.5rem',
        height: 'auto',
    },
    smallPrepCheckIcon: {
        width: '2rem',
        height: 'auto',
    },
    mwPrepCheckIcon: {
        width: '3rem',
        height: 'auto',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    prepCheckText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '0.8rem',
    },
    tightPrepCheckText: {
        gap: 0,
    },
    translateIcon: {
        width: '2rem',
        height: 'auto',
    },
    prepCheckPhraseContainer: {
        gridColumn: '1 / -1',
    },
    prepCheckPhrase: {
        color: colors.grays.BLANC,
    },
    desktopJourneyPhaseIcon: {
        width: '8rem',
        height: 'auto',
    },
    mwJourneyPhaseIcon: {
        width: '5rem',
        height: 'auto',
    },
    dataPillDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1rem',
        width: '100%',
    },
    dataPillObjects: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.5rem',
        flex: 1,
        width: `calc(100% - 6.5rem)`,
        flexWrap: 'wrap',
    },
    dataPills: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
    },
    journey: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    journeyPhasePreviewWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '1rem',
    },
    journeyPhasePreview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
})
